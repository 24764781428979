<template>
    <div class="z-40 fixed w-full h-[105%] -mt-2 bg-[#252526] top-0">

    </div>
    <ExitConfirmForm 
        v-if="isExitConfirmFormVisible" 
        @doSave="processAddStudentsToGroup" 
        @dontSave="$emit('close')"
        @returnToCurrent="isExitConfirmFormVisible = false"
        :alertText="'Бажаєте зберегти состав групи ' + group.name + '?'"
        class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[110px] z-50 border-[1px] border-[#FFF] border-opacity-10 w-[300px]"
    />
    <div 
        v-if="!isExitConfirmFormVisible" 
        class="absolute top-[50%] left-[50%] items-center translate-x-[-50%] h-[730px] translate-y-[-50%] z-50"
        :class="{
            'ml-[110px]' : screenWidth > 1610,
            'ml-[140px]' : screenWidth < 1610 && screenWidth > 1410,
            'scale-[0.9] ml-[130px]' : screenWidth < 1410,
        }"    
    >
    
        <div class="border-b-[1px] w-[98%] mx-auto mb-[40px] border-white text-white text-end">
            Гравців у групі — <span class="font-bold text-[25px]">{{ studentsWithCurrentGroup.length }}</span>
        </div> 
        <div class="flex items-center">
            <div class="mr-2 overflow-hidden rounded-[10px] w-[537px] bg-[#252526]">
                <div class="w-[537px] mx-auto max-h-[579px] flex flex-col rounded-[10px]">
                    <div class="flex ml-[10px] mb-[10px] w-[40%] border-[1px] border-[#FFF] border-opacity-10 pl-[10px] mt-[12px] rounded-[6px] ">
                        <img src="@/assets/icons/magnify_icon.svg" class="w-4 opacity-60 mr-2">
                        <input v-model="searchInput" @change="addFilter" type="text" placeholder="Пошук..." class="outline-none text-[#FFF] placeholder:text-[#FFF] placeholder:opacity-50 py-[4px] w-[60%] bg-transparent">
                    </div>
                    <div class="select-none w-full pr-[10px] border-b-[1px] border-[#FFF] border-opacity-10">
                        <div class="text-white flex">
                            <div class="px-3 py-2 text-start font-medium text-sm w-[13%]"></div>
                            <div class="px-3 py-2 text-start font-medium text-sm w-[34%]">Учень</div>
                            <div class="px-3 py-2 text-start font-medium text-sm w-[23%]">
                                <div @click="showSelectGroupFlag = true" class="cursor-pointer">
                                    Група
                                </div>
                                <!-- <div @click="showSelectGroupFlag = false" v-if="showSelectGroupFlag" class="absolute w-full z-10 h-full top-0 left-0"></div>
                                <div v-if="showSelectGroupFlag" class="bg-white px-1 py-2 z-20 absolute flex translate-x-[-70px] mt-[9px] border-[1px] rounded-b-[5px]">
                                    <div class="flex items-center align-middle mr-[10px]">
                                        <span>з групою</span>
                                        <input type="checkbox" v-model="withGroupFilterFlag" @change="addFilter" class="mt-[5px] ml-[3px] cursor-pointer">
                                    </div>
                                    <div class="flex items-center align-middle">
                                        <span>без групи</span>
                                        <input type="checkbox" v-model="withoutGroupFilterFlag" @change="addFilter" class="mt-[5px] ml-[3px] cursor-pointer">
                                    </div>
                                </div> -->
    
                            </div>
                            <div class="py-2 text-start font-medium text-sm w-[20%] relative">
                                <div @click="showAgeRangeFilter = true">
                                    Вік
                                </div>
                                <div v-if="showAgeRangeFilter" @click="showAgeRangeFilter = false" class="absolute w-full z-10 h-full top-0 left-0"></div>
                                <div v-if="showAgeRangeFilter" class="bg-[#252526] px-1 py-2 z-20 absolute flex translate-x-[-40%] mt-[9px] border-[1px] rounded-b-[5px]">
                                    <div class="flex items-center align-middle mr-[10px]">
                                        <span>від:</span>
                                        <input type="number" v-model="startAgeRange" @change="addFilter" class="ml-[3px] w-[6ch] bg-transparent cursor-pointer outline-none">
                                    </div>
                                    <div class="flex items-center align-middle">
                                        <span>до:</span>
                                        <input type="number" v-model="endAgeRange" @change="addFilter" class=" ml-[3px] w-[6ch] bg-transparent cursor-pointer outline-none">
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 py-2 text-start font-medium text-sm w-[10%]"></div>
                        </div>
                    </div>
                    <div class="select-none block h-[480px] pr-[10px] scrollbar" :class="{
                        'overflow-y-auto' : showStudents.length > 0,
                    }">
                        <div v-if="showStudents.length > 0" v-for="student in showStudents" class="relative w-full flex items-center border-b-[1px] border-[#FFF] border-opacity-10 pb-[7.5px]">
                            <div class="px-2 py-2 w-[13%] flex justify-center items-center">
                                <div class="w-9 h-9 rounded-full border-[#FFF] border-[1px] relative top-[3px] overflow-hidden">
                                    <img v-if="student.photo" :src="apiURL + 'static/' + student.photo" alt="">
                                    <div v-else class="text-[#FFF] bg-[#FFF] bg-opacity-20 text-[12px] h-full w-full flex justify-center items-center">
                                        {{ student.name[0] + student.surname[0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="px-2 pt-2 w-[34%] text-white truncate text-start font-medium text-sm">{{ student.name + ' ' + student.surname}}</div>
                            <div class="px-4 pt-2 w-[23%] text-white text-start font-medium text-sm">{{ student.group_name ? student.group_name : '—' }}</div>
                            <div class="px-2 pt-2 w-[20%] text-white text-start font-medium text-sm">{{ student.age }}</div>
                            <div class="px-3 pt-2 w-[10%] text-white text-start font-medium text-sm">
                                <div class="flex justify-end">
                                    <img @click="addStudentToGroup(student)" src="@/assets/icons/circle_plus_icon.svg" class="w-7 cursor-pointer">
                                </div>
                            </div>
                        </div>
                        <div v-else class="h-[500px]">
    
                        </div>
                    </div>
                </div>
                <div class="flex mt-[15px] px-2 w-[537px]">  
                    <div class="flex items-center mx-auto">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px] text-[#FFF]">Сторінка: <input v-model="currentPage" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                </div>
            </div>
            <div class="w-[1px] mr-[40px] ml-[40px] h-[49.4vh] mt-[57px] bg-white">
    
            </div>
            <div class="mr-2 rounded-[10px] overflow-hidden h-[610px] relative bg-[#252526]">
                <div class="bg-[#BD9135] text-[#252526] mb-[11px] w-full flex justify-between px-[25px] mx-auto text-center font-bold text-[20px] rounded-[6px] mt-[12px]">
                    <div>{{ group.name }}</div> 
                    <div>{{ trainer.name + ' ' + trainer.surname}}</div> 
                </div>
                <div class="w-[427px] rounded-[10px]">
                    <div class="select-none">
                        <div class="text-white border-b-[1px] border-[#FFF] border-opacity-10 ">
                            <div class="px-3 py-2 text-center font-medium text-sm" colspan="3">Учні в групі</div>
                        </div>
                    </div>
                    <div class="overflow-y-auto h-[480px] select-none scrollbar">
                        <div v-for="student in studentsWithCurrentGroup" class="text-white border-b-[1px] border-[#FFF] border-opacity-10 flex">
                            <div class="pl-2 py-1 text-center font-medium text-sm w-[17%]">
                                <div class="w-9 h-9 rounded-full overflow-hidden border-[1px] relative top-[8px] border-[#FFF]">
                                    <img v-if="student.photo" :src="apiURL + 'static/' + student.photo">
                                    <div v-else class="text-[#FFF] bg-[#FFF] bg-opacity-20 text-[12px] h-full w-full flex justify-center items-center">
                                        {{ student.name[0] + student.surname[0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="pl-1 py-2 text-start font-medium flex items-center text-sm w-[63%]">{{ student.surname + ' ' + student.name[0] + '.' }}</div>
                            <div class="px-3 py-2 text-start font-medium flex items-center justify-end text-sm w-[20%]">
                                <div @click="removeStudentFromGroup(student)" class="flex py-2 justify-end cursor-pointer">
                                    <img src="@/assets/icons/circle_minus_icon.svg" class="w-7 cursor-pointer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="isExitConfirmFormVisible = true" class="bg-[#DA4167] text-center text-white px-2 py-[2px] w-[228px] rounded-[6px] absolute bottom-0 left-[50%] translate-x-[-50%]">Закрити</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'
    import { getAllStudents, addGroupToStudents, getAllStudentsListInGroup, getStudentsCount } from '@/services/apiRequests'
    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'

    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${day}.${month}.${year}`
    }

    export default {
        name: 'select-students-form',

        props: {
            group: {
                type: Object,
                required: true
            },
            trainer: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const studentsWithCurrentGroup = ref([])
            const allStudents = ref([])
            const showStudents = ref([])
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const screenWidth = ref(window.innerWidth)

            const withGroupFilterFlag = ref(true)
            const withoutGroupFilterFlag = ref(true)
            const showSelectGroupFlag = ref(false)

            const startAgeRange = ref(undefined)
            const endAgeRange = ref(undefined)
            const showAgeRangeFilter = ref(false)

            const isExitConfirmFormVisible = ref(false)

            let studentsWithCurrentGroupBuffer = []

            const searchInput = ref('')
            const currentPage = ref(1)
            const maxPage = ref(1)

            const addFilter = () => {
                if (withGroupFilterFlag.value && withoutGroupFilterFlag.value) {
                    showStudents.value = allStudents.value.filter(item => !studentsWithCurrentGroup.value.includes(item))
                } else if (withGroupFilterFlag.value) {
                    showStudents.value = allStudents.value.filter(item => item.group_name !== '').filter(item => !studentsWithCurrentGroup.value.includes(item))
                } else if (withoutGroupFilterFlag.value) {
                    showStudents.value = allStudents.value.filter(item => item.group_name === '').filter(item => !studentsWithCurrentGroup.value.includes(item))
                } else if (!withGroupFilterFlag.value && !withoutGroupFilterFlag.value) {
                    showStudents.value = []
                }

                if (startAgeRange.value != undefined && endAgeRange.value != undefined) {
                    showStudents.value = showStudents.value.filter(item => item.age >= startAgeRange.value && item.age <= endAgeRange.value)
                } else if (startAgeRange.value != undefined) {
                    showStudents.value = showStudents.value.filter(item => item.age >= startAgeRange.value)
                } else if (endAgeRange.value != undefined) {
                    showStudents.value = showStudents.value.filter(item => item.age <= endAgeRange.value)
                } else {
                    showStudents.value = showStudents.value
                }

                showStudents.value = showStudents.value.filter(item => {
                    let buffer = item.name.toUpperCase() + item.surname.toUpperCase()
                    return buffer.includes(searchInput.value.toUpperCase())
                })
            }

            // watch(() => searchInput.value, () => {
            //     addFilter()
            // })

            watch(startAgeRange, async () => {
                // addFilter()
                await updateStudents()
            })

            watch(endAgeRange, async () => {
                // addFilter()
                await updateStudents()
            })

            const formattedDate = (date) => formateDate(date)

            const addStudentToGroup = (student) => {
                showStudents.value = showStudents.value.filter(item => item.student_id !== student.student_id)
                allStudents.value = allStudents.value.filter(item => item.student_id !== student.student_id)
                studentsWithCurrentGroup.value.push(student)
            }

            const removeStudentFromGroup = (student) => {
                studentsWithCurrentGroup.value = studentsWithCurrentGroup.value.filter(item => item.student_id !== student.student_id)
                allStudents.value.push(student)
                // addFilter()
            }

            const processAddStudentsToGroup = async () => {
                let studentsIds = []
                
                let deletedStudents = studentsWithCurrentGroupBuffer.filter(item => !studentsWithCurrentGroup.value.includes(item))
                let deletedStudentsIds = []

                deletedStudents.forEach(item => {
                    deletedStudentsIds.push(item.student_id)
                })

                studentsWithCurrentGroup.value.forEach(item => {
                    studentsIds.push(item.student_id)
                })

                const groupName = props.group.name
                const groupId = props.group.group_id

                await addGroupToStudents(studentsIds, groupId, groupName)
                await addGroupToStudents(deletedStudentsIds, '', '')

                emit('close')
            }

            const prevPage = () => {
                currentPage.value > 1 ? currentPage.value-- : currentPage.value = 1
            }

            const nextPage = () => {
                currentPage.value += 1
            }

            watch(() => currentPage.value, async () => {
                if (currentPage.value >= maxPage.value) {
                    currentPage.value = maxPage.value
                } else if (currentPage.value <= 1 && currentPage.value != '') {
                    currentPage.value = 1
                }
                await updateStudents()
            })

            const updateStudents = async () => {
                const allStudentsResponse = await getAllStudents(20, (currentPage.value - 1) * 20, null, searchInput.value, startAgeRange.value, endAgeRange.value)
                // studentsWithCurrentGroup.value = allStudentsResponse.data.filter(item => item.group_id === props.group.group_id)
                allStudents.value = allStudentsResponse.data.filter(item => item.group_id !== props.group.group_id)
                // studentsWithCurrentGroupBuffer = studentsWithCurrentGroup.value
                showStudents.value = allStudents.value.filter(item => !studentsWithCurrentGroup.value.includes(item))
                // addFilter()
            }

            onMounted(async () => {
                const allStudentsResponse = await getAllStudents(20, (currentPage.value - 1) * 20)
                const allUsersCount = await getStudentsCount()
                maxPage.value = Math.floor(allUsersCount / 20)
                // studentsWithCurrentGroup.value = allStudentsResponse.data.filter(item => item.group_id === props.group.group_id)
                studentsWithCurrentGroup.value = await getAllStudentsListInGroup(props.group.group_id)
                allStudents.value = allStudentsResponse.data.filter(item => item.group_id !== props.group.group_id)
                studentsWithCurrentGroupBuffer = studentsWithCurrentGroup.value
                showStudents.value = allStudents.value.filter(item => !studentsWithCurrentGroup.value.includes(item))
                // addFilter()
                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })
            })

            watch(searchInput, () => {
                updateStudents()
                currentPage.value = 1
            })

            return {
                studentsWithCurrentGroup,
                allStudents,
                apiURL,
                formattedDate,
                addStudentToGroup,
                removeStudentFromGroup,
                withGroupFilterFlag,
                withoutGroupFilterFlag,
                showSelectGroupFlag,
                showStudents,
                startAgeRange,
                endAgeRange,
                showAgeRangeFilter,
                addFilter,
                searchInput,
                processAddStudentsToGroup,
                currentPage,
                prevPage,
                nextPage,
                maxPage,
                isExitConfirmFormVisible,
                screenWidth
            }
        },

        components: {
            ExitConfirmForm
        }
    }
</script>

<style lang="scss" scoped>

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #FFF;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


</style>