<template>
    <div class="bg-[#252526] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>