<template>
    <div class="chart-container">
      <canvas ref="canvas" class="" :height="225" :width="850"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BarChart',
  
    props: {
      data: {
        type: Array,
        required: true
      },
      barColor: {
        type: String,
        default: '#00bfff'
      },
      lables: {
        type: Array,
        required: true
      },
      height: {
        type: Number,
        default: 167
      },
      width: {
        type: Number,
        default: 790
      }
    },
  
    mounted() {
      this.drawChart();
    },

    updated() {
      this.drawChart();
    },
  
    methods: {
      formatNumber(number) {
        const parts = number.toFixed(2).toString().split('.');
        const integerPart = parts[0];
        const decimalPart = parts[1];

        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        const formattedNumber = formattedInteger + '.' + decimalPart;

        return formattedNumber;
      },

      drawChart() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        const height = canvas.height;
        const width = canvas.width;
        const max = Math.max(...this.data) > 0 ? Math.max(...this.data) : 5;
        const barWidth = 36 //width / (this.data.length * 2);
        const spacing = 0;
        const marginBottom = 25;
        const marginTop = 45;
        const barMaxHeight = height - (marginBottom + marginTop);

        ctx.beginPath();
        this.data.forEach((bar, i) => {
            const buff = bar;

            const currentBarPercentFromFull = (100 * bar) / max;

            const currentBlockFullHeight = bar > 0 ? (barMaxHeight * currentBarPercentFromFull) / 100 : 1;

            ctx.fillStyle = this.barColor;
            ctx.fillRect(
                (i * barWidth * 1.99) + spacing,
                height - currentBlockFullHeight - marginBottom,
                barWidth,
                currentBlockFullHeight
            );

            ctx.fillStyle = '#A1D9F7';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            let labelX = ((i * barWidth * 1.99) + spacing) + (barWidth / 2);
            let labelY = height ;
            let labelText = this.lables[i];
            ctx.fillText(labelText, labelX, labelY - marginBottom / 2);

            ctx.fillStyle = '#DA4167';
            ctx.font = 'bold 12px Arial';
            ctx.textAlign = 'center';
            labelX = ((i * barWidth * 1.99) + spacing) + (barWidth / 2);
            labelY = height - currentBlockFullHeight - marginBottom - 5;
            labelText = this.formatNumber(buff);
            ctx.fillText(labelText, labelX, labelY);
            
        });
        ctx.stroke();

        
      }
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    width: 100%;
    height: 100%;
  }
  </style>
  