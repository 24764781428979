<template>
    <div v-if="!showMediaFlag" class="mx-auto mt-[-55px] z-0 flex justify-between">
        <div class="w-[40%]">
            <div><b class="text-[#BD9135]">Тип елемента:</b> <span class="text-[#fff]">{{element.name}}</span></div>
            <div><b class="text-[#BD9135]">Назва вправи:</b> <span class="text-[#fff]">{{parseElementType(element.type)}}</span></div>
            <div><b class="text-[#BD9135]">Вікова група:</b> <span class="text-[#fff]">{{parseAgeGroup(element.training_program_years)}}</span></div>
            <div class="mt-[50px] text-[#BD9135] flex items-center">
                <b class="mr-[3px]">Час виконання (хв.):</b>
                <input :value="element.time" @change="e => $emit('changeTime', e.target.value)" class="bg-[#1b1b1b] outline-none text-white text-[14px] w-[150px] py-[5px] px-[5px] rounded-[6px]" placeholder="Введіть час">
            </div>
            <div class="mt-[50px] text-[#BD9135]"><b>Опис елементу:</b></div>
            <div class="text-[#fff]">
                {{element.info}}
            </div>
        </div>
        <div class="w-[40%]">
            <div v-if="element.additional_images.length > 0">
                <div class="font-bold text-[#BD9135]">Ілюстрації</div>
                <div class="flex mt-[15px]">
                    <!-- <img v-for="picture in element.additional_images" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" /> -->

                    <carousel :per-page="1">
                        <slide v-for="picture in element.additional_images" :key="picture">
                            <img class="mr-[10px] cursor-pointer w-[250px]" :src="apiURL + 'static/' + picture" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div v-if="element.schemas.length > 0" class="mt-[35px]">
                <div class="font-bold text-[#BD9135]">Схеми</div>
                <div class="flex mt-[15px]">
                    <!-- <img v-for="schema in element.schemas" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" /> -->

                    <carousel :per-page="1">
                        <slide v-for="schema in element.schemas" :key="schema">
                            <img class="mr-[10px] cursor-pointer w-[250px]" :src="apiURL + 'static/' + schema" />
                        </slide>
                    </carousel>
                </div>
            </div>
            <div v-if="element.additional_videos.length > 0" class="mt-[35px]">
                <div class="font-bold text-[#BD9135]">Відео</div>
                <div class="flex mt-[15px]">
                    <img v-for="video in element.additional_videos" @click="() => {videoToShow = video; showMediaFlag = true}" class="mr-[10px] cursor-pointer" src="@/assets/icons/video_icon.svg" />
                </div>
            </div>
        </div>
    </div>

    <div v-if="showMediaFlag" class="w-full flex flex-col items-center justify-center">
        <YouTube 
            v-if="videoToShow"
            :src="videoToShow" 
            class="w-full"
            ref="youtube" 
        />
        <div @click="() => {showMediaFlag = false; videoToShow = ''}" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[20%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
        
    </div>

    <div v-if="!showMediaFlag" class="w-[100%] flex flex-col items-center justify-center mt-[50px]">
        <div @click="$emit('back')" class="bg-[#BD9135] text-[#252526] cursor-pointer mt-[20px] border-[1px] text-center border-[#BD9135] text-[18px] w-[20%] py-[3px] px-[5px] rounded-[6px]">Назад</div>
    </div>
</template>

<script>
    import { ref } from "vue";
    import YouTube from 'vue3-youtube';
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'


    export default {
        name: "ElementView",

        props: {
            element: {
                type: Object,
                default: () => {}
            }
        },

        setup() {
            const videoToShow = ref('')
            const showMediaFlag = ref(false)

            const apiURL = process.env.VUE_APP_API_URL

            const parseElementType = (type) => {
                switch (type) {
                    case 'standard':
                        return 'Норматив'
                    case 'exercise':
                        return 'Вправа'
                    case 'task':
                        return 'Завдання'
                    default:
                        return 'Невідомий тип елементу'
                }
            }

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            const parseScoring = (scoring) => {
                switch (scoring) {
                    case 'time':
                        return 'Час'
                    case 'point':
                        return 'Оцінка'
                    case 'length':
                        return 'Відстань'
                    default:
                        return '-'
                }
            }

            return {
                parseElementType,
                parseAgeGroup,
                parseScoring,
                videoToShow,
                showMediaFlag,
                apiURL
            }
        },

        components: {
            YouTube,
            Carousel,
            Slide
        }
    }
</script>

<style lang="scss" scoped>

</style>