<template>
    <div class="px-[30px]">
        <full-training-view 
            v-if="showFullTrainingFlag" 
            @updateTrainings="updateTrainings" 
            @close="showFullTrainingFlag = false; fullDayViewFlag = true" 
            :training="trainingToView"
        />
        <new-training-form 
            v-if="newTrainingFormFlag" 
            @updateTrainings="updateTrainings" 
            @close="newTrainingFormFlag = false" 
            :date="currentNewTrainingDate"
        />
        <full-day-view 
            v-if="fullDayViewFlag" 
            @setTrainingToView="training => { trainingToView = training;  showFullTrainingFlag = true; fullDayViewFlag = false}" 
            @addTraining="$event => {newTrainingFormFlag = true; currentNewTrainingDate = $event}" 
            @deleteTraining="deleteEvent($event)" 
            @close="fullDayViewFlag = false" :day="fullDayViewDay"
        />
        <div class="mt-[-22px] z-[100] absolute left-[50%] translate-x-[-50%] ml-[130px] top-[70px]">
            <div class="flex justify-center items-center select-none">
                <div>
                    <img @click="prevMonth" src="@/assets/icons/left_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
                <div class="mx-2 w-[150px] text-center font-semibold text-[14px] text-[#FFF] relative top-[2px]">
                    {{ currentMonthParsed.toUpperCase() }} {{ currentYear }}
                </div>
                <div>
                    <img @click="nextMonth" src="@/assets/icons/right_arrow.svg" class="cursor-pointer h-[14px]">
                </div>
            </div>
        </div>
        
        <div 
            class="h-full overflow-x-auto"
            :class="{
                'w-full' : screenWidth > 1410,
                'w-[80vw]' : screenWidth <= 1410 && screenWidth > 1300,
                'w-[90vw]' : screenWidth <= 1300,
            }"    
        >
            <div class="w-[1190px] mx-auto  flex justify-center">
                <CalenarComponent 
                    :days = "calendarParsedDays"
                    :unparsedDays = "calendarUnparsedDays"
                    @viewFull="viewFull($event)"
                    @addTraining="addTraining($event)"
                    class="w-[1190px] overflow-x-auto"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {ref, onBeforeMount } from 'vue'
    import { getAllTrainings, getAllTrainerMeetUps } from '@/services/apiRequests'
    import CalenarComponent from './components/CalenarComponent.vue'
    import NewTrainingForm from './components/NewTrainingForm.vue'
    import FullDayView from './components/FullDayView.vue'
    import FullTrainingView from './components/FullTrainingView.vue'

    export default {
        name: 'calendar',

        setup() {
            const currentDate = new Date()
            const currentYear = ref(currentDate.getFullYear())
            const currentMonth = ref(currentDate.getMonth())
            const currentMonthParsed = ref(currentDate.toLocaleString('uk-UA', { month: 'long' }))
            const calendarParsedDays = ref([])
            const calendarUnparsedDays = ref([])
            const newTrainingFormFlag = ref(false)
            const currentNewTrainingDate = ref(null)
            const allTrainings = ref([])
            const trainingToView = ref({})
            const showFullTrainingFlag = ref(false)

            const fullDayViewFlag = ref(false)
            const fullDayViewDay = ref(null)

            const viewFull = (day) => {
                fullDayViewFlag.value = true
                fullDayViewDay.value = day
            }

            const getWeekDayId = (dayName) => {
                switch (dayName) {
                    case 'пн':
                        return 1
                    case 'вт':
                        return 2
                    case 'ср':
                        return 3
                    case 'чт':
                        return 4
                    case 'пт':
                        return 5
                    case 'сб':
                        return 6
                    case 'нд':
                        return 7
                }
            }

            const getDays = (allTrainings) => {
                const lastDayInMonth = new Date(currentYear.value, currentMonth.value + 1, 0).getDate()
                
                const days = []

                for (let i = 1; i <= lastDayInMonth; i++) {
                    const currentDayDate = new Date(currentYear.value, currentMonth.value, i)
                    const dayName = currentDayDate.toLocaleString('uk-UA', { weekday: 'short' })
                    days.push({
                        dayName: dayName,
                        weekDayId: getWeekDayId(dayName),
                        dayNumber: i,
                        dateObj: currentDayDate,
                        trainings: allTrainings?.filter(training => new Date(training.date).toISOString().split('T')[0] === currentDayDate.toISOString().split('T')[0])
                    })
                }

                return days
            }

            const parseWeeksToCalendar = (allTrainings) => {
                const calendarDaysFull = new Array(42).fill(null)
                const currentMonthDays = getDays(allTrainings)
                let startMonthDay = 30 - (currentMonthDays[0].weekDayId - 2) 
                for (let i = 1; i <= 6; i++) {
                    for (let j = 1; j <= 7; j++) {
                        const currentDay = currentMonthDays[0]
                        if (currentDay?.weekDayId == j) {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = currentDay
                            if (currentDay.dayNumber == new Date().getDate() && currentDay.dateObj.getMonth() == new Date().getMonth() && currentDay.dateObj.getFullYear() == new Date().getFullYear()) {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = true
                            } else {
                                calendarDaysFull[(i - 1) * 7 + j - 1].is_current = false
                            }
                            currentMonthDays.shift()
                            startMonthDay = 1
                        } else {
                            calendarDaysFull[(i - 1) * 7 + j - 1] = {
                                type: 'empty',
                                month_day: startMonthDay
                            }
                            startMonthDay++
                        }
                    }
                }

                return calendarDaysFull
            }

            const nextMonth = () => {
                if (currentMonth.value == 11) {
                    currentMonth.value = 0
                    currentYear.value++
                } else {
                    currentMonth.value++
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)
            }

            const prevMonth = () => {
                if (currentMonth.value == 0) {
                    currentMonth.value = 11
                    currentYear.value--
                } else {
                    currentMonth.value--
                }
                currentMonthParsed.value = new Date(currentYear.value, currentMonth.value, 1).toLocaleString('uk-UA', { month: 'long' })
                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)
            }

            const addTraining = (date) => {
                newTrainingFormFlag.value = true
                currentNewTrainingDate.value = date.dateObj
            }

            const updateTrainings = async () => {
                const trainingsRes = await getAllTrainings()
                const all_meet_ups = await getAllTrainerMeetUps()

                allTrainings.value = trainingsRes.data
                allTrainings.value.push(...all_meet_ups)
                calendarParsedDays.value = parseWeeksToCalendar(allTrainings.value)
                calendarUnparsedDays.value = getDays(allTrainings.value)

                fullDayViewDay.value?.trainings.forEach((training, id) => {
                    const currentTraining = trainingsRes.data.find(t => t.training_id === training.training_id)
                    if (currentTraining) {
                        fullDayViewDay.value.trainings[id] = currentTraining
                    }
                })

                trainingsRes.data.forEach((training, id) => {
                    if (new Date(training.date).toISOString().split('T')[0] === fullDayViewDay.value?.dateObj.toISOString().split('T')[0] && !fullDayViewDay.value.trainings.find(t => t.training_id === training.training_id)) {
                        fullDayViewDay.value.trainings.push(training)
                    }
                })
            }

            const deleteEvent = async (id) => {
                fullDayViewDay.value.trainings = fullDayViewDay.value.trainings.filter(t => (t.training_id && t.training_id !== id) || (t.meet_up_id && t.meet_up_id !== id)); 
                await updateTrainings()
            } 

            onBeforeMount(async () => {
                await updateTrainings()
            })

            return {
                calendarParsedDays,
                currentMonthParsed,
                currentYear,
                nextMonth,
                prevMonth,
                calendarUnparsedDays,
                addTraining,
                newTrainingFormFlag,
                currentNewTrainingDate,
                updateTrainings,
                fullDayViewFlag,
                fullDayViewDay,
                viewFull,
                trainingToView,
                showFullTrainingFlag,
                deleteEvent
            }
        },

        components: {
            CalenarComponent,
            NewTrainingForm,
            FullDayView,
            FullTrainingView
        }
    }
</script>
