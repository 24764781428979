<template>
    <div class="container">
        <div class="title">{{ title }}</div>
        <div v-if="loading" class="skeletor"></div>
        <div v-else>
            <div v-if="type == 'finance'" class="finance-value"><span class="sign">₴</span>{{ value.toFixed(2).toLocaleString() }}</div>
            <div v-else class="poor-value">{{ value }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StaticAndFinanceBlock',

        props: {
            value: {
                type: Number,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            loading: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        @apply bg-[#47484966] border-[#D9D9D9] border-[1px] border-opacity-10 text-center px-[10px] py-[5px] rounded-[10px];
    }

    .title {
        @apply text-[#BD9135];
    }

    .skeletor {
        @apply rounded-full bg-gray-400 animate-pulse w-[50%] mx-auto my-[7px];
    }

    .finance-value {
        @apply font-bold text-white;
    }

    .poor-value {
        @apply text-white font-bold;
    }

    @media (min-width: 1410px) and (max-width: 8000px) {
        .title {
            @apply text-[15px];
        }

        .skeletor {
            @apply h-[21px];
        }

        .finance-value {
            @apply text-[24px];
        }

        .sign {
            @apply text-[16px];
        }

        .poor-value {
            @apply text-[24px];
        }
    }

    @media (min-width: 0px) and (max-width: 1410px) {
        .title {
            @apply text-[13px];
        }

        .skeletor {
            @apply h-[18px];
        }

        .finance-value {
            @apply text-[21px];
        }

        .sign {
            @apply text-[13px];
        }

        .poor-value {
            @apply text-[21px];
        }
    }
</style>