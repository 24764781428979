<template>
    <div class="fixed bg-[#27272733] z-[999] w-full h-screen backdrop-blur-md" @click="$emit('close')"></div>
    <div v-if="!dataLoaded">
        <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000]">
            <div class="w-[50px] h-[50px] border-4 border-l-[#DA4167] border-t-[#DA4167] border-r-[#DA4167] border-b-transparent rounded-full animate-spin"></div>
        </div>
    </div>
    <FormBlock v-else class="new-match-form z-[1000]">
        <div class="new-match-form__title">Дані для<br>протоколу матчу</div>
        <div class="new-match-form__double-input">
            <div class="new-match-form__double-input-title">введіть дату та час</div>
            <div class="new-match-form__double-input-inputs">
                <InputComponent :value="matchDate" @update:modelValue="e => matchDate = e" placeholder="Дата" name="date" type="date"/>
                <InputComponent :value="matchTime" @update:modelValue="e => matchTime = e" placeholder="Час" name="time" type="time"/>
            </div>
        </div>
        <div class="new-match-form__match-place-type">
            <div>вдома</div>
            <div class="switch"><IphoneSwitch :value="matchPlaceType == 'home' ? false : true" @input="matchPlaceType == 'home' ? matchPlaceType = 'out' : matchPlaceType = 'home'" /></div>
            <div>виїзд</div>
        </div>
        <div class="new-match-form__one-input">
            <div class="new-match-form__double-input-title">оберіть команду А (примаюча)</div>
            <div class="new-match-form__one-input-inputs">
                <SelectComponent
                    v-if="dataLoaded"
                    :key="selectKey" 
                    class="new-match-form__select"
                    :objectOptions="matchPlaceType == 'home' ? allOurTeams : otherTeamsLeague" 
                    :name="'приймаюча'" 
                    @change="e => matchPlaceType == 'home' ? selectedOurTeam = e : selectedOpponent = e"
                />
            </div>
        </div>
        <div class="new-match-form__one-input">
            <div class="new-match-form__double-input-title">оберіть команду В (гості)</div>
            <div class="new-match-form__one-input-inputs">
                <SelectComponent 
                    v-if="dataLoaded"
                    :key="selectKey"
                    class="new-match-form__select"
                    :objectOptions="matchPlaceType == 'home' ? otherTeamsLeague : allOurTeams" 
                    :name="'гості'" 
                    @change="e => matchPlaceType == 'home' ? selectedOpponent = e : selectedOurTeam = e"
                />
            </div>
        </div>
        <div class="new-match-form__double-input">
            <div class="new-match-form__double-input-title">вкажіть турнір, тур та сезон</div>
            <div class="new-match-form__double-input-inputs">
                <SelectComponent 
                    v-if="dataLoaded"
                    class="new-match-form__select"
                    :objectOptions="allLeagues" 
                    :name="'турнір'" 
                    :default="selectedLeague"
                    @change="e => selectedLeague = e"
                />

                <SelectComponent 
                    v-if="dataLoaded"
                    class="new-match-form__select"
                    :options="allSeasons" 
                    :name="'сезон'" 
                    @change="e => selectedSeason = e"
                />

                <InputComponent :value="tour" @update:modelValue="e => tour = e" placeholder="Тур" name="tour" class="w-full" />
            </div>
        </div>
        <div class="new-match-form__one-input">
            <div class="new-match-form__double-input-title">вкажіть стадіон</div>
            <div class="new-match-form__one-input-inputs">
                <InputComponent :value="stadium" @update:modelValue="e => stadium = e" class="new-match-form__select" placeholder="Стадіон" name="stadium"/>
            </div>
        </div>

        <div class="flex justify-center">
            <ButtonComponent @click="saveMatchReport">
                Додати матч
            </ButtonComponent>
        </div>
    </FormBlock>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

import { getAllLeagues, getAllTeams, getAllGroups, addMatchReport } from '@/services/apiRequests';

import SelectComponent from '@/components/uikit/Select/SelectComponent.vue';

export default {
    name: 'NewMatchForm',
    props: {
        
    },
    setup(props, { emit }) {
        const allLeagues = ref([]);
        const allOtherTeams = ref([]);
        const allOurTeams = ref([]);
        const otherTeamsLeague = ref([]);
        const allSeasons = ref([]);

        const selectedLeague = ref(null);
        const selectedOpponent = ref(null);
        const selectedOurTeam = ref(null);
        const selectedSeason = ref(null);

        const matchDate = ref(null);
        const matchTime = ref(null);
        const stadium = ref(null);
        const tour = ref(null);

        const matchPlaceType = ref('home');

        const dataLoaded = ref(false);

        const selectKey = ref(new Date().getTime());

        watch(() => matchPlaceType, () => {
            selectKey.value = new Date().getTime();
        })

        watch(selectedLeague, () => {
            console.log('selectedLeague.value ----- ', selectedLeague.value);
            otherTeamsLeague.value = allOtherTeams.value.filter(team => team.league == selectedLeague.value);
            selectKey.value = new Date().getTime();

            allSeasons.value = otherTeamsLeague.value.map(team => {
                return team.season
            });

            allSeasons.value = [...new Set(allSeasons.value)];

            selectedSeason.value = allSeasons.value[0];

            otherTeamsLeague.value = otherTeamsLeague.value.filter(team => team.season == selectedSeason.value);
        })

        watch(selectedSeason, () => {
            otherTeamsLeague.value = allOtherTeams.value.filter(team => team.season == selectedSeason.value);
            selectKey.value = new Date().getTime();
        })

        const saveMatchReport = async () => {
            const firstTeam = matchPlaceType.value == 'home' ? allOurTeams.value.find(team => team.id == selectedOurTeam.value) : allOtherTeams.value.find(team => team.id == selectedOpponent.value);
            const secondTeam = matchPlaceType.value == 'home' ? allOtherTeams.value.find(team => team.id == selectedOpponent.value) : allOurTeams.value.find(team => team.id == selectedOurTeam.value);
            const selectedLeagueObject = allLeagues.value.find(league => league.id == selectedLeague.value);
            console.log('firstTeam ----- ', firstTeam);
            console.log('secondTeam ----- ', secondTeam);

            await addMatchReport({
                matchType: matchPlaceType.value,
                time: matchTime.value,
                date: new Date(matchDate.value).toISOString(),
                stadium: stadium.value,
                league: selectedLeagueObject.label,
                tour: tour.value,
                season: selectedSeason.value,
                firstTeamScore: 0,
                firstTeamName: firstTeam.label,
                firstTeamLogo: firstTeam.logo ? firstTeam.logo : null,
                secondTeamName: secondTeam.label,
                secondTeamLogo: secondTeam.logo ? secondTeam.logo : null,
                secondTeamScore: 0,
                matchTime: 0,
                clubTeamId: matchPlaceType.value == 'home' ? firstTeam.id : secondTeam.id,
            })

            emit('updateMatches')
            emit('close');
        }

        onMounted(async () => {
            allLeagues.value = (await getAllLeagues()).map(league => {
                return {
                    id: league.league_id,
                    label: league.league_name
                }
            });
            allOtherTeams.value = (await getAllTeams()).map(team => {
                return {
                    id: team.team_id,
                    label: team.team_name,
                    league: team.league_id,
                    logo: team.team_logo,
                    season: team.season
                }
            });
            allOurTeams.value = (await getAllGroups()).data.map(group => {
                return {
                    id: group.group_id,
                    label: group.name
                }
            });

            console.log('allOtherTeams.value ----- ', await getAllTeams());
            selectedLeague.value = allLeagues.value[0].id;

            otherTeamsLeague.value = allOtherTeams.value.filter(team => team.league == selectedLeague.value);

            allSeasons.value = allOtherTeams.value.map(team => {
                console.log('team ----- ', team);
                return team.season
            });

            allSeasons.value = [...new Set(allSeasons.value)];

            console.log('allOtherTeams.value ----- ', allOtherTeams.value);
            console.log('allSeasons.value ----- ', allSeasons.value);

            selectedSeason.value = allSeasons.value[0];

            console.log('otherTeamsLeague.value ----- ', otherTeamsLeague.value);

            otherTeamsLeague.value = otherTeamsLeague.value.filter(team => team.season == selectedSeason.value);

            console.log('selectedSeason.value ----- ', selectedSeason.value);   
            console.log('otherTeamsLeague.value ----- ', otherTeamsLeague.value);

            selectedLeague.value = allLeagues.value[0].id;

            selectedOurTeam.value = allOurTeams.value[0].id;
            selectedOpponent.value = otherTeamsLeague.value[0].id;

            dataLoaded.value = true;
        })

        return {
            allLeagues,
            allOtherTeams,
            matchPlaceType,
            allOurTeams,
            selectedOpponent,
            selectedOurTeam,
            selectedLeague,
            selectKey,
            otherTeamsLeague,
            allSeasons,
            selectedSeason,
            dataLoaded,
            matchDate,
            matchTime,
            stadium,
            tour,
            saveMatchReport
        }
    }
}
</script>

<style>
    
</style>