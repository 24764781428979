<template>
    <div class="w-[90%] mx-auto">
        <MainIndicators 
            :countAlienReports="countAlienReports"
            :countClubReports="countClubReports"
            :countAlienPlayers="countAlienPlayers"
            :countClubPlayers="countClubPlayers"
            :subscribedPlayersCount="subscribedPlayersCount"
            :signingPlayersCount="signingPlayersCount"
            :alienReportsUniqueMatchesCount="alienReportsUniqueMatchesCount"
        />

        <ScoutsList :allScouts="allScouts" />

        <ShadowTeams :shadowTeams="shadowTeams" />
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';

import { getAllShadowTeams, getAllScoutProfiles, getCountPlayerScoutReports, getCountAlienPlayerScoutReports, getAlienPlayersCount, getStudentsCount, getSubscribedPlayersCount, getSigningPlayersCount, getAlienReportsUniqueMatches } from '@/services/apiRequests';

import MainIndicators from './components/MainIndicators.vue';
import ScoutsList from './components/ScoutsList.vue';
import ShadowTeams from './components/ShadowTeams.vue';

export default {
    name: 'ScoutDashboard',
    
    setup() {
        const allScouts = ref([]);
        const shadowTeams = ref([]);

        const countAlienReports = ref(0);
        const countClubReports = ref(0);

        const countAlienPlayers = ref(0);
        const countClubPlayers = ref(0);

        const subscribedPlayersCount = ref(0);
        const signingPlayersCount = ref(0);

        const alienReportsUniqueMatchesCount = ref(0);

        onMounted(async () => {
            allScouts.value = await getAllScoutProfiles();
            shadowTeams.value = await getAllShadowTeams();

            countAlienReports.value = await getCountAlienPlayerScoutReports();
            countClubReports.value = await getCountPlayerScoutReports();

            countAlienPlayers.value = await getAlienPlayersCount();
            countClubPlayers.value = await getStudentsCount();

            subscribedPlayersCount.value = await getSubscribedPlayersCount();
            signingPlayersCount.value = await getSigningPlayersCount();

            alienReportsUniqueMatchesCount.value = await getAlienReportsUniqueMatches();
        });

        return {
            allScouts,
            shadowTeams,
            countAlienReports,
            countClubReports,
            countAlienPlayers,
            countClubPlayers,
            subscribedPlayersCount,
            signingPlayersCount,
            alienReportsUniqueMatchesCount
        }
    },

    components: {
        MainIndicators,
        ScoutsList,
        ShadowTeams
    }
}
</script>

<style>
    
</style>