
<template>
  <div class="left-chart-block__header">
    <h2 class="left-chart-block__title">{{text.title}}</h2>
    <label class="right-chart-block__select">
      <select v-model="selectedYear">
        <option v-for="yearObj in data" :key="yearObj.year" :value="yearObj.year">
          {{ yearObj.year }}
        </option>
      </select>
    </label>
  </div>
  <div class="right-chart-block__chart">
    <ExpensiveLineChartElement :color="activeYearData.color" :label="updatedLabels"  :dataSet="activeYearData.dataSet" ></ExpensiveLineChartElement>
  </div>
</template>

<script >
import {computed, ref, toRefs, watch} from "vue";
import ExpensiveLineChartElement from "./ExpensiveLineChartElement.vue";
export default {
  name: "ExpensiveLineChart",
  components: {ExpensiveLineChartElement},
  props: {
    data: {
      type: Array,
      required: true
    },
    text: {
      type: Object,
      required: true
    }
  },
  setup(props){
    const { data,text } = toRefs(props);

    const selectedYear = ref(data.value.find(year => year.isActive)?.year || data.value[0].year);
    const activeYearData = computed(() => {
      return data.value.find(yearObj => yearObj.year === selectedYear.value) || {};
    });
    const updatedLabels= computed(()=>{
      return text.value.labels.map(label=>`${label}.${selectedYear.value}`)||[]
    })
    watch(selectedYear, newValue => {
      data.value.forEach(yearObj => {
        yearObj.isActive = yearObj.year === newValue;
      });
    });
    return {
      selectedYear,
      activeYearData,
      updatedLabels
    }
  }

}
</script>

<style>
</style>
