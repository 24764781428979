<template>
  <div class="info-block">
    <div
      :class="[
        'info-block__main-text',
        { 'info-block__main-text--primary': mainTextPrimary },
      ]"
    >
      {{ mainText }}
    </div>
    <div class="info-block__value">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: "InfoBlock",
  props: {
    mainText: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    mainTextPrimary: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
