<template>
    <div v-if="!addSummaryFlag" style="background-color: rgba(53, 53, 53, 0.10)" class="w-[100%] top-[0] ml-[-20px] h-full backdrop-blur-[7px] fixed z-[120]"></div>
    <div v-else style="background-color: #252526" class="w-[100%] top-[0] ml-[-20px] h-full backdrop-blur-[7px] fixed z-[120]"></div>
    
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[100px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent "></div>
    </div>
    <div v-else-if="!addSummaryFlag" class="bg-[#252526] w-[254px] fixed top-[50%] left-[50%] ml-[110px] border-[1px] border-[#FFF] border-opacity-10 translate-x-[-50%] translate-y-[-50%] z-[150] px-[16px] py-5 rounded-[10px] select-none">
        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <img v-if="trainingType == 'training'" src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <img v-else-if="trainingType == 'gym'" src="@/assets/icons/red_dumbbell.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'competition'" src="@/assets/icons/gold_cup.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'homework'" src="@/assets/icons/homework_icon.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'friendly-game'" src="@/assets/icons/friendly_game_icon.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'championship'" src="@/assets/icons/championship_icon.svg" class="h-[18px]">
                        <img v-else src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <select v-model="trainingType" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="null" class="bg-[#252526]">Не задано</option>
                            <option value="training" class="bg-[#252526]">Тренування</option>
                            <option value="gym" class="bg-[#252526]">Спортзал</option>
                            <option value="competition" class="bg-[#252526]">Змагання</option>
                            <option value="homework" class="bg-[#252526]">Домашнє завдання</option>
                            <option value="friendly-game" class="bg-[#252526]">Товариська гра</option>
                            <option value="championship" class="bg-[#252526]">Гра-чемпіонат</option>
                            <option value="trainer_meetup" class="bg-[#252526]">Нарада тренерів</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="trainingType != 'trainer_meetup'">
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center bg-[#474849] bg-opacity-20">
                        <input :disabled="addCurrentDateFlag" v-model="changeDate" class="custom-date-input bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[1px] px-[3px] w-full disabled:opacity-40" type="date">
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div class="flex justify-center items-center">
                    <div class="bg-[#474849] bg-opacity-20">
                        <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                    </div>
                    <span class="font-bold mx-2 text-[#A1D9F7]">></span>
                    <div class="bg-[#474849] bg-opacity-20">
                        <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div>
                    <div class="">
                        <multi-select 
                            @update="trainingRepeat = $event"
                            @enable="addCurrentDateFlag = false"
                            @disable="addCurrentDateFlag = true"
                        />
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center">
                        <div class="w-full">
                            <div class="bg-[#474849] bg-opacity-20">
                                <input
                                    @change="changeGroupText($event.target.value)"
                                    :value="groupText"
                                    @focus="groupOptionsShow = true"
                                    @blur="groupOptionsShow = false" 
                                    class="bg-transparent text-[#fff] text-[14px] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[3px] w-full disabled:opacity-40" 
                                    type="text"
                                    placeholder="група"
                                />
                            </div>
                            <div v-if="groupOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20">
                                <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{groupOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center">
                        <div class="w-full">
                            <div class="bg-[#474849] bg-opacity-20">
                                <input
                                    @change="changeLocationText($event.target.value)"
                                    :value="locationText"
                                    @focus="locationOptionsShow = true"
                                    @blur="locationOptionsShow = false" 
                                    class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 text-center rounded-[4px] py-1 px-[3px] w-full disabled:opacity-40" 
                                    type="text"
                                    placeholder="локація"
                                />
                            </div>
                            <div v-if="locationOptionsShow" class="z-[150] overflow-auto absolute max-h-[100px] w-[230px] bg-[#252526]">
                                <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] hover:bg-opacity-80 cursor-pointer text-[#000]">{{locationOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center">
                        <div class="w-full flex flex-col items-center relative">
                            <div class="bg-[#474849] bg-opacity-20 w-full">
                                <textarea
                                    v-model="description"
                                    class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                                    type="text"
                                    rows="6"
                                    placeholder="Коментар до тренування"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <button @click="addSummaryFlag = true" :class="{'opacity-50' : trainingPrograms.length == 0}" class="border-[#BD9135] border-[1px] text-[#BD9135] text-center w-full rounded-md py-1 hover:bg-opacity-90">Додати конспект</button>
            </div>
            <div v-if="selectedSummary" class="mt-[10px]">
                <div class="truncate text-gray-400">Додано конспект: <span class="text-[#BD9135]">{{ selectedSummary.name }}</span></div>
            </div>
        </div>

        <div v-else>
            <div class="h-[300px] scrollbar overflow-y-auto">
                <div v-for="trainer in allTrainers" class="flex justify-between overflow-hidden ro h-[44px] rounded-[10px] my-[10px]">
                    <div class="h-full truncate w-[50%] flex justify-start px-[10px] items-center bg-[#474849] text-white">
                        <div class="truncate">{{ trainer.surname + ' ' + trainer.name[0] + '.'}}</div>
                    </div>
                    <div v-if="trainer.selected" @click="trainer.selected = false" class="w-[50%] cursor-pointer bg-[#8FC408] h-full flex justify-center items-center">
                        <div class="text-black">Доданий</div>
                    </div>
                    <div v-else @click="trainer.selected = true" class="w-[50%] cursor-pointer bg-[#DA4167] h-full flex justify-center items-center">
                        <div class="text-black">Не доданий</div>
                    </div>
                </div>
            </div>
    
            <div class="h-full w-[100%] mt-[20px] flex justify-center items-center bg-[#474849] text-white">
                <textarea
                    v-model="description"
                    class="bg-transparent text-[#fff] text-[14px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                    type="text"
                    rows="6"
                    placeholder="Коментар до тренування"
                ></textarea>
            </div>

            <div class="my-[20px] flex justify-center items-center">
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
                <span class="font-bold mx-2 text-[#A1D9F7]">></span>
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
            </div>
        </div>

        <div class="mt-5">
            <button @click="sendData" class="bg-[#8FC408] text-[#252526] text-center w-full rounded-md py-1 hover:bg-opacity-90">Зберегти</button>
        </div>
        <div class="mt-2">
            <button @click="$emit('close')" class="bg-[#BD9135] text-[#252526] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>
        
    </div>
    <AddSummaryToTrainingForm v-else :date="changeDate" :programs="trainingPrograms" @close="addSummaryFlag = false" @addSummary="e => selectSummary(e)" />
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, addNewTraining, getProgramsByGroupId, getAllTrainers, addTrainerMeetUp, getAllTrainerMeetUps } from '@/services/apiRequests'
    import MultiSelect from './MultiSelect.vue'
    import AddSummaryToTrainingForm from './AddSummaryToTrainingForm.vue'

    export default {
        name: 'new-training-form',

        props: {
            date: {
                type: Date,
                default: '2022-12-12',
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.date)
            const settedYear = settedDate.getFullYear() >= 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 >= 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() >= 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const trainingRepeat = ref([])
            const addCurrentDateFlag = ref(false)

            const group = ref(null)
            const groupText = ref('')
            
            const location = ref(null)
            const locationText = ref('')

            const groupOptions = ref([])
            const locationOptions = ref([])

            const loadingSpinnerFlag = ref(false)

            const trainingType = ref('null')

            const trainingPrograms = ref([])

            const description = ref('')

            const addSummaryFlag = ref(false)
            const selectedSummary = ref(null)

            const allTrainers = ref([])

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data.map(trainer => {
                    return {
                        ...trainer,
                        selected: false
                    }
                })
                console.log('allTrainers', allTrainers.value)
            })

            const selectSummary = (e) => {
                selectedSummary.value = e
                addSummaryFlag.value = false
            }

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                console.log('location', location)
                location.value = locationOption
                console.log('location', location.value)
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = async (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;

                const res = await getProgramsByGroupId(groupOption.group_id)
                trainingPrograms.value = res
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: '00:00',
                end: '00:00'
            })

            const sendData = async () => {
                trainingType.value = trainingType.value == 'none' ? null : trainingType.value
                if (!addCurrentDateFlag.value) {
                    loadingSpinnerFlag.value = true

                    const trainers_ids = allTrainers.value.filter(trainer => trainer.selected).map(trainer => trainer.trainer_id)

                    if (trainingType.value != 'trainer_meetup') {
                        addNewTraining(
                            group.value.name, 
                            group.value.group_id, 
                            location.value.small_name, 
                            location.value.location_id, 
                            trainingTime.value.start, 
                            trainingTime.value.end, 
                            changeDate.value,
                            trainingType.value,
                            selectedSummary.value
                        ).then(res => {
                            console.log('result adding', res)
                            loadingSpinnerFlag.value = false
                            emit('updateTrainings')
                            emit('close')
                        })
                    } else {
                        addTrainerMeetUp({
                            date: changeDate.value,
                            start_time: trainingTime.value.start,
                            end_time: trainingTime.value.end,
                            description: description.value,
                            type: trainingType.value,
                            trainers_ids: trainers_ids
                        }).then(res => {
                            console.log('result adding', res)
                            loadingSpinnerFlag.value = false
                            emit('updateTrainings')
                            emit('close')
                        })
                    }
                } else {
                    loadingSpinnerFlag.value = true
                    const days = []
                    trainingRepeat.value.forEach(day => {
                        if(day.selected) {
                            const allCurrentDayInMonth = getDatesByDayOfWeek(settedMonth, settedYear, day.value)
                            days.push(...allCurrentDayInMonth)
                        }
                    })

                    const trainers_ids = allTrainers.value.filter(trainer => trainer.selected).map(trainer => trainer.trainer_id)
                    for (let i = 0; i < days.length; i++) {
                        if (trainingType.value != 'trainer_meetup') {
                            await addNewTraining(
                                group.value.name, 
                                group.value.group_id, 
                                location.value.small_name, 
                                location.value.location_id, 
                                trainingTime.value.start, 
                                trainingTime.value.end, 
                                days[i],
                                trainingType.value,
                                selectedSummary.value
                            )
                        } else {
                            addTrainerMeetUp({
                                date: changeDate.value,
                                start_time: trainingTime.value.start,
                                end_time: trainingTime.value.end,
                                description: description.value,
                                type: trainingType.value,
                                trainers_ids: trainers_ids
                            }).then(res => {
                                console.log('result adding', res)
                                loadingSpinnerFlag.value = false
                                emit('updateTrainings')
                                emit('close')
                            })
                        }
                    }
                    loadingSpinnerFlag.value = false
                    emit('updateTrainings')
                    emit('close')
                }
            }

            const getDatesByDayOfWeek = (month, year, dayOfWeek) => {
                const dates = [];
                const date = new Date(year, month - 1, 1);
                const daysInMonth = new Date(year, month, 0).getDate();
                
                let firstDayOfWeek = date.getDay();
                if (firstDayOfWeek > dayOfWeek) {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek + 7;
                } else {
                    firstDayOfWeek = dayOfWeek - firstDayOfWeek;
                }
                
                date.setDate(firstDayOfWeek + 1);
                dates.push(new Date(date));
                
                while (date.getDate() + 7 <= daysInMonth) {
                    date.setDate(date.getDate() + 7);
                    dates.push(new Date(date));
                }
                
                console.log('dates', dates)
                return dates;
            }

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingRepeat,
                addCurrentDateFlag,
                loadingSpinnerFlag,
                trainingType,
                trainingPrograms,
                addSummaryFlag, 
                selectedSummary,
                selectSummary,
                allTrainers,
                description
            }
        },

        components: {
            MultiSelect,
            AddSummaryToTrainingForm
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Fixel';
        src: url('../../../assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    .window {
        font-family: 'Fixel';
    }

    .scrollbar::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrollbar::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background-color: #696969;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }
</style>