<template>
    <AsideMenu v-if="componentName != 'Login' && componentName != 'Loading'" :menuItems="menuItems" :onAsideArrow="onAsideArrow" @exit="confirmExit" />
    <div class="wrapper">
      <header v-if="componentName != 'Login' && componentName != 'Loading'" class="header">
        <div class="header__container">
          <h1 class="header__title">{{ pageName }}</h1>
          <div class="header__right">
            <button class="header__alerm alerm">
              <img src="@/assets/refactored_assets/img/header/alerm.svg" alt="alerm" />
              <img
                class="alerm__new"
                src="@/assets/refactored_assets/img/header/new.svg"
                alt="new"
              />
            </button>
            <hr class="header__divider" />
            <div class="header__user">
              <div class="header__user-name">
                <div>{{ adminObject.admin_name }}</div>
                <div>{{ adminObject.admin_surname }}</div>
              </div>
              <AvatarContainer :label="adminObject.admin_name[0] + adminObject.admin_name[0]" alt="user" small />
            </div>
          </div>
        </div>
      </header>
      <component class="bg-[rgb(30, 30, 30)]" :is="componentName"></component>
      <footer class="footer"></footer>
    </div>
</template>

<script>
    import {ref, onMounted, onUpdated, computed} from 'vue'
    import { useRoute, useRouter } from 'vue-router'

    import AsideMenu from "@/components/AsideMenu.vue";
    import AvatarContainer from "@/components/AvatarContainer.vue";

    import Dashboard from '@/views/Dashboard/Dashboard.vue'
    import Login from '@/views/Login/Login.vue'
    import Trainers from '@/views/Trainers/Trainers.vue'
    import Groups from '@/views/Groups/Groups.vue'
    import Students from '@/views/Students/Students.vue'
    import TransactionLog from '@/views/TransactionLog/TransactionLog.vue'
    import CurrentStudent from '@/views/CurrentStudent/CurrentStudent.vue'
    import Locations from '@/views/Locations/Locations.vue'
    import Calendar from '@/views/Calendar/Calendar.vue'
    import Incomes from '@/views/Finance/Incomes/Incomes.vue'
    import Outlays from '@/views/Finance/Outlays/Outlays.vue'
    import Transactions from '@/views/Finance/Transactions/Transactions.vue'
    import Admins from '@/views/Admins/Admins.vue'
    import OutlaysCategories from '@/views/Finance/OutlaysCategories/OutlaysCategories.vue'
    import ClubOutlays from '@/views/Finance/ClubOutlays/ClubOutlays.vue'
    import Settings from '@/views/Settings/Settings.vue'
    import TrainingConstructor from '@/views/TrainingSummary/TrainingConstructor/TrainingConstructor.vue'
    import TrainingPrograms from '@/views/TrainingSummary/TrainingPrograms/TrainingPrograms.vue'
    import TrainingSchemes from '@/views/TrainingSummary/TrainingSchemes/TrainingSchemes.vue'
    import TrainingElements from '@/views/TrainingSummary/TrainingConstructor/TrainingElements/TrainingElements.vue'
    import Trainings from '@/views/TrainingSummary/TrainingConstructor/Trainings/Trainings.vue'
    import TrainingProgramView from '@/views/TrainingSummary/TrainingPrograms/TrainingProgramView.vue'
    import ElementForm from '@/views/TrainingSummary/TrainingElementForm/ElementForm.vue'
    import ElementUpdateForm from '@/views/TrainingSummary/TrainingElementForm/ElementUpdateForm.vue'
    import TrainingCreationForm from '@/views/TrainingSummary/TrainingCreationForm/TrainingCreationForm.vue'
    import TrainingUpdateForm from '@/views/TrainingSummary/TrainingCreationForm/TrainingUpdateForm.vue'
    import TrainingProgramCreationForm from '@/views/TrainingSummary/TrainingPrograms/TrainingProgramCreationForm.vue'
    import TrainingProgramUpdateForm from '@/views/TrainingSummary/TrainingPrograms/TrainingProgramUpdateForm.vue'
    import TrainingSummaryAddons from '@/views/TrainingSummary/Addons/TrainingSummaryAddons.vue'
    import Matches from '@/views/MatchCenter/Matches/Matches.vue'
    import MatchReportForm from '@/views/MatchCenter/Matches/components/MatchReportForm.vue'
    import Teams from '@/views/MatchCenter/Teams/Teams.vue'
    import StandardConstructor from '@/views/TrainingSummary/StandardConstructor/StandardConstructor.vue'
    import PlayerMatchMarkView from '@/views/Students/components/NewFullStudentCard/components/PlayerMatchMarkView.vue'
    import PlayerMatchReportForm from '@/views/MatchCenter/Matches/components/PlayerMatchReportForm.vue'
    import PlayerScoutReportForm from '@/views/Students/components/NewFullStudentCard/components/PlayerScoutReportForm.vue'
    import PlayerScoutReportView from '@/views/Students/components/NewFullStudentCard/components/PlayerScoutReportView.vue'
    import ScoutDashboard from '@/views/Scouting/Dashboard/ScoutDashboard.vue';
    import Scouts from '@/views/Scouting/Scouts/Scouts.vue';
    import ScoutReports from '@/views/Scouting/ScoutReports/ScoutReports.vue';
    import ScoutPlayers from '@/views/Scouting/ScoutPlayers/ScoutPlayers.vue';
    import ShadowTeams from '@/views/Scouting/ShadowTeams/ShadowTeams.vue';
    import StudentSelectionForm from '@/views/Scouting/ScoutReports/components/StudentSelectionForm.vue';
    import AlienPlayerFullCard from '@/views/Scouting/ScoutPlayers/components/AlienPlayerFullCard/AlienPlayerFullCard.vue';
    import AlienPlayerScoutReportForm from '@/views/Scouting/ScoutPlayers/components/AlienPlayerScoutReportForm.vue';
    import AlienPlayerScoutReportView from '@/views/Scouting/ScoutPlayers/components/AlienPlayerScoutReportView.vue';
    import ShadowTeamPlayerSelection from '@/views/Scouting/ShadowTeams/components/ShadowTeamPlayerSelection.vue';
    import CreateShadowTeam from '@/views/Scouting/ShadowTeams/components/CreateShadowTeam.vue';
    import ShadowTeamView from '@/views/Scouting/ShadowTeams/components/ShadowTeamView.vue';
    import CurrentShadowTeamPlayerSelection from '@/views/Scouting/ShadowTeams/components/CurrentShadowTeamPlayerSelection.vue';
    import ScoutPlayersComparison from '@/views/Scouting/ScoutPlayersComparison/ScoutPlayersComparison.vue';
    import ComparePlayersView from '@/views/Scouting/ScoutPlayersComparison/components/ComparePlayersView.vue';
    import BestShadowTeam from '@/views/Scouting/ShadowTeams/components/BestShadowTeam.vue';

    import Loading from '@/views/Loading.vue'

    import SystemSidebar from '@/components/SystemSidebar.vue'

    import { getAdmin, getScoreSettings, getScoutByTokens } from '@/services/apiRequests' 
    import { useGlobalStore } from '@/storage/globalStorage'

    export default {
        setup() {
        
            // Basic const
            const route = useRoute()
            const router = useRouter()
            const windowWidth = ref(0)
            const isSidebarVisible = ref(false)
            const isLoginSuccess = ref(false)
            const adminObject = ref({})
            const pageName = ref('')

            const store = useGlobalStore()

            const setPageName = (component) => {
                switch (component) {
                    case 'Dashboard':
                        pageName.value = 'Показники'
                        break;
                    case 'Groups':
                        pageName.value = 'Групи'
                        break;
                    case 'Trainers':
                        pageName.value = 'Тренери'
                        break;
                    case 'Students':
                        pageName.value = 'Учні'
                        break;
                    case 'Locations':
                        pageName.value = 'Локації'
                        break;
                    case 'Calendar':
                        pageName.value = 'Календар'
                        break;
                    case 'Incomes':
                        pageName.value = 'Надходження'
                        break;    
                    case 'Outlays':
                        pageName.value = 'Нарахування'
                        break;   
                    case 'Transactions':
                        pageName.value = 'Транзакції'
                        break;    
                    case 'Admins':
                        pageName.value = 'Адміни'
                        break;    
                    case 'OutlaysCategories':
                        pageName.value = 'Категорії витрат'
                        break;
                    case 'ClubOutlays':
                        pageName.value = 'Витрати клубу'
                        break;
                    case 'Settings':
                        pageName.value = 'Налаштування'
                        break;
                    case 'AgeGroups':
                        pageName.value = 'Вікові групи'
                        break;
                    case 'TrainingConstructor':
                        pageName.value = 'Конструктор'
                        break;
                    case 'TrainingPrograms':
                        pageName.value = 'Програми'
                        break;
                    case 'TrainingSchemes':
                        pageName.value = 'Редактор схем'
                        break;
                    case 'TrainingElements':
                        pageName.value = 'Елементи тренування'
                        break;
                    case 'Trainings':
                        pageName.value = 'Тренування'
                        break;
                    case 'ElementForm':
                        pageName.value = 'Створити елемент тренування'
                        break;
                    case 'TrainingSummaryAddons':
                        pageName.value = 'Додаткові інтеграції'
                        break;
                    case 'Matches':
                        pageName.value = 'Матчі'
                        break;
                    case 'MatchReportForm':
                        pageName.value = 'Звіт про матч'
                        break;
                    case 'Teams':
                        pageName.value = 'Команди та ліги'
                        break;
                    case 'StandardConstructor':
                        pageName.value = 'Стандартні нормативи'
                        break;
                    case 'PlayerMatchMarkView':
                        pageName.value = 'Матч-звіт'
                        break;
                    case 'PlayerMatchReportForm':
                        pageName.value = 'Статистика матчу'
                        break;
                    case 'PlayerScoutReportForm':
                        pageName.value = 'Звіт скаута по гравцю'
                        break;
                    case 'PlayerScoutReportView':
                        pageName.value = 'Перегляд звіту скаута'
                        break;
                    case 'ScoutDashboard':
                        pageName.value = 'Дашборд скаута'
                        break;
                    case 'Scouts':
                        pageName.value = 'Скаути'
                        break;
                    case 'ScoutReports':
                        pageName.value = 'Звіти скаутів'
                        break;
                    case 'ScoutPlayers':
                        pageName.value = 'Гравці'
                        break;
                    case 'ShadowTeams':
                        pageName.value = 'Shadow Teams'
                        break;
                    case 'StudentSelectionForm':
                        pageName.value = 'Вибір учня для звіту'
                        break;
                    case 'AlienPlayerFullCard':
                        pageName.value = 'Карточка гравця'
                        break;
                    case 'AlienPlayerScoutReportForm':
                        pageName.value = 'Звіт скаута по гравцю'
                        break;
                    case 'ShadowTeamPlayerSelection':
                        pageName.value = 'Вибір гравців для Shadow Team'
                        break;
                    case 'CreateShadowTeam':
                        pageName.value = 'Створення Shadow Team'
                        break;
                    case 'ScoutPlayersComparison':
                        pageName.value = 'Порівняння гравців'
                        break;
                    case 'ComparePlayersView':
                        pageName.value = 'Порівняння двох гравців'
                        break;
                    case 'BestShadowTeam':
                        pageName.value = 'Найкраща Shadow Team'
                        break;
                }
            }

            //Login routing            
            const componentName = ref('Loading')
            onMounted(async () => { 
                windowWidth.value = window.innerWidth
                window.addEventListener('resize', () => windowWidth.value = window.innerWidth)

                setPageName(route.meta.component)

                if (sessionStorage.access_token && sessionStorage.refresh_token) {
                    const adminResponse = await getAdmin()
                    const scoutResponse = await getScoutByTokens()

                    console.log('adminResponse', adminResponse)
                    console.log('scoutResponse', scoutResponse)

                    if (adminResponse.data.status == 'success') {
                        adminObject.value = adminResponse.data
                    } else if (scoutResponse.status == 'success') {
                        adminObject.value = {
                            admin_name: scoutResponse.name,
                            admin_surname: scoutResponse.surname
                        }
                    }
                    if (adminResponse.data.status == 'success' || scoutResponse.status == 'success') {
                        const scoreSettingsResponse = await getScoreSettings()
                        store.$state.scoringRadarChartSettings = scoreSettingsResponse

                        console.log('scoreSettingsResponse', scoreSettingsResponse)

                        componentName.value = route.meta.component
                        isLoginSuccess.value = true
                    } else {
                        componentName.value = 'Login'
                    }
                } else {
                    componentName.value = 'Login'
                }
            })

            const swipeHandler = () => {
                isSidebarVisible.value = true
            }

            const swipeHandlerLeft = () => {
                isSidebarVisible.value = false
            }

            onUpdated(() => {
                if(isLoginSuccess.value) {
                    componentName.value = route.meta.component
                    setPageName(route.meta.component)
                }
            })

            const adminMenuItems = ref([
                { 
                    link: "/", 
                    name: "Показники", 
                    icon: "dashboard.svg", 
                    isActive: true 
                },
                {
                    link: "/groups",
                    name: "Групи",
                    icon: "players.svg",
                    isActive: false,
                },
                {
                    link: "/trainers",
                    name: "Тренери",
                    icon: "trainers.svg",
                    isActive: false,
                },
                {
                    link: "",
                    name: "Тренув. процес",
                    icon: "training_proccess_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/training-programs",
                            name: "Програми",
                            isActive: false,
                        },
                        {
                            link: "/training-elements",
                            name: "Елементи",
                            isActive: false,
                        },
                        {
                            link: "/standard-constructor",
                            name: "Нормативи",
                            isActive: false,
                        },
                        {
                            link: "/trainings-constructor",
                            name: "Тренування",
                            isActive: false,
                        },
                        {
                            link: "/training-schemes",
                            name: "Редактор схем",
                            isActive: false,
                        },
                        {
                            link: "/training-summary-addons",
                            name: "Додатково",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
                {
                    link: "",
                    name: "Матч-центр",
                    icon: "training_programs_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/match-center/matches",
                            name: "Матчі",
                            isActive: false,
                        },
                        {
                            link: "/match-center/teams",
                            name: "Команди та ліги",
                            isActive: false,
                        },
                    ],
                    sublinksView: false
                },
                {
                    link: "/students",
                    name: "Учні",
                    icon: "players.svg",
                    isActive: false,
                },
                {
                    link: "/admins",
                    name: "Адміни",
                    icon: "trainers.svg",
                    isActive: false,
                },
                {
                    link: "/locations",
                    name: "Локації",
                    icon: "location.svg",
                    isActive: false,
                },
                {
                    link: "/calendar",
                    name: "Календар",
                    icon: "calendar.svg",
                    isActive: false,
                },
                {
                    link: "",
                    name: "Фінанси",
                    icon: "finance.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/incomes",
                            name: "Платежі",
                            isActive: false,
                        },
                        {
                            link: "/outlays",
                            name: "Нарахування",
                            isActive: false,
                        },
                        {
                            link: "/transactions",
                            name: "Транзакції",
                            isActive: false,
                        },
                        {
                            link: "/outlays-categories",
                            name: "Категорії витрат",
                            isActive: false,
                        },
                        {
                            link: "/club-outlays",
                            name: "Витрати",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
                {
                    link: "",
                    name: "Скаутинг",
                    icon: "scouting_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/scouting-dashboard",
                            name: "Дашборд",
                            isActive: false,
                        },
                        {
                            link: "/scout-profiles",
                            name: "Скаути",
                            isActive: false,
                        },
                        {
                            link: "/scout-reports",
                            name: "Звіти скаутів",
                            isActive: false,
                        },
                        {
                            link: "/scout-players",
                            name: "Гравці",
                            isActive: false,
                        },
                        {
                            link: "/scout-players-comparison",
                            name: "Порівняння гравців",
                            isActive: false,
                        },
                        {
                            link: "/shadow-teams",
                            name: "Shadow Teams",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
                {
                    link: "/settings",
                    name: "Налаштування",
                    icon: "settings.svg",
                    isActive: false,
                }
            ])
            const leadScoutMenuItems = ref([
                {
                    link: "",
                    name: "Матч-центр",
                    icon: "training_programs_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/match-center/matches",
                            name: "Матчі",
                            isActive: false,
                        },
                        {
                            link: "/match-center/teams",
                            name: "Команди та ліги",
                            isActive: false,
                        },
                    ],
                    sublinksView: false
                },
                {
                    link: "",
                    name: "Скаутинг",
                    icon: "scouting_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/scouting-dashboard",
                            name: "Дашборд",
                            isActive: false,
                        },
                        {
                            link: "/scout-profiles",
                            name: "Скаути",
                            isActive: false,
                        },
                        {
                            link: "/scout-reports",
                            name: "Звіти скаутів",
                            isActive: false,
                        },
                        {
                            link: "/scout-players",
                            name: "Гравці",
                            isActive: false,
                        },
                        {
                            link: "/scout-players-comparison",
                            name: "Порівняння гравців",
                            isActive: false,
                        },
                        {
                            link: "/shadow-teams",
                            name: "Shadow Teams",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
            ])
            const scoutMenuItems = ref([
                {
                    link: "",
                    name: "Матч-центр",
                    icon: "training_programs_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/match-center/matches",
                            name: "Матчі",
                            isActive: false,
                        },
                    ],
                    sublinksView: false
                },
                {
                    link: "",
                    name: "Скаутинг",
                    icon: "scouting_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/scout-reports",
                            name: "Звіти скаутів",
                            isActive: false,
                        },
                        {
                            link: "/scout-players",
                            name: "Гравці",
                            isActive: false,
                        },
                        {
                            link: "/scout-players-comparison",
                            name: "Порівняння гравців",
                            isActive: false,
                        },
                        {
                            link: "/shadow-teams",
                            name: "Shadow Teams",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
            ])

            const menuItems = ref(localStorage.userType == 'admin' ? adminMenuItems.value : localStorage.userType == 'scout' ? scoutMenuItems.value : leadScoutMenuItems.value);
            
            let isMenuOpen = ref(false);
            
            const onAsideArrow = () => {
                isMenuOpen.value = !isMenuOpen.value;
                document.body.classList.toggle("menu-open");
            };

            const activeItemName = computed(() => {
                const activeItem = menuItems.value.find((item) => item.isActive);
                return activeItem ? activeItem.name : "Не визначено";
            });

            const confirmExit = () => {
                localStorage.clear()
                sessionStorage.clear()
                router.go()
            }

            return {
                componentName, 
                windowWidth,
                isSidebarVisible,
                swipeHandler,
                swipeHandlerLeft,
                adminObject,
                pageName,
                setPageName,
                menuItems,
                onAsideArrow,
                isMenuOpen,
                activeItemName,
                confirmExit
            }
        }, 

        components: {
            Dashboard,
            Login,
            Trainers,
            Groups,
            Students,
            SystemSidebar,
            TransactionLog,
            CurrentStudent,
            Locations,
            Calendar,
            Loading,
            Incomes,
            Outlays,
            Transactions,
            Admins,
            OutlaysCategories,
            ClubOutlays,
            Settings,
            TrainingConstructor,
            TrainingPrograms,
            TrainingSchemes,
            TrainingElements,
            Trainings,
            TrainingProgramView,
            ElementForm,
            TrainingCreationForm,
            ElementUpdateForm,
            TrainingUpdateForm,
            TrainingProgramCreationForm,
            TrainingProgramUpdateForm,
            TrainingSummaryAddons,
            Matches,
            MatchReportForm,
            Teams,
            StandardConstructor,
            PlayerMatchMarkView,
            PlayerMatchReportForm,
            PlayerScoutReportForm,
            PlayerScoutReportView,
            ScoutDashboard,
            Scouts,
            ScoutReports,
            ScoutPlayers,
            ShadowTeams,
            StudentSelectionForm,
            AlienPlayerFullCard,
            AlienPlayerScoutReportForm,
            AlienPlayerScoutReportView,
            ShadowTeamPlayerSelection,
            CreateShadowTeam,
            ShadowTeamView,
            CurrentShadowTeamPlayerSelection,
            ScoutPlayersComparison,
            ComparePlayersView,
            BestShadowTeam,

            AsideMenu,
            AvatarContainer
        }
    }

</script>

<style lang="scss">

    .v-enter-active,
    .v-leave-active {
        transition: all 0.3s ease-in-out;
    }

    .v-enter-from,
    .v-leave-to {
        transform: translateX(-100px);
        opacity: 0;
    }

</style>