<template>
    <div class="double-input">
        <div class="double-input__row">
            <div class="double-input__fields">
                <input  :name="name_1" :placeholder="placeholder_1" class="double-input__input" :value="value_1" @change="e => $emit('update:value_1', e.target.value)"/>
                <input  :name="name_2" :placeholder="placeholder_2" class="double-input__input" :value="value_2" @change="e => $emit('update:value_2', e.target.value)"/> 
            </div>
            <RemoveButton v-if="buttonType == 'remove'" @click="$emit('delete')"/>
            <AddButton v-if="buttonType == 'add'" @click="$emit('add')"/>
            </div>
        </div>

</template>

<script>
export default {
    name: 'DoubleInput',
    props: {
        name_1: {
            type: String,
            required: true
        },
        placeholder_1: {
            type: String,
            required: true
        },
        name_2: {
            type: String,
            required: true
        },
        placeholder_2: {
            type: String,
            required: true
        },
        value_1: {
            type: String,
            default: ''
        },
        value_2: {
            type: String,
            default: ''
        },
        buttonType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            
        }
    },

}
</script>