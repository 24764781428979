
<template>
  <div class="right-chart-block__header">
    <label class="right-chart-block__select">
      <select v-model="selectedYear">
        <option v-for="yearObj in data" :key="yearObj.year" :value="yearObj.year">
          {{ yearObj.year }}
        </option>
      </select>
    </label>
  </div>
  <div class="right-chart-block__chart">
    <HorizontalLineChartElement :color="activeYearData.color"  :dataSet="filterDataSet" ></HorizontalLineChartElement>
  </div>
</template>

<script >
import {computed, ref, toRefs, watch} from "vue";
import HorizontalLineChartElement from "./HorizontalLineChartElement.vue";
export default {
  name: "HorizontalLineChart",
  components: {HorizontalLineChartElement},
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup(props){
    const { data } = toRefs(props);

    const selectedYear = ref(data.value.find(year => year.isActive)?.year || data.value[0].year);
    const activeYearData = computed(() => {
      return data.value.find(yearObj => yearObj.year === selectedYear.value) || {};
    });
    const filterDataSet = computed(() => {
      return activeYearData.value.dataSet ? activeYearData.value.dataSet.slice().sort((a, b) => a.value - b.value) : [];
    });
    watch(selectedYear, newValue => {
      data.value.forEach(yearObj => {
        yearObj.isActive = yearObj.year === newValue;
      });
    });
    return {
      selectedYear,
      activeYearData,
      filterDataSet
    }
  }

}
</script>

<style>
</style>
