<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] w-[400px] h-[305px] rounded-[10px] pt-[10px] mt-[15px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="bg-[#BD9135] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            ФІНАНСИ
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="text-[#DA4167] text-[20px] font-bold block-title">
                    <span class="font-['Avenir Cyrillic2']">{{ balanceState.toFixed(2) }}</span><span class="font-sans">₴</span>
                </div>
                <div class="text-gray-300 text-[8px] font-light">
                    (поточний баланс)
                </div>
            </div>
        </div>

        <outlay-accounting 
            :studentOutlays="allOutlays"
            @newYear="newYear => outlaysYear = newYear"
        />
        
        <income-accounting 
            :studentIncomes="allIncomes"
            :balanceId="balanceId"
        />
    </div>
</template>

<script>
    
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { getBalanceState, getAllIncomes, getAllOutlays } from '@/services/apiRequests'
    import OutlayAccounting from '../ui/OutlayAccounting.vue'
    import IncomeAccounting from '../ui/IncomeAccounting.vue'
    export default {
        name: 'new-finance-card',
        props: {
            balanceId: {
                type: String,
                default: ''
            },
        },

        setup(props) {
            const balanceState = ref(0)
            const allIncomes = ref([])
            const allOutlays = ref([])
            const outlaysYear = ref(new Date().getFullYear())
        
            onMounted(async () => {
                allIncomes.value = (await getAllIncomes(props.balanceId)).virtual_incomes
                allOutlays.value = (await getAllOutlays(props.balanceId, outlaysYear.value)).virtual_outlay

                const balanceResponse = await getBalanceState(props.balanceId)
                console.log(props.balanceId)
                console.log('current balance', balanceResponse)
                balanceState.value = balanceResponse
            })

            watch(outlaysYear, async () => {
                allOutlays.value = (await getAllOutlays(props.balanceId, outlaysYear.value)).virtual_outlay
            })

            return {
                balanceState,
                allIncomes,
                allOutlays,
                outlaysYear
            }
        },

        components: {
            OutlayAccounting,
            IncomeAccounting
        },
    }
</script>

