<template>
    <div>
        <svg width="135" height="82" viewBox="0 0 135 82" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M25.8185 1.888H133L100.25 53.888L2.00034 65.588L25.8185 1.888Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M133 1.888V62.5546L100.25 53.888L133 1.888Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M25.8184 79.888V1.88806L2.00024 65.588L25.8184 79.888Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'LeftGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>