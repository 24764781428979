<template>
    <TableRow>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <div class="flex items-center select-none">
                <div @click="closed = !closed" class="cursor-pointer">
                    <svg v-if="!closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L11.2929 14.2929C11.6262 14.6262 11.7929 14.7929 12 14.7929C12.2071 14.7929 12.3738 14.6262 12.7071 14.2929L18 9" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>    
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99991 15.0001L11.2928 9.70717C11.6261 9.37383 11.7928 9.20717 11.9999 9.20717C12.207 9.20717 12.3737 9.37383 12.707 9.70717L17.9999 15.0001" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div>
                    <div v-if="!isUpdateLeague" class="font-bold">
                        {{ league.name }}
                    </div>
                    <input v-else :value="league.name" @change="changeLeagueName" class="text-[#339989] text-[16px] w-[100px]" type="text" placeholder="Назва ліги">
        
                </div>
            </div>
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <img v-if="league.logo && league.logo.split('/')[league.logo.split('/').length - 1] != 'null'" @click="addLogoToLeague" :src="league.logo" class="w-[20px] cursor-pointer"  />
            <img v-else @click="addLogoToLeague" src="@/assets/icons/upload_icon.svg" class="w-[20px] cursor-pointer" />
            <input ref="newLeagueLogoAdd" type="file" @change="proccessNewLeagueLogo" class="hidden" />

        </TableCell>
        <TableCell :width="50" textAlign="start" :asChild="true">
            
            <div class="flex justify-end gap-[10px]">
                <img v-if="!isUpdateLeague" @click="isUpdateLeague = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img v-if="isUpdateLeague" @click="isUpdateLeague = false" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="$emit('deleteLeague', league.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>

        </TableCell>
    </TableRow>

    <SeasonBlock 
        v-if="closed"
        :season="season" 
        :league="league" 
        v-for="(season, index) in league.seasons"
        @addNewTeam="$emit('addNewTeam', $event)" 
        @deleteSeason="$emit('deleteSeason', {season: $event, leagueId: league.id})"   
        @deleteTeam="$emit('deleteTeam', $event)" 
        @changeOldTeamLogo="$emit('changeOldTeamLogo', $event)"

        @changeTeamName="$emit('changeTeamName', $event)"

        @updateSeasonName="$emit('updateSeasonName', $event)"
    />

    <TableRow v-if="closed">
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <input type="text" v-model="newSeasonName" placeholder="YYYY/YYYY">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="50" textAlign="start" :asChild="true">
            
            <div class="flex justify-end gap-[10px]">
                <!-- <img v-if="!isUpdateLeague" @click="isUpdateLeague = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" /> -->
                <img @click="addNewSeason" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="$emit('deleteLeague', league.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>

        </TableCell>
    </TableRow>
</template>
<script>
import { ref, onMounted, onBeforeUpdate, onUpdated } from 'vue';
import SeasonBlock from './SeasonBlock.vue'

export default {
    name: 'LeagueBlock',

    props: {
        league: {
            type: Object,
            required: true
        }
    },

    setup(props, { emit }) {
        const newTeamName = ref('');
        const newTeamLogo = ref(null);
        const oldTeamLogoAdd = ref(null);

        const isUpdateLeague = ref(false);

        const newSeasonName = ref('')

        const closed = ref(false)

        const addNewSeason = () => {
            emit('addNewSeason', {
                name: newSeasonName.value,
                leagueId: props.league.id
            })
            newSeasonName.value = ''
        }

        const changeLeagueName = (e) => {
            emit('changeLeagueName', {
                value: e.target.value,
                leagueId: props.league.id,
            });
        }

        let allLogosInputsRef = []

        onBeforeUpdate(() => {
            allLogosInputsRef = []
        })
        onUpdated(() => {
            console.log(allLogosInputsRef)
        })

        const addLogoInputRef = (ref) => {
            allLogosInputsRef.push(ref)
        }

        const newLeagueLogoAdd = ref(null)

        const newLeagueLogo = ref(null)
        const newLeagueLogoObject = ref(null)

        const changeOldTeamFlag = ref(false)

        const addLogoToLeague = () => {
            // if (isUpdateLeague.value) {
            newLeagueLogoAdd.value.click()
            // }
        }

        const proccessNewLeagueLogo = () => {
            const file = newLeagueLogoAdd.value.files[0]
            newLeagueLogoObject.value = file
            // const reader = new FileReader()
            // reader.onload = () => {
            //     newLeagueLogo.value = reader.result
            //     emit('changeLeagueLogo', reader.result)
            // }
            // reader.readAsDataURL(file)

            emit('changeLeagueLogo', {
                logo: file,
                leagueId: props.league.id,
                name: props.league.name
            })
        }

        const deleteNewTeam = () => {
            newTeamName.value = ''
            newTeamLogo.value = null
        }

        const addNewTeam = () => {
            emit('addNewTeam', {
                id: Math.random().toString(36).substr(2, 9),
                name: newTeamName.value,
                logo: newTeamLogo.value,
                logoObj: newTeamLogoObject.value,
                leagueId: props.league.id,
                season: props.league.seasons[0].id
            })
            newTeamName.value = ''
            newTeamLogo.value = null
        }

        const newTeamLogoAdd = ref(null)
        const newTeamLogoObject = ref(null)

        const proccessNewTeamLogo = () => {
            const file = newTeamLogoAdd.value.files[0]
            newTeamLogoObject.value = file
            const reader = new FileReader()
            reader.onload = () => {
                newTeamLogo.value = reader.result
            }
            reader.readAsDataURL(file)
        }

        const proccessOldTeamLogo = (teamId, index) => {
            const file = document.getElementById(`logoInput_${index}`).files[0]
            // newTeamLogo.value = file
            const reader = new FileReader()
            reader.onload = () => {
                emit('changeOldTeamLogo', {
                    teamId,
                    logo: reader.result
                })
            }
            reader.readAsDataURL(file)
        }

        const addLogoToTeam = () => {
            newTeamLogoAdd.value.click()
        }
        const addLogoToOldTeam = (index) => {
            document.getElementById(`logoInput_${index}`).click()
        }

        return {
            newTeamName,
            newTeamLogo,
            isUpdateLeague,
            newLeagueLogo,
            newLeagueLogoObject,
            newLeagueLogoAdd,
            proccessNewLeagueLogo,
            newTeamLogoAdd,
            proccessNewTeamLogo,
            addLogoToTeam,
            newTeamLogoObject,
            addLogoToLeague,
            changeLeagueName,
            oldTeamLogoAdd,
            proccessOldTeamLogo,
            addLogoToOldTeam,
            changeOldTeamFlag,
            addLogoInputRef,
            deleteNewTeam,
            newSeasonName,
            addNewSeason,
            addNewTeam,
            closed
        }
    },

    components: {
        SeasonBlock
    }
}
</script>
<style>
    
</style>