<template>
    <div class="mx-auto mt-[-55px] z-0">
        <input type="file" @change="e => $emit('addNewImages', e.target.files)" multiple class="hidden" id="additionalImages" />
        <div class="w-full mt-[-55px]">
            <div class="flex w-full">
                <div class="w-full flex justify-between">
                    <div class="w-[30%] h-[1px]">

                    </div>
                    <div class="w-[30%] z-50 flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Введіть назву вправи</div>
                        <input 
                            :value="elementName" 
                            @change="e => $emit('update:elementName', e.target.value)" 
                            class="bg-[#252526] text-white  text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                            :class="{
                                'border-[#DA4167] border-[3px]': !validation.elementName
                            }" 
                            placeholder="Розминка"
                        >
                    </div>
                    <div class="w-[30%] z-50 flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Шкала оцінювання</div>
                        <select :value="scoreboardType" @change="e => $emit('update:scoreboardType', e.target.value)" class="bg-[#252526] outline-none text-white  text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]">
                            <option value="time">на час</option>
                            <option value="length">на відстань</option>
                            <option value="count">на кількість</option>
                            <option value="point">на оцінку</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="flex w-full mt-[50px]">
                <div class="w-full flex justify-between">
                    <div class="w-[30%] flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Базовий норматив</div>
                        <select 
                            :value="baseStandart" 
                            @change="e => $emit('update:baseStandart', e.target.value)" 
                            class="bg-[#252526] outline-none text-white  text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"
                            :class="{
                                'border-[#DA4167] border-[3px]': !validation.baseStandart,
                                'text-[#8C8C8C]': baseStandart === '',
                            }"    
                        >
                            <option value="" disabled>Виберіть базовий норматив</option>
                            <option v-for="standard in allStandards" class="text-white" :value="standard.id" >{{ standard.name }}</option>
                        </select>    
                    </div>
                    <div class="w-[30%] flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Вікова група</div>
                        <select 
                            :value="ageGroup" 
                            @change="e => $emit('update:ageGroup', e.target.value)" 
                            class="bg-[#252526] outline-none text-white  text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"
                            :class="{
                                'border-[#DA4167] border-[3px]': !validation.ageGroup,
                                'text-[#8C8C8C]': ageGroup === '',
                            }"    
                        >
                            <option value="" disabled>Виберіть вікову категорію</option>
                            <option class="text-white" value="6_years" >6 років</option>
                            <option class="text-white" value="7_years">7 років</option>
                            <option class="text-white" value="8_years">8 років</option>
                            <option class="text-white" value="9_years">9 років</option>
                            <option class="text-white" value="10_years">10 років</option>
                            <option class="text-white" value="11_years">11 років</option>
                            <option class="text-white" value="12_years">12 років</option>
                            <option class="text-white" value="13_years">13 років</option>
                            <option class="text-white" value="14_years">14 років</option>
                            <option class="text-white" value="15_years">15 років</option>
                            <option class="text-white" value="16_years">16 років</option>
                            <option class="text-white" value="17_years">17 років</option>
                            <option class="text-white" value="18_years">18 років</option>
                            <option class="text-white" value="19_years">19 років</option>
                            <option class="text-white" value="20_years">20 років</option>
                            <option class="text-white" value="21_years">21 років</option>
                            <option class="text-white" value="22_years">22 років</option>
                            <option class="text-white" value="23_years">23 років</option>
                            <option class="text-white" value="main_team">Основна команда</option>
                        </select>    
                        
                    </div>
                    <div class="w-[30%] flex flex-col items-start justify-start">
                        <ScoreBoard :elementType="'standard'" :validation="!validation.scoreboardStructure" :boardType="scoreboardType" :scoreboardStructure="scoreboardStructure" @update="e => $emit('update:scoreboardStructure', e)"/>
                    </div>
                </div>
            </div>
            <div class="flex w-full mt-[50px]">
                <div class="w-full flex justify-between">
                    <div class="w-[30%] flex flex-col items-start justify-between">
                        <div>
                            <div class="text-[#BD9135] text-[14px] font-bold">Завантажте ілюстрацію схеми вправи</div>
                            <div class="flex justify-between mt-[7px]">
                                <div class="flex">
                                    <img v-for="image in additionalImages" @click="$emit('openSavedPicture', image)" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" />
                                    <img v-for="image in newAdditionalImages" @click="$emit('openNewPicture', image)" class="mr-[10px] cursor-pointer" src="@/assets/icons/picture_icon.svg" />
                                </div>
                                <div @click="selectAdditionalImage" class="cursor-pointer">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L12 4.5M12 14.5C11.2998 14.5 9.99153 12.5057 9.5 12M12 14.5C12.7002 14.5 14.0085 12.5057 14.5 12" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="text-[#BD9135] text-[14px] font-bold">Додані схеми</div>
                            <div class="flex justify-between mt-[7px]">
                                <div class="flex">
                                    <img v-for="schema in additionalShemas" @click="$emit('openSchema', schema)" class="mr-[10px]" src="@/assets/icons/picture_icon.svg" />
                                </div>
                                <div>

                                </div>
                            </div>
                        </div>
                        <div @click="$emit('addSchema')" class="border-[2px] border-[#BD9135] text-[#BD9135] px-[15px] py-[10px] mt-[15px] rounded-[5px] cursor-pointer">створити схему у редакторі</div>
                    </div>
                    <div class="w-[30%] flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Відео</div>
                        <div v-for="(link, id) in videos" class="flex items-center w-full">
                            <input :value="link" @change="e => $emit('changeVideoLink', {'value': e.target.value, 'id': id})" class="bg-[#252526] text-white  text-[18px] mt-[10px] w-full py-[3px] px-[5px] rounded-[6px]" placeholder="Посилання">
                            <div @click="$emit('deleteLink', id)" class="mt-[5px] ml-[5px] cursor-pointer">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.5" cy="8.5" r="8.5" fill="#FF0000"/>
                                <line x1="2.83325" y1="8.91663" x2="14.1666" y2="8.91663" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </div>
                        <input @change="e => {$emit('addNewVideoLink', e.target.value); e.target.value = ''}" class="bg-[#252526] text-white  text-[18px] mt-[10px] w-full py-[3px] px-[5px] rounded-[6px]" placeholder="Посилання">
                    </div>
                    <div class="w-[30%] flex flex-col items-start justify-start">
                        <div class="text-[#BD9135] text-[14px] font-bold">Опис</div>
                        <textarea 
                            :value="description" 
                            @change="e => $emit('update:description', e.target.value)" 
                            rows="6" 
                            class="bg-[#252526] mt-[10px] outline-none text-white  text-[18px] w-[100%] py-[5px] px-[5px] rounded-[6px]"
                            placeholder="Опис тренування"
                            :class="{
                                'border-[#DA4167] border-[3px]': !validation.description
                            }"     
                        ></textarea>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getAllStandardsRequest } from '@/services/apiRequests'
    import ScoreBoard from './ScoreBoard.vue'
    export default {
        name: 'StandartForm',

        props: {
            elementName: {
                type: String,
                default: ''
            },
            scoreboardType: {
                type: String,
                default: ''
            },
            scoreboardStructure: {
                type: Object,
                default: () => {}
            },
            ageGroup: {
                type: String,
                default: ''
            },
            videos: {
                type: Array,
                default: () => []
            },
            description: {
                type: String,
                default: ''
            },
            additionalImages: {
                type: Array,
                default: () => []
            },
            schemas: {
                type: Array,
                default: () => []
            },
            programId: {
                type: String,
                default: ''
            },
            newAdditionalImages: {
                type: Array,
                default: () => []
            },
            additionalShemas: {
                type: Array,
                default: () => []
            },
            validation: {
                type: Object,
                default: () => {}
            },
            baseStandart: {
                type: String,
                default: ''
            }
        },

        setup() {
            const selectAdditionalImage = () => {
                document.getElementById('additionalImages').click()
            }

            const allStandards = ref([])

            onMounted(async () => {
                allStandards.value = await getAllStandardsRequest()
            })

            return {
                selectAdditionalImage,
                allStandards
            }
        },

        components: {
            ScoreBoard
        }
    }
</script>

<style lang="scss" scoped>

</style>