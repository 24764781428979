<template>
  <router-view />
</template>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #252526;
    overflow-x: hidden;
  }

  body::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
  }

  body::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
  }

  body::-webkit-scrollbar-thumb {
    background-color: #696969;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
  }

  @font-face {
    font-family: 'Helvetica Bold';
    src: url('@/assets/fonts/helvetica_bold.otf');
  }

  @font-face {
    font-family: 'Helvetica Regular';
    src: url('@/assets/fonts/helvetica_regular.otf');
  }

  @font-face {
    font-family: 'Avenir Roman';
    src: url('@/assets/fonts/MaybeAvenir.ttf');
  }

  @font-face {
    font-family: 'Avenir Roman Bold';
    src: url('@/assets/fonts/AvenirLTStd-Black.otf');
  }

  @font-face {
    font-family: 'Avenir Cyrillic';
    src: url('@/assets/fonts/MaybeAvenir.ttf');
  }

  @font-face {
    font-family: 'Avenir Cyrillic Bold';
    src: url('@/assets/fonts/avenirnextcyr-bold.ttf');
  }
</style>