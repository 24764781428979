<template>
<svg width="123" height="169" viewBox="0 0 123 169" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <rect width="122.716" height="168.734" fill="#40A769"/>
        <path d="M72.8022 84.1967C72.8022 90.5064 67.6456 95.6253 61.2798 95.6253C54.9139 95.6253 49.7573 90.5064 49.7573 84.1967C49.7573 77.887 54.9139 72.7681 61.2798 72.7681C67.6456 72.7681 72.8022 77.887 72.8022 84.1967Z" stroke="#EFEFEF" stroke-width="0.575231"/>
        <ellipse cx="61.2696" cy="84.2749" rx="0.678738" ry="0.673347" fill="#EFEFEF"/>
        <rect x="5.46535" y="84.272" width="111.787" height="78.0396" stroke="#EFEFEF" stroke-width="0.575231"/>
        <mask id="path-5-inside-1_704_12530" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M50.9531 142.468H71.5795C69.1504 139.495 65.6884 137.674 61.3596 137.674C56.8006 137.674 53.3295 139.495 50.9531 142.468Z"/>
        </mask>
        <path d="M50.9531 142.468L50.5038 142.109L49.7568 143.043H50.9531V142.468ZM71.5795 142.468V143.043H72.7925L72.025 142.104L71.5795 142.468ZM61.3596 137.674L61.3596 137.099L61.3596 137.674ZM50.9531 143.043H71.5795V141.893H50.9531V143.043ZM61.3596 138.249C65.5094 138.249 68.8102 139.988 71.1341 142.832L72.025 142.104C69.4907 139.003 65.8674 137.099 61.3596 137.099L61.3596 138.249ZM51.4024 142.827C53.6679 139.993 56.9725 138.249 61.3596 138.249L61.3596 137.099C56.6286 137.099 52.9911 138.997 50.5038 142.109L51.4024 142.827Z" fill="#EFEFEF" mask="url(#path-5-inside-1_704_12530)"/>
        <rect x="49.7583" y="155.217" width="23.201" height="7.09451" stroke="#EFEFEF" stroke-width="0.575231"/>
        <rect x="56.6607" y="162.313" width="9.39544" height="3.64313" stroke="#EFEFEF" stroke-width="0.575231"/>
        <ellipse cx="61.2705" cy="146.974" rx="0.678738" ry="0.673347" fill="#EFEFEF"/>
        <line y1="-0.287615" x2="3.78587" y2="-0.287615" transform="matrix(-0.707107 0.707107 -0.712723 -0.701445 117.149 159.531)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <line y1="-0.287615" x2="3.78587" y2="-0.287615" transform="matrix(0.707107 0.707107 -0.712723 0.701445 5.17773 159.914)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <rect x="36.3355" y="142.178" width="50.0451" height="20.1331" stroke="#EFEFEF" stroke-width="0.575231"/>
        <rect x="117.249" y="84.2729" width="111.787" height="78.0396" transform="rotate(180 117.249 84.2729)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <mask id="path-14-inside-2_704_12530" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M71.7627 26.0791L51.1363 26.0791C53.5654 29.0515 57.0274 30.873 61.3562 30.873C65.9153 30.8731 69.3863 29.0516 71.7627 26.0791Z"/>
        </mask>
        <path d="M71.7627 26.0791L72.212 26.4383L72.959 25.5039L71.7627 25.5039L71.7627 26.0791ZM51.1363 26.0791L51.1363 25.5039L49.9233 25.5039L50.6909 26.4431L51.1363 26.0791ZM61.3562 30.873L61.3562 31.4483L61.3562 30.873ZM71.7627 25.5039L51.1363 25.5039L51.1363 26.6544L71.7627 26.6543L71.7627 25.5039ZM61.3562 30.2978C57.2064 30.2978 53.9057 28.5588 51.5817 25.7151L50.6909 26.4431C53.2252 29.5442 56.8484 31.4482 61.3562 31.4483L61.3562 30.2978ZM71.3134 25.7199C69.0479 28.5536 65.7433 30.2979 61.3562 30.2978L61.3562 31.4483C66.0872 31.4483 69.7247 29.5495 72.212 26.4383L71.3134 25.7199Z" fill="#EFEFEF" mask="url(#path-14-inside-2_704_12530)"/>
        <rect x="72.9565" y="13.3276" width="23.201" height="7.09451" transform="rotate(180 72.9565 13.3276)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <rect x="66.0542" y="6.23387" width="9.39544" height="3.64313" transform="rotate(180 66.0542 6.23387)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <ellipse cx="61.4433" cy="21.5708" rx="0.678738" ry="0.673347" transform="rotate(180 61.4433 21.5708)" fill="#EFEFEF"/>
        <line y1="-0.287615" x2="3.78587" y2="-0.287615" transform="matrix(0.707107 -0.707107 0.712723 0.701445 5.56543 9.01367)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <line y1="-0.287615" x2="3.78587" y2="-0.287615" transform="matrix(-0.707107 -0.707107 0.712723 -0.701445 117.537 8.63086)" stroke="#EFEFEF" stroke-width="0.575231"/>
        <rect x="86.3794" y="26.3667" width="50.0451" height="20.1331" transform="rotate(180 86.3794 26.3667)" stroke="#EFEFEF" stroke-width="0.575231"/>
    </g>
    <g v-if="playerAmplua">
        <circle :cx="positions[playerAmplua].dot_x" :cy="positions[playerAmplua].dot_y" r="3.22129" fill="white" stroke="#474849" stroke-width="2"/>
        <text :x="positions[playerAmplua].text_x" :y="positions[playerAmplua].text_y" font-family="Arial" font-size="14" fill="black" font-weight="900" text-anchor="middle">#{{playerNumber}}</text>
        <text :x="positions[playerAmplua].text_x" :y="positions[playerAmplua].text_y + 10" font-family="Arial" font-size="10" fill="black" text-anchor="middle">({{playerAmplua}})</text>
    </g>
</svg>

</template>

<script>
export default {
    name: 'PositionsField',

    props: {
        playerAmplua: {
            type: String,
            default: 'НП'
        },

        playerNumber: {
            type: Number,
            default: 1
        },

        role: {
            type: String,
            default: 'Опорний'
        }
    },

    data() {
        return {
            positions: {
                'ВР' : {
                    dot_x: 61.5,
                    dot_y: 20,
                    text_x: 61.5,
                    text_y: 38
                },
                'ЦЗ' : {
                    dot_x: 61.5,
                    dot_y: 50,
                    text_x: 61.5,
                    text_y: 68
                },
                'ЛЗ' : {
                    dot_x: 31.5,
                    dot_y: 50,
                    text_x: 31.5,
                    text_y: 68
                },
                'ПЗ' : {
                    dot_x: 91.5,
                    dot_y: 50,
                    text_x: 91.5,
                    text_y: 68
                },
                'ЦОП' : {
                    dot_x: 61.5,
                    dot_y: 70,
                    text_x: 61.5,
                    text_y: 88
                },
                'ЦП' : {
                    dot_x: 61.5,
                    dot_y: 70,
                    text_x: 61.5,
                    text_y: 88
                },
                'ЦАП' : {
                    dot_x: 61.5,
                    dot_y: 70,
                    text_x: 61.5,
                    text_y: 88
                },
                'ЛП' : {
                    dot_x: 31.5,
                    dot_y: 80,
                    text_x: 31.5,
                    text_y: 98
                },
                'ПП' : {
                    dot_x: 91.5,
                    dot_y: 80,
                    text_x: 91.5,
                    text_y: 98
                },
                'ПВ' : {
                    dot_x: 91.5,
                    dot_y: 110,
                    text_x: 91.5,
                    text_y: 128
                },
                'ЛВ' : {
                    dot_x: 31.5,
                    dot_y: 110,
                    text_x: 31.5,
                    text_y: 128
                },
                'НП' : {
                    dot_x: 61.5,
                    dot_y: 120,
                    text_x: 61.5,
                    text_y: 138
                }
            }
        }
    },
}
</script>

<style>
    
</style>