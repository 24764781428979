<template>
    <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "LineGraphElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { labels, dataSet } = toRefs(props);
    const chartContainer = ref(null);
    let myChart = null;

    let resizeObserver = null;

    const screenWidth = ref(window.innerWidth);
    const bottom = screenWidth.value>780? "10%": "10%"
    const isShow =screenWidth.value>700? true: false;
    const newLabels = screenWidth.value>900? labels.value : labels.value.map(element=>element.substring(0, 4))
    console.log(isShow)


    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const option = {
        tooltip: { trigger: 'item', },
        legend: { data: dataSet.value.map((element) => element.title), bottom: 0 },
        grid: { left: '0%', right: '2%', bottom: bottom, containLabel: true, },
        xAxis: { type: 'category', boundaryGap: false, data: newLabels, axisLine: { show: false, lineStyle: { color: '#fff', } }, axisLabel: { margin: 19, }, show: isShow },
        yAxis: { type: 'value', show: false, color: '#fff' },
        series: dataSet.value.map((element) => ({
          name: element.title,
          type: 'line',
          stack: 'Total',
          symbol: "circle",
          symbolSize: 10,
          itemStyle: {
            color: "#252526",
            borderColor: 'auto', // This doesn't work :(
            borderWidth: 3,
            shadowColor: '#252526',
            shadowBlur: 2,
            shadowOffsetY: 1,
          },
          color: element.color,
          lineStyle: { width: 5, color: element.color, },
          data: element.lineArray,
          label: { show: true, color: element.color, position: 'top', },
        })),
      };
      myChart.setOption(option, true);
    });
    onMounted(() => {
      if (!chartContainer.value) return;
      // Графік ініціалізується у watchEffect
    });
    const updateChartSize = () => {
      console.log("hello")
      if(myChart)myChart.resize();
    };

    onMounted(() => {
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }

      window.addEventListener('resize', updateChartSize);


    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });

    return { chartContainer };
  }
};
</script>

