<template>
    <div style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] fixed w-full z-40 h-full top-0"></div>
    <div v-if="false" style="background-color: rgba(53, 53, 53, 0.10)" class="fixed w-full z-[70] h-full top-0"></div>
    <div v-if="false" class="z-[90] fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div v-if="false" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-175px] mt-[-80px]"></div>
    </div>
    <div
        style="box-shadow: 4px 4px 4px 0px #00000040;"
        class="bg-[#252526] border-[1px] border-[#FFF] border-opacity-10 z-50 ml-[100px] rounded-[10px] w-[512px] px-[17px] py-[27px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="flex justify-center">
            <img src="@/assets/logo.svg" class="w-[56px]">
        </div>
        <div class="text-[18px] text-center mt-[10px] text-white font-semibold">
            Фільтри тренувань
        </div>

        <!-- <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    дата від
                </div>
                <div class="mt-[3px]">
                    <input 
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                        type="date" 
                        name="name"
                    >
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    дата до
                </div>
                <div class="mt-[3px]">
                    <input 
                        class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none"
                        type="date" 
                        name="name"
                    >
                </div>
            </div>
        </div> -->

        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    вікова категорія
                </div>
                <div class="mt-[3px]">
                    <select v-model="cloneFilter.ageCategory" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option class="bg-[#252526] text-white" value="all">всі</option>
                        <option class="bg-[#252526] text-white" value="6_years">6 років</option>
                        <option class="bg-[#252526] text-white" value="7_years">7 років</option>
                        <option class="bg-[#252526] text-white" value="8_years">8 років</option>
                        <option class="bg-[#252526] text-white" value="9_years">9 років</option>
                        <option class="bg-[#252526] text-white" value="10_years">10 років</option>
                        <option class="bg-[#252526] text-white" value="11_years">11 років</option>
                        <option class="bg-[#252526] text-white" value="12_years">12 років</option>
                        <option class="bg-[#252526] text-white" value="13_years">13 років</option>
                        <option class="bg-[#252526] text-white" value="14_years">14 років</option>
                        <option class="bg-[#252526] text-white" value="15_years">15 років</option>
                        <option class="bg-[#252526] text-white" value="16_years">16 років</option>
                        <option class="bg-[#252526] text-white" value="17_years">17 років</option>
                        <option class="bg-[#252526] text-white" value="18_years">18 років</option>
                        <option class="bg-[#252526] text-white" value="19_years">19 років</option>
                        <option class="bg-[#252526] text-white" value="20_years">20 років</option>
                        <option class="bg-[#252526] text-white" value="21_years">21 років</option>
                        <option class="bg-[#252526] text-white" value="22_years">22 років</option>
                        <option class="bg-[#252526] text-white" value="23_years">23 років</option>
                        <option class="bg-[#252526] text-white" value="main_team">Основна команда</option>
                    </select>
                </div>
            </div>
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    направлення
                </div>
                <div class="mt-[3px]">
                    <select disabled v-model="cloneFilter.direction" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option class="bg-[#252526] text-white" value="all">всі</option>
                        <option class="bg-[#252526] text-white" value="physical">фізичне</option>
                        <option class="bg-[#252526] text-white" value="tactical">тактичне</option>
                        <option class="bg-[#252526] text-white" value="technical">технічне</option>
                    </select>
                </div>
            </div>
        </div>


        <!-- <div class="text-[18px] text-center mt-[20px] text-white font-semibold">
            Сортування тренувань
        </div>


        <div class="flex justify-between mt-[20px]">
            <div class="w-[48%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    по часу
                </div>
                <div class="mt-[3px]">
                    <select 
                        class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none"
                    >
                        <option class="bg-[#252526] text-white" value="">без сортування</option>
                        <option class="bg-[#252526] text-white" value="desc">від більшого до меншого</option>
                        <option class="bg-[#252526] text-white" value="asc">від меншого до більшого</option>
                    </select>
                </div>
            </div>
        </div> -->

        <div class="w-full mt-[25px]">
            <div class="w-full flex justify-center">
                <button @click="$emit('setFilters', cloneFilter)" class="bg-[#8FC408] text-[#252526] w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                    Застосувати
                </button>
            </div>
            <div class="w-full mt-[10px] flex justify-center">
                <button @click="$emit('dropFilters')" class="bg-[#DA4167] text-white w-[260px] mx-auto font-medium h-[28px] rounded-[5px]">
                    Скинути фільтри
                </button>
            </div>
            <div class="w-full mt-[10px] flex justify-center">
                <button @click="$emit('close')" class="w-[260px] bg-[#BD9135] font-medium h-[28px] mx-auto rounded-[5px] text-[#252526]">
                    Закрити
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'

    export default {
        name: 'ElementFilter',

        props: {
            filter: {
                type: String,
                required: true
            }
        },

        setup(props, { emit }) {
            const cloneFilter = ref(props.filter)

            const filterElements = () => {
                emit('filterElements', cloneFilter.value)
            }

            return {
                cloneFilter
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>