<template>
    <div class="">
        <div class="flex items-center justify-between mx-[30px]">
            <div class="text-white text-[12px] font-semibold">
                списання
            </div>
            <div class="px-4 py-0.5 bg-[#BD9135] text-center text-[12px] text-black font-bold rounded-md ">
                {{ currentYear }}
            </div>
        </div>
        <div class="mt-[4px]  mx-[6px] flex justify-between align-bottom items-center ">
            <div>
                <img @click="backYear" class="cursor-pointer select-none" src="@/assets/icons/left_arrow.svg">
            </div>
            <div class="flex h-[60px] justify-between align-bottom items-end w-full mx-5">
                <div v-for="month in outlaysByMonth" class="flex flex-col justify-end align-bottom items-center w-[15px]">
                    <div class="text-[#FFF] text-xs">
                        {{ month.outlays }}
                    </div>
                    <div 
                        :style="{ height: `${month.barHeight > 0 ? month.barHeight : 1 }px` }"
                        class="w-[20px] bg-[#BD9135]"
                    ></div>
                    <div class="text-xs text-[#FFF]">
                        {{ month.month }}
                    </div>
                </div>

            </div>
            <div>
                <img @click="nextYear" class="cursor-pointer select-none" src="@/assets/icons/right_arrow.svg">
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, watch } from 'vue'

    export default {
        name: 'outlay-accounting',

        props: {
            studentOutlays: {
                type: Array,
            }
        },

        setup(props, { emit }) {
            const currentYear = ref(new Date().getFullYear())
            const outlaysByMonth = ref([])

            const backYear = () => {
                currentYear.value--
                buildDiagram()
            }

            const nextYear = () => {
                currentYear.value++
                buildDiagram()
            }

            const mapOutlaysMounthPerAmount = (studentOutlays) => {
                let outlaysWithMounth = []
                studentOutlays.forEach(outlay => {
                    const outlayDate = new Date(outlay.date)
                    const outlayMonth = outlayDate.toLocaleString('uk', { month: 'short' })
                    const outlayYear = outlayDate.getFullYear()

                    outlaysWithMounth.push({
                        month: outlayMonth,
                        year: outlayYear,
                        amount: outlay.amount
                    })
                })
                return outlaysWithMounth
            }

            const buildDiagram = () => {
                outlaysByMonth.value = []
                const mappedOutlays = mapOutlaysMounthPerAmount(props.studentOutlays)
                const outlaysToMonth = []

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthOutlays = mappedOutlays.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentOutlay = 0

                    if (currentMonthOutlays) {
                        currentMonthOutlays?.forEach(outlay => {
                            currentOutlay += outlay.amount
                        })
                    }
                    outlaysToMonth.push(currentOutlay)
                }

                const maxOutlay = Math.max(...outlaysToMonth)

                for (let i = 0; i < 12; i++) {
                    const currentMonth = new Date(currentYear.value, i, 1).toLocaleString('uk', { month: 'short' })
                    const currentMonthOutlays = mappedOutlays.filter(item => item.month === currentMonth && item.year === currentYear.value) 
                    
                    let currentOutlay = 0

                    if (currentMonthOutlays) {
                        currentMonthOutlays?.forEach(outlay => {
                            currentOutlay += outlay.amount
                        })
                    }
                    
                    const percentOfMaxOutlay = (currentOutlay * 100) / maxOutlay
                    const heightOfBar = (percentOfMaxOutlay * 30) / 100
                    outlaysByMonth.value.push({
                        month: currentMonth,
                        outlays: currentOutlay,
                        barHeight: heightOfBar
                    })
                }
            }

            watch(() => props.studentOutlays, () => {
                if (props.studentOutlays.length != 0) {
                    buildDiagram()
                }
            })

            watch(currentYear, () => {
                emit('newYear', currentYear.value)
            })

            onMounted(() => {
                if (props.studentOutlays.length == 0) {
                    buildDiagram()
                }
            })

            return {
                currentYear,
                outlaysByMonth,
                backYear,
                nextYear
            }
        }
    }
</script>
