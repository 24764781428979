<template>
    <div class="fixed shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] top-[50%] left-[50%] translate-x-[-50%] bg-[#252526] p-4 rounded-[10px] mt-[-20px] w-[250px]">
        <div v-if="scout?.status == 'archive'" class="text-center mb-3 text-[#FFF]">Ви дійсно хочете видалити скаута <b>{{ scout.name + ' ' + scout.surname }}</b>?</div>
        <div v-else class="text-center mb-3 text-[#FFF]">Ви дійсно хочете перенести в архів скаута <b>{{ scout.name + ' ' + scout.surname }}</b>?</div>
        <div class="flex justify-between">
            <button @click="deleteScout" class="bg-[#DA4167] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="$emit('close')" class="px-4 py-1 rounded-[10px] bg-[#BD9135]">Ні</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-scout-form',

        props: {
            scout: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteScout = () => {
                emit('acceptDeleteScout', props.scout)
            }

            return {
                deleteScout
            }
        }
    }
</script>
