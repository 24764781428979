<template>
  <div class="form-block">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBlock',
}
</script>
