<template>
    <TrainingsFilter v-if="filterFlag" :filter="filters" @dropFilters="dropFilters" @setFilters="e => setFilters(e)" @close="filterFlag = false" />
    <div class="bg-[#252526] border-[#101011] shadow-md shadow-black border-[1px] w-[500px] absolute py-[10px] rounded-[6px] ml-[120px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="flex justify-end px-[20px]">
            <svg @click="$emit('close')" class="cursor-pointer" width="19" height="19" viewBox="0 0 19 19" fill="#DA4167" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.375211 18.5122C0.884504 19.0098 1.74348 19.0034 2.2284 18.5185L9.43732 11.3096L16.642 18.5164C17.1354 19.0098 17.9934 19.0173 18.4931 18.5101C18.9928 18.0008 18.9949 17.162 18.5015 16.6665L11.2968 9.45006L18.5015 2.24536C18.9949 1.75201 19.0024 0.903575 18.4931 0.403892C17.9838 -0.105401 17.1354 -0.10751 16.642 0.395455L9.43732 7.60016L2.2284 0.393346C1.74348 -0.0990726 0.874894 -0.11712 0.375211 0.401783C-0.122363 0.911076 -0.116035 1.75833 0.368883 2.24325L7.5778 9.45006L0.368883 16.6707C-0.116035 17.1535 -0.131973 18.0125 0.375211 18.5122Z" fill="#DA4167"/>
            </svg>
        </div>
        <div>
            <div class="flex flex-col justify-between mb-[15px]">
                <div class="flex justify-center">
                    <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                        <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                        <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
                        <!-- <button class="bg-slate-500 hover:bg-slate-600 text-slate-200 px-2 py-1 rounded-md ml-2">Фільтр</button> -->
                    </div>
                    <div class="flex ml-[15px] items-center cursor-pointer" @click="filterFlag = true">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.85746 12.5061C6.36901 10.6456 4.59564 8.59915 3.62734 7.44867C3.3276 7.09253 3.22938 6.8319 3.17033 6.3728C2.96811 4.8008 2.86701 4.0148 3.32795 3.5074C3.7889 3 4.60404 3 6.23433 3H17.7657C19.396 3 20.2111 3 20.672 3.5074C21.133 4.0148 21.0319 4.8008 20.8297 6.37281C20.7706 6.83191 20.6724 7.09254 20.3726 7.44867C19.403 8.60062 17.6261 10.6507 15.1326 12.5135C14.907 12.6821 14.7583 12.9567 14.7307 13.2614C14.4837 15.992 14.2559 17.4876 14.1141 18.2442C13.8853 19.4657 12.1532 20.2006 11.226 20.8563C10.6741 21.2466 10.0043 20.782 9.93278 20.1778C9.79643 19.0261 9.53961 16.6864 9.25927 13.2614C9.23409 12.9539 9.08486 12.6761 8.85746 12.5061Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class=" w-[90%] mx-auto h-[380px] mt-3 mb-5 rounded-[10px] overflow-y-auto">
                <table class="w-full">
                    <thead class="text-white select-none">
                        <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                            <th class="px-3 py-2 w-[60%] text-start font-bold text-sm">Назва тренування</th>
                            <th class="px-3 w-[35%] py-2 text-end font-bold text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="summary in allSummaries" class="cursor-pointer border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                    <span class="text-[18px]">{{ summary.name[0] }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 w-[60%] text-start font-bold text-sm">{{ summary.name }}</td>
                            <td class="px-3 w-[35%] py-2 text-end font-medium text-sm">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                    @click="addSummary(summary)"
                                    v-if="!addedSummaries.includes(summary)"
                                >
                                    <img src="@/assets/icons/circle_plus_icon.svg"/>
                                </button>
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                    v-else
                                    @click="removeSummary(summary)"
                                >   
                                    <img src="@/assets/icons/circle_minus_icon.svg"/>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex justify-center flex-col items-center">
            <div class="flex justify-end px-[84px] mb-[15px]">
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
            </div>
            <div class="filter w-[30%]">
                <button @click="$emit('addTrainingsSummaries', addedSummaries)" class="bg-[#BD9135] hover:bg-[#a77f2b] w-full text-white px-4 py-[6px] text-[14px] rounded-md">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { addTrainingSummaryProgram, getAllSummaries, getStatisticsByElements } from '@/services/apiRequests'
    import TrainingsFilter  from './TrainingsFilter.vue'

    export default {
        name: 'AddTrainingForm',

        setup() {
            const addedSummaries = ref([])
            const allSummaries = ref([])

            const page = ref(1)
            const maxPage = ref(1)

            const searchQuery = ref('')

            const prevPage = () => {
                if (page.value > 1) {
                    page.value--
                }
            }

            const nextPage = () => {
                if (page.value < maxPage.value) {
                    page.value++
                }
            }

            const filterFlag = ref(false);

            const filters = ref({
                ageCategory: 'all',
                direction: 'all',
                timeSort: 'none',
            });
            

            watch(searchQuery, async (newSearchQuery) => {
                await updateSummaries()
            })

            const updateSummaries = async () => {
                allSummaries.value = await getAllSummaries(5, (page.value - 1) * 5, filters.value, searchQuery.value)
            }

            watch(page, async (newPage) => {
                await updateSummaries()
            })

            const addSummary = async (summary) => {
                addedSummaries.value.push(summary)
            }

            onMounted(async () => {
                await updateSummaries()

                const statistics = await getStatisticsByElements()
                maxPage.value = Math.ceil((statistics.standard + statistics.exercise + statistics.task) / 20)
            })

            const removeSummary = (summary) => {
                console.log('summary-id ----- ', summary)
                addedSummaries.value = addedSummaries.value.filter(item => item.summary_id !== summary.summary_id)
            }


            return {
                addedSummaries,
                addSummary,
                allSummaries,
                removeSummary,
                page,
                maxPage,
                prevPage,
                nextPage,
                searchQuery,
                filterFlag,
                filters,
                updateSummaries
            }
        },

        components: {
            TrainingsFilter
        }
    }
</script>

<style lang="scss" scoped>

</style>