<template>
    <div class="remove-button__wrapper ">

        <button @click="onClick" class="remove-button__item">
            <img :src="icon">
        </button>
    </div>
</template>

<script>
import icon from '@/assets/refactored_assets/img/players/remove.svg'
export default {
    name: 'RemoveButton',
    props: {
        onClick: {
            type: Function,
            required: true
        }
    },
    data(){
        return {
            icon: icon
        }
    }
}
</script>
