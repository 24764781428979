<template>
    <div class="w-[97%] mx-auto flex justify-between">
        <div class="flex w-[50%]">
            <div class="mt-[40px] w-[50%]">
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">логін</div>
                    <div class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px] flex">
                        <div class="mr-[10px]">380</div>
                        <input 
                            :value="login"
                            @change="$emit('update:login', $event.target.value)"
                            type="text"
                            class="text-white bg-transparent"
                            placeholder="login"
                        >
                    </div>
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">амплуа</div>
                    <select :value="amplua" @change="$emit('update:amplua', $event.target.value)" class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">
                        <option class="bg-[#252526]" value="">Не задано</option>
                        <option class="bg-[#252526]" value="ВР">ВР</option>
                        <option class="bg-[#252526]" value="ЦЗ">ЦЗ</option>
                        <option class="bg-[#252526]" value="ЛЗ">ЛЗ</option>
                        <option class="bg-[#252526]" value="ПЗ">ПЗ</option>
                        <option class="bg-[#252526]" value="ПЛ">ПЛ</option>
                        <option class="bg-[#252526]" value="ЛЛ">ЛЛ</option>
                        <option class="bg-[#252526]" value="ЦОП">ЦОП</option>
                        <option class="bg-[#252526]" value="ЦП">ЦП</option>
                        <option class="bg-[#252526]" value="ЦАП">ЦАП</option>
                        <option class="bg-[#252526]" value="ПП">ПП</option>
                        <option class="bg-[#252526]" value="ЛП">ЛП</option>
                        <option class="bg-[#252526]" value="ПВ">ПВ</option>
                        <option class="bg-[#252526]" value="ЛВ">ЛВ</option>
                        <option class="bg-[#252526]" value="НП">НП</option>
                    </select>
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">номер гравця</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="playerNumber"
                        @change="$emit('update:playerNumber', $event.target.value)"
                        type="text"
                        placeholder="77"
                    >
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">зріст (см)</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="height"
                        @change="$emit('update:height', $event.target.value)"
                        type="text"
                        placeholder="0.00"
                    >
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">розмір ноги</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="footSize"
                        @change="$emit('update:footSize', $event.target.value)"
                        type="text"
                        placeholder="0.00"
                    >
                </div>
            </div>
            <div class="mt-[40px] w-[50%]">
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">пароль</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="password"
                        @change="$emit('update:password', $event.target.value)"
                        type="text"
                        placeholder="*******"
                    >
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">роль</div>
                    <select :value="role" @change="$emit('update:role', $event.target.value)" class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">
                        <option class="bg-[#252526]" value="">Не задано</option>
                        <option class="bg-[#252526]" value="Стовп">Стовп</option>
                        <option class="bg-[#252526]" value="Бомбардир">Бомбардир</option>
                        <option class="bg-[#252526]" value="Мобільний">Мобільний</option>
                        <option class="bg-[#252526]" value="Інсайд">Інсайд</option>
                        <option class="bg-[#252526]" value="Плеймейкер">Плеймейкер</option>
                        <option class="bg-[#252526]" value="Конектор">Конектор</option>
                        <option class="bg-[#252526]" value="Ранер">Ранер</option>
                        <option class="bg-[#252526]" value="Латераль">Латераль</option>
                        <option class="bg-[#252526]" value="Диспетчер">Диспетчер</option>
                        <option class="bg-[#252526]" value="Руйнівник">Руйнівник</option>
                        <option class="bg-[#252526]" value="Оборонець">Оборонець</option>
                        <option class="bg-[#252526]" value="Гравець">Гравець</option>
                        <option class="bg-[#252526]" value="Атакуючий">Атакуючий</option>
                    </select>
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">нога</div>
                    <select :value="leadingLeg" @change="$emit('update:leadingLeg', $event.target.value)" class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]">
                        <option class="bg-[#252526]" value="">Не задано</option>
                        <option class="bg-[#252526]" value="Права">Права</option>
                        <option class="bg-[#252526]" value="Ліва">Ліва</option>
                        <option class="bg-[#252526]" value="Дві">Дві</option>
                    </select>
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">вага</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="weight"
                        @change="$emit('update:weight', $event.target.value)"
                        type="text"
                        placeholder="0.00"
                    >
                </div>
                <div class="w-[90%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">об'єм гр. (см)</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="breastVolume"
                        @change="$emit('update:breastVolume', $event.target.value)"
                        type="text"
                        placeholder="0.00"
                    >
                </div>
            </div>
        </div>

        <div class="flex w-[40%] flex-col mt-[40px]">
            <div class="text-[#BD9135] text-[14px] font-bold">кар'єра</div>
            <div>
                <div class="flex justify-between items-center mb-[20px]">
                    <input 
                        class="w-[46%] border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] py-[3px] px-[5px] rounded-[6px]" 
                        type="text"
                        placeholder="часовий проміжок"
                        v-model="newCareerTime"
                    >
                    <input 
                        class="w-[46%] border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] py-[3px] px-[5px] rounded-[6px]" 
                        type="text"
                        placeholder="клуб"
                        @blur="addNewCareer"
                        v-model="newCareerClub"
                    >
                    <div class="w-[26px]">
                        <!-- <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13Z" fill="#DA4167"/>
                        <path d="M6.11816 13.7656H13.0005L19.8829 13.7656" stroke="white" stroke-width="2"/>
                        </svg> -->
                    </div>
                </div>
                <div v-for="(career, id) in careerList" class="flex justify-between items-center mt-[20px]">
                    <input 
                        class="w-[46%] border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] py-[3px] px-[5px] rounded-[6px]" 
                        v-model="career.time"
                        type="text"
                        placeholder="часовий проміжок"
                    >
                    <input 
                        class="w-[46%] border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] py-[3px] px-[5px] rounded-[6px]" 
                        v-model="career.club"
                        type="text"
                        placeholder="клуб"
                    >
                    <div>
                        <svg @click="deleteCareer(id)" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13Z" fill="#DA4167"/>
                        <path d="M6.11816 13.7656H13.0005L19.8829 13.7656" stroke="white" stroke-width="2"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
    name: 'ParametersTab',

    props: {
        login: {
            type: String,
            default: ''
        },
        password: {
            type: String,
            default: ''
        },
        amplua: {
            type: String,
            default: ''
        },
        role: {
            type: String,
            default: ''
        },
        playerNumber: {
            type: String,
            default: ''
        },
        leadingLeg: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        weight: {
            type: String,
            default: ''
        },
        footSize: {
            type: String,
            default: ''
        },
        breastVolume: {
            type: String,
            default: ''
        },
        careerList: {
            type: Array,
            default: []
        }
    },

    setup(props, { emit }) {
        const newCareerTime = ref('')
        const newCareerClub = ref('')

        const careerList = ref(Object.assign([], props.careerList))

        const deleteCareer = (id) => {
            careerList.value.splice(id, 1)
            emit('update:careerList', careerList.value)
        }

        const addNewCareer = () => {
            careerList.value.push({
                time: newCareerTime.value,
                club: newCareerClub.value
            })
            emit('update:careerList', careerList.value)
            newCareerClub.value = ''
            newCareerTime.value = ''
        }

        return {
            careerList,
            newCareerTime,
            newCareerClub,
            deleteCareer,
            addNewCareer
        }
    },
}
</script>

<style>
    
</style> 
