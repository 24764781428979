<template>

    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M3.49951 16.8749V2L32.3742 13.8124V28.6872" class="color_changed_stroke" stroke="white" stroke-width="2.62497" stroke-linejoin="round"/>
            <ellipse cx="32.3745" cy="28.6875" rx="3.49996" ry="1.31249" fill="white"/>
            <ellipse cx="3.49996" cy="16.4375" rx="3.49996" ry="1.31249" fill="white"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'Barrier',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>