<template>
  <div class="report-section">
    <VerticalTextBlock :text="'потенціал'" :value="potential" />
    <VerticalTextBlock :text="'звітів по гравцю'" :value="number_of_reports" />

    <VerticalTextBlock :text="'загальний рейтинг'">
      <StarsComponent :rating="rating" />
    </VerticalTextBlock>

    <div class="report-section__last-item">
      <VerticalTextBlock :text="'оціночна вартість'">
        <CurrencyComponent
          :value="estimated_value"
          :smallSign="true"
          :currency="'EUR'"
        />
      </VerticalTextBlock>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportHeaderSection",
  props: {
    rating: {
      type: Number,
      required: true,
    },
    number_of_reports: {
      type: Number,
      required: true,
    },
    potential: {
      type: String,
      required: true,
    },
    estimated_value: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
