<template>

    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <circle cx="14" cy="14" r="12" fill="white" class="color_changed_fill" stroke="#474849" stroke-width="4"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'LinearDot',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>