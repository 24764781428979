<template>
  <div :class="['vertical-text-block',
    { 'vertical-text-block--text-end': textEnd},
    { 'vertical-text-block--text-start': textStart},
    
  ]">
    <span class="vertical-text-block__text">{{ text }}</span>
    <span v-if="value" class="vertical-text-block__value">{{ value }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VerticalTextBlock",
  props: {
    text: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: false
    },
    textEnd: {
        type:Boolean,
        default:false
    },
    textStart: {
        type: Boolean,
        default: false
    }
  }
}
</script>
