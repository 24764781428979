<template >
    <div class="player-model">
        <div><b>{{ getClothSize(breastVolume) }}</b> розмір</div>
        <img class="player-model__model" src="@/assets/refactored_assets/img/player-model/player_model.svg">
        <div class="player-model__breast-volume"><b>{{ breastVolume }}</b>см</div>
        <div class="player-model__leading-leg"><b>{{ leadingLeg ? leadingLeg.slice(0,2) + '.' : '' }}</b></div>
        <div class="player-model__leg-size"><b>{{ legSize }}</b>р.</div>
        <div class="player-model__weight"><b>{{ weight }}</b>кг</div>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            required: true,
            default: 0
        },
        breastVolume: {
            type: Number,
            required: true,
            default: 0
        },
        leadingLeg: {
            type: String,
            required: true,
            default: 0
        },
        legSize: {
            type: Number,
            required: true,
            default: 0
        },
        weight: {
            type: Number,
            required: true,
            default: 0
        }
    },

    setup(props) {
        const getClothSize = (chestCircumference) => {
            let size = ''
            
            if (chestCircumference < 94) {
                size = 'XS'
            } else if (chestCircumference < 98) {
                size = 'S'
            } else if (chestCircumference < 102) {
                size = 'M'
            } else if (chestCircumference < 106) {
                size = 'L'
            } else if (chestCircumference < 110) {
                size = 'XL'
            } else if (chestCircumference < 114) {
                size = 'XXL'
            } else {
                size = 'XXXL'
            }

            return size
        }

        return {
            getClothSize
        }
    }


}
</script>

<style lang="scss">
    
</style>