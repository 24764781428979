<template>
    <div>
        <svg width="43" height="33" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M1 16L6.5 4L15 1L18 2L21.5 2.5L24.8953 2L27.8256 1L36.1279 4L41.5 16L34.6628 19V32H8V19L1 16Z" class="color_changed_fill" fill="#C59014" stroke="#000" stroke-linecap="round"/>
                <text v-if="number <= 9" y="28" x="12" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="30px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
                <text v-if="number >= 10" y="23" x="10.4" fill="#FFFFFF" font-family="Avenir" font-weight="bold" font-size="18px" line-height="6px" text-align="center" stroke="#474849" stroke-width="1">{{number}}</text>
            </g>
        </svg>
    </div>

</template>

<script>
    export default {
        name: "SmallPlayer",
        props: {
            number: {
                type: Number,
                default: 1
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>