<template>
    <div class="avatar-container" :class="{ 'avatar-container--small': small }">
        <img :src="src" :alt="alt" class="avatar-container__image" />
    </div>
</template>

<script>
export default {
    name: 'AvatarContainer',
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: 'User Avatar'
        },
        small: {
            type: Boolean,
            default: false
        }
    }
}
</script>

