<template>
  <table class="date-component">
    <div class="date-component__day">{{ day }}</div>
    <div class="date-component__time">{{ time }}</div>
  </table>
</template>

<script>
export default {
  name: "DateComponent",
  components: {},
  props: {
    day: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
