<template>
    <FileManager 
        v-if="currentFilesSelect" 
        :filesObjects="currentFiles" 
        :marker="selectedFilesMarker" 
        @close="currentFilesSelect = false" 
        @delete="e => deleteFile(e.file, e.marker)"
        @upload="e => uploadFile(e.marker)"    
    />
    <div class="w-[97%] mx-auto flex justify-between">
        <div class="mt-[24px] w-[33%]">
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">name</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="name_eng"
                    @change="$emit('update:name_eng', $event.target.value)"
                    type="text"
                    placeholder="Leo"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">surname</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="surname_eng"
                    @change="$emit('update:surname_eng', $event.target.value)"
                    type="text"
                    placeholder="Messi"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">прізвище</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="surname"
                    @change="$emit('update:surname', $event.target.value)"
                    type="text"
                    placeholder="Мессі"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">ім’я</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="name"
                    @change="$emit('update:name', $event.target.value)"
                    type="text"
                    placeholder="Лео"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">по батькові</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="patronymic"
                    @change="$emit('update:patronymic', $event.target.value)"
                    type="text"
                    placeholder="Рональдович"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">національність</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="nationality"
                    @change="$emit('update:nationality', $event.target.value)"
                    type="text"
                    placeholder="українець"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">профіль соцмережі #1</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="social_network1"
                    @change="$emit('update:social_network1', $event.target.value)"
                    type="text"
                    placeholder="інстаграм"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">профіль соцмережі #2</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="social_network2"
                    @change="$emit('update:social_network2', $event.target.value)"
                    type="text"
                    placeholder="фейсбук"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">довірена особа</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="patronName"
                    @change="$emit('update:patronName', $event.target.value)"
                    type="text"
                    placeholder="Батько або мати"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">дата народження</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="birthday"
                    @change="$emit('update:birthday', $event.target.value)"
                    type="date"
                    placeholder="0.00"
                >
            </div>
        </div>
        <div class="mt-[24px] w-[33%]">
            <div class="w-[100%] mb-[15px] flex items-center">
                <div class="w-[70%]">
                    <div class="text-[#BD9135] text-[14px] font-bold">паспорт закордонний</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="abroadPassport"
                        @change="$emit('update:abroadPassport', $event.target.value)"
                        type="text"
                        placeholder="0000000-0000"
                    >
                </div>
                <div class="mt-[17px] ml-[7px] flex">
                    <div @click="uploadAbroadPassportFile" class="cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#339989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="$emit('addAbroadPassportFile', $event.target.files[0])"
                        ref="abroadPassportFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                    <div v-for="img in abroadPassportFiles" class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                </div>
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">дата закінчення закордонного паспорту </div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="abroadPassportEndDate"
                    @change="$emit('update:abroadPassportEndDate', $event.target.value)"
                    type="date"
                    placeholder="0.00"
                >
            </div>
            <div class="w-[100%] mb-[15px] flex items-center">
                <div class="w-[70%]">
                    <div class="text-[#BD9135] text-[14px] font-bold">паспорт UKR</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-full py-[3px] px-[5px] rounded-[6px]" 
                        :value="passport"
                        @change="$emit('update:passport', $event.target.value)"
                        type="text"
                        placeholder="UA0000000000"
                    >
                </div>
                <div class="mt-[17px] ml-[7px] flex">
                    <div @click="uploadPassportPhoto" class="cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#339989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="$emit('addPassportPhoto', $event.target.files[0])"
                        ref="passportFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                    <div v-if="passportPhotos.length > 0" @click="selectFiles(passportPhotos, 'PassportPhotos')" class="mx-[10px] cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">ким виданий</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="passportOffice"
                    @change="$emit('update:passportOffice', $event.target.value)"
                    type="text"
                    placeholder="Паспортний стіл"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">дата видачі паспорту</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="passportIssueDate"
                    @change="$emit('update:passportIssueDate', $event.target.value)"
                    type="date"
                    placeholder="0.00"
                >
            </div>
            <div class="w-[100%] mb-[15px] flex items-center">
                <div class="w-[70%]">
                    <div class="text-[#BD9135] text-[14px] font-bold">ID код</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="idCode"
                        @change="$emit('update:idCode', $event.target.value)"
                        type="text"
                        placeholder="00000000000"
                    >
                </div>

                <div class="mt-[17px] ml-[7px] flex">
                    <div @click="uploadIdPhoto" class="cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#339989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="$emit('addIdPhoto', $event.target.files[0])"
                        ref="idPhotosInput" 
                        type="file" 
                        class="hidden"
                    >
                    <div v-if="idPhotos.length > 0" @click="selectFiles(idPhotos, 'IdPhotos')" class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                </div>
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">тел.</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="phone"
                    @change="$emit('update:phone', $event.target.value)"
                    type="text"
                    placeholder="+380000000000"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">ел. адреса</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="email"
                    @change="$emit('update:email', $event.target.value)"
                    type="text"
                    placeholder="messi@gmail.com"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">адреса проживання</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="address"
                    @change="$emit('update:address', $event.target.value)"
                    type="text"
                    placeholder="Барселона, 29"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">тел. довіреної особи</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="patronPhone"
                    @change="$emit('update:patronPhone', $event.target.value)"
                    type="text"
                    placeholder="+380000000000"
                >
            </div>
        </div>
        <div class="mt-[24px] w-[33%]">
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">старт в клубі</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="startInClub"
                    @change="$emit('update:startInClub', $event.target.value)"
                    type="date"
                    placeholder="0.00"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">вид угоди</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="offerType"
                    @change="$emit('update:offerType', $event.target.value)"
                    type="text"
                    placeholder="0.00"
                >
            </div>
            <div class="w-[100%] mb-[15px] flex items-center">
                <div class="w-[70%]">
                    <div class="text-[#BD9135] text-[14px] font-bold">№ контракту/договору</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="contractNumber"
                        @change="$emit('update:contractNumber', $event.target.value)"
                        type="text"
                        placeholder="2012-01-02V2"
                    >
                </div>

                <div class="mt-[17px] ml-[7px] flex">
                    <div @click="uploadContractFile" class="cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#339989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="$emit('addContractFile', $event.target.files[0])"
                        ref="contractFilesInput" 
                        type="file" 
                        class="hidden"
                    >
                    <div v-if="contractFiles.length > 0" @click="selectFiles(contractFiles, 'ContractFiles')" class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                </div>
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">контракт діє до</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="contractExpireDate"
                    @change="$emit('update:contractExpireDate', $event.target.value)"
                    type="date"
                    placeholder="0.00"
                >
            </div>
            <div class="w-[100%] mb-[15px] flex items-center">
                <div class="w-[70%]">
                    <div class="text-[#BD9135] text-[14px] font-bold">довіреність</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
                        :value="attorney"
                        @change="$emit('update:attorney', $event.target.value)"
                        type="text"
                        placeholder="0.00"
                    >
                </div>
                <div class="mt-[17px] ml-[7px] flex">
                    <div @click="uploadAttorney" class="cursor-pointer">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18L4 13M16 8L12 4M12 4L8 8M12 4L12 16" stroke="#339989" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <input 
                        @change="$emit('addAttorneyPhoto', $event.target.files[0])"
                        ref="attorneyInput" 
                        type="file" 
                        class="hidden"
                    >
                    <div v-for="img in attorneyPhotos" class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div class="mx-[10px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.02693 18.329C4.18385 19.277 5.0075 20 6 20H18C19.1046 20 20 19.1046 20 18V14.1901M4.02693 18.329C4.00922 18.222 4 18.1121 4 18V6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V14.1901M4.02693 18.329L7.84762 14.5083C8.52765 13.9133 9.52219 13.8482 10.274 14.3494L10.7832 14.6888C11.5078 15.1719 12.4619 15.1305 13.142 14.5865L15.7901 12.4679C16.4651 11.9279 17.4053 11.8856 18.1228 12.3484C18.2023 12.3997 18.2731 12.4632 18.34 12.5302L20 14.1901M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#DA4167" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                </div>
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">предстакник/менеджер/агент</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="representative"
                    @change="$emit('update:representative', $event.target.value)"
                    type="text"
                    placeholder="Ланчелотті"
                >
            </div>
            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">агенція</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[70%] py-[3px] px-[5px] rounded-[6px]" 
                    :value="agency"
                    @change="$emit('update:agency', $event.target.value)"
                    type="text"
                    placeholder="назва агенції"
                >
            </div>
        </div>
    </div>
</template>


<script>
import { ref } from 'vue'
import FileManager from '../components/FileManager.vue'

export default {
    name: 'documents-tab',    

    props: {
        name: {
            type: String,
            required: true
        },
        surname: {
            type: String,
            required: true
        },
        patronymic: {
            type: String,
            required: true
        },
        name_eng: {
            type: String,
            required: true
        },
        surname_eng: {
            type: String,
            required: true
        },
        nationality: {
            type: String,
            required: true
        },
        address: {
            type: String,
            required: true
        },
        social_network1: {
            type: String,
            required: true
        },
        social_network2: {
            type: String,
            required: true
        },
        patronName: {
            type: String,
            required: true
        },
        patronPhone: {
            type: String,
            required: true
        },
        birthday: {
            type: String,
            required: true
        },
        abroadPassport: {
            type: String,
            required: true
        },
        abroadPassportEndDate: {
            type: String,
            required: true
        },
        passport: {
            type: String,
            required: true
        },
        passportOffice: {
            type: String,
            required: true
        },
        passportIssueDate: {
            type: String,
            required: true
        },
        idCode: {
            type: String,
            required: true
        },
        phone: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: true
        },
        address: {
            type: String,
            required: true
        },
        startInClub: {
            type: String,
            required: true
        },
        offerType: {
            type: String,
            required: true
        },
        contractNumber: {
            type: String,
            required: true
        },
        contractExpireDate: {
            type: String,
            required: true
        },
        attorney: {
            type: String,
            required: true
        },
        representative: {
            type: String,
            required: true
        },
        agency: {
            type: String,
            required: true
        },
        passportPhotos: {
            type: Array,
            required: true
        },
        idPhotos: {
            type: Array,
            required: true
        },
        attorneyPhotos: {
            type: Array,
            required: true
        },
        contractFiles: {
            type: Array,
            required: true
        },
        abroadPassportFiles: {
            type: Array,
            required: true
        }
    },

    setup(props, { emit }) {
        const passportFilesInput = ref(null)
        const idPhotosInput = ref(null)
        const attorneyInput = ref(null)
        const contractFilesInput = ref(null)
        const abroadPassportFilesInput = ref(null)

        const currentFilesSelect = ref(false)
        const currentFiles = ref([])
        const selectedFilesMarker = ref('')

        const selectFiles = (files, marker) => {
            currentFilesSelect.value = true
            currentFiles.value = files
            selectedFilesMarker.value = marker
        }

        const uploadAbroadPassportFile = () => {
            abroadPassportFilesInput.value.click()
        }

        const uploadContractFile = () => {
            contractFilesInput.value.click()
        }

        const uploadAttorney = () => {
            attorneyInput.value.click()
        }

        const uploadIdPhoto = () => {
            idPhotosInput.value.click()
        }

        const uploadPassportPhoto = () => {
            passportFilesInput.value.click()
        }

        const deleteFile = (file, marker) => {
            console.log('delete', file, marker)
            emit(`delete${marker}`, file)
        }

        const uploadFile = (marker) => {
            if (marker === 'PassportPhotos') {
                uploadPassportPhoto()
            }
        }

        return {
            passportFilesInput,
            uploadPassportPhoto,
            idPhotosInput,
            uploadIdPhoto,
            attorneyInput,
            uploadAttorney,
            contractFilesInput,
            uploadContractFile,
            abroadPassportFilesInput,
            uploadAbroadPassportFile,
            selectFiles,
            currentFilesSelect,
            currentFiles,
            selectedFilesMarker,
            deleteFile,
            uploadFile
        }
    },

    components: {
        FileManager
    }
}
</script>


<style>
    
</style>