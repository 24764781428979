<template>
    <div v-if="!dataLoaded && !editParametersFlag">
        <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000]">
            <div class="w-[50px] h-[50px] border-4 border-l-[#DA4167] border-t-[#DA4167] border-r-[#DA4167] border-b-transparent rounded-full animate-spin"></div>
        </div>
    </div>
    <div v-else-if="matchReport.startTeam && !editParametersFlag" class="match-report">
        <SaveMatchReport 
            v-if="saveMatchFlag"
            :score="`${matchReport.firstTeamName} ${ matchReport.firstTeamScore }:${ matchReport.secondTeamScore } ${matchReport.secondTeamName}`" 
            @close="saveMatchFlag = false"
            @save="saveFinalReport"
        />
        <GoalForm 
            v-if="goalFormFlag" 
            :team="matchReport.startTeam" 
            @close="goalFormFlag = false" 
            @save="e => { matchReport.events.push(e); goalFormFlag = false }" 
        />
        <YellowCardForm 
            v-if="yellowCardFormFlag" 
            :team="matchReport.startTeam" 
            @close="yellowCardFormFlag = false" 
            @save="e => { matchReport.events.push(e); yellowCardFormFlag = false }" 
        />
        <RedCardForm 
            v-if="redCardFormFlag" 
            :team="matchReport.startTeam" 
            @close="redCardFormFlag = false" 
            @save="e => { matchReport.events.push(e); redCardFormFlag = false }" 
        />
        <SwipeForm 
            v-if="swipeFormFlag" 
            :startTeam="matchReport.startTeam" 
            :reserveTeam="matchReport.reserveTeam" 
            @close="swipeFormFlag = false" 
            @save="swipeEventHandler" 
        />
        <EndMatchForm 
            v-if="endFormFlag" 
            @close="endFormFlag = false" 
            @save="e => { matchReport.events.push(e); endFormFlag = false }" 
        />
        <ChangeForm 
            v-if="changeFormFlag" 
            @close="changeFormFlag = false" 
            @save="changeEventHandler" 
        />
        <EndTimeForm 
            v-if="endTimeFlag" 
            @close="endTimeFlag = false" 
            @save="endTimeEventHandler"
        />
        <div class="match-report__ui">
            <Field3D class="match-report__field mx-auto" :currentPositionFirstTeam="matchReport.scheme" :fieldPart="matchReport.fieldPart" />

            <div class="match-report__interface">
                <div class="match-report__ui--event-buttons">
                    <ButtonComponent @click="yellowCardFormFlag = true">
                        жовта картка
                    </ButtonComponent>
                    <ButtonComponent @click="redCardFormFlag = true" :negative="true">
                        червона картка
                    </ButtonComponent>
                    <ButtonComponent @click="swipeFormFlag = true" :positive="true">
                        заміна
                    </ButtonComponent>
                    <ButtonComponent @click="goalFormFlag = true" class="white">
                        гол
                    </ButtonComponent>
                    <ButtonComponent @click="endFormFlag = true" class="dark-blue">
                        кінець матчу
                    </ButtonComponent>
                    <ButtonComponent @click="changeFormFlag = true" class="gold">
                        зміна схеми гри
                    </ButtonComponent>
                    <ButtonComponent @click="endTimeFlag = true" class="spring-blue">
                        кінець першого тайму
                    </ButtonComponent>
                    <ButtonComponent @click="saveMatchFlag = true" :outlined="true" class="gray-outline">
                        зберегти протокол
                    </ButtonComponent>
                    <ButtonComponent @click="$router.push('/match-center/matches')">
                        назад
                    </ButtonComponent>
                </div>
    
                <div class="match-report__banner">
                    <div class="match-report__banner--title">
                        <div>{{ matchReport.league }}</div>
                        <div>Тур {{ matchReport.tour }}</div>
                    </div>
    
                    <div class="match-report__banner--teams">
                        <div class="match-report__banner--team">
                            <img :src="matchReport.firstTeamLogo ? apiURL + 'static/' + matchReport.firstTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                            <div class="text-center truncate select-text" :title="matchReport.firstTeamName">{{ matchReport.firstTeamName }}</div>
                        </div>
                        <div class="match-report__banner--score">
                            <div>{{ matchReport.firstTeamScore }}:{{ matchReport.secondTeamScore }}</div>
                            <div>{{ matchTime }}''</div>
                        </div>
                        <div class="match-report__banner--team">
                            <img :src="matchReport.secondTeamLogo ? apiURL + 'static/' + matchReport.secondTeamLogo : require('@/assets/kolos_team.svg')" class="w-max" alt="">
                            <div class="truncate select-text" :title="matchReport.secondTeamName">{{ matchReport.secondTeamName }}</div>
                        </div>
                    </div>
    
                    <div class="match-report__banner--info">
                        <div>{{ matchReport.date.split('T')[0].split('-').reverse().join('.') }}</div>
                        <div>({{ matchReport.time }})</div>
                        <div>{{ matchReport.stadium }}</div>
                    </div>
                </div>
    
                <div class="match-report__team-structure">
                    <div class="match-report__team-structure--title">склад на матч</div>
                    <div class="match-report__team-structure--trainers">
                        <div class="match-report__team-structure--info-row">
                            <div>тренер:</div>
                            <div>{{ matchReport.trainer }}</div>
                        </div>
                        <div class="match-report__team-structure--info-row">
                            <div>схема:</div>
                            <div>{{ matchReport.scheme }}</div>
                        </div>
                    </div>
                    <div class="match-report__team-structure--comand overflow-x-hidden">
                        <div v-for="player in matchReport.startTeam" class="match-report__team-structure--player-line-active">
                            <div>{{ player.number }}</div>
                            <div>{{ player.name }}</div>
                        </div>
                        <div v-for="player in matchReport.reserveTeam" class="match-report__team-structure--player-line-not-active">
                            <div>{{ player.number }}</div>
                            <div>{{ player.name }}</div>
                        </div>
                    </div>
                    <ButtonComponent @click="editParametersFlag = true">
                        Редагувати
                    </ButtonComponent>
                </div>
            </div>

            <div class="match-report__timeline">
                <div class="match-report__timeline--line">
                    <component 
                        v-for="(event, id) in matchReport.events" 
                        :style="[`left: -${100 * id}px`]" 
                        :key="id" 
                        :is="event.type" 
                        :event="event" 

                        :data="event.data"
                        :time="event.time"
                        @delete="matchReport.events.splice(id, 1)"
                        @deleteSwipe="swipeEventDeletionHandler(event)"
                        @deleteSchemeEventHandler="deleteSchemeEventHandler(event)"
                        @deleteEndTimeEventHandler="deleteEndTimeEventHandler(event)"
                    />
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="editParametersFlag || !matchReport.startTeam">
        <SetPlayersStart :matchReport="matchReport" @close="$router.push('/match-center/matches')" @onlySave="onlySaveReport" @saveAndToReport="saveAndToReport"/>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getMatchReportById, updateMatchReport } from '@/services/apiRequests'

import Field3D from './Field3D.vue'
import SetPlayersStart from './SetPlayersStart.vue'

import EndMatch from './components/timelineEvents/EndMatch.vue'
import StartEvent from './components/timelineEvents/StartEvent.vue'
import SwipeEvent from './components/timelineEvents/SwipeEvent.vue'
import RedCard from './components/timelineEvents/RedCard.vue'
import YellowCard from './components/timelineEvents/YellowCard.vue'
import OpponentGoal from './components/timelineEvents/OpponentGoal.vue'
import GoalEvent from './components/timelineEvents/GoalEvent.vue'
import ChangeSchemeEvent from './components/timelineEvents/ChangeSchemeEvent.vue'
import EndTime from './components/timelineEvents/EndTime.vue'

import GoalForm from './components/EventForms/GoalForm.vue'
import YellowCardForm from './components/EventForms/YellowCardForm.vue'
import RedCardForm from './components/EventForms/RedCardForm.vue'
import SwipeForm from './components/EventForms/SwipeForm.vue'
import EndMatchForm from './components/EventForms/EndMatchForm.vue'
import ChangeForm from './components/EventForms/ChangeForm.vue'
import EndTimeForm from './components/EventForms/EndTimeForm.vue'

import SaveMatchReport from './components/SaveMatchReport.vue'

import { data } from 'autoprefixer'

export default {
    name: 'MatchReportForm',
    props: {
        match: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const route = useRoute()
        const router = useRouter()

        const dataLoaded = ref(false)

        const apiURL = ref(process.env.VUE_APP_API_URL)

        const goalFormFlag = ref(false)
        const yellowCardFormFlag = ref(false)
        const redCardFormFlag = ref(false)
        const swipeFormFlag = ref(false)
        const endFormFlag = ref(false)
        const changeFormFlag = ref(false)
        const endTimeFlag = ref(false)

        const saveMatchFlag = ref(false)

        const matchTime = ref(0)

        const editParametersFlag = ref(false)

        // const matchReport = ref({
        //     matchType: 'out',
            
        //     fieldPart: 'left',
        //     startFieldPart: 'left',

        //     time: '12:00',
        //     date: '12.12.2023',
        //     stadium: 'Стадіон "Колос"',
        //     league: 'УПЛ',
        //     tour: 'Тур 4',

        //     firstTeamScore: 0,
        //     firstTeamName: 'Зоря',
        //     firstTeamLogo: require('@/assets/zorya_team.svg'),

        //     secondTeamName: 'Колос',
        //     secondTeamLogo: require('@/assets/kolos_team.svg'),
        //     secondTeamScore: 0,

        //     trainer: 'А. Поздєєв',
        //     scheme: '4-5-1',
        //     startScheme: '4-5-1',

        //     startTeam: [
        //         {
        //             number: 11,
        //             name: 'Л. Ранжел',
        //             position: 'Воротар',
        //             active: true
        //         },
        //         {
        //             number: 8,
        //             name: 'В. Велетень',
        //             position: 'Захисник',
        //             active: true
        //         },
        //         {
        //             number: 10,
        //             name: 'П. Оріховський',
        //             position: 'Захисник',
        //             active: true
        //         },
        //         {
        //             number: 69,
        //             name: 'О. Ільїн',
        //             position: 'Півзахисник',
        //             active: true
        //         },
        //         {
        //             number: 7,
        //             name: 'А. Демченко',
        //             position: 'Півзахисник',
        //             active: true
        //         },
        //         {
        //             number: 48,
        //             name: 'О. Криворучко',
        //             position: 'Півзахисник',
        //             active: true
        //         },
        //         {
        //             number: 3,
        //             name: 'Р. Гончаренко',
        //             position: 'Півзахисник',
        //             active: true
        //         },
        //         {
        //             number: 27,
        //             name: 'В. Лучкевіч',
        //             position: 'Півзахисник',
        //             active: true
        //         },
        //         {
        //             number: 6,
        //             name: 'Н. Бурда',
        //             position: 'Нападник',
        //             active: true
        //         },
        //         {
        //             number: 9,
        //             name: 'А. Цуріков',
        //             position: 'Нападник',
        //             active: true
        //         },
        //         {
        //             number: 1,
        //             name: 'В. Горох',
        //             position: 'Нападник',
        //             active: true
        //         },
        //     ],
        //     reserveTeam: [
        //         {
        //             number: 17,
        //             name: 'А. Салабай',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 19,
        //             name: 'Д. Каріока',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 22,
        //             name: 'Д. Безбородько',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 91,
        //             name: 'А. Гусол',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 2,
        //             name: 'К. Кукош',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 14,
        //             name: 'В. Милько',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 15,
        //             name: 'А. Чорноморець',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 18,
        //             name: 'Є. Поправка',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 20,
        //             name: 'Р. Мисак',
        //             position: 'Захисник'
        //         },
        //         {
        //             number: 31,
        //             name: 'І. Пахолюк',
        //             position: 'Захисник'
        //         },
        //     ],

        //     events: [
        //         {
        //             id: 1,
        //             type: 'StartEvent',
        //             time: 0
        //         },
        //     ]
        // })

        const matchReport = ref({})

        onMounted(async () => {
            matchReport.value = await getMatchReportById(route.params.id)
            console.log(matchReport.value)

            if (matchReport.value.events == null) {
                matchReport.value.events = []

                matchReport.value.events.push(
                    {
                        id: 1,
                        type: 'StartEvent',
                        time: 0
                    }
                )
            }

            matchReport.value.startFieldPart = matchReport.value.fieldPart
            matchReport.value.startScheme = matchReport.value.scheme

            dataLoaded.value = true
        })

        watch(() => matchReport.value.events, (events) => {
            matchReport.value.events = matchReport.value.events.sort((a, b) => a.time - b.time)

            const firstTeamGoalsCount = events.filter(e => e.type === 'GoalEvent').length
            const secondTeamGoalsCount = events.filter(e => e.type === 'OpponentGoal').length

            matchTime.value = events[events.length - 1].time

            matchReport.value.firstTeamScore = matchReport.value.matchType == 'home' ? firstTeamGoalsCount : secondTeamGoalsCount
            matchReport.value.secondTeamScore = matchReport.value.matchType == 'home' ? secondTeamGoalsCount : firstTeamGoalsCount
        }, { deep: true })

        const swipeEventHandler = (e) => {
            matchReport.value.events.push(e)

            const currentStartPlayer = matchReport.value.startTeam.find(player => player.name === e.data.player1.name)
            const currentReservePlayer = matchReport.value.reserveTeam.find(player => player.name === e.data.player2.name)

            matchReport.value.startTeam = matchReport.value.startTeam.filter(player => player.name !== e.data.player1.name)
            matchReport.value.reserveTeam.push(currentStartPlayer)
            matchReport.value.reserveTeam = matchReport.value.reserveTeam.filter(player => player.name !== e.data.player2.name)
            matchReport.value.startTeam.push(currentReservePlayer)

            swipeFormFlag.value = false
        }

        const changeEventHandler = (e) => {
            matchReport.value.events.push(e)

            matchReport.value.scheme = e.data.newScheme

            changeFormFlag.value = false
        }

        const swipeEventDeletionHandler = (currentSwipeEvent) => {

            console.log(currentSwipeEvent)
            console.log(matchReport.value.startTeam, matchReport.value.reserveTeam)

            const currentStartPlayer = matchReport.value.startTeam.find(player => player.name === currentSwipeEvent.data.player2.name)
            const currentReservePlayer = matchReport.value.reserveTeam.find(player => player.name === currentSwipeEvent.data.player1.name)

            console.log(currentStartPlayer, currentReservePlayer)

            matchReport.value.startTeam = matchReport.value.startTeam.filter(player => player.name !== currentSwipeEvent.data.player2.name)
            matchReport.value.reserveTeam.push(currentStartPlayer)
            matchReport.value.reserveTeam = matchReport.value.reserveTeam.filter(player => player.name !== currentSwipeEvent.data.player1.name)
            matchReport.value.startTeam.push(currentReservePlayer)
            matchReport.value.events = matchReport.value.events.filter(e => e.id !== currentSwipeEvent.id)
        }

        const deleteSchemeEventHandler = (currentSchemeEvent) => {
            matchReport.value.events = matchReport.value.events.filter(e => e.id !== currentSchemeEvent.id)
            const allSchemeChangesEvents = matchReport.value.events.filter(e => e.type === 'ChangeSchemeEvent')
            const lastSchemeEvent = allSchemeChangesEvents[allSchemeChangesEvents.length - 1]
            console.log(lastSchemeEvent)
            if (lastSchemeEvent) {
                matchReport.value.scheme = lastSchemeEvent.data.newScheme
            } else {
                matchReport.value.scheme = matchReport.value.startScheme   
            }
        }

        const endTimeEventHandler = (e) => {
            matchReport.value.events.push(e)

            matchReport.value.fieldPart = matchReport.value.fieldPart == 'left' ? matchReport.value.fieldPart = 'right' : matchReport.value.fieldPart = 'left'

            endTimeFlag.value = false
        }

        const deleteEndTimeEventHandler = (currentEndTimeEvent) => {
            matchReport.value.events = matchReport.value.events.filter(e => e.id !== currentEndTimeEvent.id)

            matchReport.value.fieldPart = matchReport.value.startFieldPart

            matchReport.value.fieldPart = matchReport.value.startFieldPart
        }

        const onlySaveReport = async (newInfo) => {
            dataLoaded.value = false

            matchReport.value.startTeam = newInfo.startTeam
            matchReport.value.reserveTeam = newInfo.reserveTeam
            matchReport.value.scheme = newInfo.scheme
            matchReport.value.arbitrator = newInfo.arbitrator
            matchReport.value.assistant1 = newInfo.assistant1
            matchReport.value.assistant2 = newInfo.assistant2
            matchReport.value.fourthArbitrator = newInfo.fourthArbitrator
            matchReport.value.delegate = newInfo.delegate
            matchReport.value.trainer = newInfo.trainer
            matchReport.value.fieldPart = newInfo.fieldPart

            await updateMatchReport(matchReport.value.match_report_id, matchReport.value)

            router.push('/match-center/matches')
        }

        const saveAndToReport = async (newInfo) => {
            dataLoaded.value = false

            matchReport.value.startTeam = newInfo.startTeam
            matchReport.value.reserveTeam = newInfo.reserveTeam
            matchReport.value.scheme = newInfo.scheme
            matchReport.value.arbitrator = newInfo.arbitrator
            matchReport.value.assistant1 = newInfo.assistant1
            matchReport.value.assistant2 = newInfo.assistant2
            matchReport.value.fourthArbitrator = newInfo.fourthArbitrator
            matchReport.value.delegate = newInfo.delegate
            matchReport.value.trainer = newInfo.trainer
            matchReport.value.fieldPart = newInfo.fieldPart

            await updateMatchReport(matchReport.value.match_report_id, matchReport.value)

            router.go()
        }

        const saveFinalReport = async () => {
            dataLoaded.value = false

            matchReport.value.matchTime = matchTime.value

            await updateMatchReport(matchReport.value.match_report_id, matchReport.value)

            router.push('/match-center/matches')
        }

        return {
            goalFormFlag,
            yellowCardFormFlag,
            redCardFormFlag,
            swipeFormFlag,
            endFormFlag,
            matchTime,
            swipeEventHandler,
            swipeEventDeletionHandler,
            deleteSchemeEventHandler,
            changeEventHandler,
            changeFormFlag,
            endTimeEventHandler,
            endTimeFlag,
            deleteEndTimeEventHandler,
            saveMatchFlag,
            matchReport,
            apiURL,
            dataLoaded,
            onlySaveReport,
            saveAndToReport,
            saveFinalReport,
            editParametersFlag
        }
    },
    components: {
        Field3D,
        EndMatch,
        StartEvent,
        SwipeEvent,
        RedCard,
        YellowCard,
        OpponentGoal,
        GoalEvent,
        ChangeSchemeEvent,
        GoalForm,
        YellowCardForm,
        RedCardForm,
        EndMatchForm,
        GoalForm,
        SwipeForm,
        ChangeForm,
        EndTimeForm,
        SaveMatchReport,
        EndTime,
        SetPlayersStart
    }  
}
</script>

<style>
    
</style>
