<template>
 <tbody class="table__body" :class="{
    'table__body_scroll': bodyScroll
 }">
    <slot></slot>
  </tbody>  
</template>

<script>
export default {
    name: 'TableBody',
    props: {
      rows: {
        type: Array,
        required: true
      },
      bodyScroll: {
        type: Boolean,
        default: false
      }
  },
    components: {
    },
    data() {
        return {
          
        }
    },
}
</script>