<template>
    <div>
        <DeleteShadowTeamForm v-if="deletionFormFlag" @acceptDeleteTeam="e => deleteShadowTeam(e.id)" @close="deletionFormFlag = false" :team="teamForDeletion" />
        <div>
            <div class="search-block w-[90%] mx-auto">
                <div class="flex justify-between mt-2">
                    <InputComponent :value="query" @update:modelValue="e => query = e" placeholder="Пошук..." class="w-[250px]"/>
                    
                    <div>
                        <ButtonComponent @click="$router.push('/best-shadow-team')">
                            <div class="flex gap-[10px] px-[20px]">
                                <div>Топова команда</div>
                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 12V18" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M12 18C10.3264 18 8.86971 19.012 8.11766 20.505C7.75846 21.218 8.27389 22 8.95877 22H15.0412C15.7261 22 16.2415 21.218 15.8823 20.505C15.1303 19.012 13.6736 18 12 18Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M5 5H3.98471C2.99819 5 2.50493 5 2.20017 5.37053C1.89541 5.74106 1.98478 6.15597 2.16352 6.9858C2.50494 8.57086 3.24548 9.9634 4.2489 11" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M19 5H20.0153C21.0018 5 21.4951 5 21.7998 5.37053C22.1046 5.74106 22.0152 6.15597 21.8365 6.9858C21.4951 8.57086 20.7545 9.9634 19.7511 11" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"/>
                                    <path d="M12 12C15.866 12 19 8.8831 19 5.03821C19 4.93739 18.9978 4.83707 18.9936 4.73729C18.9508 3.73806 18.9295 3.23845 18.2523 2.61922C17.5751 2 16.8247 2 15.324 2H8.67596C7.17526 2 6.42492 2 5.74772 2.61922C5.07051 3.23844 5.04915 3.73806 5.00642 4.73729C5.00215 4.83707 5 4.93739 5 5.03821C5 8.8831 8.13401 12 12 12Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </div>
                            </div>
                        </ButtonComponent>
                    </div>

                    <div class="flex">
                        <div class="select-none flex items-center w-[200px] justify-between mr-[10px] text-[#FFF]">
                            <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                            <div class="flex mx-[20px] truncate">Сторінка: {{ currentPage }} / {{ maxPage }}</div>
                            <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                        </div>
                        <div class="filter w-[228px]">
                            <ButtonComponent @click="$router.push('/select-student-to-form-shadow-team')">Додати</ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
                <div class="text-white ml-[20px]">
                    <span class="text-[28px] font-semibold">{{ shadowTeamsCount }}</span> команд
                </div>
            </div>
        </div>

        <div class="w-[90%] mx-auto mb-3 rounded-[10px] overflow-hidden">
            <table class="w-full">
                <thead class="">
                    <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm">#</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">назва команди</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">дата</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">остання зміна</th>
                        <th class="px-3 py-2 text-start font-medium text-sm">скаут</th>
                        <th class=" py-2 text-end font-medium text-sm">коментар</th>
                        <th class="px-3 py-2 text-end font-medium text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="(team, id) in shadowTeams">
                        <td class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                            {{ id + 1 }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm font-medium truncate w-[10%]">
                            {{ team.name }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate">
                            {{ formateDateAndTime(team.created_date) }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm w-[15%] truncate">
                            {{ formateDateAndTime(team.last_update) }}
                        </td>
                        <td class="px-3 py-2 text-start text-sm">
                            {{ team.scout_name + ' ' + team.scout_surname }}
                        </td>
                        <td class="py-2 text-end text-sm">
                            {{ team.comment }}
                        </td>
                        <td class="px-3 py-2 text-end text-sm w-[10%]">
                            <button 
                                class="py-1 rounded-[10px] relative bottom-[3px] mr-[10px] text-sm font-medium text-red-600"
                                @click="$router.push('/shadow-team-view/' + team.id)"
                            >
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"></path><path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"></path></svg>
                            </button>
                            <button 
                                class="py-1 rounded-[10px] border-[1px] text-sm font-medium text-red-600"
                                @click="() => {teamForDeletion = team; deletionFormFlag = true}"
                            >
                                <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6">
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { getAllShadowTeams, deleteShadowTeamById, getCountShadowTeams } from '@/services/apiRequests'

import DeleteShadowTeamForm from './components/DeleteShadowTeamForm.vue'
import ButtonComponent from '@/components/uikit/Button/ButtonComponent.vue';

export default {
    name: 'ShadowTeams',    

    setup() {
        const shadowTeams = ref([])

        const teamForDeletion = ref(null)
        const deletionFormFlag = ref(false)

        const currentPage = ref(1)
        const query = ref('')
        const shadowTeamsCount = ref(0)
        const maxPage = ref(1)

        const prevPage = () => {
            if (currentPage.value > 1) {
                currentPage.value -= 1
            }
        }

        const nextPage = () => {
            if (currentPage.value < maxPage.value) {
                currentPage.value += 1
            }
        }

        const formateDateAndTime = (date) => {
            const newDate = new Date(date)
            return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString()
        }

        const deleteShadowTeam = async (id) => {
            await deleteShadowTeamById(id)
            deletionFormFlag.value = false
            await updateShadowTeams()
        }

        const updateShadowTeams = async () => {
            shadowTeams.value = await getAllShadowTeams(
                20,
                20 * (currentPage.value - 1),
                query.value
            )
        }

        watch(currentPage, async () => {
            await updateShadowTeams()
        })

        watch(query, async () => {
            await updateShadowTeams()
        })

        onMounted(async () => {
            await updateShadowTeams()

            shadowTeamsCount.value = await getCountShadowTeams()
            maxPage.value = Math.ceil(shadowTeamsCount.value / 20)
        })

        return {
            shadowTeams,
            formateDateAndTime,
            deleteShadowTeam,
            teamForDeletion,
            deletionFormFlag,

            currentPage,
            query,
            shadowTeamsCount,
            maxPage,
            prevPage,
            nextPage
        }
    },

    components: {
        DeleteShadowTeamForm
    }
}
</script>

<style>
    
</style>