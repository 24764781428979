<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] w-[400px] h-[297px] mt-[20px] rounded-[10px] pt-[10px] pb-[27px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="w-full text-center ">
            <div class="flex text-center items-center py-[4px] bg-[#BD9135] w-[95%] rounded-[3px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#252526] text-center mx-auto w-[60%]">ДОКУМЕНТИ</div>
            </div>
        </div>
        <image-viewer 
            class="absolute z-40 "
            v-if="showImageViewer"
            :image="currentImage"
            :photoType="currentPhotoType"
            @close="showImageViewer = false"
            @deletePhoto="$emit('deletePhoto', {currentImage, currentPhotoType})"
        />
        <div class="w-full px-[15px] mt-[-3px]">
        <div class="flex justify-between items-center align-middle mb-[8px]">
            <div>
                <input :value="nameEng" @change="$emit('update:nameEng', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Ім'я англійською">
            </div>
            <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                name
            </div>
        </div>
        <div class="flex justify-between items-center align-middle mb-[8px]">
            <div>
                <input :value="surnameEng" @change="$emit('update:surnameEng', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Прізвище англійською">
            </div>
            <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                surname
            </div>
        </div>

        <div class="flex justify-between items-center align-middle mb-[14px]">
            <div>
                <input :value="formattedPassportEndDate" @change="formattedPassportEndDate = $event.target.value" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="date">
            </div>
            <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                дата закінчення паспорту
            </div>
        </div>

        <div class="flex justify-between text-[#FFF] w-full items-center mb-[15px] h-[19px]">
            <div class="flex justify-between items-center align-middle">
                <div>
                    <input :value="passport" @change="$emit('update:passport', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Номер паспорту">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    паспорт
                </div>
            </div>
            <div class="text-sm flex">
                <img 
                    v-for="passportPhoto in passportPhotoArray"
                    @click="setImageToView(passportPhoto, 'passport')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadPassportPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addPassportPhoto', $event.target.files[0])"
                    ref="passportFilesInput" 
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>
        <div class="flex justify-between text-[#252526] w-full items-center mb-[15px] h-[19px]">
            <div class="flex justify-between items-center align-middle">
                <div>
                    <input :value="idNumber" @change="$emit('update:idNumber', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="00000000000">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    ID код
                </div>
            </div>
            <div class="text-sm flex">
                <img 
                    v-for="idNumber in idNumberPhotoArray"
                    @click="setImageToView(idNumber, 'idNumber')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadIdNumberPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addIdNumberPhoto', $event.target.files[0])"
                    ref="idNumberFilesInput" 
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>
        <div class="flex justify-between text-[#252526] w-full items-center mb-[12px] h-[19px]">
            <div class="flex justify-between items-center align-middle">
                <div>
                    <input :value="urkainianPassport" @change="$emit('update:urkainianPassport', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Номер паспорту">
                </div>
                <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                    паспорт України
                </div>
            </div>
            <div class="text-sm flex">
                <img 
                    v-for="ukrPassport in urkainianPassportPhotoArray"
                    @click="setImageToView(ukrPassport, 'ukrPassport')"
                    src="@/assets/icons/picture_icon.svg" 
                    class="mx-[2px] w-[16px] cursor-pointer"
                >
                <img 
                    @click="uploadUrkainianPassportPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="w-[16px] ml-2 cursor-pointer"
                >
                <input 
                    @change="$emit('addUkrPassportPhoto', $event.target.files[0])"
                    ref="ukrPassportFilesInput"
                    type="file" 
                    class="hidden"
                >
            </div>
        </div>

        <div class="flex justify-between items-center align-middle mb-[7px]">
            <div>
                <input :value="issuingAuthority" @change="$emit('update:issuingAuthority', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="text" placeholder="Адреса паспортного столу">
            </div>
            <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                ким виданий
            </div>
        </div>

        <div class="flex justify-between items-center align-middle">
            <div>
                <input :value="formattedIssuingDate" @change="formattedIssuingDate = $event.target.value" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" type="date">
            </div>
            <div class="text-[#BD9135] font-normal opacity-90 text-[11px]">
                дата видачі
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import {ref, computed} from 'vue'

    import DataInput from '../ui/DataInput.vue'
    import ImageViewer from '../ui/ImageViewer.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }


    export default {
        name: 'documentary-card',

        props: {
            nameEng: {
                type: String,
                default: ''
            },
            surnameEng: {
                type: String,
                default: ''
            },
            passport: {
                type: String,
                default: ''
            },
            passportPhotoArray: {
                type: Array,
                default: []
            },
            idNumber: {
                type: String,
                default: ''
            },
            idNumberPhotoArray: {
                type: Array,
                default: []
            },
            passportEndDate: {
                type: String,
                default: ''
            },
            urkainianPassport: {
                type: String,
                default: ''
            },
            urkainianPassportPhotoArray: {
                type: Array,
                default: []
            },
            issuingAuthority: {
                type: String,
                default: ''
            },
            issuingDate: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const passportFilesInput = ref(null)
            const idNumberFilesInput = ref(null)
            const ukrPassportFilesInput = ref(null)
            const currentImage = ref(null)
            const showImageViewer = ref(false)
            const currentPhotoType = ref(null)

            const formattedPassportEndDate = computed({
                get() {
                    if (!props.passportEndDate?.includes('T'))
                        return props.passportEndDate
                    return formateDate(props.passportEndDate)
                },
                set(value) {
                    emit('update:passportEndDate', value)
                }
            })

            const formattedIssuingDate = computed({
                get() {
                    if (!props.issuingDate?.includes('T'))
                        return props.issuingDate
                    return formateDate(props.issuingDate)
                },
                set(value) {
                    emit('update:issuingDate', value)
                }
            })

            const uploadPassportPhoto = () => {
                passportFilesInput.value.click()
            }

            const uploadIdNumberPhoto = () => {
                idNumberFilesInput.value.click()
            }

            const uploadUrkainianPassportPhoto = () => {
                ukrPassportFilesInput.value.click()
            }

            const setImageToView = (image, type) => {
                currentImage.value = image
                currentPhotoType.value = type
                showImageViewer.value = true
                console.log(currentPhotoType.value)
            }

            return {
                uploadPassportPhoto,
                passportFilesInput,
                currentImage,
                showImageViewer,
                setImageToView,
                uploadIdNumberPhoto,
                idNumberFilesInput,
                currentPhotoType,
                ukrPassportFilesInput,
                uploadUrkainianPassportPhoto,
                formattedPassportEndDate,
                formattedIssuingDate
            }
        },

        components: {
            DataInput,
            ImageViewer
        }
    }
</script>

<style lang="scss" scoped>
    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }

    .block-title { 
        font-family: 'Avenir Cyrillic Bold';
    }
    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23BD9135" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>