<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="w-[250px] h-[500px] bg-[#252526] rounded-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div>
            <div>
                <img 
                    @click="uploadPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="$emit('loadPhoto', $event)"
                />
            </div>
            <div class="flex justify-center h-[162px] w-full">
                <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo">
            </div>
        </div>

        <div class="bg-[#BD9135] w-full h-[74px] relative top-[10px]">
            <div class="text-[30px] font-semibold text-center">
                <div class="h-[45px]" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3 text-[30px]">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else class="text-[30px]">{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25 text-[30px]">Лєо Мессі</span>
            </div>
            <div class="flex relative justify-between top-[-5px] mx-[8px]">
                <div class="flex flex-col items-center justify-center">
                    <div class="text-[9px]">група</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="group?.length > 0">{{ group }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">вік</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="age > 0">{{ age }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">амплуа</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="studentAmplua?.length > 0">{{ studentAmplua }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">номер</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="studentNumber?.length > 0">{{ studentNumber }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex  items-center justify-center font-normal">
                    <img src="@/assets/logo.svg" class="w-[30px]">
                    <div class="text-[#252526] mx-1 mb-[2px]">|</div>
                    <img src="@/assets/icons/uk_flag_dark.svg" class="w-[20px] ml-[5px]">
                </div>
            </div>
        </div>

        <div class="mx-[14px] relative  top-[27px]">
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="surname" @change="$emit('update:surname', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="name" @change="$emit('update:name', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input :value="patronymic" @change="$emit('update:patronymic', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="По батькові" type="text">
                </div>
                <div class="font-normal w-[50%] text-end text-[#BD9135] opacity-90 text-[11px]">
                    по батькові
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="formattedBirthDate" @change="formattedBirthDate = $event.target.value" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="phoneNumber" @change="$emit('update:phoneNumber', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="+38000000000" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    телефон
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="email" @change="$emit('update:email', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="student@gmail.com" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    email
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input :value="nationality" @change="$emit('update:nationality', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Українець" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    національність
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import DataInput from '../ui/DataInput.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    export default {
        name: 'new-info-card',

        props: {
            surname: {
                type: String,
                default: '',
                required: true
            },
            name: {
                type: String,
                default: '',
                required: true
            },
            patronymic: {
                type: String,
                default: '',
                required: true
            },
            birthDate: {
                type: String,
                default: '',
                required: true
            },
            phoneNumber: {
                type: String,
                default: '',
                required: true
            },
            email: {
                type: String,
                default: '',
                required: true
            },
            group: {
                type: String,
                default: '',
                required: true
            },
            studentPhoto: {
                type: [String, null],
                default: null,
                required: true
            },
            nationality: {
                type: String,
                default: '',
                required: true
            },
            studentNumber: {
                type: String,
                default: '',
                required: true
            },
            studentAmplua: {
                type: String,
                default: '',
                required: true
            }
        },

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(props.studentPhoto)

            const formattedBirthDate = computed({
                get() {
                    if (!props.birthDate.includes('T'))
                        return props.birthDate
                    return formateDate(props.birthDate)
                },
                set(value) {
                    emit('update:birthDate', value)
                }
            })

            const age = computed({
                get() {
                    return new Date(props.birthDate) < new Date() ? new Date().getFullYear() - new Date(props.birthDate).getFullYear() : '—'
                }
            }) 
            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                console.log(typeof photo.value)
                if(photo.value === null || photo.value === 'null') {
                    photo.value = require('@/assets/student_photo_placeholder.svg')
                }
            })

            watch(() => props.studentPhoto, (first, second) => {
                photo.value = first
                inputValue.value = null
            })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                age,
                formattedBirthDate
            }
        },

        components: {
            DataInput
        },
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23BD9135" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>