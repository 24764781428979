<template>
  <div class="main__first-line first-line">
    <div class="first-line__item" v-for="(metric, index) in metricsData" :key="index">
      <h3 class="first-line__title">{{ metric.name }}</h3>
      <div class="first-line__value" :style="{'color' : metric.color}">
        <span>₴</span> <span>{{ metric.number.toLocaleString() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, watch} from 'vue';
export default {
  name: "TransparentGridMetrics",
  props: {
    metricsData: {
      type: Array,
      required: true
    },

    color: {
      type: String,
      default: '#9FA2B4'
    }
  },
  setup(props) {
    const { metricsData } = toRefs(props);
    watch(metricsData, (newVal) => {
      console.log('Змінено metricsData:', newVal);
    }, { deep: true });
  }
};
</script>

<style>
/* Ваші стилі для компонента */
</style>
