import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
    state: () => ({ 
        shadowTeamClubPlayers: [], 
        shadowTeamAlienPlayers: [],

        firstPlayerForComparison: null,
        secondPlayerForComparison: null,

        scoringRadarChartSettings: {}
    }),
})