<template>
  <div class="textarea-container">
    <label :for="name" class="textarea-container__label">{{ label }}</label>
    <textarea 
      :id="name" 
      :name="name" 
      :placeholder="placeholder" 
      :value="value" 
      @change="e => $emit('update:modelValue', e.target.value)" 
      :style="[`background-color: ${backgroundColor}`]"
      class="textarea-container__field"></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextareaContainer',
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'transparent'
    }
  }
}
</script>
