<template>
    <div>
        <svg width="131" height="78" viewBox="0 0 131 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M106.322 2H2L33.8763 51.6773L129.505 62.8547L106.322 2Z" fill="#474849" class="color_changed_fill" fill-opacity="0.7" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M2 2V59.9569L33.8763 51.6773L2 2Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
                <path d="M106.323 76.516V2L129.506 62.8547L106.323 76.516Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="2.48387" stroke-linejoin="round"/>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'RightGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>