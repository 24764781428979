<template>
    <FormBlock class="absolute left-[50%] top-[50%] translate-x-[-50%] z-[9999]">
        <div class="delete-form__title">Ви впевнені, що хочете видалити цей звіт?</div>
        <div class="flex">
            <ButtonComponent @click="$emit('yes')" :negative="true">Так</ButtonComponent>
            <ButtonComponent @click="$emit('no')" class="delete-form__button">Ні</ButtonComponent>
        </div>
    </FormBlock>
</template>

<script>
export default {
    name: 'DeleteForm',
}
</script>

<style>
    
</style>