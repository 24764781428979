<template>
    <SimpleStudentCard  
        v-if="group?.student_card_type == 'simple'"
        :student="currentStudent"
        @close="$emit('close')"
        @updateUsers="$emit('updateUsers')" 
    />
    <!-- <FullStudentCard 
        v-if="group?.student_card_type == 'full'" 
        :student="currentStudent"
        @close="$emit('close')" 
        @updateStudents="$emit('updateUsers')" 
        
    /> -->
    <NewFullStudentCard 
        v-else-if="group?.student_card_type == 'full'" 
        :student="currentStudent"
        @close="$emit('close')" 
        @updateStudents="$emit('updateUsers')" 
    />
    <NewFullStudentCard 
        v-else
        :student="currentStudent"
        @close="$emit('close')" 
        @updateStudents="$emit('updateUsers')" 
    />
    <!-- <SimpleStudentCard v-if="group?.student_card_type == 'full'" /> -->
</template>

<script>
import { ref, onMounted } from 'vue'
import { getStudentById, getAllCompletedTrainingsByStudentId, getGroupById } from '@/services/apiRequests'
import SimpleStudentCard from '../SimpleStudentCard/SimpleStudentCard.vue'
import FullStudentCard from '../FullStudentCard/FullStudentCard.vue'
import NewFullStudentCard from '../NewFullStudentCard/NewFullStudentCard.vue'

export default {
    name: 'student-card',

    props: {
        currentStudent: {
            type: Object,
            required: false
        }
    },

    setup(props, { emit }) {
        const student = ref(null)
        const trainings = ref([])
        const group = ref(null)

        onMounted(async () => {
            group.value = (await getGroupById(props.currentStudent.group_id)).data
            console.log('group.value ------ ', group.value)
            if (group.value?.student_card_type == 'full' || group.value?.student_card_type != 'simple') {
                emit('fullCard')
            }
        })

        return {
            student,
            trainings,
            group
        }
    },

    components: {
        SimpleStudentCard,
        FullStudentCard,
        NewFullStudentCard
    }
}
</script>

<style>
    
</style>