<template>
    <div class="fixed z-[300] w-full h-full backdrop-blur-md"></div>
    <FormBlock class="match-event-form">
        <div class="match-event-form__title">Зберегти протокол матчу</div>

        <div class="text-[#DA4167] text-[12px] font-semibold text-center">Після збереження протоколу матчу статистика з нього буде завантажена всім гравцям!</div>
        <div class="text-[#DA4167] text-[12px] font-semibold text-center">Якщо всі дані внесено вірно - натисніть “Зберегти”</div>

        <div class="h-[204px] flex justify-center">
            <div class="match-event-form__details">
                <img src="@/assets/icons/white_save_icon.svg" class="w-[60px]">
                <div>Рахунок матчу</div>
                <div>{{ score }}</div>
            </div>
        </div>

        <div class="match-event-form__buttons">
            <ButtonComponent @click="save">
                Зберегти
            </ButtonComponent>
            <ButtonComponent @click="$emit('close')">
                Відмінити
            </ButtonComponent>
        </div>

    </FormBlock>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'SaveMatchReport',

    props: {
        score: {
            type: String,
            default: '0:0'
        }
    },

    setup(props, { emit }) {
        const save = () => {
            emit('save')
        }

        return {
            save
        }
    }
}
</script>

<style>
    
</style>