<template>
    <div>
        <div class="search-block w-[90%] mx-auto">
            <div class="flex justify-between mt-2">
                <InputComponent :value="searchQuery" @update:modelValue="e => $emit('chageQuery', e)" placeholder="Пошук..." class="w-[250px]"/>
                <div class="flex gap-[10px]">
                    <IconButton :src="filter_icon" @click="$emit('setFilters')" />
                    <div class="ml-[20px] select-none flex items-center w-[200px] justify-between mr-[10px] text-[#FFF]">
                        <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                        <div class="flex mx-[20px] truncate">Сторінка: {{ currentPage }} / {{ studentsType === 'club_players' ? maxPlayerReports : maxAlienReports }}</div>
                        <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                    </div>
                    <div 
                        @click="$emit('clubPlayers')" 
                        :class="{
                            'border-[#C59014]': studentsType === 'club_players',
                            'border-[#A9AAAB66]': studentsType !== 'club_players'
                        }" 
                        class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                        гравці клубу
                    </div>
                    <div 
                        @click="$emit('otherPlayers')"
                        :class="{
                            'border-[#C59014]': studentsType === 'other_players',
                            'border-[#A9AAAB66]': studentsType !== 'other_players'
                        }"  
                        class="bg-[#47484966] cursor-pointer select-none border-[2px] text-[#C59014] text-[12px] flex justify-center items-center w-[128px] rounded-[8px]">
                        інші гравці
                    </div>
                    <div class="filter w-[228px]">
                        <ButtonComponent @click="$router.push('/select-student-to-form-scout-report')">Створити звіт</ButtonComponent>
                    </div>
                </div>
            </div>
        </div>

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white ml-[20px]">
                <div v-if="studentsType == 'club_players'"><span class="text-[28px] font-semibold">{{ clubPlayerReportsCount }}</span> виконано звітів</div>
                <div v-if="studentsType == 'other_players'"><span class="text-[28px] font-semibold">{{ alienPlayerReportsCount }}</span> виконано звітів</div>
            </div>
        </div>
    </div>
</template>

<script>
import filter_icon from '@/assets/icons/mini_filter_icon.svg';

export default {
    name: 'MenuBar',   
    
    setup(props, { emit }) {
        const prevPage = () => {
            if (props.currentPage > 1) {
                emit('update:currentPage', props.currentPage - 1)
            }
        }

        const nextPage = () => {
            if (props.currentPage < props.maxPage) {
                emit('update:currentPage', props.currentPage + 1)
            }
        }

        return {
            prevPage,
            nextPage,
            filter_icon: filter_icon
        }
    },

    props: {
        scoutCount: {
            type: Number,
            default: 0
        },
        studentsType: {
            type: String,
            default: 'club_players'
        },
        currentPage: {
            type: Number,
            default: 1
        },
        maxPage: {
            type: Number,
            default: 1
        },
        searchQuery: {
            type: String,
            default: ''
        },
        clubPlayerReportsCount: {
            type: Number,
            default: 0
        },
        alienPlayerReportsCount: {
            type: Number,
            default: 0
        },
        maxAlienReports: {
            type: Number,
            default: 0
        },
        maxPlayerReports: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style>
    
</style>