<template>
    <svg width="56" height="52" viewBox="0 0 56 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M1 23.619L7.13636 6.95238L20.6364 1L28.6136 2.19048L36.5909 1L49.4773 6.95238L55 23.619L46.4091 26L43.9545 18.8571L45.3294 51H12.0455L13.2727 18.8571L10.8182 26L1 23.619Z" fill="#FFFFFF" stroke="#474849" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
            <text y="33" x="19" fill="#000" font-family="Avenir" font-weight="bold" font-size="30px" line-height="6px" text-align="center">{{number}}</text>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'PlayerTwo',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            number: {
                type: Number,
                default: 1
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>