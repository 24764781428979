<template>
  <tfoot class="table__footer">
    <slot></slot>
  </tfoot>
</template>

<script>
export default {
  name: "TableFooter",
  props: {},
  components: {},
  data() {
    return {};
  },
};
</script>
