<template>
    <div>
        <div class="w-full flex justify-end">
            <div class="absolute top-[-60px] right-[40px]">
                <select v-model="currentMonth" name="month" class="outline-none rounded-[4px] w-[144px] px-[5px] py-[0.6px] text-start border-[#A4A6B3] text-[#FFF] text-[13px] cursor-pointer border-[1px] bg-transparent ">
                    <option v-for="month in generateMonthesAndYear()" :value="month.value" class="bg-[#252526]">{{ month.label }}</option>
                </select>
            </div>
        </div>
        <CircleDiagram v-if="dataLoadedFlag" class="w-max h-max relative bottom-[5px]" :canvasSize="charWidth" :data="chartData" :colors="['#8FC408', '#A1D9F7', '#e8f502']"/>
        <div v-else class="h-[220px] w-[260px] relative">
            <div class="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                <div class="text-[14px] text-gray-400 relative left-[15px] bottom-[10px]">Немає даних</div>
            </div>
        </div>
        <div class="flex justify-between relative top-[56px] left-[15px]">
            <div class="flex items-center">
                <div class="w-3 h-3 bg-[#8FC408] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">готівка</div>
            </div>
            <div class="flex items-center">
                <div class="w-3 h-3 bg-[#A1D9F7] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">безготівка</div>
            </div>
            <div class="flex items-center">
                <div class="w-3 h-3 bg-[#e8f502] rounded-2xl"></div>
                <div class="mx-2 text-[12px] text-[#9FA2B4]">інвестиції</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { getIncomesBySourcesByMonth } from '@/services/apiRequests'
    import CircleDiagram from '../CircleDiagram.vue'

    export default {
        name: 'PercentageIncomesBySource',

        setup() {
            const currentMonth = ref(0)
            const chartData = ref([0, 0, 0])
            const getMonthByNumber = (number) => {
                switch (number) {
                    case 1:
                        return 'січень'
                    case 2:
                        return 'лютий'
                    case 3:
                        return 'березень'
                    case 4:
                        return 'квітень'
                    case 5:
                        return 'травень'
                    case 6:
                        return 'червень'
                    case 7:
                        return 'липень'
                    case 8:
                        return 'серпень'
                    case 9:
                        return 'вересень'
                    case 10:
                        return 'жовтень'
                    case 11:
                        return 'листопад'
                    case 12:
                        return 'грудень'
                }
            }
            const dataLoadedFlag = ref(false)
            const charWidth = ref(220)
            const screenWidth = ref(window.innerWidth)
            const chartKey = ref(uuid.v4())

            // watch(screenWidth, (newValue) => {
            //     if (newValue < 1410) {
            //         charWidth.value = 180
            //     } else {
            //         charWidth.value = 220
            //     }
            // })

            const generateMonthesAndYear = () => {
                const monthes = []
                const year = new Date().getFullYear()
                for (let i = 0; i < 12; i++) {
                    monthes.push({
                        value: `${i + 1} ${year}`,
                        label: `${getMonthByNumber(i + 1)} ${year}`
                    })
                }
                return monthes
            }

            const getCurrentMonth = () => {
                const date = new Date()
                const month = date.getMonth()
                const year = date.getFullYear()

                return `${month + 1} ${year}`
            }

            onMounted(async () => {
                currentMonth.value = getCurrentMonth()

                const month = currentMonth.value.split(' ')[0]
                const year = currentMonth.value.split(' ')[1] 

                const data = await getIncomesBySourcesByMonth(year, month)
                console.log('month data', data)
                chartData.value = [data.cash_incomes, data.cashless_incomes, data.invest_incomes]
                dataLoadedFlag.value = true

                // window.addEventListener('resize', () => {
                //     screenWidth.value = window.innerWidth
                // })
            })

            watch(currentMonth, async (newValue) => {
                dataLoadedFlag.value = false
                const month = newValue.split(' ')[0]
                const year = newValue.split(' ')[1]
                chartData.value = [0, 0, 0] 

                const data = await getIncomesBySourcesByMonth(year, month)
                if (!data || data.cash_incomes + data.cashless_incomes + data.invest_incomes == 0) {
                    return
                }
                console.log('month data', data)
                const incomesSumm = data.cash_incomes + data.cashless_incomes + data.invest_incomes
                const cashIncomesPercentage = Math.round(data.cash_incomes / incomesSumm * 100)
                const cashlessIncomesPercentage = Math.round(data.cashless_incomes / incomesSumm * 100)
                const investIncomesPercentage = Math.round(data.invest_incomes / incomesSumm * 100)
                chartData.value = [data.cash_incomes, data.cashless_incomes, data.invest_incomes]
                console.log('percentages', chartData.value)
                chartKey.value = uuid.v4()
                dataLoadedFlag.value = true
            })

            return {
                getMonthByNumber,
                generateMonthesAndYear,
                currentMonth,
                dataLoadedFlag,
                chartData,
                charWidth,
                chartKey
            }
        },

        components: {
            CircleDiagram
        }
    }
</script>
