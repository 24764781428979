<template>
    <div>
        <div class="w-[30%] mx-auto">
            <div @click="$router.push('/training-elements')" class="bg-[#BD9135] cursor-pointer text-center text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Елементи тренування
            </div>
            <div @click="$router.push('/trainings-constructor')" class="bg-[#BD9135] cursor-pointer text-center text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Тренування
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TrainingConstructor',
    }
</script>

<style lang="scss" scoped>

</style>