<template>
    <!-- <div class="w-[100%] h-full bg-gray-300 opacity-30 absolute z-20"></div> -->
    <div style="background-color: rgba(53, 53, 53, 0.10);" class="w-[100%] backdrop-blur-[20px] ml-[-10px] top-[-30px] h-full opacity-30 absolute z-20"></div>
    <div v-if="loadingSpinnerFlag" class="z-[150] fixed w-full h-full top-[50%] left-[50%] ml-[115px]">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent "></div>
    </div>
    <ExitConfirmForm 
        v-else-if="exitConfirmForm && !loadingSpinnerFlag"
        @doSave="sendData"
        @dontSave="$emit('close')"
        @returnToCurrent="exitConfirmForm = false"
        :alertText="'Бажаєте зберігти зміни в тренуванні?'"
        class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[130px] z-50 border-[1px] border-[#FFF] border-opacity-10 w-[254px]"
    />
    <TrainingAttendingView 
        v-if="showAttendingView"
        :trainingDate="training.date"
        :groupId="group.group_id"
        :startTime="training.start_time"
        @close="showAttendingView = false"
    />
    <div v-else-if="!exitConfirmForm && !loadingSpinnerFlag && !addSummaryFlag && !showSummaryFlag" style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] shadow-md  w-[254px] absolute top-[50%] left-[50%] ml-[130px] translate-x-[-50%] translate-y-[-50%] z-50 px-[17px] py-[17px] rounded-[10px] select-none border-[1px] border-[#FFF] border-opacity-10">

        <div class="">
            <div>
                <div class="flex justify-center items-center">
                    <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 border-[1px] w-full items-center flex justify-center relative rounded-[4px] px-[4px]">
                        <img v-if="training.type == 'training'" src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <img v-else-if="training.type == 'gym'" src="@/assets/icons/red_dumbbell.svg" class="h-[18px]">
                        <img v-else-if="training.type == 'competition'" src="@/assets/icons/gold_cup.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'homework'" src="@/assets/icons/homework_icon.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'friendly-game'" src="@/assets/icons/friendly_game_icon.svg" class="h-[18px]">
                        <img v-else-if="trainingType == 'championship'" src="@/assets/icons/championship_icon.svg" class="h-[18px]">
                        <img v-else src="@/assets/icons/green_ball.svg" class="h-[18px] relative right-[2px]">
                        <select v-model="trainingType" class="bg-transparent text-[#fff] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                            <option value="null" class="bg-[#252526]">Не задано</option>
                            <option value="training" class="bg-[#252526]">Тренування</option>
                            <option value="gym" class="bg-[#252526]">Спортзал</option>
                            <option value="competition" class="bg-[#252526]">Змагання</option>
                            <option value="homework" class="bg-[#252526]">Домашнє завдання</option>
                            <option value="friendly-game" class="bg-[#252526]">Товариська гра</option>
                            <option value="championship" class="bg-[#252526]">Гра-чемпіонат</option>
                            <option value="trainer_meetup" class="bg-[#252526]">Нарада тренерів</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center bg-[#474849] bg-opacity-20">
                    <input :disabled="addCurrentDateFlag" v-model="changeDate" class="custom-date-input bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[1px] px-[3px] w-full disabled:opacity-40" type="date">
                </div>
            </div>
        </div>
   
        <div class="mt-5">
            <div class="flex justify-center items-center">
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.start" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
                <span class="font-bold mx-2 text-[#A1D9F7]">></span>
                <div class="bg-[#474849] bg-opacity-20">
                    <input v-model="trainingTime.end" class="custom-time-input bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[2px] px-1 w-full disabled:opacity-40" type="time">
                </div>
            </div>
        </div>

        <div v-if="trainingType != 'trainer_meetup'">
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center">
                        <div class="w-full">
                            <div class="bg-[#474849] bg-opacity-20">
                                <input
                                    @change="changeGroupText($event.target.value)"
                                    :value="groupText"
                                    @focus="groupOptionsShow = true"
                                    @blur="groupOptionsShow = false" 
                                    class="bg-transparent text-[#fff] text-center outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] py-[3px] w-full disabled:opacity-40" 
                                    type="text"
                                />
                            </div>
                            <div v-if="groupOptionsShow" class="overflow-auto absolute max-h-[100px] w-[220px] border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20">
                                <div v-for="groupOption in groupVisibleOptions" @mousedown="setGroup(groupOption)" class="w-full px-1 bg-[#8C8C8C] hover:bg-[#8C8C8C] cursor-pointer text-[#000]">{{groupOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="mt-5">
                <div>
                    <div class="flex justify-center items-center">
                        <div class="w-full">
                            <div class="bg-[#474849] bg-opacity-20">
                                <input
                                    @change="changeLocationText($event.target.value)"
                                    :value="locationText"
                                    @focus="locationOptionsShow = true"
                                    @blur="locationOptionsShow = false" 
                                    class="bg-transparent text-[#fff] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 text-center rounded-[4px] py-1 px-[3px] w-full disabled:opacity-40" 
                                    type="text"
                                />
                            </div>
                            <div v-if="locationOptionsShow" class="overflow-auto z-[150] absolute max-h-[100px] w-[220px] bg-[#252526]">
                                <div v-for="locationOption in locationVisibleOptions" @mousedown="setLocation(locationOption)" class="w-full px-1 bg-[#252526] hover:bg-[#252526] cursor-pointer text-[#A1D9F7]">{{locationOption.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div v-else class="h-[300px] scrollbar overflow-y-auto mt-[10px]">
            <div v-for="trainer in allTrainers" class="flex justify-between overflow-hidden ro h-[44px] rounded-[10px] my-[10px]">
                <div class="h-full truncate w-[50%] flex justify-start px-[10px] items-center bg-[#474849] text-white">
                    <div class="truncate">{{ trainer.surname + ' ' + trainer.name[0] + '.'}}</div>
                </div>
                <div v-if="trainer.selected" @click="trainer.selected = false" class="w-[50%] cursor-pointer bg-[#8FC408] h-full flex justify-center items-center">
                    <div class="text-black">Доданий</div>
                </div>
                <div v-else @click="trainer.selected = true" class="w-[50%] cursor-pointer bg-[#DA4167] h-full flex justify-center items-center">
                    <div class="text-black">Не доданий</div>
                </div>
            </div>
        </div>


        <div class="mt-5">
            <div>
                <div class="flex justify-center items-center">
                    <div class="w-full flex flex-col items-center relative">
                        <div class="bg-[#474849] bg-opacity-20 w-full">
                            <textarea
                                v-model="description"
                                class="bg-transparent text-[#fff] text-[13px] outline-none border-[1px] border-[#8C8C8C] bg-[#474849] bg-opacity-20 placeholder-gray-500 rounded-[4px] p-1 w-full disabled:opacity-40" 
                                type="text"
                                rows="6"
                                placeholder="Коментар до тренування"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="trainingType == 'trainer_meetup' && training.status == 'planed'" class="mt-5">
            <button @click="checkMeetUp" class="bg-[#8FC408] text-[#252526] text-center w-full rounded-md py-1 hover:bg-opacity-90">Відмітити як проведена</button>
        </div>
        <div v-if="trainingType == 'trainer_meetup' && training.status == 'completed'" class="mt-5">
            <button class="border-[#8FC408] border-[1px] text-[#8FC408] text-center w-full rounded-md py-1 hover:bg-opacity-90">Зустріч проведена</button>
        </div>

        <div v-if="trainingType != 'trainer_meetup'" class="mt-5">
            <button @click="addSummaryFlag = true" :class="{'opacity-50' : programsForGroup.length == 0}" class="border-[#BD9135] border-[1px] text-[#BD9135] text-center w-full rounded-md py-1 hover:bg-opacity-90">Додати конспект</button>
        </div>
        
        <div v-if="training.summary" class="mt-[10px]">
            <div class="truncate text-gray-400">Додано конспект: <span @click="showSummaryFlag = true" class="text-[#BD9135] cursor-pointer">{{ selectedSummary.name }}</span></div>
        </div>

        <div v-if="training.status == 'completed' && trainingType != 'trainer_meetup'" class="mt-2">
            <button @click="showAttendingView = true" class="bg-[#686868] text-[#fff] text-center w-full rounded-md py-1 hover:bg-opacity-90">Переглянути відвідування</button>
        </div>
        <div v-if="trainingType != 'trainer_meetup'" class="mt-5">
            <!-- <button @click="" class="bg-[#8FC408] text-[#252526] text-center w-full rounded-md py-1 hover:bg-opacity-90">Відкрити на карті</button> -->
        </div>
        <div class="mt-2">
            <button @click="exitConfirmForm = true" class="bg-[#BD9135] text-[#252526] text-center w-full rounded-md py-1 hover:bg-opacity-90">Закрити</button>
        </div>
    </div>
    <AddSummaryToTrainingForm v-if="addSummaryFlag" :date="trainingDate" :programs="programsForGroup" @addSummary="e => selectSummary(e)" />

    <TrainingForm v-if="showSummaryFlag" @close="showSummaryFlag = false" class="relative z-[999] w-[90%] h-[100vh]" :summary="selectedSummary" />
</template>

<script>
    import { ref, computed, reactive, onBeforeMount } from 'vue'
    import { getAllLocations, getAllGroups, updateTraining, getGroupById, getLocationById, getProgramsByGroupId, getAllTrainers, updateTrainerMeetUpById } from '@/services/apiRequests'
    import ExitConfirmForm from '../ui/ExitConfirmForm.vue'
    import AddSummaryToTrainingForm from './AddSummaryToTrainingForm.vue'
    import TrainingForm from './SummaryView/TrainingForm.vue'
    import TrainingAttendingView from './TrainingAttendingView.vue'

    export default {
        name: 'full-training-view',

        props: {
            training: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const settedDate = new Date(props.training.date)
            const settedYear = settedDate.getFullYear() > 10 ? settedDate.getFullYear() : `0${settedDate.getFullYear()}`
            const settedMonth = settedDate.getMonth() + 1 > 10 ? settedDate.getMonth() + 1 : `0${settedDate.getMonth() + 1}`
            const settedDay = settedDate.getDate() > 10 ? settedDate.getDate() : `0${settedDate.getDate()}`
            const changeDate = ref(`${settedYear}-${settedMonth}-${settedDay}`)

            const exitConfirmForm = ref(false)

            const group = ref(null)
            const groupText = ref(props.training.group_label)
            
            const location = ref(null)
            const locationText = ref(props.training.location_label)

            const groupOptions = ref([])
            const locationOptions = ref([])

            const trainingType = ref('none')
            const description = ref('')

            const loadingSpinnerFlag = ref(false)
            const showAttendingView = ref(false)

            const programsForGroup = ref([])
            const addSummaryFlag = ref(false)
            const selectedSummary = ref(props.training.summary)

            const trainingDate = ref(props.training.date.split('T')[0].split('-').reverse().join('.'))

            const showSummaryFlag = ref(false)
            const allTrainers = ref([])

            const parserDatetime = (date) => {
                const [year, month, day] = date.split('-')
                return new Date(year, month - 1, day, 0, 0)
            }

            const checkMeetUp = async () => {
                loadingSpinnerFlag.value = true
                await updateTrainerMeetUpById(
                    props.training.meet_up_id,
                    {
                        date: parserDatetime(changeDate.value),
                        start_time: trainingTime.value.start,
                        end_time: trainingTime.value.end,
                        description: description.value,
                        trainers_ids: allTrainers.value.filter(trainer => trainer.selected).map(trainer => trainer.trainer_id),
                        type: trainingType.value,
                        status: 'completed'
                    }
                )
                emit('updateTrainings')
                emit('close')
                loadingSpinnerFlag.value = false
            }

            onBeforeMount(async () => {
                const resGroups = await getAllGroups()
                groupOptions.value = resGroups.data

                const resLocations = await getAllLocations()
                locationOptions.value = resLocations.data

                console.log('props.training --- ', props.training.type)

                if (props.training.type != 'trainer_meetup') {
                    const currentGroupRes = await getGroupById(props.training.group_id)
                    group.value = currentGroupRes.data
    
                    const currentLocationRes = await getLocationById(props.training.location_id)
                    location.value = currentLocationRes.data
    
                    const res = await getProgramsByGroupId(group.value.group_id)
                    programsForGroup.value = res
                }

                const resTrainers = await getAllTrainers()
                allTrainers.value = resTrainers.data.map(trainer => {
                    return {
                        ...trainer,
                        selected: props.training.trainers_ids.includes(trainer.trainer_id)
                    }
                })

                trainingType.value = props.training.type
                description.value = props.training.description
            })

            const groupVisibleOptions = computed(() => {
                return groupOptions.value.filter(option => option.name.toLowerCase().includes(groupText.value.toLowerCase()) || groupText.value === '')
            })

            const locationVisibleOptions = computed(() => {
                return locationOptions.value.filter(option => option.name.toLowerCase().includes(locationText.value.toLowerCase()) || locationText.value === '')
            })

            const changeGroupText = (groupName) => {
                groupText.value = groupName
                group.value = groupVisibleOptions.value[0]
            }

            const changeLocationText = (locationName) => {
                locationText.value = locationName
                location.value = locationVisibleOptions.value[0]
            }

            const setLocation = (locationOption) => {
                location.value = locationOption
                locationText.value = locationOption.name
                locationOptionsShow.value = false
            }

            const setGroup = (groupOption) => {
                group.value = groupOption;
                groupText.value = groupOption.name;
                groupOptionsShow.value = false;
            }

            const locationOptionsShow = ref(false)
            const groupOptionsShow = ref(false)

            const trainingTime = ref({
                start: props.training.start_time,
                end: props.training.end_time
            })

            const selectSummary = (summary) => {
                console.log(summary)
                selectedSummary.value = summary
                addSummaryFlag.value = false
            }

            const sendData = async () => {
                loadingSpinnerFlag.value = true

                if (trainingType.value == 'trainer_meetup') {
                    await updateTrainerMeetUpById(
                        props.training.meet_up_id,
                        {
                            date: parserDatetime(changeDate.value),
                            start_time: trainingTime.value.start,
                            end_time: trainingTime.value.end,
                            description: description.value,
                            trainers_ids: allTrainers.value.filter(trainer => trainer.selected).map(trainer => trainer.trainer_id),
                            type: trainingType.value,
                            status: trainingType.status
                        }
                    )
                } else {
                    await updateTraining(
                        props.training.training_id,
                        group.value.name, 
                        group.value.group_id, 
                        location.value.small_name, 
                        location.value.location_id, 
                        trainingTime.value.start, 
                        trainingTime.value.end, 
                        changeDate.value,
                        trainingType.value,
                        description.value,
                        selectedSummary.value
                    )
                }
                emit('updateTrainings')
                emit('close')
                loadingSpinnerFlag.value = false
            }            

            return {
                changeDate,
                trainingTime,
                group,
                location,
                groupOptions,
                locationOptions,
                locationOptionsShow,
                groupOptionsShow,
                groupVisibleOptions,
                locationVisibleOptions,
                groupText,
                locationText,
                changeGroupText,
                changeLocationText,
                setLocation,
                setGroup,
                sendData,
                trainingType,
                description,
                exitConfirmForm,
                loadingSpinnerFlag,
                addSummaryFlag,
                programsForGroup,
                trainingDate,
                selectSummary,
                selectedSummary,
                showSummaryFlag,
                showAttendingView,
                allTrainers,
                checkMeetUp
            }
        },

        components: {
            ExitConfirmForm,
            AddSummaryToTrainingForm,
            TrainingForm,
            TrainingAttendingView
        }
    }
</script>

<style lang="scss">

/* Скрываем стандартную стрелку в поле ввода */
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/white_calendar.svg');
    cursor: pointer;
}

.custom-time-input::-webkit-calendar-picker-indicator {
    background-image: url('../../../assets/icons/clock_icon.svg');
    cursor: pointer;
}


.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #696969;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

</style>
