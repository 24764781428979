<template>
    <div class="w-[90%] mx-auto">
        <div class="border-[1px] border-[#BD9135] px-[20px] py-[10px] w-[300px] rounded-[9px]">
            <div class="text-center font-bold text-[#BD9135] mb-[10px]">
                PALAR
            </div>

            <div class="text-white text-[12px] text-center mb-[10px]">
                Ця додаткова інтеграція розраховує середнє та загальне значення показників з звіту PALAR
            </div>

            <div 
                class="border-[1px] border-[#BD9135] mb-[4px] px-[4px] text-[#BD9135]"
                :class="{
                    'opacity-30' : !firstPalarReport
                }"    
            >
                <div v-if="!firstPalarReport">Не вибрано звіт</div>
                <div v-else>{{ firstPalarReport }}</div>
            </div>
            <div @click="selectFile('first_time')" class="w-full cursor-pointer mb-[12px] text-center bg-[#BD9135] rounded-[4px]">
                Вибрати звіт за перший тайм
            </div>

            <div 
                class="border-[1px] border-[#BD9135] mb-[4px] px-[4px] text-[#BD9135]"
                :class="{
                    'opacity-30' : !secondPalarReport
                }"        
            >
                <div v-if="!secondPalarReport">Не вибрано звіт</div>
                <div v-else>{{ secondPalarReport }}</div>
            </div>
            <div @click="selectFile('second_time')" class="w-full cursor-pointer mb-[12px] text-center bg-[#BD9135] rounded-[4px]">
                Вибрати звіт за другий тайм
            </div>

            <div 
                class="border-[1px] border-[#BD9135] mb-[4px] px-[4px] text-[#BD9135]"
                :class="{
                    'opacity-30' : !gamePalarReport
                }"       
            >
                <div v-if="!gamePalarReport">Не вибрано звіт</div>
                <div v-else>{{ gamePalarReport }}</div>
            </div>
            <div @click="selectFile('game')" class="w-full cursor-pointer text-center bg-[#BD9135] rounded-[4px]">
                Вибрати звіт за гру
            </div>

            <div v-if="gamePalarReport && firstPalarReport && secondPalarReport" @click="proccessTimes">
                <div class="w-full mt-[10px] text-center bg-[#339989] rounded-[4px]">
                    Завантажити результат
                </div>
            </div>

            <input ref="firstPalarReportFile" type="file" class="hidden" accept=".xls" @change="onFirstPalarFileChange" />
            <input ref="secondPalarReportFile" type="file" class="hidden" accept=".xls" @change="onSecondPalarFileChange" />
            <input ref="gamePalarReportFile" type="file" class="hidden" accept=".xls" @change="onGamePalarFileChange" />
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'
    import { parsePalarXls, parsePalarXlsNew } from '@/services/apiRequests'

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    export default {
        name: "TrainingSummaryAddons",

        setup() {
            const firstPalarReportFile = ref(null)
            const secondPalarReportFile = ref(null)
            const gamePalarReportFile = ref(null)

            const firstPalarReportFileObj = ref(null)
            const secondPalarReportFileObj = ref(null)
            const gamePalarReportFileObj = ref(null)
            

            const palarResult = ref(null)

            const firstPalarReport = ref(null)
            const secondPalarReport = ref(null)
            const gamePalarReport = ref(null)

            const downloadFile = (blob, fileName) => {
                console.log(blob)
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = fileName
                a.click()
                URL.revokeObjectURL(url)
            }

            const proccessTimes = async () => {
                const result = await parsePalarXlsNew(firstPalarReportFileObj.value, secondPalarReportFileObj.value, gamePalarReportFileObj.value)

                downloadFile(result.data, 'result.xls')
            }

            const onFirstPalarFileChange = async (e) => {
                const file = e.target.files[0]
                firstPalarReportFileObj.value = file
                console.log(file)
                firstPalarReport.value = firstPalarReportFileObj.value.name
            }

            const onSecondPalarFileChange = async (e) => {
                const file = e.target.files[0]
                secondPalarReportFileObj.value = file
                console.log(file)
                secondPalarReport.value = secondPalarReportFileObj.value.name
            }

            const onGamePalarFileChange = async (e) => {
                const file = e.target.files[0]
                gamePalarReportFileObj.value = file
                console.log(file)
                gamePalarReport.value = gamePalarReportFileObj.value.name
            }

            const selectFile = (marker) => {
                switch (marker) {
                    case 'first_time':
                        firstPalarReportFile.value.click()
                        break
                    case 'second_time':
                        secondPalarReportFile.value.click()
                        break
                    case 'game':
                        gamePalarReportFile.value.click()
                        break
                }
            }

            return {
                selectFile,
                palarResult,
                firstPalarReport,
                secondPalarReport,
                gamePalarReport,
                firstPalarReportFile,
                secondPalarReportFile,
                gamePalarReportFile,
                onFirstPalarFileChange,
                onSecondPalarFileChange,
                onGamePalarFileChange,
                proccessTimes
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>