<template>
  <div class="report-tab">
    <DeleteScoutReportForm v-if="deleteReportFlag" @delete="deleteReport" @close="deleteReportFlag = false" :report="reportForDeletion" />
    <ScoutReportsFiltersForm v-if="filtersFlag" :allScouts="allScouts" @applyFilter="applyFilter" @close="filtersFlag = false" />
    <div>
      <div class="flex justify-end mb-[10px] px-[40px]">
        <IconButton @click="filtersFlag = true" :src="filter_icon" class="mr-[10px]" />
        <ButtonComponent @click="$router.push('/player-scout-report/' + playerId)">
          <span>Додати звіт</span>
        </ButtonComponent>
      </div>
      <div class="w-[97%]">
        <TableWrapper>
          <TableComponent :fullWidth="true">
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell v-for="item in header" :key="item" :value="item" />
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              <TableRow class="cursor-pointer" v-if="playerReports.length > 0" v-for="(report, index) in playerReports" :key="index">
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="index + 1" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.report_date.split('T')[0].split('-').reverse().join('.')" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.match_firts_team + ' - ' + report.match_second_team" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.scout_name + ' ' + report.scout_surname" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.match_rating" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.grading" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :value="report.next_steps" />
                <TableCell @click="$router.push('/player-scout-report-view/' + report.report_id)" :asChild="true">
                  <StarsComponent :rating="calculateAverageRating(report)" />
                </TableCell>
                <TableCell :asChild="true" :borderNone="true">
                  <RemoveButton @click="() => {reportForDeletion = report; deleteReportFlag = true}" />
                </TableCell>
              </TableRow>
              <tr v-else>
                <td :colspan="header.length" class="text-center py-[10px] opacity-50">Звітів поки немає</td>
              </tr>
            </TableBody>
          </TableComponent>
        </TableWrapper>
      </div>
    </div>

    <div class="report-tab__stats w-[350px]">
      <div class="report-tab__stats-grid">
        <div class="stats-grid__header">
          <div></div>
          <div class="stats-grid__row stats-grid__row--header">
            <span v-for="i in 10" class=" text-center" :key="i">{{ i }}</span>
          </div>
        </div>
        <div class="stats-grid__body">
          <div class="stats-grid__column">
            <span v-for="row in rows" :key="row" class="truncate pr-[5px]" :title="row">{{ row }}</span>
          </div>
          <div class="stats-grid__row stats-grid__row--body">
            <div class="stats-grid__column " v-for="(i, id) in 10" :key="i">
              <label class="radio"  v-for="(j, jid) in rows" :key="j">
                <input type="radio" disabled :name="j" :value="id + 1" v-model="roundedMarks[jid]">
                <span class="hidden"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="report-tab__stats-graph">
        <RadarChart :chartData="marks" :indicators="radarSettings" :key="chartKey" :max="10" :chart-size="{ width: '270px', height: '220px' }" />
        <div>дата останнього звіту {{ playerReports[0]?.report_date.split('T')[0].split('-').reverse().join('.') }}</div>
      </div>
      <div class="report-tab__pricing">
        <InputComponent
          :name="value"
          :placeholder="'оціночна вартість'"
          :label="'оціночна вартість'"
          :value="endPrice"
          @update:modelValue="$emit('update:endPrice', $event)"
        />
        <InputComponent
          :name="bought_value"
          :placeholder="'вартість покупки'"
          :label="'вартість покупки'"
          :value="startPrice"
          @update:modelValue="$emit('update:startPrice', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { getPlayerScoutReportsByStudentId, deletePlayerScoutReportById, getAllScoutsWhichDoReportsByStudent, getAverageScoreByStudentId } from "@/services/apiRequests";
import { useGlobalStore } from '@/storage/globalStorage'

import DeleteScoutReportForm from "../DeleteScoutReportForm.vue";
import ScoutReportsFiltersForm from "../ScoutReportsFiltersForm.vue";

import filter_icon from "@/assets/filter_icon.svg";

export default {
  name: "ReportTab",

  props: {
    startPrice: {
      type: Number,
      default: 0,
    },
    endPrice: {
      type: Number,
      default: 0,
    },
    amplua: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const marks = ref(Array(7).fill(null));
    const chartKey = ref(new Date().getTime()); 

    const route = useRoute();

    const playerReports = ref([]);

    const playerId = ref(route.params.id);

    const reportForDeletion = ref(null);
    const deleteReportFlag = ref(false);

    const filtersFlag = ref(false);

    const allScouts = ref([]);

    const store = useGlobalStore();

    const radarSettings = ref(props.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})));
    const rows = ref(props.amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null));

    const roundedMarks = ref([]);

    const updateReportsList = async () => {
      playerReports.value = await getPlayerScoutReportsByStudentId(playerId.value);
      console.log('playerReports', playerReports.value)
      
      if (playerReports.value.length === 0) {
        marks.value = Array(7).fill(0);
      } else {
        playerReports.value.forEach((report) => {
          marks.value[0] += report.first_point;
          marks.value[1] += report.second_point;
          marks.value[2] += report.third_point;
          marks.value[3] += report.fourth_point;
          marks.value[4] += report.fifth_point;
          marks.value[5] += report.sixth_point;
          marks.value[6] += report.seventh_point;
          marks.value[7] += report.eighth_point;
          marks.value[8] += report.ninth_point;
        });

        marks.value[0] = Math.floor(marks.value[0] / playerReports.value.length);
        marks.value[1] = Math.floor(marks.value[1] / playerReports.value.length);
        marks.value[2] = Math.floor(marks.value[2] / playerReports.value.length);
        marks.value[3] = Math.floor(marks.value[3] / playerReports.value.length);
        marks.value[4] = Math.floor(marks.value[4] / playerReports.value.length);
        marks.value[5] = Math.floor(marks.value[5] / playerReports.value.length);
        marks.value[6] = Math.floor(marks.value[6] / playerReports.value.length);
        marks.value[7] = Math.floor(marks.value[7] / playerReports.value.length);
        marks.value[8] = Math.floor(marks.value[8] / playerReports.value.length);
      }
    }

    const getMatchRating = async () => {
      const matchRating = await getAverageScoreByStudentId(playerId.value);
      return matchRating;
    }

    onMounted(async () => {
      await updateReportsList();

      //attention
      const matchRatingPoints = await getMatchRating();
      marks.value = matchRatingPoints;
      roundedMarks.value = marks.value.map((mark) => Math.floor(mark));
      
      allScouts.value = await getAllScoutsWhichDoReportsByStudent(playerId.value);

      console.log('allScouts', allScouts.value)
    });

    watch(marks, () => {
      chartKey.value = new Date().getTime();
    }, { deep: true });

    const deleteReport = async () => {
      await deletePlayerScoutReportById(reportForDeletion.value.report_id);
      playerReports.value = await getPlayerScoutReportsByStudentId(playerId.value);
      updateReportsList();
      deleteReportFlag.value = false;
    };

    const calculateAverageRating = (report) => {
      const allPoints = [
        playerReports.value[0],
        playerReports.value[1],
        playerReports.value[2],
        playerReports.value[3],
        playerReports.value[4],
        playerReports.value[5],
        playerReports.value[6],
        playerReports.value[7],
      ];

      const filteredAllPoints = allPoints.filter((point) => point);
      if (filteredAllPoints.length === 0) {
        return 0;
      }

      const sum = filteredAllPoints.reduce((acc, point) => +acc + +point, 0);

      return Math.floor(sum / rows.value.length);
    };

    const applyFilter = async (filters) => {
      const scoutId = filters.scout == 'all' ? null : filters.scout;

      console.log('filters', filters);

      playerReports.value = await getPlayerScoutReportsByStudentId(playerId.value, scoutId);
      if (playerReports.value.length === 0) {
        marks.value = Array(7).fill(0);
      } else {
        marks.value[0] = 0;
        marks.value[1] = 0;
        marks.value[2] = 0;
        marks.value[3] = 0;
        marks.value[4] = 0;
        marks.value[5] = 0;
        marks.value[6] = 0;
        marks.value[7] = 0;
        marks.value[8] = 0;

        playerReports.value.forEach((report) => {
          marks.value[0] += report.first_point;
          marks.value[1] += report.second_point;
          marks.value[2] += report.third_point;
          marks.value[3] += report.fourth_point;
          marks.value[4] += report.fifth_point;
          marks.value[5] += report.sixth_point;
          marks.value[6] += report.seventh_point;
          marks.value[7] += report.eighth_point;
          marks.value[8] += report.ninth_point;
        });

        marks.value[0] = Math.floor(marks.value[0] / playerReports.value.length);
        marks.value[1] = Math.floor(marks.value[1] / playerReports.value.length);
        marks.value[2] = Math.floor(marks.value[2] / playerReports.value.length);
        marks.value[3] = Math.floor(marks.value[3] / playerReports.value.length);
        marks.value[4] = Math.floor(marks.value[4] / playerReports.value.length);
        marks.value[5] = Math.floor(marks.value[5] / playerReports.value.length);
        marks.value[6] = Math.floor(marks.value[6] / playerReports.value.length);
        marks.value[7] = Math.floor(marks.value[7] / playerReports.value.length);
        marks.value[8] = Math.floor(marks.value[8] / playerReports.value.length);
      }
      filtersFlag.value = false;
    };

    return {
      marks,
      chartKey,
      playerId,
      playerReports,
      deleteReport,
      calculateAverageRating,
      reportForDeletion,
      deleteReportFlag,
      allScouts,
      filtersFlag,
      applyFilter,
      radarSettings,
      rows,
      roundedMarks,
      filter_icon: filter_icon,
    };
  },

  components: {
    DeleteScoutReportForm,
    ScoutReportsFiltersForm
  },

  data() {
    return {
      header: ["#", "дата", "матч", "скаут", "match rating", "grading", "next steps", "загальна оцінка"],
      data: [
        {
          id: 1,
          date: { day: "12.11.2020", time: "11:00" },
          report: "Звіт 1",
          document: "Документ",
          scaut: "D. Maradona",
          potential: "(A) Exellent A/P",
          rating: 4,
        },
        {
          id: 1,
          date: { day: "12.11.2020", time: "11:00" },
          report: "Звіт 1",
          document: "Документ",
          scaut: "D. Maradona",
          potential: "(A) Exellent A/P",
          rating: 4,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.radio{
  display: block;
  cursor: pointer; 
  user-select:none;
  text-align: left;
  &+.radio{
    margin-top: 0;
  }
  input{
    display: none;
    &+span{
      display: inline-block; 
      position: relative;
      padding-left: 0;
      &:before{
        content: '';
        display: block; 
        // position: absolute;
        top: -14px;
        left: 0px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #474849;
        background: #474849;
      }
      &:after{
        content: '';
        display: block; 
        width: 10px;
        height: 10px;
        background: #BD9135;
        position: absolute;
        border-radius: 50%;
        top: 3px;
        left: 3px;
        opacity: 0;
        transform: scale(0,0); 
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked+span:after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}
</style>