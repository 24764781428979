<template>
    <div v-if="boardType != 'point'" class="w-[90%] pb-[40px]" :key="flagKey">
        <div class="h-[3px] w-[100%] mt-[30px] ml-[25px] mb-[10px] bg-[#BD9135] flex flex-col justify-center">
            <div class="w-full mt-[34px] pb-[10px] flex justify-between px-[20px]">
                <div class="h-[20px] w-[5px] bg-[#BD9135] relative">
                    <div class="absolute mt-[-25px] text-[#BD9135] mr-[20px]" :class="{
                        'left-[-45px]' : boardType != 'time',
                        'left-[-24px]' : boardType == 'time',
                    }">
                        {{
                            boardType == 'time' ? 'чудово' : 'недостатньо'
                        }}
                    </div>
                </div>
                <div class="h-[20px] w-[5px] bg-[#BD9135] relative">
                    <div class="absolute mt-[-25px] text-[#BD9135] left-[-20px] mr-[20px]">добре</div>
                </div>
                <div class="h-[20px] w-[5px] bg-[#BD9135] relative">
                    <div class="absolute mt-[-25px] text-[#BD9135] mr-[20px]" :class="{
                        'left-[-25px]' : boardType != 'time',
                        'left-[-42px]' : boardType == 'time',
                    }">
                        {{
                            boardType == 'time' ? 'недостатньо' : 'чудово'
                        }}
                    </div>
                </div>
            </div>
            <div class="w-full flex relative justify-between">
                <div 
                    class="w-[70px] flex border-[1px] border-[#BD9135] rounded-[4px] bg-[#524A3A] text-[#BD9135] outline-none px-[5px] text-center"
                    :class="{
                        'border-[#DA4167] border-[3px] placeholder:text-red-400' : validation
                    }"    
                >
                    <input :value="scoreboardStructureClone.minimal_value" @change="e => {scoreboardStructureClone.minimal_value = e.target.value; update()}" type="number" class="bg-transparent w-[42px] outline-none" />
                    <div v-if="boardType == 'time'">c.</div>
                    <div v-if="boardType == 'length'">м.</div>
                    <div v-if="boardType == 'count'">р.</div>
                </div>
                <div 
                    class="w-[70px] flex border-[1px] border-[#BD9135] rounded-[4px] bg-[#524A3A] text-[#BD9135] outline-none px-[5px] text-center"
                    :class="{
                        'border-[#DA4167] border-[3px] placeholder:text-red-400' : validation
                    }"    
                >
                    <input v-model="scoreboardStructureClone.middle_value" @change="e => {scoreboardStructureClone.middle_value = e.target.value; update()}" type="number" class="bg-transparent w-[42px] outline-none" />
                    <div v-if="boardType == 'time'">c.</div>
                    <div v-if="boardType == 'length'">м.</div>
                    <div v-if="boardType == 'count'">р.</div>
                </div>
                <div 
                    class="w-[70px] flex border-[1px] border-[#BD9135] rounded-[4px] bg-[#524A3A] text-[#BD9135] outline-none px-[5px] text-center"
                    :class="{
                        'border-[#DA4167] border-[3px] placeholder:text-red-400' : validation
                    }"    
                >
                    <input v-model="scoreboardStructureClone.maximal_value" @change="e => {scoreboardStructureClone.maximal_value = e.target.value; update()}" type="number" class="bg-transparent w-[42px] outline-none" />
                    <div v-if="boardType == 'time'">c.</div>
                    <div v-if="boardType == 'length'">м.</div>
                    <div v-if="boardType == 'count'">р.</div>
                </div>
             
            </div>
        </div>
    </div>
    <div v-else-if="elementType == 'standard' && boardType == 'point'" class="flex justify-center w-full">
        <div class="flex mt-[10px] mb-[22px]">
            <div class="mr-[15px] w-[50px] h-[50px] text-[21px] font-semibold rounded-full border-[1px] border-[#BD9135] flex justify-center items-center text-[#BD9135] bg-[#524A3A]">1</div>
            <div class="mr-[15px] w-[50px] h-[50px] text-[21px] font-semibold rounded-full border-[1px] border-[#BD9135] flex justify-center items-center text-[#BD9135] bg-[#524A3A]">2</div>
            <div class="mr-[15px] w-[50px] h-[50px] text-[21px] font-semibold rounded-full border-[1px] border-[#BD9135] flex justify-center items-center text-[#BD9135] bg-[#524A3A]">3</div>
            <div class="mr-[15px] w-[50px] h-[50px] text-[21px] font-semibold rounded-full border-[1px] border-[#BD9135] flex justify-center items-center text-[#BD9135] bg-[#524A3A]">4</div>
            <div class="mr-[15px] w-[50px] h-[50px] text-[21px] font-semibold rounded-full border-[1px] border-[#BD9135] flex justify-center items-center text-[#BD9135] bg-[#524A3A]">5</div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'
    import { uuid } from 'vue3-uuid'

    export default {
        name: 'ScoreBoard',

        props: {
            boardType: {
                type: String,
                default: 'time'
            },
            elementType: {
                type: String,
                default: 'standard'
            },
            scoreboardStructure: {
                type: Object,
                default: () => {}
            },
            validation: {
                type: Boolean,
                default: true
            }
        },

        setup(props, { emit }) {
            const scoreboardStructureClone = ref(props.scoreboardStructure)
            const flagKey = ref(uuid.v4())

            const update = (value) => {
                emit('update', scoreboardStructureClone)
            }

            watch(props.validation, () => {
                flagKey.value = uuid.v4()
            })


            return {
                scoreboardStructureClone,
                update,
                flagKey
            }
        }
    }
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
</style>