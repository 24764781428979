<template>
  <div class="healthcare-section">
    <VerticalTextBlock
      :text="'група крові'"
      :value="blood_group"
      :textEnd="true"
    />

    <VerticalTextBlock :text="'загальна оцінка'">
      <StarsComponent :rating="rating" />
    </VerticalTextBlock>

    <VerticalTextBlock :text="'середній серцевий ритм'" :textEnd="true">
      <div class="healthcare-section__value">
        {{ heart_rate }}
        <span class="healthcare-section__small-text">уд.хв</span>
      </div>
    </VerticalTextBlock>
  </div>
</template>

<script>
export default {
  name: "HealthcareHeaderSection",
  props: {
    rating: {
      type: Number,
      required: true,
    },
    blood_group: {
      type: String,
      required: true,
    },
    heart_rate: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
