<template>
    <div class="w-[97%] mx-auto">
        <div class="w-[50%] mt-[22px]">
            <div class="flex items-center w-full justify-between">
                <div class="font-bold text-[#C59014]">Виплати</div>
                <div>
                    <select name="years" class="outline-none border-[#A4A6B3] text-[#FFF] border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center mr-[20px]">
                        <option class="bg-[#252526]">2022</option>
                        <option class="bg-[#252526]">2023</option>
                        <option class="bg-[#252526]">2024</option>
                    </select>
                </div>

            </div>

            <div>
                <BarChart />
            </div>

            <div class="mt-[20px] h-[300px] overflow-auto scrollbar">
                <div class="text-white flex justify-between px-[10px] border-b-[1px] py-[10px] border-[#4748494D]">
                    <div>06.06.2024</div>
                    <div class="font-bold">40 000 грн.</div>
                    <div>Зарплата за травень 2024</div>
                </div>
                <div class="text-white flex justify-between px-[10px] border-b-[1px] py-[10px] border-[#4748494D]">
                    <div>06.06.2024</div>
                    <div class="font-bold">40 000 грн.</div>
                    <div>Зарплата за травень 2024</div>
                </div>
                <div class="text-white flex justify-between px-[10px] border-b-[1px] py-[10px] border-[#4748494D]">
                    <div>06.06.2024</div>
                    <div class="font-bold">40 000 грн.</div>
                    <div>Зарплата за травень 2024</div>
                </div>
                <div class="text-white flex justify-between px-[10px] border-b-[1px] py-[10px] border-[#4748494D]">
                    <div>06.06.2024</div>
                    <div class="font-bold">40 000 грн.</div>
                    <div>Зарплата за травень 2024</div>
                </div>
                <div class="text-white flex justify-between px-[10px] border-b-[1px] py-[10px] border-[#4748494D]">
                    <div>06.06.2024</div>
                    <div class="font-bold">40 000 грн.</div>
                    <div>Зарплата за травень 2024</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BarChart from '../components/BarChart.vue';

export default {
    name: 'finance-tab',    

    components: {
        BarChart
    }
}
</script>

<style scoped>
    .scrollbar::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    .scrollbar::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background-color: #696969;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }
</style>