<template>
  <div class="table-wrapper">
    <slot></slot>
  </div> 
</template>

<script>
export default {
    name: 'TableWrapper',
    components: {
    },
    data() {
        return {
        }
    },
}
</script>