<template>

    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_37_388)">
            <circle cx="8.50769" cy="8.24307" r="8" fill="white"/>
            <path d="M14.1645 2.58622C12.6535 1.0752 10.6445 0.243073 8.50764 0.243073C6.37074 0.243073 4.36181 1.0752 2.85084 2.58622C1.33985 4.09719 0.50769 6.10615 0.50769 8.24303C0.50769 10.3799 1.33985 12.3889 2.85084 13.8999C4.36181 15.4109 6.3708 16.243 8.50771 16.243C10.6446 16.243 12.6535 15.4109 14.1646 13.8999C15.6756 12.3889 16.5077 10.3799 16.5077 8.24303C16.5077 6.10612 15.6755 4.09719 14.1645 2.58622ZM14.1071 3.338L13.0911 6.46487L11.4555 7.01543L8.7838 5.07428V3.33057L11.4429 1.3986C12.4705 1.84104 13.3793 2.50819 14.1071 3.338ZM5.57243 1.39866L8.23155 3.33063V5.07434L5.55987 7.01547L3.92425 6.4649L2.90828 3.338C3.63609 2.50819 4.54481 1.84104 5.57243 1.39866ZM1.09128 8.92227L3.75153 6.98947L5.38712 7.54003L6.40837 10.6831L5.39809 12.0538H2.11119C1.55381 11.1217 1.19447 10.0585 1.09128 8.92227ZM8.50764 15.6907C7.94168 15.6907 7.39033 15.6269 6.86015 15.5067L5.84406 12.3795L6.85433 11.0088H10.1609L11.1712 12.3795L10.1551 15.5068C9.62499 15.6269 9.07364 15.6907 8.50764 15.6907ZM14.9042 12.0538H11.6172L10.607 10.6831L11.1176 9.11152L11.6282 7.53996L13.2638 6.9894L15.924 8.92221C15.8208 10.0585 15.4615 11.1217 14.9042 12.0538Z" fill="#474849"/>
        </g>
    </svg>


</template>

<script>
    export default {
        name: 'Ball',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>