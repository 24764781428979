<template>
  <div class="input-horizontal">
    <input
      :id="name"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      :type="type"
      @change="$emit('update:modelValue', $event.target.value)"
      class="input-horizontal__field"
    />
    <label :for="name" class="input-horizontal__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "InputHorizontal",
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
};
</script>
