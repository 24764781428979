<template>
    <div>
        <MenuBar @addScout="showForm = true" v-model:query="searchQuery" :scoutCount="scoutCount"/>

        <ScoutTable :allScouts="allScouts" @deleteScout="deleteScout" @selectScout="scout => {selectedScout = scout; viewScoutFlag = true}" />

        <ScoutForm v-if="showForm" @addNewScout="addNewScout" @close="showForm = false" />
        <ScoutView v-if="viewScoutFlag" :scout="selectedScout" @close="viewScoutFlag = false" @addNewScout="updateScout" />
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { addScoutProfile, getAllScoutProfiles, deleteScoutProfileById, updateScoutProfileById, getScoutCount } from '@/services/apiRequests';

import MenuBar from './components/MenuBar.vue';
import ScoutTable from './components/ScoutTable.vue';
import ScoutForm from './components/ScoutForm.vue';
import ScoutView from './components/ScoutView.vue';

export default {
    name: 'Scouts',

    setup() {
        const showForm = ref(false);

        const allScouts = ref([]);
        const bufferedScouts = ref([]);

        const selectedScout = ref({});
        const viewScoutFlag = ref(false);

        const scoutCount = ref(0);

        const searchQuery = ref('');

        watch(searchQuery, (newVal) => {
            allScouts.value = bufferedScouts.value.filter(scout => {
                return scout.name.toLowerCase().includes(newVal.toLowerCase()) || scout.surname.toLowerCase().includes(newVal.toLowerCase());
            });

            if (newVal === '') {
                allScouts.value = bufferedScouts.value;
            }
        });

        onMounted(async () => {
            allScouts.value = await getAllScoutProfiles();
            bufferedScouts.value = Object.assign([], allScouts.value);

            scoutCount.value = await getScoutCount();
            console.log(scoutCount.value);
        });

        const addNewScout = async (newScout) => {
            let scout = newScout.scout;
            let photo = newScout.photo;
            scout.birthdate = new Date(scout.birthdate).toISOString();
            scout.start_working_date = new Date(scout.start_working_date).toISOString();
            scout.status = 'active';
            await addScoutProfile(scout, photo);
            allScouts.value = await getAllScoutProfiles();
            showForm.value = false;
        }

        const updateScout = async (updateScout) => {
            let scout = updateScout.scout;
            let photo = updateScout.photo;

            scout.birthdate = new Date(scout.birthdate).toISOString();
            scout.start_working_date = new Date(scout.start_working_date).toISOString();

            await updateScoutProfileById(scout.id, scout, photo);
            allScouts.value = await getAllScoutProfiles();

            viewScoutFlag.value = false;
        }

        const deleteScout = async (scout) => {
            await deleteScoutProfileById(scout.id);
            allScouts.value = await getAllScoutProfiles();
        }

        return {
            showForm,
            allScouts,
            deleteScout,
            addNewScout,
            selectedScout,
            viewScoutFlag,
            updateScout,
            scoutCount,
            searchQuery
        }
    },
    
    components: {
        MenuBar,
        ScoutTable,
        ScoutForm,
        ScoutView
    }
}
</script>

<style>
    
</style>