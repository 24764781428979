<template>
    <div>
        <svg width="132" height="79" viewBox="0 0 132 79" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M131 77.26V1L126.42 4.66414L120.008 72.2218L131 77.26Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M130.999 1L1.83838 1.45802L6.41856 4.66414H126.419L130.999 1Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M11.6847 72.2212L6.41748 4.66357H126.418L120.006 72.2212H11.6847Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
                <path d="M6.41856 4.89217L1.83838 1.45703V77.488L11.6858 72.4498L6.41856 4.89217Z" fill="#474849" fill-opacity="0.7" class="color_changed_fill" stroke="white" stroke-width="1.83207" stroke-linejoin="round"/>
            </g>
        </svg>
    </div>

</template>

<script>
    export default {
        name: 'FrontGates',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>