<template>
    <div class="mt-[70px] h-full">
        <div v-if="tabs[0].isActive" class="flex w-full justify-evenly">
            <div 
                v-for="tab in tabs" 
                class="w-[11%] text-center rounded-[5px] cursor-pointer select-none mb-[18px]"
                @click="selectTab(tab)"
                :class="{ 
                            'text-black bg-[#C59014]': tab.isActive,
                            'text-[#C59014]': !tab.isActive 
                        }"
            >{{
                tab.name
            }}</div>
        </div>
        <div v-else class="bg-[#47484966] pt-[20px] w-[97%] rounded-[8px] mx-auto">
            <div class="flex justify-between items-center mb-[15px] px-[59px]">
                <div class="flex items-center">
                    <div class="w-[94px] h-[94px] rounded-full relative overflow-hidden border-[1px] border-[#BD9135]">
                        <!-- <img src="@/assets/student_photo_placeholder.svg" class="w-[94px] h-[94px] absolute bottom-[-12px]"> -->
                        <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="w-[94px] h-[94px] absolute bottom-[-12px]">
                    </div>
                    <div class="flex flex-col w-[300px] ml-[20px]">
                        <div class="text-[26px] font-semibold text-white mb-[10px]">{{ currentStudent.name + ' ' + currentStudent.surname }}</div>
                        <div>
                            <div class="flex relative justify-between top-[-5px] mx-[8px]">
                                <div class="flex flex-col items-center justify-center">
                                    <div class="text-[9px] text-[#FFF]">група</div>
                                    <div class="text-[12px] font-semibold text-[#FFF]">
                                        <span v-if="group?.length > 0">{{ group }}</span>
                                        <span v-else class="opacity-25">—</span>
                                    </div>    
                                </div>
                                <div class="flex flex-col items-center justify-center  font-normal">
                                    <div class="text-[9px] text-[#FFF]">вік</div>
                                    <div class="text-[12px] font-semibold text-[#FFF]">
                                        <span v-if="age > 0">{{ age }}</span>
                                        <span v-else class="opacity-25">—</span>
                                    </div>    
                                </div>
                                <div class="flex flex-col items-center justify-center  font-normal">
                                    <div class="text-[9px] text-[#FFF]">амплуа</div>
                                    <div class="text-[12px] font-semibold text-[#FFF]">
                                        <span v-if="studentAmplua?.length > 0">{{ studentAmplua }}</span>
                                        <span v-else class="opacity-25">—</span>
                                    </div>    
                                </div>
                                <div class="flex flex-col items-center justify-center  font-normal">
                                    <div class="text-[9px] text-[#FFF]">номер</div>
                                    <div class="text-[12px] font-semibold text-[#FFF]">
                                        <span v-if="studentNumber?.length > 0">{{ studentNumber }}</span>
                                        <span v-else class="opacity-25">—</span>
                                    </div>    
                                </div>
                                <div class="flex  items-center justify-center font-normal">
                                    <img src="@/assets/logo.svg" class="w-[20px]">
                                    <div class="text-[#FFF] mx-1 mb-[2px]">|</div>
                                    <img src="@/assets/icons/uk_flag_dark.svg" class="w-[20px] ml-[5px]">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex">
                    <div @click="$emit('close')" class="bg-[#BD9135] w-[43px] mr-[10px] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 12L20 11.9998" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 7L4.70711 11.2929C4.37377 11.6262 4.20711 11.7929 4.20711 12C4.20711 12.2071 4.37377 12.3738 4.70711 12.7071L9 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div v-if="updatedFlag" class="bg-[#339989] text-[13px] w-[228px] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                        Зміни збережено
                    </div>
                    <div v-else @click="updateFullCard" class="bg-[#BD9135] text-[13px] w-[228px] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                        Редагувати
                    </div>
                </div>
            </div>

            <div class="flex w-full justify-evenly">
                <div 
                    v-for="tab in tabs" 
                    class="w-[11%] text-center rounded-[5px] cursor-pointer select-none mb-[18px]"
                    @click="selectTab(tab)"
                    :class="{ 
                                'text-black bg-[#C59014]': tab.isActive,
                                'text-[#C59014]': !tab.isActive 
                            }"
                >{{
                    tab.name
                }}</div>
            </div>
        </div>

        <MainTab 
            v-if="tabs[0].isActive" 
            @close="$emit('close')"
            :student="currentStudent" 
        />
        <ParametersTab 
            v-if="tabs[1].isActive"   
            v-model:login="currentStudent.login"
            v-model:password="currentStudent.password"
            v-model:amplua="currentStudent.amplua"
            v-model:role="fullcardInfo.role"
            v-model:playerNumber="currentStudent.player_number"
            v-model:leadingLeg="currentStudent.leading_foot"
            v-model:height="currentStudent.height"
            v-model:weight="currentStudent.weight"
            v-model:footSize="currentStudent.foot_size"
            v-model:breastVolume="currentStudent.breast_volume"
            v-model:careerList="fullcardInfo.career"
        />
        <DocumentsTab 
            v-if="tabs[2].isActive" 
            v-model:name="currentStudent.name"
            v-model:surname="currentStudent.surname"
            v-model:patronymic="currentStudent.patronymic"
            v-model:name_eng="currentStudent.name_eng"
            v-model:surname_eng="currentStudent.surname_eng"
            v-model:nationality="currentStudent.nationality"
            v-model:address="currentStudent.address"
            v-model:social_network1="fullcardInfo.social_profile_1"
            v-model:social_network2="fullcardInfo.social_profile_2"
            v-model:patronName="currentStudent.patrons[0].name"
            v-model:patronPhone="currentStudent.patrons[0].phone_number"
            v-model:birthday="birthday"
            v-model:abroadPassport = "fullcardInfo.abroad_passport"
            v-model:abroadPassportEndDate = "abroadPassportEndDate"
            v-model:passport = "currentStudent.passport"
            v-model:passportOffice = "fullcardInfo.passport_issue_office"
            v-model:passportIssueDate = "passportIssueDate"
            v-model:idCode = "currentStudent.id_number"
            v-model:phone = "currentStudent.phone"
            v-model:email = "currentStudent.email"
            v-model:startInClub = "startInClub"
            v-model:offerType = "fullcardInfo.offer_type"
            v-model:contractNumber = "fullcardInfo.contract_number"
            v-model:contractExpireDate = "contractExpireDate"
            v-model:attorney = "fullcardInfo.attorney"
            v-model:representative = "fullcardInfo.representative"
            v-model:agency = "fullcardInfo.agency"

            :passportPhotos="passportPhotos"
            @addPassportPhoto="e => passportPhotos.push(e)"
            :idPhotos="idPhotos"
            @addIdPhoto="e => idPhotos.push(e)"
            :attorneyPhotos="attorneyFiles"
            @addAttorneyPhoto="e => attorneyFiles.push(e)"
            :contractFiles="contractFiles"
            @addContractFile="e => contractFiles.push(e)"
            :abroadPassportFiles="abroadPassportFiles"
            @addAbroadPassportFile="e => abroadPassportFiles.push(e)"

            @deletePassportPhotos="e => passportPhotos.splice(e, 1)"
            @addPassportPhotos="e => passportPhotos.push(e)"

            @deleteIdPhotos="e => idPhotos.splice(e, 1)"
            @addIdPhotos="e => idPhotos.push(e)"

            @addContractFiles="e => contractFiles.push(e)"
            @deleteContractFiles="e => contractFiles.splice(e, 1)"

            @addAbroadPassportFiles="e => abroadPassportFiles.push(e)"
            @deleteAbroadPassportFiles="e => abroadPassportFiles.splice(e, 1)"

            @addAttorneyFiles="e => attorneyFiles.push(e)"
            @deleteAttorneyFiles="e => attorneyFiles.splice(e, 1)"
        />
        <StatisticsTab v-if="tabs[3].isActive" />
        <TestingTab v-if="tabs[4].isActive" />
        <FinanceTab v-if="tabs[5].isActive" />
        <MedicineTab v-if="tabs[6].isActive" />
        <ScoutTab v-if="tabs[7].isActive" />
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getPlayerFullCardByStudentId, updatePlayerFullCardByStudentId, updateStudent } from '@/services/apiRequests'

    import MainTab from './pages/MainTab.vue'
    import ParametersTab from './pages/ParametersTab.vue'
    import DocumentsTab from './pages/DocumentsTab.vue'
    import StatisticsTab from './pages/StatisticsTab.vue'
    import TestingTab from './pages/TestingTab.vue'
    import FinanceTab from './pages/FinanceTab.vue'
    import MedicineTab from './pages/MedicineTab.vue'
    import ScoutTab from './pages/ScoutTab.vue'

    export default {
        name: 'full-student-card',

        props: {
            student: {
                type: Object,
                required: false
            }
        },

        setup(props, { emit }) {
            const tabs = ref([
                { name: 'ГОЛОВНА', isActive: true },
                { name: 'ПАРАМЕТРИ', isActive: false },
                { name: 'ДОКУМЕНТИ', isActive: false },
                { name: 'СТАТИСТИКА', isActive: false },
                { name: 'ТЕСТУВАННЯ', isActive: false },
                { name: 'ФІНАНСИ', isActive: false },
                { name: 'МЕДИЦИНА', isActive: false },
                { name: 'ЗВІТ СКАУТА', isActive: false }
            ])

            const group = ref(props.student.group_name)
            const age = ref(props.student.age)
            const studentAmplua = ref(props.student.amplua)
            const studentNumber = ref(props.student.player_number)

            const fullcardInfo = ref(null)
            const currentStudent = ref(Object.assign({}, props.student))
            const studentPhoto = ref(process.env.VUE_APP_API_URL + 'static/' + currentStudent.value.photo)

            const updatedFlag = ref(false)

            const birthday = ref(props.student.birthday.split('T')[0])
            const abroadPassportEndDate = ref(fullcardInfo.value?.abroad_passport_end_date ? fullcardInfo.value?.abroad_passport_end_date.split('T')[0] : '')
            const passportIssueDate = ref(fullcardInfo.value?.passport_issue_date ? fullcardInfo.value?.passport_issue_date.split('T')[0] : '')
            const startInClub = ref(currentStudent.value?.start_training ? currentStudent.value?.start_training.split('T')[0] : '')
            const contractExpireDate = ref(fullcardInfo.value?.contract_term ? fullcardInfo.value?.contract_term.split('T')[0] : '')

            const passportPhotos = ref(currentStudent.value.passport_files ? currentStudent.value.passport_files : [])
            const idPhotos = ref(currentStudent.value.id_code_files ? currentStudent.value.id_code_files : [])
            const attorneyFiles = ref(currentStudent.value.attorney_child_files ? currentStudent.value.attorney_child_files : [])

            const contractFiles = ref(fullcardInfo.value?.contracts_files ? fullcardInfo.value?.contracts_files : [])
            const abroadPassportFiles = ref(fullcardInfo.value?.abroad_passport_files ? fullcardInfo.value?.abroad_passport_files : [])

            const photoForPassport = ref(currentStudent.value.photo_for_passport ? currentStudent.value.photo_for_passport : [])

            watch(birthday, (newVal) => {
                console.log('birthday ----- ', newVal)
            })

            const apiURL = ref(process.env.VUE_APP_API_URL)
            const photo = ref(
                props.student?.photo ? apiURL.value + 'static/' + props.student.photo : ''
            )

            const selectTab = (tab) => {
                tabs.value.forEach(t => {
                    t.isActive = false
                })
                tab.isActive = true
            }

            const updateFullCard = async () => {
                fullcardInfo.value.abroad_passport_end_date = abroadPassportEndDate.value ? new Date(abroadPassportEndDate.value).toISOString() : null
                fullcardInfo.value.passport_issue_date = passportIssueDate.value ? new Date(passportIssueDate.value).toISOString() : null
                fullcardInfo.value.contract_term = contractExpireDate.value ? new Date(contractExpireDate.value).toISOString() : null
                currentStudent.value.start_training = startInClub.value ? new Date(startInClub.value).toISOString() : null
                currentStudent.value.birthday = birthday.value ? new Date(birthday.value).toISOString() : null

                const res = await updatePlayerFullCardByStudentId(props.student.student_id, fullcardInfo.value)
                const res2 = await updateStudent(currentStudent.value, studentPhoto.value, [], [], [], [])
                emit('updateStudents')
                updatedFlag.value = true
                setTimeout(() => {
                    updatedFlag.value = false
                }, 2000)
            }

            onMounted(async () => {
                const res = await getPlayerFullCardByStudentId(props.student.student_id)
                fullcardInfo.value = res

                abroadPassportEndDate.value = fullcardInfo.value?.abroad_passport_end_date ? fullcardInfo.value?.abroad_passport_end_date.split('T')[0] : ''
                passportIssueDate.value = fullcardInfo.value?.passport_issue_date ? fullcardInfo.value?.passport_issue_date.split('T')[0] : ''
                contractExpireDate.value = fullcardInfo.value?.contract_term ? fullcardInfo.value?.contract_term.split('T')[0] : ''
                startInClub.value = currentStudent.value?.start_training ? currentStudent.value?.start_training.split('T')[0] : ''
                birthday.value = currentStudent.value?.birthday ? currentStudent.value?.birthday.split('T')[0] : ''
            })
         
            return {
                tabs,
                group,
                age,
                studentAmplua,
                studentNumber,
                photo,
                fullcardInfo,
                currentStudent,
                birthday,
                abroadPassportEndDate,
                passportIssueDate,
                startInClub,
                contractExpireDate,
                updateFullCard,
                updatedFlag,
                selectTab,
                passportPhotos,
                idPhotos,
                attorneyFiles,
                contractFiles,
                abroadPassportFiles
            }
        },

        components: {
            MainTab,
            ParametersTab,
            DocumentsTab,
            StatisticsTab,
            TestingTab,
            FinanceTab,
            MedicineTab,
            ScoutTab
        }
    }
</script>

<style lang="scss" scoped>

</style>