<template>
  <div class="info-line">
    <div class="info-line__label">{{ label }}</div>
    <div class="info-line__value" :style="{'font-weight: 900' : valueBold}" v-if="value">{{ value }}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "InfoLine",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },

    valueBold: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
