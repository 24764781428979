<template>
    <div class="absolute top-0 left-0 w-full h-full backdrop-blur-sm" @click="$emit('close')"></div>
    <FormBlock class="w-[470px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div>
            <div>
                <h2 class="text-2xl text-center font-bold">Фільтри</h2>
            </div>

            <div class="flex justify-between my-[20px]">
                <div class="flex w-[49%] flex-col gap-4">
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Команда</label>
                        <SelectComponent :default="defaultSelectValues.team" :key="inputKey" @change="e => currentTeam = e" id="name" :options="teams" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Скаут</label>
                        <SelectComponent :default="defaultSelectValues.scout" :key="inputKey" @change="e => currentScout = e" id="name" :options="scouts" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Вік</label>
                        <SelectComponent :default="defaultSelectValues.age" :key="inputKey" @change="e => currentAge = e" id="name" :options="ages" />
                    </div>
                </div>

                <div class="flex w-[49%] flex-col gap-4">
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Позиція</label>
                        <SelectComponent :default="defaultSelectValues.position" :key="inputKey" @change="e => currentPosition = e" class="relative z-[999]" id="name" :options="positions" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Потенціал</label>
                        <SelectComponent :default="defaultSelectValues.potential" :key="inputKey" @change="e => currentPotential = e" class="relative z-[998]" id="name" :options="potentials" />
                    </div>
                    <div class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Середня оцінка</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="minRating"
                                :maxValue="maxRating"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="update_oBarValues"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-center justify-center mt-4">
                <ButtonComponent @click="applyFilters">Застосувати</ButtonComponent>
                <ButtonComponent @click="clearFilters" class="mt-[10px]" :gray="true">Скинути</ButtonComponent>
            </div>

        </div>
    </FormBlock>
</template>
<script>
import { ref, onMounted, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MultiRangeSlider from "multi-range-slider-vue";

export default {
    name: 'FiltersForm',

    props: {
        teams: {
            type: Array,
            default: () => [
                'не вибрано',
                'Team 1',
                'Team 2',
                'Team 3',
                'Team 4',
                'Team 5'
            ]
        },
        positions: {
            type: Array,
            default: () => [
                'не вибрано', 'ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'
            ]
        },
        potentials: {
            type: Array,
            default: () => [
                'не вибрано', 'A - Відмінно', 'B - Добре', 'C - Задовільно', 'D - Погано'
            ]
        },
        ages: {
            type: Array,
            default: () => [
                'не вибрано',
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
            ]
        },
        scouts: {
            type: Array,
            default: () => [
                'не вибрано',
                'Scout 1',
                'Scout 2',
                'Scout 3',
                'Scout 4',
                'Scout 5'
            ]
        }
    },

    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();

        const minRating = ref(+route.query.minRating || 0);
        const maxRating = ref(+route.query.maxRating || 10);

        const inputKey = ref(new Date().getTime());

        const update_oBarValues = (values) => {
            console.log('values', values)
            minRating.value = values.minValue;
            maxRating.value = values.maxValue;
        }

        const currentAge = ref(route.query.age || '');
        const currentPotential = ref(route.query.potential || '');
        const currentScout = ref(route.query.scout || '');
        const currentTeam = ref(route.query.team || '');
        const currentPosition = ref(route.query.position || '');

        const defaultSelectValues = ref({
            age: +route.query.age || 0,
            potential: route.query.potential || '',
            scout: route.query.scout || '',
            team: route.query.team || '',
            position: route.query.position || '',
            minRating: +route.query.minRating || 0,
            maxRating: +route.query.maxRating || 10
        })

        watch(() => [currentAge.value, currentPotential.value, currentScout.value, currentTeam.value, currentPosition.value, minRating.value, maxRating.value], () => {
            router.push({
                query: {
                    ...route.query,
                    age: currentAge.value,
                    potential: currentPotential.value,
                    scout: currentScout.value,
                    team: currentTeam.value,
                    position: currentPosition.value,
                    minRating: minRating.value,
                    maxRating: maxRating.value
                }
            })
        }, { immediate: true, deep: true })

        onMounted(() => {
            emit('updateFilters', {
                age: currentAge.value || 0,
                potential: currentPotential.value,
                scout: currentScout.value,
                team: currentTeam.value,
                position: currentPosition.value,
                minRating: minRating.value,
                maxRating: maxRating.value
            })
        })

        const applyFilters = () => {
            emit('applyFilters', {
                age: currentAge.value || 0,
                potential: currentPotential.value,
                scout: currentScout.value,
                team: currentTeam.value,
                position: currentPosition.value,
                minRating: minRating.value,
                maxRating: maxRating.value
            })
        }

        const clearFilters = () => {
            currentAge.value = 0;
            currentPotential.value = '';
            currentScout.value = '';
            currentTeam.value = '';
            currentPosition.value = '';
            minRating.value = 0;
            maxRating.value = 10;
            inputKey.value = new Date().getTime();

            emit('applyFilters', {
                age: currentAge.value,
                potential: currentPotential.value,
                scout: currentScout.value,
                team: currentTeam.value,
                position: currentPosition.value,
                minRating: minRating.value,
                maxRating: maxRating.value
            })
        }

        return {
            minRating,
            maxRating,
            update_oBarValues,
            currentAge,
            currentPotential,
            currentScout,
            currentTeam,
            currentPosition,
            applyFilters,
            clearFilters,
            inputKey,
            defaultSelectValues
        }
    },

    components: {
        MultiRangeSlider
    }
}
</script>
<style >
.multi-range-slider-bar-only * {
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}
.multi-range-slider-bar-only {
	display: flex;
	position: relative;
	border: none;
	border-radius: 0px;
	padding: 10px;
	box-shadow: none;
	flex-direction: column;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.multi-range-slider-bar-only .bar {
	display: flex;
}
.multi-range-slider-bar-only .bar-left {
	width: 25%;
	background-color: #252526;
	border-radius: 10px 0px 0px 10px;
	
	padding: 4px 0px;
}
.multi-range-slider-bar-only .bar-right {
	width: 25%;
	background-color: #252526;
	border-radius: 0px 10px 10px 0px;
	
}
.multi-range-slider-bar-only .bar-inner {
	background-color: #BD9135;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	position: relative;
	border: solid 1px black;
	justify-content: space-between;
	
}
.multi-range-slider-bar-only .bar-inner-left {
	width: 50%;
}
.multi-range-slider-bar-only .bar-inner-right {
	width: 50%;
}
.multi-range-slider-bar-only .thumb {
	background-color: red;
	position: relative;
	z-index: 1;
	cursor: pointer;
    top: 3px;
}
.multi-range-slider-bar-only .thumb::before {
	content: '';
	background-color: white;
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 1px black;
	
	border-radius: 50%;
	z-index: 1;
	margin: -8px;
	cursor: pointer;
}
.multi-range-slider-bar-only .input-type-range:focus + .thumb::after {
	content: '';
	position: absolute;
	top: -4px;
	left: -4px;
	width: 11px;
	height: 11px;
	z-index: 2;
	border-radius: 50%;
	border: dotted 1px black;
	
}
.multi-range-slider-bar-only .caption {
	position: absolute;
	bottom: 35px;
	width: 2px;
	height: 2px;
	left: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	display: none;
}
.multi-range-slider-bar-only .thumb .caption * {
	position: absolute;
	min-width: 30px;
	height: 30px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: #BD9135;
	border-radius: 15px;
	color: #252526;
	
	padding: 0px 5px;
	white-space: nowrap;
}
.multi-range-slider-bar-only .thumb:active .caption {
	display: flex;
}
.multi-range-slider-bar-only .input-type-range:focus + .thumb .caption {
	display: flex;
}
.multi-range-slider-bar-only .input-type-range {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.multi-range-slider-bar-only .ruler {
	margin: 10px 0px -5px 0px;
	display: flex;
	display: none;
	overflow: hidden;
}
.multi-range-slider-bar-only .ruler .ruler-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 5px 0px;
	display: none;
}
.multi-range-slider-bar-only .ruler .ruler-rule:last-child {
	border-right: solid 1px;
}

.multi-range-slider-bar-only .sub-ruler {
	margin: -2px 0px -5px 0px;
	display: flex;
	display: none;
}
.multi-range-slider-bar-only .sub-ruler .ruler-sub-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 3px 0px;
}
.multi-range-slider-bar-only .sub-ruler .ruler-sub-rule:last-child {
	border-right: solid 1px;
}

.multi-range-slider-bar-only .labels {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: -20px;
	display: none;
}
.multi-range-slider-bar-only .label {
	font-size: 80%;
	display: flex;
	width: 1px;
	justify-content: center;
}
.multi-range-slider-bar-only .label:first-child {
	justify-content: start;
}
.multi-range-slider-bar-only .label:last-child {
	justify-content: end;
}
.multi-range-slider-bar-only.zero-ranage-margin .thumb-left {
	right: 12px;
}
.multi-range-slider-bar-only.zero-ranage-margin .thumb-right {
	left: 8px;
}

</style>