<template>
  <tr class="table__row">
    <slot></slot>
  </tr> 
</template>

<script>
export default {
    name: 'TableRow',
    props: {
  },
    components: {
    },
    data() {
        return {
          
        }
    },
}
</script>