<template>
  <div class="left-chart-block__header">
    <h2 class="left-chart-block__title">{{graphText.title}}</h2>
    <label class="left-chart-block__select">
      <select v-model="selectedYear">
        <option v-for="yearObj in data" :key="yearObj.year" :value="yearObj.year">
          {{ yearObj.year }}
        </option>
      </select>
    </label>
  </div>
  <div class="left-chart-block__chart">
    <TwoLineBarGraphElement :labels="updatedLabels" :dataSet="activeYearData.dataSet" ></TwoLineBarGraphElement>
  </div>
</template>

<script >
import TwoLineBarGraphElement from "./TwoLineBarGraphElement.vue";
import {computed, ref, toRefs, watch} from "vue";

export default {
  name: "TwoLineBarGraph",
  components: {TwoLineBarGraphElement},
  props: {
    graphText: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  setup(props){
    const { data ,graphText} = toRefs(props);

    const selectedYear = ref(data.value.find(year => year.isActive)?.year || data.value[0].year);
    const activeYearData = computed(() => {
      return data.value.find(yearObj => yearObj.year === selectedYear.value) || [];
    });
    const updatedLabels= computed(()=>{
      return graphText.value.labels.map(label=>`${label}.${selectedYear.value}`)||[]
    })
    watch(selectedYear, newValue => {
      data.value.forEach(yearObj => {
        yearObj.isActive = yearObj.year === newValue;
      });

    });
    return {
      selectedYear,
      updatedLabels,
      activeYearData
    }
  }

}
</script>

<style>
</style>
