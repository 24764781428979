<template>
  <div ref="chartContainer" style="width: 100%; height: 400px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "TwoLineBarGraphElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    labels:{
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { dataSet,labels} = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;
    const screenWidth = ref(window.innerWidth);
    const isShow = screenWidth.value>700? true: false;
    const newLabels = screenWidth.value>900? labels.value : labels.value.map(element=>element.substring(0, 4))

    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const lines=dataSet.value.filter(element=> element.data!=0);
      const bar=dataSet.value.filter(element=> element.data==0);
      const barData =[];
      for(let i = 0; i<lines[0].data.length;i++){
        barData.push(lines[0].data[i]+lines[1].data[i])
      }
      const lineOpitons = lines.map((element) => {
        return {
          name: element.title,
          data: element.data,
          type: 'line',
          symbol: "circle",
          lineStyle: {
            color: element.color,
            width: 5
          },
          symbolSize: 8,
          label: {
            fontSize:14,
            show: true,
            position: element.data[0]>0?"top": "bottom",
            color: element.color
          }
        }
      })

      const option ={
        color: dataSet.value.map(element=>element.color),
        xAxis: {
          show:isShow,
          type: 'category',
          data: newLabels,
          splitLine: { show: false }, // Приховати лінії сітки, щоб вони не заважали
          axisLine: { show: false }, // Приховати лінію осі Y
          axisTick: { show: false }, // Приховати риски на осі Y
          axisLabel: {
            show: true,
            bottom: 20,
            color: "#fff"

          } // Приховати мітки на осі Y
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          data: dataSet.value.map(element=>element.title),
          bottom: 0 // Розміщення легенди знизу
        },
        grid: {
          left: 5,
          right: 5,
          top:40,
          bottom: 50
        },
        series: [
          ...lineOpitons,
          {
            name: bar[0].title,
            data: barData,
            type: 'bar',
            barWidth: '60%',
            label: {
              show: true,
              rotate: 90,
              textStyle: {
                fontSize:18,
                color: "#000",
              }
            }
          }

        ]
      };

      myChart.setOption(option, true);

    });

    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };
    onMounted(() => {
      if (!chartContainer.value) return;

      window.addEventListener('resize', updateChartSize);
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });

    return {chartContainer};
  }
};
</script>

