<template>
    <div class="px-[60px]">
        <div class="flex justify-end mb-[20px]">
            <IconButton :src="arrow_icon" @click="$router.go(-1)" class="mr-[10px]"/>
            <ButtonComponent @click="saveTeam">
                Зберегти дошку
            </ButtonComponent>
        </div>
        <div class="flex gap-[30px] h-[100vh] justify-center">
            <div class="w-[300px] mt-[54px]">
                <div v-for="amplua in Object.keys(playerList)" class="w-full py-[5px]">
                    <div class="flex items-center">
                        <div class="text-[#C59014] font-bold mr-[20px]">{{ amplua }}</div>
                        <div class="h-[2px] w-full bg-[#C59014]"></div>
                    </div>
                    <div class="pl-[35px] mt-[10px]">
                        <div v-for="player in playerList[amplua]" class="flex justify-between items-center mb-[15px]">
                            <div class="flex items-center cursor-pointer select-none" @click="selectPlayer(player)">
                                <div>
                                    <AvatarContainer v-if="player.photo" :label="'ТА'" :small="true" :src="apiURL + 'static/' + player.photo" />
                                    <div v-else class="avatar-container avatar-container--small">
                                        <div class="text-[#BD9135]">
                                            {{ player.name[0] + player.surname[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-[14px] ml-[10px]">
                                    {{ player.name + ' ' + player.surname }}
                                </div>
                            </div>
                            <div class="flex gap-[10px]">
                                <svg v-if="player.onField" @click="player.onField = false" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#DA4167"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                                <svg v-else @click="player.onField = true" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#339989"/>
                                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
            <div>
                <div>
                    <input v-model="teamName" type="text" placeholder="Назва команди" class="font-bold text-[20px] text-center w-full mb-[5px]">
                    <input v-model="teamDescription" type="text" placeholder="Коментар до команди" class="text-[14px] text-center w-full mb-[15px]">
                </div>
                <div class="relative select-none">
                    <img src="@/assets/shadow_team_field.svg" class="h-[880px] cursor-pointer" >
                    <div class="absolute h-full w-full top-0 left-0">
                        <!-- <div id="CF" class="w-[123px] h-[70px] overflow-y-auto absolute top-[47px] bg-white bg-opacity-30 flex flex-col items-end justify-end left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerList['ВР']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div> -->
                        <div id="ЦФ" class="w-[123px] h-[70px] overflow-y-auto absolute top-[165px] bg-white bg-opacity-30 flex flex-col items-end justify-end left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['НП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>

                        <div id="ЛВ" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[210px] left-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЛВ']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ПВ" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[210px] right-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ПВ']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ЦАП" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[270px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЦАП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        
                        <!-- <div id="LM" class="w-max absolute top-[375px] left-[70px]">
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                        </div> -->
                        <!-- <div id="RM" class="w-max absolute top-[375px] right-[70px]">
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                        </div> -->
                        <div id="ЦП" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[378px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЦП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>

                        <div id="ЛП" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[505px] left-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЛП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ПП" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[505px] right-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ПП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ЦОП" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[505px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЦОП']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>

                        <div id="ЛЗ" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[630px] left-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЛЗ']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ПЗ" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[630px] right-[70px]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ПЗ']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                        <div id="ЦЗ" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[610px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ЦЗ']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>

                        <!-- <div id="SW" class="w-max absolute top-[719px] left-[28%]">
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex mb-[2px]">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                            <div class="flex">
                                <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">Я. Яремчук</div>
                                <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">51</div>
                            </div>
                        </div> -->
                        <div id="GK" class="w-[123px] h-[70px] overflow-y-auto absolute bg-white bg-opacity-30 flex flex-col items-end justify-end top-[747px] left-[50%] translate-x-[-50%]">
                            <draggable 
                                class="overflow-y-auto mini-scrollbar w-full h-full"
                                v-model="playerListOnField['ВР']" 
                                group="people" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                            >
                                <template #item="{element}">
                                    <div class="flex mb-[2px] select-none cursor-pointer">
                                        <div class="text-white text-[11px] bg-[#474849] w-[96px] pt-[2px] px-[6px] rounded-sm mr-[2px]">{{ element.name[0] + '. ' + element.surname }}</div>
                                        <div class="text-white text-[14px] bg-[#1B4079] w-[23px] text-center font-bold rounded-sm ">{{ element.player_number || element.playerNumber }}</div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-[67px] w-[260px]">
                <div v-if="isPlayerSelected" class="card">
                    <div class="flex justify-center">
                        <AvatarContainer v-if="selectedPlayer.photo" :src="apiURL + 'static/' + selectedPlayer.photo"/>
                        <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                            <div class="text-[#BD9135] text-[32px] ">
                                {{ selectedPlayer.name[0] + selectedPlayer.surname[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="text-[21px] truncate font-semibold mt-[10px]">
                        {{ selectedPlayer.name + ' ' + selectedPlayer.surname }}
                    </div>
    
                    <div class="w-full h-[74px] mt-[12px]">
                        <div class="flex justify-between w-full mx-[8px]">
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/logo.svg" class="w-[30px]">
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <div class="text-[9px]">група</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.group_name?.length > 0 || selectedPlayer.teamName?.length > 0">{{ selectedPlayer.group_name || selectedPlayer.teamName }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">вік</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.age > 0 || countAge(selectedPlayer.birthdate) > 0">{{ selectedPlayer.age || countAge(selectedPlayer.birthdate) }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">амплуа</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.amplua?.length > 0">{{ selectedPlayer.amplua }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex flex-col items-center justify-center  font-normal">
                                <div class="text-[9px]">номер</div>
                                <div class="text-[12px] font-semibold">
                                    <span v-if="selectedPlayer.player_number?.length > 0 || selectedPlayer.playerNumber?.length > 0">{{ selectedPlayer.player_number || selectedPlayer.playerNumber }}</span>
                                    <span v-else class="opacity-25">—</span>
                                </div>    
                            </div>
                            <div class="flex  items-center justify-center font-normal">
                                <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                            </div>
                        </div>
                    </div>
    
                    <div class="relative top-[-39px] mx-auto">
                        <RadarChart :key="new Date().getTime()" :indicators="getCurrentScoreSettingsForAmplua(selectedPlayer.amplua)" :chartData="selectedPlayer.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { addShadowTeam, getAdmin } from "@/services/apiRequests";
import { useGlobalStore } from "@/storage/globalStorage";

import draggable from 'vuedraggable'

import arrow_icon from "@/assets/refactored_assets/img/header/arrow.svg";

export default {
    name: 'CreateShadowTeam',

    setup() {
        const apiURL = process.env.VUE_APP_API_URL

        const route = useRoute()
        const router = useRouter()

        const store = useGlobalStore()

        const getCurrentScoreSettingsForAmplua = (amplua) => {
            return amplua == 'ВР' ? Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})) : Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10}))
        }

        const teamName = ref('')
        const teamDescription = ref('')

        const alienPlayers = ref(store.$state.shadowTeamAlienPlayers.map(player => {
            player.type = 'alien'
            return player
        }))
        const clubPlayers = ref(store.$state.shadowTeamClubPlayers.map(player => {
            player.type = 'club'
            return player
        }))

        const currentScout = ref({})

        const myArray = ref([
            {
                id: 1,
                name: 'test1'
            },
            {
                id: 2,
                name: 'test2'
            }
        ])

        const myArray2 = ref([
            {
                id: 3,
                name: 'test3'
            },
            {
                id: 4,
                name: 'test4'
            }
        ])

        const playerList = ref({
            'ВР': [], 
            'ЦЗ': [], 
            'ЛЗ': [], 
            'ПЗ': [], 
            'ЦОП': [], 
            'ЦП': [], 
            'ЦАП': [], 
            'ПП': [], 
            'ЛП': [], 
            'ПВ': [], 
            'ЛВ': [], 
            'НП': []
        })

        const playerListOnField = ref({
            'ВР': [], 
            'ЦЗ': [], 
            'ЛЗ': [], 
            'ПЗ': [], 
            'ЦОП': [], 
            'ЦП': [], 
            'ЦАП': [], 
            'ПП': [], 
            'ЛП': [], 
            'ПВ': [], 
            'ЛВ': [], 
            'НП': []
        })

        const selectedPlayer = ref(null)
        const isPlayerSelected = ref(false)

        const selectPlayer = (player) => {
            if (selectedPlayer.value && selectedPlayer.value === player) {
                selectedPlayer.value = null
                isPlayerSelected.value = false
                return
            } else {
                selectedPlayer.value = player
                isPlayerSelected.value = true
            }
        }

        const countAge = (date) => {
            const today = new Date()
            const birthDate = new Date(date)
            let age = today.getFullYear() - birthDate.getFullYear()
            const m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--
            }
            return age
        }

        const saveTeam = async () => {
            const players = []

            const allPlayersNotOnField = []
            const allPlayersOnField = []

            for (const amplua in playerList.value) {
                playerList.value[amplua].forEach(player => {
                    if (!player.onField) {
                        allPlayersNotOnField.push(player)
                    }
                })

                playerListOnField.value[amplua].forEach(player => {
                    player.onFieldAmplua = amplua
                    allPlayersOnField.push(player)
                })
            }

            players.push(...allPlayersNotOnField, ...allPlayersOnField)

            const data = {
                name: teamName.value,
                comment: teamDescription.value,
                players: players,
                scout_id: currentScout.value.admin_id,   
                scout_name: currentScout.value.admin_name,
                scout_surname: currentScout.value.admin_surname
            }

            await addShadowTeam(data)
            router.push('/shadow-teams')
        }

        watch(playerList, (newValue) => {
            for (const amplua in newValue) {
                playerListOnField.value[amplua] = newValue[amplua].filter(player => player.onField)
            }
        }, { deep: true })

        onMounted(async () => {
            const allPlayers = alienPlayers.value.concat(clubPlayers.value)
            
            allPlayers.forEach(player => {
                player.onField = false
                playerList.value[player.amplua].push(player)
            })

            currentScout.value = (await getAdmin()).data
            console.log(currentScout)
        })

        return {
            apiURL,
            playerList,
            teamName,
            myArray,
            myArray2,
            playerListOnField,
            selectPlayer,
            isPlayerSelected,
            selectedPlayer,
            countAge,
            teamDescription,
            saveTeam,
            getCurrentScoreSettingsForAmplua,
            drag: false,
            arrow_icon: arrow_icon,
        }
    },

    components: {
        draggable
    }
}
</script>

<style>
    .mini-scrollbar::-webkit-scrollbar {
        width: 0px; /* Ширина scrollbar */
    }

    .mini-scrollbar::-webkit-scrollbar-track {
        background-color: transparent; /* Цвет фона трека */
        border-radius: 10px; /* Скругление углов трека */
    }

    .mini-scrollbar::-webkit-scrollbar-thumb {
        background-color: #888; /* Цвет ползунка scrollbar */
        border-radius: 10px; /* Скругление углов ползунка */
        border: 2px solid transparent; /* Отступы для ползунка */
    }

    .mini-scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Цвет ползунка при наведении */
    }

    .card {
        background-color: #47484966;
        border: 1px solid #A9AAAB66;
        border-radius: 8px;
        padding: 20px;
        width: 270px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        text-align: center;
        height: 400px;

        h3 {
            margin-bottom: 10px;
            font-size: 1.5rem;
            color: #333;
        }

        p {
            font-size: 1rem;
            color: #666;
        }
    }
</style>