import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vue3TouchEvents from "vue3-touch-events"
import './assets/refactored_assets/scss/style.scss';
import { TroisJSVuePlugin } from 'troisjs';

import AvatarContainer from './components/uikit/Avatar/AvatarContainer.vue'

import FormBlock from './components/uikit/Block/FormBlock.vue'

import ButtonComponent from './components/uikit/Button/ButtonComponent.vue'
import IconButton from './components/uikit/Button/IconButton.vue'
import AddButton from './components/uikit/Button/EventButton/AddButton.vue'
import RemoveButton from './components/uikit/Button/EventButton/RemoveButton.vue'

import DoubleInput from './components/uikit/Input/DoubleInput.vue'
import InputComponent from './components/uikit/Input/InputComponent.vue'
import InputHorizontal from './components/uikit/Input/InputHorizontal.vue'
import InputUpload from './components/uikit/Input/InputUpload.vue'
import InputUploadOnlyFile from './components/uikit/Input/InputUploadOnlyFile.vue'

import TableBody from './components/uikit/TableComponent/table/TableBody.vue'
import TableCell from './components/uikit/TableComponent/table/TableCell.vue'
import TableComponent from './components/uikit/TableComponent/table/TableComponent.vue'
import TableFooter from './components/uikit/TableComponent/table/TableFooter.vue'
import TableHeader from './components/uikit/TableComponent/table/TableHeader.vue'
import TableHeaderCell from './components/uikit/TableComponent/table/TableHeaderCell.vue'
import TableHeaderRow from './components/uikit/TableComponent/table/TableHeaderRow.vue'
import TableRow from './components/uikit/TableComponent/table/TableRow.vue'
import TableWrapper from './components/uikit/TableComponent/table/TableWrapper.vue'

import CurrencyComponent from './components/uikit/Text/CurrencyComponent.vue'
import DateComponent from './components/uikit/Text/DateComponent.vue'
import InfoBlock from './components/uikit/Text/InfoBlock.vue'
import InfoLine from './components/uikit/Text/InfoLine.vue'
import InfoRow from './components/uikit/Text/InfoRow.vue'
import VerticalTextBlock from './components/uikit/Text/VerticalTextBlock.vue'

import SelectComponent from './components/uikit/Select/SelectComponent.vue'

import RadarChart from './components/charts/RadarChart.vue'

import StarsComponent from './components/uikit/Indicators/StarsComponent.vue'

import IphoneSwitch from './components/uikit/Switch/IphoneSwitch.vue'

import TextArea from './components/uikit/TextArea/TextArea.vue'

const pinia = createPinia()
const app = createApp(App)

app.component('AvatarContainer', AvatarContainer)
app.component('FormBlock', FormBlock)
app.component('ButtonComponent', ButtonComponent)
app.component('IconButton', IconButton)
app.component('AddButton', AddButton)
app.component('RemoveButton', RemoveButton)
app.component('DoubleInput', DoubleInput)
app.component('InputComponent', InputComponent)
app.component('InputHorizontal', InputHorizontal)
app.component('InputUpload', InputUpload)
app.component('TableBody', TableBody)
app.component('TableCell', TableCell)
app.component('TableComponent', TableComponent)
app.component('TableFooter', TableFooter)
app.component('TableHeader', TableHeader)
app.component('TableHeaderCell', TableHeaderCell)
app.component('TableHeaderRow', TableHeaderRow)
app.component('TableRow', TableRow)
app.component('TableWrapper', TableWrapper)
app.component('CurrencyComponent', CurrencyComponent)
app.component('DateComponent', DateComponent)
app.component('InfoBlock', InfoBlock)
app.component('InfoLine', InfoLine)
app.component('InfoRow', InfoRow)
app.component('VerticalTextBlock', VerticalTextBlock)
app.component('StarsComponent', StarsComponent)
app.component('RadarChart', RadarChart)
app.component('SelectComponent', SelectComponent)
app.component('IphoneSwitch', IphoneSwitch)
app.component('InputUploadOnlyFile', InputUploadOnlyFile)
app.component('TextArea', TextArea)

app.use(router).use(pinia).use(Vue3TouchEvents).use(TroisJSVuePlugin).mount('#app')
