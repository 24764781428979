<template>
    <div v-if="loaded" class="flex justify-between px-[70px]">
        <div class="card">
            <div class="flex justify-center">
                <AvatarContainer v-if="firstPlayer.photo" :src="apiURL + 'static/' + firstPlayer.photo"/>
                <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                    <div class="text-[#BD9135] text-[32px] ">
                        {{ firstPlayer.name[0] + firstPlayer.surname[0] }}
                    </div>
                </div>
            </div>
            <div class="text-[21px] truncate font-semibold mt-[10px]">
                {{ firstPlayer.name + ' ' + firstPlayer.surname }}
            </div>

            <div class="w-full h-[74px] mt-[12px]">
                <div class="flex justify-between w-full mx-[8px]">
                    <div class="flex  items-center justify-center font-normal">
                        <img src="@/assets/logo.svg" class="w-[30px]">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <div class="text-[9px]">група</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="firstPlayer.group_name?.length > 0 || firstPlayer.teamName?.length > 0">{{ firstPlayer.group_name || firstPlayer.teamName }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">вік</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="firstPlayer.age > 0 || calculateAge(firstPlayer.birthdate) > 0">{{ firstPlayer.age || calculateAge(firstPlayer.birthdate) }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">амплуа</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="firstPlayer.amplua?.length > 0">{{ firstPlayer.amplua }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">номер</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="firstPlayer.player_number?.length > 0 || firstPlayer.playerNumber > 0">{{ firstPlayer.player_number || firstPlayer.playerNumber }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex  items-center justify-center font-normal">
                        <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                    </div>
                </div>
            </div>

            <div class="relative top-[-39px] mx-auto">
                <RadarChart :key="new Date().getTime()" :indicators="currentPoints" :chartData="firstPlayer.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
            </div>
        </div>

        <div class="w-[50vw] h-[70vh] flex flex-col items-center justify-center">
            <CompareRadarChart 
                v-if="chartType == 'radar'" 
                :data1="firstPlayer.average_score" 
                :data2="secondPlayer.average_score" 
                :indicators="currentPoints" 
                :firstPlayerName="firstPlayer.name + ' ' + firstPlayer.surname"
                :secondPlayerName="secondPlayer.name + ' ' + secondPlayer.surname"
                />
            <CompareBarChart 
                v-if="chartType == 'bar'"
                :data1="firstPlayer.average_score" 
                :data2="secondPlayer.average_score" 
                :xAxisData="processPointsSettingsToArray(currentPoints)"    
                :firstPlayerName="firstPlayer.name + ' ' + firstPlayer.surname"
                :secondPlayerName="secondPlayer.name + ' ' + secondPlayer.surname"
            />

            <div class="flex gap-[10px]">
                <div 
                    @click="chartType = 'radar'" 
                    :class="{
                        'border-[#C59014]': chartType == 'radar',
                        'border-[#A9AAAB66]': chartType !== 'radar'
                    }" 
                    class="bg-[#47484966] p-[5px] cursor-pointer select-none border-[2px] text-[#C59014] text-[14px] flex justify-center items-center w-[228px] rounded-[8px]">
                    Радарна діаграма
                </div>
                <div 
                    @click="chartType = 'bar'" 
                    :class="{
                        'border-[#C59014]': chartType == 'bar',
                        'border-[#A9AAAB66]': chartType !== 'bar'
                    }" 
                    class="bg-[#47484966] p-[5px] cursor-pointer select-none border-[2px] text-[#C59014] text-[14px] flex justify-center items-center w-[228px] rounded-[8px]">
                    Стовбчиковий графік
                </div>
            </div>
        </div>

        <div class="card">
            <div class="flex justify-center">
                <AvatarContainer v-if="secondPlayer.photo" :src="apiURL + 'static/' + secondPlayer.photo"/>
                <div v-else class="avatar-container" :class="{ 'avatar-container--small': small }">
                    <div class="text-[#BD9135] text-[32px] ">
                        {{ secondPlayer.name[0] + secondPlayer.surname[0] }}
                    </div>
                </div>
            </div>
            <div class="text-[21px] truncate font-semibold mt-[10px]">
                {{ secondPlayer.name + ' ' + secondPlayer.surname }}
            </div>

            <div class="w-full h-[74px] mt-[12px]">
                <div class="flex justify-between w-full mx-[8px]">
                    <div class="flex  items-center justify-center font-normal">
                        <img src="@/assets/logo.svg" class="w-[30px]">
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        <div class="text-[9px]">група</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="secondPlayer.group_name?.length > 0 || secondPlayer.teamName?.length > 0">{{ secondPlayer.group_name || secondPlayer.teamName }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">вік</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="secondPlayer.age > 0 || calculateAge(secondPlayer.birthdate) > 0">{{ secondPlayer.age || calculateAge(secondPlayer.birthdate) }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">амплуа</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="secondPlayer.amplua?.length > 0">{{ secondPlayer.amplua }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex flex-col items-center justify-center  font-normal">
                        <div class="text-[9px]">номер</div>
                        <div class="text-[12px] font-semibold">
                            <span v-if="secondPlayer.player_number?.length > 0 || secondPlayer.playerNumber > 0">{{ secondPlayer.player_number || secondPlayer.playerNumber }}</span>
                            <span v-else class="opacity-25">—</span>
                        </div>    
                    </div>
                    <div class="flex  items-center justify-center font-normal">
                        <img src="@/assets/icons/ua_flag.svg" class="w-[20px] ml-[5px]">
                    </div>
                </div>
            </div>

            <div class="relative top-[-39px] mx-auto">
                <RadarChart :key="new Date().getTime()" :indicators="currentPoints" :chartData="secondPlayer.average_score" :max="10" :chart-size="{ width: '230px', height: '200px' }" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useGlobalStore } from '@/storage/globalStorage'

import CompareRadarChart from './CompareRadarChart.vue';
import CompareBarChart from './CompareBarChart.vue';

export default {
    name: 'ComparePlayersView',   

    setup() {
        const store = useGlobalStore()
        const route = useRoute()
        const router = useRouter()

        const loaded = ref(false)

        const goalkeeperPoints = ref(Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})))
        const fieldPlayerPoints = ref(Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2).filter(value => value !== null).map(e => ({name: e, max: 10})))
        const currentPoints = ref(goalkeeperPoints.value)

        const playersAmplya = ref(store.$state.firstPlayerForComparison.amplua)

        const apiURL = process.env.VUE_APP_API_URL

        const firstPlayer = ref(store.$state.firstPlayerForComparison)
        const secondPlayer = ref(store.$state.secondPlayerForComparison)

        const chartType = ref('radar')

        const calculateAge = (date) => {
            const today = new Date()
            const birthDate = new Date(date)
            let age = today.getFullYear() - birthDate.getFullYear()
            const m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--
            }
            return age
        }

        const processPointsSettingsToArray = (points) => {
            return points.map(e => e.name)
        }

        onMounted(() => {
            firstPlayer.value = store.$state.firstPlayerForComparison
            secondPlayer.value = store.$state.secondPlayerForComparison

            playersAmplya.value = store.$state.firstPlayerForComparison.amplua

            if (playersAmplya.value === 'ВР') {
                currentPoints.value = goalkeeperPoints.value
            } else {
                currentPoints.value = fieldPlayerPoints.value
            }

            loaded.value = true
        })

        return {
            firstPlayer,
            secondPlayer,
            apiURL,
            chartType,
            goalkeeperPoints,
            fieldPlayerPoints,
            playersAmplya,
            currentPoints,
            loaded,
            processPointsSettingsToArray,
            calculateAge
        }
    },

    components: {
        CompareRadarChart,
        CompareBarChart
    }
}
</script>

<style>
    
</style>