<template>
  <div class="stats-section">
    <div class="stats-section__circle">
      <img :src="circle" />
    </div>
    <div class="stats-section__block">
      <span>тренувань</span>
      <div class="stats-section__square--positive">{{ trainings_number }}</div>
    </div>
    <div class="stats-section__block">
      <span>відвідувань</span>
      <div class="stats-section__square--negative">{{ visitings_number }}</div>
    </div>
    <div class="stats-section__rating">
      <StarsComponent :rating="rating" />
    </div>
  </div>
</template>

<script>
import circle from "@/assets/refactored_assets/img/players/stats-ring.png";

export default {
  name: "StatsHeaderSection",
  props: {
    visitings_number: Number,
    trainings_number: Number,
    rating: Number,
  },
  data() {
    return {
      circle: circle,
    };
  },
};
</script>
