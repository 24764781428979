<template>
    <div class="main__second-line second-line">
        <div class="second-line__item h-[110px]">
            <h3 class="second-line__title">звітів по гравцям</h3>
            <div class="flex justify-evenly">
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ countClubReports }}</div>
                    <div class="text-[#C59014] text-[10px]">клубні</div>
                </div>
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ countAlienReports }}</div>
                    <div class="text-[#C59014] text-[10px]">зовнішні</div>
                </div>
            </div>
        </div>
        <div class="second-line__item h-[110px]">
            <h3 class="second-line__title">опрацьовано матчів</h3>
            <div class="flex justify-evenly">
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">-</div>
                    <div class="text-[#C59014] text-[10px]">клубні</div>
                </div>
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ alienReportsUniqueMatchesCount }}</div>
                    <div class="text-[#C59014] text-[10px]">зовнішні</div>
                </div>
            </div>
        </div>
        <div class="second-line__item h-[110px]">
            <h3 class="second-line__title">гравців в базі</h3>
            <div class="flex justify-evenly">
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ countClubPlayers }}</div>
                    <div class="text-[#C59014] text-[10px]">клубні</div>
                </div>
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ countAlienPlayers }}</div>
                    <div class="text-[#C59014] text-[10px]">зовнішні</div>
                </div>
            </div>
        </div>
        <div class="second-line__item h-[110px]">
            <h3 class="second-line__title">контрактів</h3>
            <div class="flex justify-evenly">
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ subscribedPlayersCount }}</div>
                    <div class="text-[#C59014] text-[10px]">підписано</div>
                </div>
                <div>
                    <div class="text-[#C59014] text-[36px] font-bold">{{ signingPlayersCount }}</div>
                    <div class="text-[#C59014] text-[10px]">в процесі</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainIndicators',

    props: {
        countAlienReports: {
            type: Number,
            required: true
        },
        countClubReports: {
            type: Number,
            required: true
        },
        countAlienPlayers: {
            type: Number,
            required: true
        },
        countClubPlayers: {
            type: Number,
            required: true
        },
        subscribedPlayersCount: {
            type: Number,
            required: true
        },
        signingPlayersCount: {
            type: Number,
            required: true
        },
        alienReportsUniqueMatchesCount: {
            type: Number,
            required: true
        },
    }
}
</script>

<style>
    
</style>