<template>
    <div class="w-[97%] mx-auto">
        <div class="w-[50%] flex justify-between mt-[20px]">
            <div class="w-[32%]">
                <div class="w-[100%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">група крові</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
        
                        type="text"
                        placeholder="O(I)-"
                    >
                </div>
                <div class="w-[100%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">кровʼяний тиск</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
        
                        type="text"
                        placeholder="98/100  мм рт. ст."
                    >
                </div>
            </div>
            <div class="w-[32%]">
                <div class="w-[100%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">кардіозагартованість</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
        
                        type="text"
                        placeholder="36 VO2  макс"
                    >
                </div>
                <div class="w-[100%] mb-[15px]">
                    <div class="text-[#BD9135] text-[14px] font-bold">середній серцевий ритм</div>
                    <input 
                        class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
        
                        type="text"
                        placeholder="73 уд.хв"
                    >
                </div>
            </div>
            <div class="w-[32%] mt-[22px] select-none">
                <div @click="canPlay = !canPlay" v-if="!canPlay" class="bg-[#DA4167] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                    не може грати
                </div>
                <div @click="canPlay = !canPlay" v-else class="bg-[#339989] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
                    може тренуватся
                </div>
            </div>
        </div>

        <div class="bg-[#BD9135] w-[228px] mt-[30px] cursor-pointer font-bold rounded-[6px] text-white text-center flex items-center justify-center h-[32px]">
            Додати документ
        </div>

        <div>
            <table class="w-[57%] mt-[24px]">
                <thead class="">
                    <tr class="text-[#C59014] border-b-[1px] border-[#C59014] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm w-[4%]">#</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">дата</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[31%]">аналіз/довідка/інше</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[10%]">документ</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[14%]">лікар</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[18%]">середня оцінка</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[14%]"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">1.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center">Загальний аналіз крові</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            О. Масловський
                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">1.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center">Загальний аналіз крові</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            О. Масловський
                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">1.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center">Загальний аналіз крові</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            О. Масловський
                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'medicine-tab',
    
    setup() {
        const canPlay = ref(false);

        return {
            canPlay
        }
    }
}
</script>

<style>
    
</style>