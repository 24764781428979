<template>
    <svg width="32" height="56" viewBox="0 0 32 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M3.95412 52.6279L8.59631 48.4077H13.6605L18.3027 52.6279H19.9908L21.2568 55.16H1L2.26605 52.6279H3.95412Z" class="color_changed_fill color_changed_stroke" fill="white" stroke="white" stroke-width="0.844034" stroke-linejoin="round"/>
            <path d="M11.1279 47.9858V0.297852" stroke="white" stroke-width="1.68807" stroke-linejoin="round"/>
            <path d="M31.3853 7.05013L13.6605 12.8978L13.6605 1.20248L31.3853 7.05013Z" fill="white" class="color_changed_fill" />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Flag',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>