<template>

    <svg width="43" height="23" viewBox="0 0 43 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M2.90927 13.8194L7.00056 1H8.09157L12.1829 13.8194H13.2739L14.0921 15.4559H1L1.81826 13.8194H2.90927Z" class="color_changed_fill" fill="white" stroke="white" stroke-width="0.545505" stroke-linejoin="round"/>
            <path d="M8.6377 7.54688L34.0162 14.1308" stroke="#474849" stroke-width="2.18202" stroke-linecap="round"/>
            <path d="M31.276 20.9112L35.3673 8.0918H36.4583L40.5496 20.9112H41.6406L42.4588 22.5477H29.3667L30.185 20.9112H31.276Z" class="color_changed_fill" fill="white" stroke="white" stroke-width="0.545505" stroke-linejoin="round"/>
        </g>
    </svg>

</template>

<script>
    export default {
        name: 'ConeBarrier',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>