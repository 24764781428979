<template>
    <DeleteScoutForm v-if="deletionFormFlag" :scout="scoutForDeletion" @acceptDeleteScout="scout => {$emit('deleteScout', scout); deletionFormFlag = false}" @close="deletionFormFlag = false" />
    <div class="w-[90%] mx-auto mb-3 rounded-[10px] overflow-hidden">
        <table class="w-full">
            <thead class="">
                <tr class="text-white border-b-[1px] border-[#FFF] border-opacity-10">
                    <th class="px-3 py-2 text-center font-medium text-sm"></th>
                    <th class="px-3 py-2 text-start font-medium text-sm">скаут</th>
                    <th class="px-3 py-2 text-start font-medium text-sm">посада</th>
                    <th class="px-3 py-2 text-start font-medium text-sm">дата старту</th>
                    <th class="px-3 py-2 text-end font-medium text-sm">статус</th>
                    <th class="px-3 py-2 text-end font-medium text-sm"></th>
                </tr>
            </thead>
            <tbody>
                
                <tr v-for="scout in allScouts">
                    <td class="px-3 py-2 text-center font-medium text-sm w-[5%]">
                        <div class="w-10 h-10 rounded-full overflow-hidden flex items-end bg-[#BD9135] bg-opacity-20 border-[#BD9135] border-[1px]">
                            <img v-if="scout.photo" :src="apiURL + 'static/' + scout.photo">
                            <div v-else class="w-full h-full  text-[#BD9135] flex justify-center items-center">
                                {{ scout.name[0] + scout.surname[0] }}
                            </div>
                        </div>
                    </td>
                    <td class="px-3 py-2 text-start font-medium text-sm w-[15%] truncate">{{ scout.name + ' ' + scout.surname }}</td>
                    <td class="px-3 py-2 text-start text-sm lowercase truncate w-[10%]">{{ parseJobTitle(scout.job_title) }}</td>
                    <td class="px-3 py-2 text-start text-sm">{{ parseDate(scout.start_working_date) }}</td>
                    <td class="py-2 text-end font-medium text-sm">
                        <div class="flex justify-end">
                            <div v-if="scout.status == 'active'" class="bg-[#339989] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-white">
                                Активний
                            </div>  
                            <div v-if="scout.status == 'archive'" class="bg-[#D9D9D9] mx-2 h-[18px] flex justify-center items-center rounded-full w-[88px] text-[13px] text-center text-[#78797C]">
                                Архів
                            </div> 
                        </div>
                    </td>
                    <td class="px-3 py-2 text-end font-medium text-sm w-[10%]">
                        <button @click="$emit('selectScout', scout)" class="px-2 py-1 rounded-[10px] text-sm font-medium">
                            <img src="@/assets/icons/pen_icon.svg" class="w-6 h-6">
                        </button>
                        <button @click="() => {scoutForDeletion = scout; deletionFormFlag = true}" class="px-2 py-1 rounded-[10px] text-sm font-medium text-red-600">
                            <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6">
                        </button>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</template>

<script>
import { ref } from 'vue'

import DeleteScoutForm from './DeleteScoutForm.vue';

export default {
    name: 'ScoutTable',
    
    props: {
        allScouts: {
            type: Array,
            required: true
        }
    },

    setup() {
        const apiURL = ref(process.env.VUE_APP_API_URL)

        const deletionFormFlag = ref(false)
        const scoutForDeletion = ref({})

        const parseJobTitle = (jobTitle) => {
            switch (jobTitle) {
                case 'scout':
                    return 'Скаут'
                case 'lead_scout':
                    return 'Шеф-скаут'
            }
        }

        const parseDate = (date) => {
            const dateObj = new Date(date)
            return dateObj.toLocaleDateString()
        }

        return {
            apiURL,
            parseJobTitle,
            parseDate,
            deletionFormFlag,
            scoutForDeletion
        }
    },

    components: {
        DeleteScoutForm
    }
}
</script>

<style>
    
</style>