<template>


    <svg width="28" height="31" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path d="M14.9286 1H12.7857L10.6429 8.00809H17.0714L14.9286 1Z" class="color_changed_fill" fill="white"/>
            <path d="M5.28571 22.5633L9.57143 11.7817H18.1429L22.4286 22.5633H5.28571Z" class="color_changed_fill" fill="white"/>
            <path d="M1 29.5714L2.60714 26.3369H25.1071L26.7143 29.5714H1Z" class="color_changed_fill" fill="white"/>
            <path d="M5.28571 22.5633L9.57143 11.7817H18.1429L22.4286 22.5633H5.28571Z" class="color_changed_fill" fill="white"/>
            <path d="M14.9286 1H12.7857L10.6429 8.00809H17.0714L14.9286 1Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M5.28571 22.5633L9.57143 11.7817H18.1429L22.4286 22.5633H5.28571Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M1 29.5714L2.60714 26.3369H25.1071L26.7143 29.5714H1Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
            <path d="M5.28571 22.5633L9.57143 11.7817H18.1429L22.4286 22.5633H5.28571Z" stroke="black" stroke-width="1.42857" stroke-linejoin="round"/>
        </g>
    </svg>



</template>

<script>
    export default {
        name: 'Cone',
        props: {
            screenWidth: {
                type: Number,
                default: 0
            },
            color: {
                type: String,
                default: '#E6E9ED'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>