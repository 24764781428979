<template>
  <thead class="table__header">
      <slot></slot>
    </thead> 
</template>

<script>
export default {
    name: 'TableHeader',
    props: {
  },
    components: {
    },
    data() {
        return {
          
        }
    },
}
</script>