<template>
    <div @click="$emit('close')" style="background-color: rgba(53, 53, 53, 0.10)" class="backdrop-blur-[7px] top-0 fixed w-full z-40 h-full -mt-2"></div>
    <div v-if="loadingSpinnerFlag" class="z-50 fixed w-full h-full">
        <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#DA4167] border-t-transparent absolute top-[50%] left-[50%] ml-[-120px] mt-[-50px]"></div>
    </div>
    <ImageViewer 
        v-if="viewImageFlag" @close="viewImageFlag = false" @delete="deleteImage" :imageUrl="viewImageURL" altText="dddd" class="z-50"/>
    <div v-if="!viewImageFlag && !loadingSpinnerFlag" style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] border-[1px] border-[#FFF] border-opacity-10 rounded-[10px] z-50 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px]">
        <div class="px-[10px] py-[10px] w-[350px]">
            <div class="flex justify-center"><img src="../../../../assets/logo.svg" class="h-[70px]"></div>
            <div class="font-semibold text-center text-[16px] text-white mb-[15px] mt-[10px]">
                Створити транзакцію
            </div>
            <div class="w-full">    
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    з балансу
                </div>
                <div class="flex justify-between">
                    <!-- <input v-model="senderBalanceQuery" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" type="text" name="sender"> -->
                
                    <select v-model="senderBalanceId" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" name="sender">
                        <option v-for="balance in allBalanceForTransactions" :value="balance.balance_id" class="bg-[#252526]">{{ balance.label }}</option>
                    </select>
                </div>
                <div v-if="senderBalanceObject.state != undefined" class="text-[#A1D9F7] mt-[10px]">
                    На балансі: <span :class="{ 'text-[#8FC408]' : senderBalanceObject.state > 0, 'text-[#FA1367]' : senderBalanceObject.state < 0, 'text-[#ccc]' : senderBalanceObject.state == 0 }">{{ senderBalanceObject.state.toFixed(2) }}</span>
                </div> 
                <div class="absolute w-[330px]" v-if="senderBalanceVariations.length > 0">
                    <div v-for="balance in senderBalanceVariations" @click="senderBalanceObject = balance; senderBalanceQuery = balance.label; senderBalanceVariations = []" class="bg-[#BD9135] py-[4px] px-[5px] hover:opacity-90 cursor-pointer">
                        {{ balance.label }}
                    </div>
                </div>           
            </div>

            <div class="w-full mt-[15px]">    
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    на баланс
                </div>
                <div class="flex justify-between">
                    <!-- <input v-model="receiverBalanceQuery" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" type="text" name="sender"> -->
                    <select v-model="receiverBalanceId" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" name="sender">
                        <option v-for="balance in allBalanceForTransactions" :value="balance.balance_id" class="bg-[#252526]">{{ balance.label }}</option>
                    </select>
                </div> 
                <div v-if="receiverBalanceObject.state != undefined" class="text-[#A1D9F7] mt-[10px]">
                    На балансі: <span :class="{ 'text-[#8FC408]' : receiverBalanceObject.state > 0, 'text-[#FA1367]' : receiverBalanceObject.state < 0, 'text-[#ccc]' : receiverBalanceObject.state == 0 }">{{ receiverBalanceObject.state.toFixed(2) }}</span>
                </div> 
                <div class="absolute w-[330px]" v-if="receiverBalanceVariations.length > 0">
                    <div v-for="balance in receiverBalanceVariations" @click="receiverBalanceObject = balance; receiverBalanceQuery = balance.label; receiverBalanceVariations = []" class="bg-[#BD9135] py-[4px] px-[5px] hover:opacity-90 cursor-pointer">
                        {{ balance.label }}
                    </div>
                </div>                 
            </div>

            <div class="w-full mt-[15px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    сума транзакції
                </div>
                <div class="flex justify-between">
                    <input v-model="amount" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" type="number" name="amount">
                </div>
            </div>
            <div class="mt-[15px]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    коментар
                </div>
                <div class="flex justify-between">
                    <textarea v-model="comment" name="invest_comment" class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[5px] pl-[5px] outline-none" rows="10"></textarea>
                </div>
            </div>

            <div class="w-full mt-[15px]">
                <div class="flex items-center justify-between text-[#fff]">
                    <div>
                        Додаткові файли:
                    </div>
                    <div class="flex justify-end">
                        <img v-for="image in allImages" @click="viewPhoto(image)" src="@/assets/icons/picture_icon.svg" class="mx-[2px] cursor-pointer">
                        <img src="@/assets/icons/upload_icon.svg" class="ml-2 cursor-pointer" @click="uploadPhoto">
                    </div>
                </div>
                <input ref="imagesInput" @change="selectPhoto($event)" class="w-full bg-gray-100 py-[5px] pl-[5px] outline-none hidden" type="file" name="additional_files">
            </div>
            <button @click="addRealTransaction" class="w-full rounded-[6px] bg-[#8FC408] text-white py-[7px] mt-[20px]">
                Прийняти
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getBalanceByQuery, addRealTransactionRequest } from '@/services/apiRequests'
    import ImageViewer from '../ui/ImageViewer.vue'

    async function readFileObjectAsDataURL (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = (event) => {
                resolve(event.target.result)
            }
            reader.onerror = (error) => {
                reject(error)
            }
            reader.readAsDataURL(file)
        })
    }

    export default {
        setup(props, { emit }) {
            const loadingSpinnerFlag = ref(false)

            const viewImageObject = ref({})
            const viewImageURL = ref('')
            const viewImageFlag = ref(false)
            const imagesInput = ref(null)
            const allImages = ref([])

            const senderBalanceQuery = ref('')
            const senderBalanceObject = ref({})
            const senderBalanceVariations = ref([])
            const senderBalanceId = ref('')

            const allBalanceForTransactions = ref([])

            watch(senderBalanceId, () => {
                senderBalanceObject.value = allBalanceForTransactions.value.find(balance => balance.balance_id == senderBalanceId.value)
            })

            watch(senderBalanceQuery, async () => {
                if (senderBalanceQuery.value.length > 0) {
                    await senderBalanceSearch()
                } else {
                    senderBalanceVariations.value = []
                }
            })

            const senderBalanceSearch = async () => {
                if (!senderBalanceObject.value?.label) {
                    console.log((await getBalanceByQuery(senderBalanceQuery.value)))
                    senderBalanceVariations.value = (await getBalanceByQuery(senderBalanceQuery.value)).balances
                }
            }

            const receiverBalanceQuery = ref('')
            const receiverBalanceObject = ref({})
            const receiverBalanceVariations = ref([])
            const receiverBalanceId = ref('')

            watch(receiverBalanceId, () => {
                receiverBalanceObject.value = allBalanceForTransactions.value.find(balance => balance.balance_id == receiverBalanceId.value)
            })

            watch(receiverBalanceQuery, async () => {
                await receiverBalanceSearch()
            })

            const receiverBalanceSearch = async () => {
                if (!receiverBalanceObject.value?.label) {
                    console.log((await getBalanceByQuery(receiverBalanceQuery.value)))
                    receiverBalanceVariations.value = (await getBalanceByQuery(receiverBalanceQuery.value)).balances
                }
            }

            const amount = ref('')
            const comment = ref('')

            const uploadPhoto = () => {
                imagesInput.value.click()
            }

            const selectPhoto = (event) => {
                allImages.value.push(event.target.files[0])
            }

            const viewPhoto = async (image) => {
                viewImageURL.value = await readFileObjectAsDataURL(image)
                viewImageObject.value = image
                viewImageFlag.value = true
            }

            const deleteImage = () => {
                allImages.value = allImages.value.filter((img) => {
                    return img !== viewImageObject.value
                })
                viewImageFlag.value = false
            }

            const addRealTransaction = async () => {
                const ref = await addRealTransactionRequest(
                    senderBalanceObject.value.balance_id,
                    receiverBalanceObject.value.balance_id,
                    amount.value,
                    comment.value,
                    allImages.value
                )
                emit('close')
            }

            onMounted(async () => {
                const adminsCashBalances = (await getBalanceByQuery('Готівковий баланс адміна')).balances
                const adminsInvestBalances = (await getBalanceByQuery('Інвестиційний баланс адміна')).balances
                const trainersStudentBalance = (await getBalanceByQuery('Студентський баланс тренера')).balances
                const trainersServicesBalance = (await getBalanceByQuery('Сервісний баланс тренера')).balances
                const bankBalance = (await getBalanceByQuery('Bank')).balances
                const wayforpayBalance = (await getBalanceByQuery('Wayforpay')).balances

                allBalanceForTransactions.value = [
                    ...adminsCashBalances,
                    ...adminsInvestBalances,
                    ...trainersStudentBalance,
                    ...trainersServicesBalance,
                    ...bankBalance,
                    ...wayforpayBalance
                ]

                console.log('allBalanceForTransactions.value ------- ', allBalanceForTransactions.value)
            })

            return {
                loadingSpinnerFlag,
                viewImageObject,
                viewImageURL,
                viewImageFlag,
                uploadPhoto,
                selectPhoto,
                viewPhoto,
                deleteImage,
                senderBalanceQuery,
                senderBalanceObject,
                senderBalanceVariations,
                senderBalanceSearch,
                receiverBalanceQuery,
                receiverBalanceObject,
                receiverBalanceVariations,
                receiverBalanceSearch,
                amount,
                comment,
                imagesInput,
                allImages,
                addRealTransaction,
                allBalanceForTransactions,
                senderBalanceId,
                receiverBalanceId
            }
        },

        components: {
            ImageViewer
        },
    }
</script>

<style lang="scss" scoped>

</style>