
<template>
  <div class="left-chart-block__header">
    <h2 class="left-chart-block__title">{{data.title}}</h2>
  </div>
  <div class="right-chart-block__chart">
    <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
  </div>
</template>

<script >
import {onMounted, onUnmounted, ref, toRefs, watchEffect} from "vue";
import * as echarts from "echarts";
export default {
  name: "LineBarGraph",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props){
    const { data } = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;

    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const screenWidth = ref(window.innerWidth);

      const fontSize = screenWidth.value>780? "18px": "14px"


      const rawData = data.value.data.map(element=>Array.isArray(element.value)?element.value: null).filter(element=>element !=null)
      const totalData = [];
      for (let i = 0; i < rawData[0].length; ++i) {
        let sum = 0;
        for (let j = 0; j < rawData.length; ++j) {
          sum += rawData[j][i];
        }
        totalData.push(sum);
      }

      const series = data.value.data.map(element=>element.title).map((name, sid) => {
        return {
          name,
          stack: 'one',
          type: 'bar',

          barWidth: '45%',
          label: {
            show: false,
            rotate: 90,
            textStyle: {
              fontSize:fontSize,
              color: "#fff",
            },
            formatter: (params) => params.value
          },
          data: rawData[sid]
        };
      });
      const lineData = data.value.data.find(element=>element.value===0)
      series.push({
        name: lineData.title,
        type: 'line',
        symbol: "circle",
        lineStyle: {
          color: lineData.color,
          width:6
        },
        symbolSize:10,
        itemStyle: {
          color: lineData.color,

        },

        label: {
          show: true,
          position: 'top',
          color: lineData.color,
          fontSize: screenWidth.value>780? "18px": "14px",
          formatter: (params) => params.value
        },
        data: totalData,
      });

      let option = {
        legend: {
          selectedMode: false,
          bottom: 0
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        color:data.value.data.map(element=>element.color),
        grid: {
          left: 5,
          right: 5,
          top: 30,
          bottom: 50
        },
        yAxis: {
          type: 'value',
          show: false
        },
        xAxis: {
          type: 'category',
          data: data.value.labels,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
        },
        series: series
      };



      myChart.setOption(option, true);
    });
    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };
    onMounted(() => {
      if (!chartContainer.value) return;

      window.addEventListener('resize', updateChartSize);
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });

    return {chartContainer};
  }

}
</script>

<style>
</style>
