<template>
  <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "PieAngelGraphElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    color:{
      type: String,
      required: true
    }
  },
  setup(props) {
    const { dataSet,color} = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;

    console.log( dataSet)
    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const option = {
        tooltip: {
          show: false
        },
        color: [color.value],
        legend: {
          show: false
        },
        graphic: [
          {
            type: 'text',
            left: '10',
            top: '10',
            style: {
              text: '',
              fontSize: 15,
              fontWeight: 'bold',
              fill: color.value,
            },
            id: 'title'

          },
          {
            type: 'text',
            left: '10',
            top: '30',
            style: {
              text: '',
              fontSize: 15,
              fontWeight: 'regular',
              fill: "#C6C6C6",
            },
            id: 'description'
          }
        ],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            padAngle: 2,
            label: {
              show: true,
              position: 'top',
              color: 'transparent',
              formatter: '{b}:  {c} {d}%'
            },
            itemStyle: {
              normal: {
                opacity: 1,
                borderRadius: 5
              }
            },
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                opacity: 0.5
              }
            },

            labelLine: {
              show: false,
            },
            data: dataSet.value.map(element=>{
              return {
                value:element.value,
                name: element.title
              }
            })
          }
        ]
      };

      myChart.setOption(option, true);
      myChart.on('mouseover', function(params) {
        if (params.componentType === 'series') {
          myChart.setOption({
            graphic: [
              {
                id: 'title',
                style: {
                  text: `${params.name}`
                }
              },
              {
                id: 'description',
                style: {
                  text: `${params.value} (${params.percent}%)`
                }
              },
            ]
          });
        }
      });
      myChart.on('mouseout', function(params) {
        console.log(params)
        myChart.setOption({
          graphic: [
            {
              id: 'title',
              style: {
                text: ``
              }
            },
            {
              id: 'description',
              style: {
                text: ``
              }
            },
          ]

        });
      });

    });

    onMounted(() => {
      if (!chartContainer.value) return;
      // Графік ініціалізується у watchEffect
      window.addEventListener('resize', updateChartSize);
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }
    });
    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };


    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });

    return {chartContainer};
  }
};
</script>

