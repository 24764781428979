<template>
    <div class="fixed z-[300] w-full h-full backdrop-blur-md"></div>
    <FormBlock class="match-event-form">
        <div class="match-event-form__title">Додати кінець першого тайму</div>
        <div class="match-event-form__time">
            <div class="input-component__label">введіть хвилини</div>
            <div class="input-component__field time-field">
                <input 
                    type="number"
                    class="text-center w-full"
                    v-model="newGoal.time"
                />
            </div>
        </div>

        <div class="match-event-form__our-goal-block h-[284px]">

            <div class="match-event-form__details">
                <img src="@/assets/icons/flag_icon.svg" class="w-[45px]">
                <div>{{ newGoal.time }}’’ кінець першого тайму</div>
            </div>

        </div>

        <div class="match-event-form__buttons">
            <ButtonComponent @click="save">
                Зберегти
            </ButtonComponent>
            <ButtonComponent @click="$emit('close')">
                Відмінити
            </ButtonComponent>
        </div>

    </FormBlock>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'EndTimeForm',

    props: {
        team: {
            type: Array,
            default: () => []
        }
    },

    setup(props, { emit }) {
        const whoReleasedGoal = ref(true);
        const isPenalty = ref(false);

        const teamNames = ref(props.team.map(player => player.name))

        const newGoal = ref(
            {
                id: new Date().getTime(),
                type: 'EndTime',
                time: 0,
                data: null
            }
        )

        const save = () => {
            emit('save', newGoal.value)
        }

        return {
            whoReleasedGoal,
            isPenalty,
            newGoal,
            teamNames,
            save
        }
    }
}
</script>

<style>
    
</style>