<template>
  <div class="right-chart-block__header">
    <label class="right-chart-block__select">
      <select v-model="selectedYear">
        <option v-for="yearObj in data" :key="yearObj.year" :value="yearObj.year">
          {{ yearObj.year }}
        </option>
      </select>
    </label>
  </div>
  <div class="right-chart-block__chart">
    <PieGraphElement  :dataSet="activeYearData.dataSet" ></PieGraphElement>
  </div>
</template>

<script >
import {computed, ref, toRefs, watch} from "vue";
import PieGraphElement from "./PieGraphElement.vue";

export default {
  name: "PieGraph",
  components: {PieGraphElement},
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup(props){
    const { data } = toRefs(props);

    const selectedYear = ref(data.value.find(year => year.isActive)?.year || data.value[0].year);
    const activeYearData = computed(() => {
      return data.value.find(yearObj => yearObj.year === selectedYear.value) || [];
    });

    watch(selectedYear, newValue => {
      data.value.forEach(yearObj => {
        yearObj.isActive = yearObj.year === newValue;
      });
    });


    return {
      selectedYear,
      activeYearData
    }
  }

}
</script>

<style>
</style>
