<template>
    <div class="w-[97%] mx-auto">
        <table class="w-[57%] mt-[24px]">
            <thead class="">
                <tr class="text-[#C59014] border-b-[1px] border-[#C59014] border-opacity-10">
                    <th class="px-3 py-2 text-center font-medium text-sm w-[25%]">Назва нормативу</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[25%]">результати</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[20%]">рейтиг</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[30%]">динаміка</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                    <td class="text-center truncate">Стрибок у довжину з місця (см)</td>
                    <td class="font-semibold text-center flex items-center h-[82px] px-[12px]">
                        <div class="mx-[7px]">
                            <div class="text-[12px] text-white">110 см</div>
                            <div class="text-[10px] text-[#474849]">12.09.2022</div>
                        </div>
                        <div class="mx-[7px]">
                            <div class="text-[12px] text-white">110 см</div>
                            <div class="text-[10px] text-[#474849]">12.09.2022</div>
                        </div>
                        <div class="mx-[7px]">
                            <div class="text-[12px] text-white">110 см</div>
                            <div class="text-[10px] text-[#474849]">12.09.2022</div>
                        </div>
                        <div class="mx-[7px]">
                            <div class="text-[12px] text-white">110 см</div>
                            <div class="text-[10px] text-[#474849]">12.09.2022</div>
                        </div>
                    </td>
                    <td class="">
                        <div class="bg-[#C59014] text-[21px] mx-auto text-white font-bold px-[10px] h-[30] w-[34px] text-center rounded-full">
                            5
                        </div>
                    </td>
                    <td class="">
                        <LineChart />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import LineChart from '../components/LineChart.vue';

export default {
    name: 'testing-tab',    

    components: {
        LineChart
    }
}
</script>


<style>
    
</style>