<template>
    <div ref="chart" style="width: 200px; height: 80px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    data() {
      return {
        // Данные для графика
        chartData: {
          categories: ['Янв', 'Фев', 'Март', 'Апр', 'Май'],
          data: [0, 200, 20, 80, 70]
        }
      };
    },
    mounted() {
      // Создание экземпляра графика при монтировании компонента
      this.createChart();
    },
    methods: {
      createChart() {
        // Инициализация экземпляра ECharts на элементе div с помощью ref
        const chartDom = this.$refs.chart;
        const myChart = echarts.init(chartDom, null, { renderer: 'svg' });
  
        // Опции для графика
        const option = {
          xAxis: {
            show: false
          },
          yAxis: {
            show: false
          },
          series: [{
            data: this.chartData.data,
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            lineStyle: {
              color: '#FFA500',
              width: 2
            },
            itemStyle: {
              color: '#FFA500'
            }
          }]
        };
  
        // Установка опций и отображение графика
        myChart.setOption(option);
      }
    }
  };
  </script>
  
  <style>
  /* Стили могут быть добавлены сюда */
  </style>
  