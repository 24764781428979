<template>
  <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "ExpensiveLineChartElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    color: {
      type: Array,
      required: true
    },
    label: {
      type: Array,
      required: true
    }

  },
  setup(props) {
    const {color, dataSet,label} = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;
    const screenWidth = ref(window.innerWidth);
    const isShow = screenWidth.value>700? true: false;
    const newLabels = screenWidth.value>900? label.value : label.value.map(element=>element.substring(0, 4))

    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      console.log(color,dataSet)
     const option = {
       grid: { left: '0%', right: '0%', bottom: '0%', containLabel: true, },

        xAxis: {
          show: isShow,
          type: 'category',
          data: newLabels,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel:{
            fontSize: "12px",
            color: "#fff",
            interval: 0 // Добавьте это свойство

          },
        },
        yAxis: {
          type: 'value',
          show: false
        },
        series: [
          {
            data: dataSet.value,
            type: 'bar',
            itemStyle: {
              color: color.value,
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize:18,
                color: color.value,
              }
            }
          }
        ]
      };
      myChart.setOption(option, true);
    });

    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };
    onMounted(() => {
      window.addEventListener('resize', updateChartSize);

      if (!chartContainer.value) return;

      window.addEventListener('resize', updateChartSize);
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }

      console.log('newLabels ------ ', dataSet.value)
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });

    return {chartContainer};
  }
};
</script>

