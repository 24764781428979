<template>
    <div ref="chart" style="width: 100%; height: 200px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts/core';
  import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
  } from 'echarts/components';
  import { BarChart } from 'echarts/charts';
  import { SVGRenderer } from 'echarts/renderers';
  
  echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, SVGRenderer]
  );
  
  export default {
    data() {
      return {
        // Дані для графіку
        chartData: {
          categories: ['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Груд'],
          data: [120, 200, 150, 80]
        }
      };
    },
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        const chartDom = this.$refs.chart;
        const myChart = echarts.init(chartDom);
        const { categories, data } = this.chartData;
  
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '-2%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: categories
          },
          yAxis: {
            show: false
          },
          series: [
            {
              name: 'Дані',
              type: 'bar',
              data: data,
              itemStyle: {
                color: '#FFA500'
              }
            }
          ]
        };
  
        myChart.setOption(option, { renderer: 'svg' }); // Встановлюємо рендер у форматі SVG
      }
    }
  };
  </script>
  
  <style>
  /* Ваші стилі, якщо потрібно */
  </style>
  