<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] w-[400px] h-[67px] rounded-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="w-full text-center mt-[10px]">
            <div class="flex text-center items-center py-[4px] bg-[#BD9135] w-[95%] rounded-[3px] mx-auto">
                <div class="block-title text-[11px] font-bold text-[#252526] text-center mx-auto w-[60%]">ДАНІ ДЛЯ ВХОДУ</div>
            </div>
        </div>

        <div class="mx-[15px] text-[#FFF] flex items-end justify-between mt-[2px]">
            <div class="flex justify-between w-[40%] items-center align-middle mb-[10px]">
                <div class="w-[70%] flex">
                    <span class="text-[#FFF] mr-[5px] font-semibold text-[13px]">380</span><input :value="login.replace('380', '')" @change="$emit('update:login', '380' + $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="000000000" type="text">
                </div>
                <div class="text-[#BD9135] font-normal mt-[4px] opacity-90 text-[11px]">
                    Логін
                </div>
            </div>

            <div class="flex justify-between w-[40%] items-center align-middle mb-[10px]">
                <div class="w-[70%]">
                    <input :value="password" @change="$emit('update:password', $event.target.value)" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[70%] truncate placeholder:opacity-60" type="text" placeholder="password">
                </div>
                <div class="text-[#BD9135] mt-[4px] font-normal opacity-90 text-[11px]">
                    Пароль
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataInput from '../ui/DataInput.vue'

    export default {
        name: 'auth-data-card',

        props: {
            login: {
                type: String,
                default: ''
            },
            password: {
                type: String,
                default: ''
            },
        },

        components: {
            DataInput
        }
    }
</script>
