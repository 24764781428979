<template>
  <main class="page" ref="resizeChartBlock" style="overflow: hidden">
    <div class="dashboard-wrapper">
        <section class="main">
          <div class="main__container">
            <TransparentGridMetrics :metricsData="yourMetricsArrayTransparent" />
            <GreenGridMetrics :metricsData = "yourMetricsArrayGreen"/>
          </div>
        </section>
        <section class="chart-block">
          <div class="chart-block__container">
            <div class="chart-block__left left-chart-block">
              <div class="left-chart-block__chart" >
                <line-graph :lineGraphText="lineGraphText" :data="lineGraph"></line-graph>
              </div>
            </div>
            <div class="chart-block__right right-chart-block">
            <pie-graph :data="pieGraph" />
            </div>
          </div>
        </section>
        <section class="chart-block">
          <div class="chart-block__container">
            <div class="chart-block__left left-chart-block">
              <LineBarGraph :data="lineBarGraph"/>
            </div>
            <div class="chart-block__right right-chart-block">
              <HorizontalLineChart :data="horizontalLineData"/>
            </div>
          </div>
        </section>
        <section class="chart-block">
          <div class="chart-block__container">
            <div class="chart-block__left left-chart-block">
              <expensive-line-chart :text="barGraphText" :data="barGraph"/>
            </div>
            <div class="chart-block__right right-chart-block">
              <pie-angel-graph :data="pieGraph" color="#DA4167" />
            </div>
          </div>
        </section>
        <!-- <section class="chart-block">
          <div class="chart-block__container">
            <div class="chart-block__left left-chart-block">
    <TwoLineBarGraph :data="yearMoneyAccounting" :graph-text="yearMoneyAccountingText"/>
            </div>
            <div class="chart-block__right right-chart-block">
              <div class="right-chart-block__header-mod">
                <h3 class="right-chart-block__title">Прибуток за увесь час</h3>
              </div>
              <div class="right-chart-block__chart"> -->
                <!-- <img src="../assets/img/profit/chart-two.png" alt="chart-two" /> -->
              <!-- </div>
            </div>
          </div>
        </section> -->
        <section class="indicators">
          <div class="indicators__container">
            <h2 class="indicators__title">Кількісні показники</h2>
            <QuantitativeIndicators :data="yourQuantitativeIndicators"/>
          </div>
        </section>
        <AnalyticsComponent :data="yourAnalytics"/>
    </div>
  </main>
</template>

<script>
    import { getServiceBalanceByLabel, getAllTrainerStudentBalanceState, 
        getAllCashFromAdmins, getAllInvestFromAdmins, 
        getSumOfAllVirtualOutlays, getAllVirtualOutlaysCount, 
        getMeanRealIncomeFromStudent, getStatisticsClubOutlaysByYear, getYearIncomesBySources,
        getAllGroups, getAllTrainers, getStudentsCountByGroupId, getTrainerCompletedTrainingsByYear, getAllIncomesByGroupInYear } from '@/services/apiRequests'
    import { ref, onMounted, watch } from 'vue'
    import { uuid } from 'vue3-uuid'
    import BalanceCard from './components/BalanceCard.vue'
    import StaticAndFinanceBlock from './components/StaticAndFinanceBlock.vue'
    import CircleDiagram from './components/CircleDiagram.vue'
    import BarOverlineChart from './components/BarOverlineChart.vue'
    import BarChart from './components/BarChart.vue'
    import CircleDiagramWithGap from './components/CircleDiagramWithGap.vue'
    import TwoLinesBarChart from './components/TwoLinesBarChart.vue'
    import MinLineChart from './components/MinLineChart.vue'
    import MinBarChart from './components/MinBarChart.vue'

    import IncomesBySource from './components/DashboardComponents/IncomesBySource.vue'
    import PercentageIncomesBySource from './components/DashboardComponents/PercentageIncomesBySource.vue'
    import IncomesByGroups from './components/DashboardComponents/IncomesByGroups.vue'
    import TrainerRow from './components/DashboardComponents/TrainerRow.vue'

    import TransparentGridMetrics from './newComponents/TransparentGridMetrics.vue';
    import GreenGridMetrics from "./newComponents/GreenGridMetrics.vue";
    import QuantitativeIndicators from "./newComponents/QuantitativeIndicators.vue";
    import AnalyticsComponent from "./newComponents/Analytics.vue";
    import LineGraph from "./newComponents/LineGraph.vue";
    import PieGraph from "./newComponents/PieGraph.vue";
    import PieAngelGraph from "./newComponents/PieAngelGraph.vue";
    import HorizontalLineChart from "./newComponents/HorizontalLineChart.vue";
    import ExpensiveLineChart from "./newComponents/ExpensiveLineChart.vue";
    import LineBarGraph from "./newComponents/LineBarGraph.vue";
    import TwoLineBarGraph from "./newComponents/TwoLineBarGraph.vue";

    export default {
        name: 'Finance', 

        setup() {
            const wayforpayBalance = ref(0)
            const bankBalance = ref(0)
            const cashboxBalance = ref(0)
            const investBalance = ref(0)
            const trainersStudentsBalances = ref(0)
            const balancesLoading = ref(true)

            const sumOfAllVirtualOutlays = ref(0)
            const allVirtualOutlaysCount = ref(0)
            const meanRealIncomeFromStudent = ref(0)
            const subParamsLoading = ref(true)

            const showCircleWithGap = ref(uuid.v4())
            const screenWidth = ref(window.innerWidth)

            const clubOutlaysStatistics = ref([])
            const clubOutlaysStatisticsYear = ref(new Date().getFullYear())
            const clubOutlaysStatisticsMonth = ref(new Date().getMonth())
            const clubOutlaysChartData = ref([])
            const clubOutlaysDiagramData = ref([])
            const clubOutlaysChartKey = ref(uuid.v4())

            const profitChartYear = ref(new Date().getFullYear())
            const profitChartIncomesData = ref([])
            const profitChartIncomesSum = ref(0)
            const profitChartOutlaysData = ref([])
            const profitChartOutlaysSum = ref(0)
            const profitChartData = ref([])
            const profitChartDataSum = ref(0)
            const profitChartDataLoaded = ref(false)
            const profitChartSumLoaded = ref(false)
            const allTrainers = ref([])
            const allGroups = ref([])

            const showDetailCashless = ref(false)

            //outlays circle chart
            const outlaysCircleChartHeight = ref(330)
            const outlaysCircleChartWidth = ref(300)

            const resizeOutlaysCircleChart = () => {
                if (screenWidth.value < 1410) {
                    outlaysCircleChartHeight.value = 230
                    outlaysCircleChartWidth.value = 200
                } else {
                    outlaysCircleChartHeight.value = 330
                    outlaysCircleChartWidth.value = 300
                }
                showCircleWithGap.value = uuid.v4()
            }

            watch(() => window.innerWidth, () => {
                screenWidth.value = window.innerWidth
            })

            watch(screenWidth, () => {
                resizeOutlaysCircleChart()
            })

            //outlays block
            const outlaysBarChartHeight = ref(127)
            const outlaysBarChartWidth = ref(790)

            const resizeOutlaysBlock = () => {
                if (screenWidth.value < 1410) {
                    outlaysBarChartHeight.value = 300
                    outlaysBarChartWidth.value = 600
                } else {
                    outlaysBarChartHeight.value = 367
                    outlaysBarChartWidth.value = 790
                }
            }

            watch(screenWidth, () => {
                resizeOutlaysBlock()
            })

            //two lines chart
            const twoLinesChartHeight = ref(400)
            const twoLinesChartWidth = ref(790)
            const twoLinesChartLoading = ref(uuid.v4())
            const outlaysCircleDiagramDate = ref('7.2023')

            const resizeTwoLinesChart = () => {
                if (screenWidth.value < 1410) {
                    twoLinesChartHeight.value = 300
                    twoLinesChartWidth.value = 630
                } else {
                    twoLinesChartHeight.value = 400
                    twoLinesChartWidth.value = 790
                }
                twoLinesChartLoading.value = uuid.v4()
            }

            watch(screenWidth, () => {
                resizeTwoLinesChart()
            })
 

            const allYearsClubOutlaysStatistics = ref([])
            const clubOutlaysChartDataLoaded = ref(false)

            const updateClubOutlaysDiagram = () => {
                let segments = []
                const currentOutlayStatisticMonth = allYearsClubOutlaysStatistics.value.find(outlay => outlay.date_format == outlaysCircleDiagramDate.value)
                console.log('currentOutlayStatisticMonth ------ ', currentOutlayStatisticMonth)

                if (currentOutlayStatisticMonth) {
                    currentOutlayStatisticMonth.categories.forEach(category => {
                        segments.push({
                            label: category.category_name,
                            value: category.outlays_sum
                        })
                    })
                    clubOutlaysDiagramData.value = segments
                } else {
                    clubOutlaysDiagramData.value = []
                }
                showCircleWithGap.value = uuid.v4()
            }

            const updateOutlaysChart = async () => {
                const clubOutlaysStatisticsRes = await getStatisticsClubOutlaysByYear(clubOutlaysStatisticsYear.value)
                allYearsClubOutlaysStatistics.value = clubOutlaysStatisticsRes 

                for (let i = 1; i <= 12; i++) {
                    let currentOutlay = allYearsClubOutlaysStatistics.value.find(outlay => outlay.date_format.split('.')[0] === `${i}`)
                    if (currentOutlay) {
                        clubOutlaysChartData.value.push(currentOutlay.total_outlays_sum) 
                    } else {
                        clubOutlaysChartData.value.push(0)
                    }
                }

                updateClubOutlaysDiagram()

                clubOutlaysChartDataLoaded.value = true
                clubOutlaysChartKey.value = uuid.v4()
                showCircleWithGap.value = uuid.v4()
            }

            watch(outlaysCircleDiagramDate, () => {
                updateClubOutlaysDiagram()
            })

            const updateProfitChart = async () => {
                const clubOutlaysStatisticsRes = await getStatisticsClubOutlaysByYear(profitChartYear.value)
                const yearIncomesStatistics = await getYearIncomesBySources(profitChartYear.value)

                
                for (let i = 1; i <= 12; i++) {
                    const currentMonthIncome = yearIncomesStatistics.find(income => income.date_formatted.split('.')[0] == i)
                    if (currentMonthIncome) {
                        profitChartIncomesData.value.push(currentMonthIncome.all_incomes)    
                    } else {
                        profitChartIncomesData.value.push(0)
                    }

                    const currentMonthOutlay = clubOutlaysStatisticsRes.find(outlay => outlay.date_format.split('.')[0] == i)
                    if (currentMonthOutlay) {
                        profitChartOutlaysData.value.push(currentMonthOutlay.total_outlays_sum)    
                    } else {
                        profitChartOutlaysData.value.push(0)
                    }

                    profitChartData.value.push(profitChartIncomesData.value[profitChartIncomesData.value.length - 1] - profitChartOutlaysData.value[profitChartOutlaysData.value.length - 1])
                }

                profitChartDataLoaded.value = true

                profitChartIncomesSum.value = profitChartIncomesData.value.reduce((sum, current) => sum + current, 0)
                profitChartOutlaysSum.value = profitChartOutlaysData.value.reduce((sum, current) => sum + current, 0)
                profitChartDataSum.value = profitChartData.value.reduce((sum, current) => sum + current, 0)
                profitChartSumLoaded.value = true
            }

            const trainersDataLoaded = ref(false)
            const getAllTrainersAndGroups = async () => {
                const allTrainersRes = await getAllTrainers()
                allTrainers.value = allTrainersRes.data
                const allGroupsRes = await getAllGroups()
                allGroups.value = allGroupsRes.data
            }

            const studentsInGroups = ref([])
            const allStudentsCount = ref(0)
            const studentsInGroupsLoaded = ref(false)
            const countStudentsInGroups = async () => {
                let studentsCount = 0

                for (let i = 0; i < allGroups.value.length; i++) {
                    const res = await getStudentsCountByGroupId(allGroups.value[i].group_id)
                    studentsInGroups.value.push({
                        group_name: allGroups.value[i].name,
                        students_count: res.data
                    })
                    
                    studentsCount += res.data
                }

                allStudentsCount.value = studentsCount
                studentsInGroupsLoaded.value = true
            }

            const trainersGroupsStats = ref([])
            const trainersGroupsStatistics = async () => {
                let trainersGroupsStatistics = []
                const incomesRes = await getAllIncomesByGroupInYear(new Date().getFullYear())
                const incomes = incomesRes.data
                console.log('incomes ------------- ', incomes)
                for (let i = 0; i < allTrainers.value.length; i++) {
                    let groupsCount = 0
                    let studentsGroupsCount = 0
                    let trainersGroupIds = []
                    for (let j = 0; j < allGroups.value.length; j++) {
                        if (allGroups.value[j].trainer_id === allTrainers.value[i].trainer_id) {
                            trainersGroupIds.push(allGroups.value[j].group_id)
                            groupsCount++
                            studentsGroupsCount += studentsInGroups.value.find(studentsInGroup => studentsInGroup.group_name === allGroups.value[j].name).students_count
                        }
                    }
                 
                    const completedTrainingsRes = await getTrainerCompletedTrainingsByYear(new Date().getFullYear(), allTrainers.value[i].trainer_id)
                    console.log('completedTrainingsRes ------------- ', completedTrainingsRes)

                    const trainerGroupsIncomes = []

                    for (let i = 1; i <= 12; i++) {
                        const currentMonthIncome = incomes.find(income => income.date_formatted.split('.')[0].includes(`${i}`))
                        let allCurrentMonthIncomes = 0

                        if (currentMonthIncome) {
                            currentMonthIncome.groups.forEach(group => {
                                if (trainersGroupIds.includes(group.group_id)) {
                                    allCurrentMonthIncomes += group.all_incomes
                                }
                            })
                        }
                        
                        trainerGroupsIncomes.push(allCurrentMonthIncomes)
                    }

                    trainersGroupsStatistics.push({
                        photo: allTrainers.value[i].photo,
                        trainer_name: allTrainers.value[i].surname + ' ' + allTrainers.value[i].name[0] + '.',
                        groups_count: groupsCount,
                        students_count: studentsGroupsCount,
                        completed_trainings_count: completedTrainingsRes.data,
                        trainer_groups_incomes: trainerGroupsIncomes
                    })
                }

                trainersDataLoaded.value = true
                return trainersGroupsStatistics
            }

            const formatNumber = (number) => {
                const parts = number.toFixed(2).toString().split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];

                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

                const formattedNumber = formattedInteger + '.' + decimalPart;

                return formattedNumber;
            }

            onMounted(async () => {
                const wayforpayBalanceRes = await getServiceBalanceByLabel('Wayforpay')
                wayforpayBalance.value = wayforpayBalanceRes.state
                const bankBalanceRes = await getServiceBalanceByLabel('Bank')
                bankBalance.value = bankBalanceRes.state
                const cashboxBalanceRes = await getAllCashFromAdmins()
                cashboxBalance.value = cashboxBalanceRes.balance_state
                const investBalanceRes = await getAllInvestFromAdmins()
                investBalance.value = investBalanceRes.balance_state
                const allTrainerStudentsBalanceRes = await getAllTrainerStudentBalanceState('AllTrainerStudents')
                trainersStudentsBalances.value = allTrainerStudentsBalanceRes.balance_state
                balancesLoading.value = false

                const sumOfAllVirtualOutlaysRes = await getSumOfAllVirtualOutlays()
                sumOfAllVirtualOutlays.value = sumOfAllVirtualOutlaysRes.sum
                const allVirtualOutlaysCountRes = await getAllVirtualOutlaysCount()
                allVirtualOutlaysCount.value = allVirtualOutlaysCountRes.count
                const meanRealIncomeFromStudentRes = await getMeanRealIncomeFromStudent()
                meanRealIncomeFromStudent.value = meanRealIncomeFromStudentRes.mean
                subParamsLoading.value = false

                await updateOutlaysChart()
                await updateProfitChart()
                await getAllTrainersAndGroups()
                await countStudentsInGroups()

                trainersGroupsStats.value = await trainersGroupsStatistics()

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                resizeOutlaysBlock()
                resizeOutlaysCircleChart()
                resizeTwoLinesChart()
            })

            //presets

            const yourMetricsArrayTransparent = ref([
                { name: 'всі джерела', number: "60 000,00", color: '#DA4167'},
                { name: 'готівка', number: "16 000,00", color: '#339989'},
                { name: 'безготівка', number: "16 000,00", color: '#8FC408'},
                { name: 'інвестиції', number: "16 000,00", color: '#BD9135'},
            ])
            const yourMetricsArrayGreen = ref([
                { name: 'середній платіж', number: "60 000,00",isShowUAH: true },
                { name: 'готівка у тренерів', number: "16 000,00",isShowUAH: true },
                { name: 'сума списань', number: "16 000",isShowUAH: false },
                { name: 'к-сть списань', number: "16 000",isShowUAH: false },
            ])
            const yourQuantitativeIndicators = ref({
                small: [
                {
                    name:"тренерів",
                    value: 4
                },
                {
                    name:"тренерів",
                    value: 5
                },
                {
                    name:"людей",
                    value: 2
                },
                ],
                big: [
                {
                    name:"2007",
                    value: 4
                },
                {
                    name:"2009",
                    value: 5
                },
                {
                    name:"2010",
                    value: 2
                },
                {
                    name:"2015",
                    value: 234
                },
                {
                    name:"2016",
                    value: 4
                },
                {
                    name:"20019",
                    value: 5
                },
                {
                    name:"2020",
                    value: 2
                },
                {
                    name:"2023",
                    value: 234
                },

                ]
            })
            const yourAnalytics = ref({
            title: "АНАЛІТИКА ПО ТРЕНЕРАХ",
            data: [
                {
                year: "2020",
                isActive: false,
                yearData: [
                    {
                    coachsName: "Іванов В",
                    numberOfGroups: 10,
                    numberOfPlayers: 50,
                    trainingsPerYear: [1,22,3,41,5,6,7,8,9],
                    totalPerYear: [1,2,23,4,5,6,7,8,9],
                    avatarLink: "person-five-chart-two.png"
                    }
                ]
                },
                {
                year: "2021",
                isActive: true,
                yearData: [
                    {
                    coachsName: "Іванов В",
                    numberOfGroups: 13,
                    numberOfPlayers: 40,
                    trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                    totalPerYear: [1,2,3,4,5,6,7,8,9],
                    avatarLink: "person-five.png"
                    },
                    {
                    coachsName: "Іванов В",
                    numberOfGroups: 13,
                    numberOfPlayers: 40,
                    trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                    totalPerYear: [1,2,3,4,5,6,7,8,9],
                    avatarLink: "person-five.png"
                    },
                    {
                    coachsName: "Іванов В",
                    numberOfGroups: 13,
                    numberOfPlayers: 40,
                    trainingsPerYear: [1,2,3,4,5,6,7,8,9],
                    totalPerYear: [1,2,3,4,5,6,7,8,9],
                    avatarLink: "person-five.png"
                    }
                ]
                },

            ]
            })
            const lineGraphText = ref({
                title: "ДОХІД ПО ДЖЕРЕЛАХ",
                labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov","Dec"],
            })
            const lineGraph = ref([
            {
                year: "2020",
                isActive: false,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    lineArray: [1,2,3,4,5,6,7,8,9,10,11,12]
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    lineArray: [11,21,31,41,51,61,71,81,91,101,111,121]
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    lineArray: [11,12,13,14,15,16,17,18,19,110,111,112]
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    lineArray: [21,22,23,24,25,26,27,28,29,210,211,212]
                }
                ],

            },
            {
                year: "2021",
                isActive: true,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    lineArray: [1,2,3,4,5,6,7,8,9,10,11,12]
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    lineArray: [11,21,31,41,51,61,71,81,91,101,111,121]
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    lineArray: [11,12,13,114,115,116,17,18,19,110,111,112]
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    lineArray: [121,122,123,24,25,26,27,28,29,210,211,212]
                }
                ],

            },
            {
                year: "2022",
                isActive: false,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    lineArray: [11,2,3,224,5,6,7,8,9,10,11,12]
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    lineArray: [11,21,61,41,51,61,71,81,91,101,111,121]
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    lineArray: [11,42,13,114,115,116,17,18,19,110,111,112]
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    lineArray: [121,122,123,24,25,26,27,28,29,210,211,212]
                }
                ],

            }


            ])
            const pieGraph = ref([
            {
                year: "березень 2020",
                isActive: false,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    value: 3
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    value: 3
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    value: 3
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    value: 3
                }
                ],
            },
            {
                year: "Квітень 2020",
                isActive: true,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    value: 3
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    value: 3
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    value: 3
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    value: 3
                }
                ],

            },
            {
                year: "травень 2020",
                isActive: false,
                dataSet: [
                {
                    color: "#DA4167",
                    title: "Заг. дохід",
                    value: 3
                },
                {
                    color: "#339989",
                    title: "Безготівка",
                    value: 3
                },
                {
                    color: "#8FC408",
                    title: "Готівка",
                    value: 3
                },
                {
                    color: "#BD9135",
                    title: "Інвестиції",
                    value: 3
                }
                ],

            }


            ])
            const horizontalLineData= ref([
            {
                year: "березень 2022",
                isActive: false,
                color: "#BD9135",
                dataSet: [
                {
                    title: "U-2009",
                    value: 1
                },
                {
                    title: "U-2010",
                    value: 2
                },
                {
                    title: "U-2011",
                    value: 3
                },
                {
                    title: "U-2012",
                    value: 4
                },
                {
                    title: "U-2013",
                    value: 3
                },
                {
                    title: "U-2014",
                    value: 1
                },
                {
                    title: "U-2015",
                    value: 8
                },
                {
                    title: "U-2016",
                    value: 7
                },
                {
                    title: "U-2017",
                    value: 12
                },

                ]
            },
            {
                year: "березень 2021",
                isActive: true,
                color: "#BD9135",
                dataSet: [
                {
                    title: "U-2009",
                    value: 1
                },
                {
                    title: "U-2010",
                    value: 2
                },
                {
                    title: "U-2011",
                    value: 3
                },
                {
                    title: "U-2012",
                    value: 43
                },
                {
                    title: "U-2013",
                    value: 3
                },
                {
                    title: "U-2014",
                    value: 1
                },
                {
                    title: "U-2015",
                    value: 8
                },
                {
                    title: "U-2016",
                    value: 7
                },
                {
                    title: "U-2017",
                    value: 16
                },

                ]
            }

            ])
            const barGraphText = ref({
            title: "Витрати",
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov","Dec"],
            })
            const barGraph=ref([
            {
                year: "2020",
                isActive: true,
                color:"#DA4167",
                dataSet: [1,2,3,4,5,6,7,8,9,10,11,12]
            },
            {
                year: "2021",
                isActive: false,
                color:"#DA4167",
                dataSet: [11,12,3,4,5,6,7,8,9,10,11,12]
            },
            {
                year: "2022",
                isActive: false,
                color:"#DA4167",
                dataSet: [1,2,3,4,5,61,7,81,9,10,11,12]
            }
            ])
            const lineBarGraph = ref({
                title: "ДОХОДИ ПО ГРУПАХ",
                labels:["U-2001","U-2002","U-2003","U-2004","U-2005","U-2006","U-2007","U-2008","U-2009"],
                data:[
                {
                    title:"Готівка",
                    value: [3,2,3,5,6,7,8,9,9],
                    color: "#8FC408",
                },
                {
                    title:"Безготівка",
                    value: [2,12,4,5,6,7,8,9,9],
                    color: "#339989",
                },
                {
                    title:"Заг дохід",
                    value: 0, //залишати для лініЇ значення 0 щоб воно автоматично рахувало суму
                    color: "#BD9135",
                },
                ]
            },
            )
            const yearMoneyAccounting= ref([
            {
                year: "2021",
                isActive: false,
                dataSet: [
                {
                    title: "Витрати",
                    data: [-10, -100, -10, -20, -30, -20, -50],
                    color: "#F61067"
                },
                {
                    title: "Дохід",
                    data: [130, 300, 160, 90, 90, 120, 150],
                    color: "#21A179"
                },
                {
                    title: "Сальдо",
                    data: 0,//має бути завжди 0 щоб автоматично рахувало
                    color: "#C6F100"
                },
                ]
            },
            {
                year: "2022",
                isActive: true,
                dataSet: [
                {
                    title: "Витрати",
                    data: [-12, -103, -14, -20, -30, -20, -50],
                    color: "#F61067"
                },
                {
                    title: "Дохід",
                    data: [230, 500, 160, 100, 90, 120, 150],
                    color: "#21A179"
                },
                {
                    title: "Сальдо",
                    data: 0,//має бути завжди 0 щоб автоматично рахувало
                    color: "#C6F100"
                },
                ]
            },
            {
                year: "2023",
                isActive: false,
                dataSet: [
                {
                    title: "Витрати",
                    data: [-10, -100, -10, -20, -30, -20, -50],
                    color: "#F61067"
                },
                {
                    title: "Дохід",
                    data: [130, 300, 160, 90, 90, 120, 150],
                    color: "#21A179"
                },
                {
                    title: "Сальдо",
                    data: 0,//має бути завжди 0 щоб автоматично рахувало
                    color: "#C6F100"
                },
                ]
            },

            ])

            const yearMoneyAccountingText = ref({
            title: "Витрати",
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov","Dec"],
            })

            return {
                wayforpayBalance,
                bankBalance,
                cashboxBalance,
                investBalance,
                trainersStudentsBalances,
                balancesLoading,
                sumOfAllVirtualOutlays,
                allVirtualOutlaysCount,
                meanRealIncomeFromStudent,
                subParamsLoading,
                screenWidth,
                outlaysBarChartHeight,
                outlaysBarChartWidth,
                outlaysCircleChartHeight,
                outlaysCircleChartWidth,
                showCircleWithGap,
                twoLinesChartHeight,
                twoLinesChartWidth,
                twoLinesChartLoading,
                clubOutlaysChartData,
                clubOutlaysChartKey,
                clubOutlaysDiagramData,

                profitChartOutlaysData,
                profitChartIncomesData,
                profitChartData,

                profitChartOutlaysSum,
                profitChartIncomesSum,
                profitChartDataSum,

                allTrainers,
                allGroups,
                allStudentsCount,
                studentsInGroups,
                trainersGroupsStats,

                clubOutlaysChartDataLoaded,
                profitChartDataLoaded,
                profitChartSumLoaded,
                studentsInGroupsLoaded,
                trainersDataLoaded,
                outlaysCircleDiagramDate,
                formatNumber,
                showDetailCashless,

                yourMetricsArrayTransparent,
                yourMetricsArrayGreen,
                yourQuantitativeIndicators,
                yourAnalytics,
                lineGraph,
                lineGraphText,
                pieGraph,
                horizontalLineData,
                barGraphText,
                barGraph,
                lineBarGraph,
                yearMoneyAccounting,
                yearMoneyAccountingText,
            }
        },

        components: {
            BalanceCard,
            StaticAndFinanceBlock,
            CircleDiagram,
            BarOverlineChart,
            BarChart,
            CircleDiagramWithGap,
            TwoLinesBarChart,
            MinLineChart,
            MinBarChart,
            IncomesBySource,
            PercentageIncomesBySource,
            IncomesByGroups,
            TrainerRow,

            TransparentGridMetrics,
            GreenGridMetrics,
            QuantitativeIndicators,
            AnalyticsComponent,
            LineGraph,
            PieGraph,
            PieAngelGraph,
            HorizontalLineChart,
            ExpensiveLineChart,
            LineBarGraph,
            TwoLineBarGraph
        }
    }
</script>

<style lang="scss" scoped>

.scrolltab::-webkit-scrollbar {
    width: 3px;
    height: 2px;               /* ширина всей полосы прокрутки */
}

.scrolltab::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrolltab::-webkit-scrollbar-thumb {
    background-color: rgba(171, 211, 237, 0.25);
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}


@media (min-width: 1410px) and (max-width: 8000px) {
    .balances-width {
        @apply w-[1205px];
    }

    .statics-width {
        @apply w-[1205px];
    }

    .incomes-by-sources {
        @apply w-[1110px];
    }

    .incomes-groups-container {
        @apply w-[1110px];
    }

    .all-income-params {
        @apply w-[250px] h-[355px];
    }

    .outlays-width {
        @apply w-[1100px];
    }
}

@media (min-width: 0px) and (max-width: 1410px) {
    .balances-width {
        @apply w-[1205px] ml-[10px];
    }

    .statics-width {
        @apply w-[1205px] ml-[10px];
    }

    .incomes-by-sources {
        @apply w-[900px];
    }

    .incomes-groups-container {
        @apply w-[900px];
    }

    .all-income-params {
        @apply w-[250px] h-[330px];
    }

    .outlays-width {
        @apply w-[880px];
    }
}

</style>
<!-- 
{ 
    "transactions_type" : "virtual_income", 
    "tx_id" : "5ba93d89-3803-4703-8875-6403feed4710", 
    "amount" : 650, 
    "date" : new Date("2023-08-16T00:00:00Z"), 
    "label" : "Поповнення балансу учня Ярослав Попович через Bank", 
    "receiver_balance_id" : "9aaee45b-2d36-40bb-84e8-9b192e7e9ce7", 
    "additional_files" : null 
} -->
