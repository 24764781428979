<template>
  <div class="relative">
    <FileManager 
      v-if="currentFilesSelect" 
      :filesObjects="currentFiles" 
      :marker="selectedFilesMarker" 
      @close="currentFilesSelect = false" 
      @delete="e => deleteFile(e.file, e.marker)"
      @upload="e => uploadFile(e.marker)"    
    />
  </div>
  <div class="documents-tab">
    <div class="documents-tab__column">
      <InputComponent 
        :label="'name'" 
        :placeholder="'name'" 
        :name="'name'" 
        :value="name_eng"
        @update:modelValue="e => $emit('update:name_eng', e)" 
      />
      <InputComponent 
        :label="'surname'" 
        :placeholder="'surname'" 
        :name="'surname'" 
        :value="surname_eng"
        @update:modelValue="e => $emit('update:surname_eng', e)" 
      />
      <InputComponent 
        :label="'прізвище'" 
        :placeholder="'прізвище'" 
        :name="'прізвище'" 
        :value="surname"
        @update:modelValue="e => $emit('update:surname', e)" 
      />
      <InputComponent 
        :label="'ім’я'" 
        :placeholder="'ім’я'" 
        :name="'ім’я'" 
        :value="name"
        @update:modelValue="e => $emit('update:name', e)" 
      />
      <InputComponent 
        :label="'по батькові'" 
        :placeholder="'по батькові'" 
        :name="'по батькові'" 
        :value="patronymic"
        @update:modelValue="e => $emit('update:patronymic', e)" 
      />
      <InputComponent 
        :label="'національність'" 
        :placeholder="'національність'" 
        :name="'національність'" 
        :value="nationality"
        @update:modelValue="e => $emit('update:nationality', e)" 
      />
      <InputComponent 
        :label="'профіль соцмережі #1'" 
        :placeholder="'профіль соцмережі #1'" 
        :name="'профіль соцмережі #1'" 
        :value="social_network1"
        @update:modelValue="e => $emit('update:social_network1', e)" 
      />
      <InputComponent 
        :label="'профіль соцмережі #2'" 
        :placeholder="'профіль соцмережі #2'" 
        :name="'профіль соцмережі #2'" 
        :value="social_network2"
        @update:modelValue="e => $emit('update:social_network2', e)" 
      />
      <InputComponent 
        :label="'дата народження'" 
        :placeholder="'дата народження'" 
        :name="'дата народження'" 
        :type="'date'"
        :value="birthday"
        @update:modelValue="e => $emit('update:birthday', e)" 
      />
      <InputUpload 
        :label="'ID код'" 
        :placeholder="'ID код'" 
        :name="'ID код'" 
        :value="idCode"
        @upload="uploadIdPhoto"
        @view="selectFiles(idPhotos, 'IdPhotos')"
        @update:modelValue="e => $emit('update:idCode', e)" 
      />
      <input 
        @change="$emit('addIdPhoto', $event.target.files[0])"
        ref="idPhotosInput" 
        type="file" 
        class="hidden"
      >
    </div>
    <div class="documents-tab__column">
      <InputUpload 
        :label="'паспорт закордонний'" 
        :placehodler="'паспорт закордонний'" 
        :name="'паспорт закордонний'" 
        :value="abroadPassport"
        @upload="uploadAbroadPassportFile"
        @view="selectFiles(abroadPassportFiles, 'AbroadPassportFiles')"
        @update:modelValue="e => $emit('update:abroadPassport', e)" 
      />
      <input 
        @change="$emit('addAbroadPassportFile', $event.target.files[0])"
        ref="abroadPassportFilesInput" 
        type="file" 
        class="hidden"
      >
      <InputComponent 
        :label="'дата закінчення закордонного паспорту'" 
        :placeholder="'дата закінчення закордонного паспорту'" 
        :name="'дата закінчення закордонного паспорту'" 
        type="date"
        :value="abroadPassportEndDate"
        @update:modelValue="e => $emit('update:abroadPassportEndDate', e)" 
      />
      <InputUpload 
        :label="'паспорт UKR'" 
        :placehodler="'паспорт UKR'" 
        :name="'паспорт UKR'" 
        :value="passport"
        @upload="uploadPassportPhoto"
        @view="selectFiles(passportPhotos, 'PassportPhotos')"
        @update:modelValue="e => $emit('update:passport', e)" 
      />
      <input 
        @change="$emit('addPassportPhoto', $event.target.files[0])"
        ref="passportFilesInput" 
        type="file" 
        class="hidden"
      >
      <InputComponent 
        :label="'ким виданий'" 
        :placeholder="'ким виданий'" 
        :name="'ким виданий'" 
        :value="passportOffice"
        @update:modelValue="e => $emit('update:passportOffice', e)" 
      />
      <InputComponent 
        :label="'дата видачі паспорту'" 
        :placeholder="'дата видачі паспорту'" 
        :name="'дата видачі паспорту'"
        :type="'date'" 
        :value="passportIssueDate"
        @update:modelValue="e => $emit('update:passportIssueDate', e)" 
      />
      <InputComponent 
        :label="'тел.'" 
        :placeholder="'тел.'" 
        :name="'тел.'" 
        :value="phone"
        @update:modelValue="e => $emit('update:phone', e)" 
      />
      <InputComponent 
        :label="'ел. адреса'" 
        :placeholder="'ел. адреса'" 
        :name="'ел. адреса'" 
        :value="email"
        @update:modelValue="e => $emit('update:email', e)" 
      />
      <InputComponent 
        :label="'адреса проживання'" 
        :placeholder="'адреса проживання'" 
        :name="'адреса проживання'" 
        :value="address"
        @update:modelValue="e => $emit('update:address', e)" 
      />
      <InputComponent 
        :label="'довірена особа'" 
        :placeholder="'довірена особа'" 
        :name="'довірена особа'" 
        :value="patronName"
        @update:modelValue="e => $emit('update:patronName', e)" 
      />
      <InputComponent 
        :label="'тел. довіреної особи'" 
        :placeholder="'тел. довіреної особи'" 
        :name="'тел. довіреної особи'" 
        :value="patronPhone"
        @update:modelValue="e => $emit('update:patronPhone', e)" 
      />
    </div>
    <div class="documents-tab__column">
      <InputComponent 
        :label="'старт в клубі'" 
        :placeholder="'старт в клубі'" 
        :name="'старт в клубі'"
        :type="'date'" 
        :value="startInClub"
        @update:modelValue="e => $emit('update:startInClub', e)" 
      />
      <InputComponent 
        :label="'вид угоди'" 
        :placeholder="'вид угоди'" 
        :name="'вид угоди'"
        :value="offerType"
        @update:modelValue="e => $emit('update:offerType', e)" 
      />
      <InputUpload 
        :label="'№ контракту/договору'" 
        :placeholder="'№ контракту/договору'" 
        :name="'№ контракту/договору'"
        :value="contractNumber"
        @upload="uploadContractFile"
        @view="selectFiles(contractFiles, 'ContractFiles')"
        @update:modelValue="e => $emit('update:contractNumber', e)" 
      />
      <input 
        @change="$emit('addContractFile', $event.target.files[0])"
        ref="contractFilesInput" 
        type="file" 
        class="hidden"
      >
      <InputComponent 
        :label="'контракт діє до'" 
        :placeholder="'контракт діє до'" 
        :name="'контракт діє до'"
        :type="'date'"
        :value="contractExpireDate"
        @update:modelValue="e => $emit('update:contractExpireDate', e)" 
      />
      <InputUpload 
        :label="'довіреність'" 
        :placeholder="'довіреність'" 
        :name="'довіреність'"
        :value="attorney"
        @upload="uploadAttorney"
        @view="selectFiles(attorneyPhotos, 'AttorneyPhotos')"
        @update:modelValue="e => $emit('update:attorney', e)" 
      />
      <input 
        @change="$emit('addAttorneyPhoto', $event.target.files[0])"
        ref="attorneyInput" 
        type="file" 
        class="hidden"
      >
      <InputComponent 
        :label="'представник/менеджер/агент'" 
        :placeholder="'представник/менеджер/агент'" 
        :name="'представник/менеджер/агент'"
        :value="representative"
        @update:modelValue="e => $emit('update:representative', e)" 
      />
      <InputComponent 
        :label="'агенція'" 
        :placeholder="'агенція'" 
        :name="'агенція'"
        :value="agency"
        @update:modelValue="e => $emit('update:agency', e)" 
      />
    </div>
    <div class="documents-tab__report-block">
      <span class="documents-tab__report-label"
        >звантажити звіт гравця
        <span class="documents-tab__report-label--small"
          >(всі параметри *.pdf)</span
        ></span
      >
      <div class="documents-tab__report-button-wrapper">
        <ButtonComponent :outlined="true">Звантажити</ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
import FileManager from '../widgets/FileManager.vue'
import { ref } from "vue";

export default {
  name: "PlayerDocumentsTab",
  props: {
    name: {
      type: String,
      required: true
    },
    surname: {
      type: String,
      required: true
    },
    patronymic: {
      type: String,
      required: true
    },
    name_eng: {
      type: String,
      required: true
    },
    surname_eng: {
      type: String,
      required: true
    },
    nationality: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    social_network1: {
      type: String,
      required: true
    },
    social_network2: {
      type: String,
      required: true
    },
    patronName: {
      type: String,
      required: true
    },
    patronPhone: {
      type: String,
      required: true
    },
    birthday: {
      type: String,
      required: true
    },
    abroadPassport: {
      type: String,
      required: true
    },
    abroadPassportEndDate: {
      type: String,
      required: true
    },
    passport: {
      type: String,
      required: true
    },
    passportOffice: {
      type: String,
      required: true
    },
    passportIssueDate: {
      type: String,
      required: true
    },
    idCode: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    startInClub: {
      type: String,
      required: true
    },
    offerType: {
      type: String,
      required: true
    },
    contractNumber: {
      type: String,
      required: true
    },
    contractExpireDate: {
      type: String,
      required: true
    },
    attorney: {
      type: String,
      required: true
    },
    representative: {
      type: String,
      required: true
    },
    agency: {
      type: String,
      required: true
    },
    passportPhotos: {
      type: Array,
      required: true
    },
    idPhotos: {
      type: Array,
      required: true
    },
    attorneyPhotos: {
      type: Array,
      required: true
    },
    contractFiles: {
      type: Array,
      required: true
    },
    abroadPassportFiles: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const passportFilesInput = ref(null)
    const idPhotosInput = ref(null)
    const attorneyInput = ref(null)
    const contractFilesInput = ref(null)
    const abroadPassportFilesInput = ref(null)

    const currentFilesSelect = ref(false)
    const currentFiles = ref([])
    const selectedFilesMarker = ref('')

    const selectFiles = (files, marker) => {
      currentFilesSelect.value = true
      currentFiles.value = files
      selectedFilesMarker.value = marker
    }

    const uploadAbroadPassportFile = () => {
      abroadPassportFilesInput.value.click()
    }

    const uploadContractFile = () => {
      contractFilesInput.value.click()
    }

    const uploadAttorney = () => {
      attorneyInput.value.click()
    }

    const uploadIdPhoto = () => {
      idPhotosInput.value.click()
    }

    const uploadPassportPhoto = () => {
      passportFilesInput.value.click()
    }

    const deleteFile = (file, marker) => {
      console.log('delete', file, marker)
      emit(`delete${marker}`, file)
    }

    const uploadFile = (marker) => {
      if (marker === 'PassportPhotos') {
        uploadPassportPhoto()
      }
      if (marker === 'IdPhotos') {
        uploadIdPhoto()
      }
      if (marker === 'AttorneyPhotos') {
        uploadAttorney()
      }
      if (marker === 'ContractFiles') {
        uploadContractFile()
      }
      if (marker === 'AbroadPassportFiles') {
        uploadAbroadPassportFile()
      }
    }

    return {
      passportFilesInput,
      uploadPassportPhoto,
      idPhotosInput,
      uploadIdPhoto,
      attorneyInput,
      uploadAttorney,
      contractFilesInput,
      uploadContractFile,
      abroadPassportFilesInput,
      uploadAbroadPassportFile,
      selectFiles,
      currentFilesSelect,
      currentFiles,
      selectedFilesMarker,
      deleteFile,
      uploadFile
    }
  },

  components: {
    FileManager
  }
};
</script>
