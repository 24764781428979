<template>
  <table
    :class="['table', { 'table--full-width': fullWidth }]"
    :style="[width && { width: `${width}px` }, tablePosition && { margin: tablePosition === 'center' ? 'auto' : '' }]"
  >
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: "TableComponent",
  components: {},
  props: {
    width: {
      type: Number,
      required: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    tablePosition: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>
