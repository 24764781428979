<template>
    <div class="absolute top-0 left-0 w-full h-full" @click="startExitFlag = true"></div>
    <ExitConfirmForm 
        v-if="startExitFlag" 
        :alertText="'Зберегти нового учна з іншого клубу?'" 
    
        class="fixed top-[50%] ml-[110px] w-[260px] left-[50%] translate-x-[-90%] translate-y-[-50%]" 

        @returnToCurrent="startExitFlag = false"
        @doSave="addAlienPlayerToDB"
        @dontSave="$emit('close')"
    />
    <div v-else style="box-shadow: 4px 4px 4px 0px #00000040;" class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[250px] h-[615px] bg-[#252526] rounded-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div>
            <div>
                <img 
                    @click="uploadPhoto"
                    src="@/assets/icons/upload_icon_light.svg" 
                    class="absolute left-[210px] top-[20px] cursor-pointer z-40"
                >
                <input 
                    ref="fileInput"
                    type="file" 
                    class="hidden"
                    :value="inputValue"
                    @change="loadImage"
                />
                <ImageCropper 
                    v-if="showImageCropper" 
                    :image="imageToCrop" 
                    @processedImage="processImage"
                    class="absolute left-0"
                />
            </div>
            <div class="flex justify-center h-[162px] w-full">
                <img :src="photo" @error="photo = require('@/assets/student_photo_placeholder.svg')" class="relative top-[10px] student-logo">
            </div>
        </div>

        <div class="bg-[#BD9135] w-full h-[74px] relative top-[10px]">
            <div class="text-[30px] font-semibold text-center">
                <div class="h-[45px]" v-if="name?.length > 0 || surname?.length > 0">
                    <marquee v-if="name?.length + surname?.length + 1 > 13" behavior="scroll" loop="-1" direction="left">
                        <span v-for="id in 100" class="mx-3 text-[30px]">{{ name + ' ' + surname }}</span>
                    </marquee>
                    <span v-else class="text-[30px]">{{ name + ' ' + surname }}</span>
                </div>
                <span v-else class="opacity-25 text-[30px]">Лєо Мессі</span>
            </div>
            <div class="flex relative justify-between top-[-5px] mx-[8px]">
                <div class="flex flex-col items-center justify-center">
                    <div class="text-[9px]">група</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="team?.length > 0">{{ team }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">вік</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="age > 0">{{ age }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">амплуа</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="amplua?.length > 0">{{ amplua }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex flex-col items-center justify-center  font-normal">
                    <div class="text-[9px]">номер</div>
                    <div class="text-[12px] font-semibold">
                        <span v-if="studentNumber?.length > 0">{{ studentNumber }}</span>
                        <span v-else class="opacity-25">—</span>
                    </div>    
                </div>
                <div class="flex  items-center justify-center font-normal">
                    <img :src="newLeagueLogo" class="h-[30px]">
                    <div class="text-[#FFF] mx-1 mb-[2px]">|</div>
                    <img :src="linqToFlag" class="w-[20px] ml-[5px]">
                </div>
            </div>
        </div>

        <div class="mx-[14px] relative  top-[27px]">
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="surname" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Прізвище" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    прізвище
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="name" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Ім'я" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    ім'я
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[50%]">
                    <input v-model="patronymic" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="По батькові" type="text">
                </div>
                <div class="font-normal w-[50%] text-end text-[#BD9135] opacity-90 text-[11px]">
                    по батькові
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="birthDate" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="12.12.2012" type="date">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    дата народження
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div class="w-[30%]">
                    <input v-model="team" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="команда" type="text">
                </div>
                <div>
                    <img v-if="newLeagueLogo" @click="addLogoToLeague" :src="newLeagueLogo" alt="team logo" class="h-[20px] cursor-pointer" />
                    <img v-else @click="addLogoToLeague" src="@/assets/icons/upload_icon.svg" alt="team logo" class="h-[20px] cursor-pointer" />
                    <input ref="newLeagueLogoAdd" type="file" @change="proccessNewLeagueLogo" class="hidden" />
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    команда
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="studentNumber" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="номер" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    номер
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <select v-model="amplua" class="text-[#FFF] font-semibold text-[13px] ml-[-4px] outline-none bg-transparent border-none w-[100%] truncate placeholder:opacity-60" type="text" placeholder="Іван">
                        <option class="bg-[#252526]" disabled value="">Виберіть амплуа</option>
                        <option v-for="amp in positions" class="bg-[#252526]" :value="amp">{{ amp }}</option>
                    </select>
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    амплуа
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="height" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="зріст" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    зріст
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="leadingLeg" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="нога" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    нога
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input v-model="agent" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="агент" type="text">
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    агент
                </div>
            </div>
            <div class="flex justify-between items-center align-middle mb-[10px]">
                <div>
                    <input @focusin="nationalityInputActive = true" v-model="currentNationality" class="text-[#FFF] font-semibold text-[13px] outline-none bg-transparent border-none w-[90%] truncate placeholder:opacity-60" placeholder="Українець" type="text">
                    <div v-if="nationalityInputActive" :key="flagsKey" class="h-[100px] overflow-y-auto bg-[#000] scrolltab absolute w-[235px] bottom-[25px] left-[-4px]">
                        <div v-for="flag in showFlags" @click="changeFlag(flag)" class="flex py-[3px] justify-between w-full text-[13px] hover:bg-[#333] cursor-pointer bg-[#000] px-[4px]">
                            <div class="text-[#FFF]">{{ flag }}</div>
                            <div><img :src="require(`@/assets/flags/${flag}.svg`)" class="w-[25px] ml-[5px]"></div>
                        </div>
                    </div>
                </div>
                <div class="font-normal text-[#BD9135] opacity-90 text-[11px]">
                    національність
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted, watch, computed } from 'vue'
    import { uuid } from 'vue3-uuid'

    import { addAlienPlayer } from '@/services/apiRequests'

    import ExitConfirmForm from './ExitConfirmForm.vue'
    import ImageCropper from '../ui/ImageCropper.vue'
    
    function formateDate(date) {
        const dateObj = new Date(date)
        const year = dateObj.getFullYear()
        const month = dateObj.getMonth() + 1 > 9 ? dateObj.getMonth() + 1 : `0${dateObj.getMonth() + 1}`
        const day = dateObj.getDate() > 9 ? dateObj.getDate() : `0${dateObj.getDate()}`
        return `${year}-${month}-${day}`
    }

    function getMimeType(file, fallback = null) {
        const byteArray = (new Uint8Array(file)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < byteArray.length; i++) {
            header += byteArray[i].toString(16);
        }
        switch (header) {
            case "89504e47":
                return "image/png";
            case "47494638":
                return "image/gif";
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
                return "image/jpeg";
            default:
                return fallback;
        }
    }

    export default {
        name: 'new-info-card',

        setup(props, { emit }) {
            const fileInput = ref(null)
            const inputValue = ref(null)
            const photo = ref(require('@/assets/student_photo_placeholder.svg'))

            const name = ref('')
            const surname = ref('')
            const patronymic = ref('')
            const birthDate = ref('')
            const phoneNumber = ref('')
            const email = ref('')
            const nationality = ref('')
            const team = ref('')
            const studentAmplua = ref('')
            const studentNumber = ref('')
            const age = ref(0)
            const amplua = ref('') 
            const height = ref('')
            const leadingLeg = ref('')
            const agent = ref('')

            const startExitFlag = ref(false)

            const positions = ref(['ВР', 'ЦЗ', 'ЛЗ', 'ПЗ', 'ЦОП', 'ЦП', 'ЦАП', 'ПП', 'ЛП', 'ПВ', 'ЛВ', 'НП'])

            const flagsArray = ref(['AD', 'BH', 'CO', 'FM', 'ID', 'KY', 'MN', 'PE', 'SH', 'TR', 'AE', 'BI', 'CR', 'FR', 'IE', 'KZ', 'MO', 'PF', 'SI', 'TT', 'AF', 'BJ', 'CU', 'GA', 'IL', 'LA', 'MR', 'PG', 'SK', 'TW', 'AG', 'BM', 'CV', 'GB', 'IM', 'LB', 'MS', 'PH', 'SL', 'TZ', 'AI', 'BN', 'CY', 'GD', 'IN', 'LC', 'MT', 'PK', 'SM', 'UA', 'AL', 'BO', 'CZ', 'GE', 'IQ', 'LI', 'MU', 'PL', 'SN', 'UG', 'AM', 'BR', 'DE', 'GG', 'IR', 'LK', 'MV', 'PR', 'SO', 'US', 'AN', 'BS', 'DJ', 'GH', 'IS', 'LR', 'MW', 'PT', 'SR', 'UY', 'AO', 'BT', 'DK', 'GI', 'IT', 'LS', 'MX', 'PW', 'ST', 'UZ', 'AR', 'BW', 'DM', 'GM', 'JE', 'LT', 'MY', 'PY', 'SV', 'VC', 'AT', 'BY', 'DO', 'GN', 'JM', 'LU', 'MZ', 'QA', 'SY', 'VE', 'AU', 'BZ', 'DZ', 'GQ', 'JO', 'LV', 'NA', 'RO', 'SZ', 'VG', 'AW', 'CA', 'EC', 'GR', 'JP', 'LY', 'NE', 'RS', 'TC', 'VN', 'AX', 'CD', 'EE', 'GT', 'KE', 'MA', 'NG', 'TD', 'VU', 'AZ', 'CF', 'EG', 'GW', 'KG', 'MC', 'NI', 'RW', 'TG', 'WS', 'BA', 'CG', 'ER', 'GY', 'KH', 'MD', 'NL', 'SA', 'TH', 'YE', 'BB', 'CH', 'ES', 'HK', 'KM', 'ME', 'NO', 'SB', 'TJ', 'BD', 'CI', 'ET', 'HN', 'KN', 'MG', 'NP', 'SC', 'TL', 'BE', 'CL', 'FI', 'HR', 'KP', 'MK', 'NZ', 'SD', 'TM', 'BF', 'CM', 'FJ', 'HT', 'KR', 'ML', 'OM', 'SE', 'TN', 'BG', 'CN', 'FK', 'HU', 'KW', 'MM', 'PA', 'SG', 'TO'])
            const showFlags = ref([])
            const currentFlag = ref('UA')
            const linqToFlag = ref(require(`@/assets/flags/UA.svg`))

            const nationalityInputActive = ref(false)
            const flagsKey = ref(uuid.v4())

            const currentNationality = ref('')

            const newLeagueLogoAdd = ref(null)

            const newLeagueLogo = ref(null)
            const newLeagueLogoObject = ref(null)

            const addLogoToLeague = () => {
                newLeagueLogoAdd.value.click()
            }

            const proccessNewLeagueLogo = () => {
                const file = newLeagueLogoAdd.value.files[0]
                newLeagueLogoObject.value = file
                const reader = new FileReader()
                reader.onload = () => {
                    newLeagueLogo.value = reader.result
                }
                reader.readAsDataURL(file)
            }

            watch(currentNationality, () => {
                emit('update:nationality', currentNationality.value)
            })

            const changeFlag = (flag) => {
                currentFlag.value = flag
                linqToFlag.value = require(`@/assets/flags/${flag}.svg`)
                currentNationality.value = flag
                nationalityInputActive.value = false
                flagsKey.value = uuid.v4()
            }

            watch(currentNationality, () => {
                filterFlagsByNationality()
            })

            const filterFlagsByNationality = () => {
                const filteredFlags = currentNationality.value.length > 0 || currentNationality.value == 'UKR' ? flagsArray.value.filter(flag => flag.toLowerCase().includes(currentNationality.value.toLowerCase())) : flagsArray.value
                showFlags.value = filteredFlags
                flagsKey.value = uuid.v4()
            }

            watch(birthDate, (newVal) => {
                const date = new Date(newVal)
                const now = new Date()
                const diff = now - date
                age.value = Math.floor(diff / 31536000000)
            })

            
            const uploadPhoto = () => {
                fileInput.value.click()
            }

            onMounted(() => {
                filterFlagsByNationality()
            })

            
            const newScoutPicture = ref(null);
            const showImageCropper = ref(false)
            const imageToCrop = ref({
                src: '',
                type: ''
            })

            const loadImage = (event) => {
                const { files } = event.target
                if (files && files[0]) {
                    if (imageToCrop.value.src) {
                        URL.revokeObjectURL(imageToCrop.value.src)
                    }
                    const blob = URL.createObjectURL(files[0])
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        imageToCrop.value.src = blob
                        imageToCrop.value.type = getMimeType(e.target.result, files[0].type)
                        showImageCropper.value = true
                    }
                    reader.readAsArrayBuffer(files[0])
                }
            }

            const processImage = (image) => {
                photo.value = image
                showImageCropper.value = false
            }

            const addAlienPlayerToDB = async () => {
                const newPlayer = {
                    name: name.value,
                    surname: surname.value,
                    patronymic: patronymic.value,
                    birthdate: new Date(birthDate.value).toISOString(),
                    teamName: team.value,
                    playerNumber: studentNumber.value,
                    amplua: amplua.value,
                    height: height.value,
                    leadingLeg: leadingLeg.value,
                    agent: agent.value,
                    nationality: currentFlag.value
                }

                await addAlienPlayer(newPlayer, photo.value, newLeagueLogo.value)

                emit('updatePlayers')
                emit('close')
            }


            // watch(() => props.studentPhoto, (first, second) => {
            //     photo.value = first
            //     inputValue.value = null
            // })

            return {
                uploadPhoto,
                photo,
                fileInput,
                inputValue,
                positions,

                name,
                surname,
                patronymic,
                birthDate,
                phoneNumber,
                email,
                nationality,
                team,
                studentAmplua,
                studentNumber,
                age,
                amplua,
                height,
                leadingLeg,
                agent,

                startExitFlag,
                addAlienPlayerToDB,

                newLeagueLogoAdd,
                newLeagueLogo,
                newLeagueLogoObject,
                addLogoToLeague,
                proccessNewLeagueLogo,

                nationalityInputActive,
                flagsKey,
                currentNationality,
                showFlags,
                currentFlag,
                linqToFlag,
                changeFlag,
                filterFlagsByNationality,

                newScoutPicture,
                showImageCropper,
                imageToCrop,
                loadImage,
                processImage
            }
        },

        components: {
            ExitConfirmForm,
            ImageCropper
        }
    }
</script>

<style scoped>

    .student-logo {
        -webkit-filter: drop-shadow(0px 0px 10px #222);
        filter: drop-shadow(0px 0px 10px #222);
    }

    ::-webkit-calendar-picker-indicator {
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23BD9135" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
    }

    .avenir-bold {
        font-family: 'Avenir Cyrillic Bold';
    }

    .avenir-standart {
        font-family: 'Avenir Cyrillic';
    }

</style>