<template>
    <div class="absolute top-0 left-0 w-full h-full backdrop-blur-sm z-[9998]" @click="$emit('close')"></div>
    <FormBlock class="w-[470px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999]">
        <div>
            <div>
                <h2 class="text-2xl text-center font-bold">Фільтри</h2>
            </div>

            <div class="flex justify-between my-[20px]">
                <div class="flex w-[49%] flex-col gap-4">
                    <div class="flex relative z-[999] flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Команда</label>
                        <SelectComponent @change="e => filterTeam = e" :default="defaultFilterTeam" id="name" :options="teams" />
                    </div>
                    <div v-if="currentPoints[0]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[0] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="firstParameterMin"
                                :maxValue="firstParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateFirstParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[1]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[1] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="secondParameterMin"
                                :maxValue="secondParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateSecondParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[2]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[2] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="thirdParameterMin"
                                :maxValue="thirdParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateThirdParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[3]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[3] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="fourthParameterMin"
                                :maxValue="fourthParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateFourthParameter"
                            />
                        </div>
                    </div>
                </div>

                <div class="flex w-[49%] flex-col gap-4">
                    <div class="flex relative z-[999] flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">Вік</label>
                        <SelectComponent @change="e => filterAge = e" :default="defaultFilterAge" id="name" :options="ages" />
                    </div>

                    <div v-if="currentPoints[4]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[4] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="fifthParameterMin"
                                :maxValue="fifthParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateFifthParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[5]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[5] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="sixthParameterMin"
                                :maxValue="sixthParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateSixthParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[6]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[6] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="seventhParameterMin"
                                :maxValue="seventhParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateSeventhParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[7]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[7] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="eighthParameterMin"
                                :maxValue="eighthParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateEighthParameter"
                            />
                        </div>
                    </div>
                    <div v-if="currentPoints[8]" class="flex flex-col gap-2">
                        <label for="name" class="text-[12px] text-[#BD9135]">{{ currentPoints[8] }}</label>
                        <div class="px-[10px]">
                            <MultiRangeSlider
                                class="relative z-[997]"
                                baseClassName="multi-range-slider-bar-only"
                                :minValue="ninthParameterMin"
                                :maxValue="ninthParameterMax"
                                :max="10"
                                :min="0"
                                :step="1"
                                :rangeMargin="0"
                                @input="onUpdateNinthParameter"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center">
                <div class="flex relative z-[999] flex-col gap-2">
                    <label for="parameters_type" class="text-[12px] text-[#BD9135]">Тип параметрів</label>
                    <SelectComponent class="w-[200px]" @change="e => currentParameterType = e" id="parameters_type" :options="parametersType" />
                </div>
            </div>

            <div class="flex flex-col items-center justify-center mt-4">
                <ButtonComponent @click="applyFilters">Застосувати</ButtonComponent>
                <ButtonComponent @click="clearFilters" class="mt-[10px]" :gray="true">Скинути</ButtonComponent>
            </div>

        </div>
    </FormBlock>
</template>
<script>
import { ref, onMounted, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGlobalStore } from '@/storage/globalStorage'

import MultiRangeSlider from "multi-range-slider-vue";

export default {
    name: 'FiltersForm',

    props: {
        teams: {
            type: Array,
            default: () => [
                'не вибрано',
                'Team 1',
                'Team 2',
                'Team 3',
                'Team 4',
                'Team 5'
            ]
        },
        ages: {
            type: Array,
            default: () => [
                'не вибрано',
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
            ]
        },
    },

    setup(props, { emit }) {
        const router = useRouter();
        const route = useRoute();

        const store = useGlobalStore();

        const goalkeeperPoints = ref(Object.values(store.$state.scoringRadarChartSettings.goalkeeper).slice(2))
        const fieldPlayerPoints = ref(Object.values(store.$state.scoringRadarChartSettings.field_player).slice(2))

        const parametersType = ref(['голкіпер', 'польовий гравець']);
        const currentParameterType = ref('голкіпер');

        const currentPoints = ref(goalkeeperPoints.value);

        watch(currentParameterType, () => {
            if (currentParameterType.value === 'голкіпер') {
                currentPoints.value = goalkeeperPoints.value;
            } else {
                currentPoints.value = fieldPlayerPoints.value;
            }
        });

        const defaultFilterTeam = ref(route.query.team || null);
        const defaultFilterAge = ref(route.query.age ? parseInt(route.query.age) : null);

        const filterTeam = ref(route.query.team || null);
        const filterAge = ref(route.query.age ? parseInt(route.query.age) : null);

        const firstParameterMin = ref(route.query.firstParameterMin ? parseInt(route.query.firstParameterMin) : 0);
        const firstParameterMax = ref(route.query.firstParameterMax ? parseInt(route.query.firstParameterMax) : 10);

        const secondParameterMin = ref(route.query.secondParameterMin ? parseInt(route.query.secondParameterMin) : 0);
        const secondParameterMax = ref(route.query.secondParameterMax ? parseInt(route.query.secondParameterMax) : 10);

        const thirdParameterMin = ref(route.query.thirdParameterMin ? parseInt(route.query.thirdParameterMin) : 0);
        const thirdParameterMax = ref(route.query.thirdParameterMax ? parseInt(route.query.thirdParameterMax) : 10);

        const fourthParameterMin = ref(route.query.fourthParameterMin ? parseInt(route.query.fourthParameterMin) : 0);
        const fourthParameterMax = ref(route.query.fourthParameterMax ? parseInt(route.query.fourthParameterMax) : 10);

        const fifthParameterMin = ref(route.query.fifthParameterMin ? parseInt(route.query.fifthParameterMin) : 0);
        const fifthParameterMax = ref(route.query.fifthParameterMax ? parseInt(route.query.fifthParameterMax) : 10);

        const sixthParameterMin = ref(route.query.sixthParameterMin ? parseInt(route.query.sixthParameterMin) : 0);
        const sixthParameterMax = ref(route.query.sixthParameterMax ? parseInt(route.query.sixthParameterMax) : 10);

        const seventhParameterMin = ref(route.query.seventhParameterMin ? parseInt(route.query.seventhParameterMin) : 0);
        const seventhParameterMax = ref(route.query.seventhParameterMax ? parseInt(route.query.seventhParameterMax) : 10);

        const eighthParameterMin = ref(route.query.eighthParameterMin ? parseInt(route.query.eighthParameterMin) : 0);
        const eighthParameterMax = ref(route.query.eighthParameterMax ? parseInt(route.query.eighthParameterMax) : 10);

        const ninthParameterMin = ref(route.query.ninthParameterMin ? parseInt(route.query.ninthParameterMin) : 0);
        const ninthParameterMax = ref(route.query.ninthParameterMax ? parseInt(route.query.ninthParameterMax) : 10);
        
        const onUpdateFirstParameter = (value) => {
            firstParameterMin.value = value.minValue;
            firstParameterMax.value = value.maxValue;
        }

        const onUpdateSecondParameter = (value) => {
            secondParameterMin.value = value.minValue;
            secondParameterMax.value = value.maxValue;
        }

        const onUpdateThirdParameter = (value) => {
            thirdParameterMin.value = value.minValue;
            thirdParameterMax.value = value.maxValue;
        }

        const onUpdateFourthParameter = (value) => {
            fourthParameterMin.value = value.minValue;
            fourthParameterMax.value = value.maxValue;
        }

        const onUpdateFifthParameter = (value) => {
            fifthParameterMin.value = value.minValue;
            fifthParameterMax.value = value.maxValue;
        }

        const onUpdateSixthParameter = (value) => {
            sixthParameterMin.value = value.minValue;
            sixthParameterMax.value = value.maxValue;
        }

        const onUpdateSeventhParameter = (value) => {
            seventhParameterMin.value = value.minValue;
            seventhParameterMax.value = value.maxValue;
        }

        const onUpdateEighthParameter = (value) => {
            eighthParameterMin.value = value.minValue;
            eighthParameterMax.value = value.maxValue;
        }

        const onUpdateNinthParameter = (value) => {
            ninthParameterMin.value = value.minValue;
            ninthParameterMax.value = value.maxValue;
        }

        watch(() => [
            filterTeam.value,
            filterAge.value,
            firstParameterMin.value,
            firstParameterMax.value,
            secondParameterMin.value,
            secondParameterMax.value,
            thirdParameterMin.value,
            thirdParameterMax.value,
            fourthParameterMin.value,
            fourthParameterMax.value,
            fifthParameterMin.value,
            fifthParameterMax.value,
            sixthParameterMin.value,
            sixthParameterMax.value,
            seventhParameterMin.value,
            seventhParameterMax.value,
            eighthParameterMin.value,
            eighthParameterMax.value,
            ninthParameterMin.value,
            ninthParameterMax.value,
        ], () => {
            router.push({
                query: {
                    ...route.query,
                    team: filterTeam.value,
                    age: filterAge.value,
                    firstParameterMin: firstParameterMin.value,
                    firstParameterMax: firstParameterMax.value,
                    secondParameterMin: secondParameterMin.value,
                    secondParameterMax: secondParameterMax.value,
                    thirdParameterMin: thirdParameterMin.value,
                    thirdParameterMax: thirdParameterMax.value,
                    fourthParameterMin: fourthParameterMin.value,
                    fourthParameterMax: fourthParameterMax.value,
                    fifthParameterMin: fifthParameterMin.value,
                    fifthParameterMax: fifthParameterMax.value,
                    sixthParameterMin: sixthParameterMin.value,
                    sixthParameterMax: sixthParameterMax.value,
                    seventhParameterMin: seventhParameterMin.value,
                    seventhParameterMax: seventhParameterMax.value,
                    eighthParameterMin: eighthParameterMin.value,
                    eighthParameterMax: eighthParameterMax.value,
                    ninthParameterMin: ninthParameterMin.value,
                    ninthParameterMax: ninthParameterMax.value,
                }
            });
        }, { deep: true, immediate: true });
        
        const applyFilters = () => {
            emit('applyFilters', {
                team: filterTeam.value,
                age: filterAge.value,
                firstParameterMin: firstParameterMin.value,
                firstParameterMax: firstParameterMax.value,
                secondParameterMin: secondParameterMin.value,
                secondParameterMax: secondParameterMax.value,
                thirdParameterMin: thirdParameterMin.value,
                thirdParameterMax: thirdParameterMax.value,
                fourthParameterMin: fourthParameterMin.value,
                fourthParameterMax: fourthParameterMax.value,
                fifthParameterMin: fifthParameterMin.value,
                fifthParameterMax: fifthParameterMax.value,
                sixthParameterMin: sixthParameterMin.value,
                sixthParameterMax: sixthParameterMax.value,
                seventhParameterMin: seventhParameterMin.value,
                seventhParameterMax: seventhParameterMax.value,
                eighthParameterMin: eighthParameterMin.value,
                eighthParameterMax: eighthParameterMax.value,
                ninthParameterMin: ninthParameterMin.value,
                ninthParameterMax: ninthParameterMax.value,
            });
        }

        const clearFilters = () => {
            filterTeam.value = null;
            filterAge.value = null;
            firstParameterMin.value = 0;
            firstParameterMax.value = 10;
            secondParameterMin.value = 0;
            secondParameterMax.value = 10;
            thirdParameterMin.value = 0;
            thirdParameterMax.value = 10;
            fourthParameterMin.value = 0;
            fourthParameterMax.value = 10;
            fifthParameterMin.value = 0;
            fifthParameterMax.value = 10;
            sixthParameterMin.value = 0;
            sixthParameterMax.value = 10;
            seventhParameterMin.value = 0;
            seventhParameterMax.value = 10;
            eighthParameterMin.value = 0;
            eighthParameterMax.value = 10;
            ninthParameterMin.value = 0;
            ninthParameterMax.value = 10;

            applyFilters();
        }

        return {
            filterTeam,
            filterAge,
            firstParameterMin,
            firstParameterMax,
            secondParameterMin,
            secondParameterMax,
            thirdParameterMin,
            thirdParameterMax,
            fourthParameterMin,
            fourthParameterMax,
            fifthParameterMin,
            fifthParameterMax,
            sixthParameterMin,
            sixthParameterMax,
            seventhParameterMin,
            seventhParameterMax,
            eighthParameterMin,
            eighthParameterMax,
            ninthParameterMin,
            ninthParameterMax,
            onUpdateFirstParameter,
            onUpdateSecondParameter,
            onUpdateThirdParameter,
            onUpdateFourthParameter,
            onUpdateFifthParameter,
            onUpdateSixthParameter,
            onUpdateSeventhParameter,
            onUpdateEighthParameter,
            onUpdateNinthParameter,
            applyFilters,
            clearFilters,
            defaultFilterAge,
            defaultFilterTeam,
            goalkeeperPoints,
            fieldPlayerPoints,
            parametersType,
            currentParameterType,
            currentPoints
        }
    },

    components: {
        MultiRangeSlider
    }
}
</script>
<style >
.multi-range-slider-bar-only * {
	box-sizing: border-box;
	padding: 0px;
	margin: 0px;
}
.multi-range-slider-bar-only {
	display: flex;
	position: relative;
	border: none;
	border-radius: 0px;
	padding: 10px;
	box-shadow: none;
	flex-direction: column;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.multi-range-slider-bar-only .bar {
	display: flex;
}
.multi-range-slider-bar-only .bar-left {
	width: 25%;
	background-color: #252526;
	border-radius: 10px 0px 0px 10px;
	
	padding: 4px 0px;
}
.multi-range-slider-bar-only .bar-right {
	width: 25%;
	background-color: #252526;
	border-radius: 0px 10px 10px 0px;
	
}
.multi-range-slider-bar-only .bar-inner {
	background-color: #BD9135;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: space-between;
	position: relative;
	border: solid 1px black;
	justify-content: space-between;
	
}
.multi-range-slider-bar-only .bar-inner-left {
	width: 50%;
}
.multi-range-slider-bar-only .bar-inner-right {
	width: 50%;
}
.multi-range-slider-bar-only .thumb {
	background-color: red;
	position: relative;
	z-index: 1;
	cursor: pointer;
    top: 3px;
}
.multi-range-slider-bar-only .thumb::before {
	content: '';
	background-color: white;
	position: absolute;
	width: 20px;
	height: 20px;
	border: solid 1px black;
	
	border-radius: 50%;
	z-index: 1;
	margin: -8px;
	cursor: pointer;
}
.multi-range-slider-bar-only .input-type-range:focus + .thumb::after {
	content: '';
	position: absolute;
	top: -4px;
	left: -4px;
	width: 11px;
	height: 11px;
	z-index: 2;
	border-radius: 50%;
	border: dotted 1px black;
	
}
.multi-range-slider-bar-only .caption {
	position: absolute;
	bottom: 35px;
	width: 2px;
	height: 2px;
	left: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: visible;
	display: none;
}
.multi-range-slider-bar-only .thumb .caption * {
	position: absolute;
	min-width: 30px;
	height: 30px;
	font-size: 75%;
	text-align: center;
	line-height: 30px;
	background-color: #BD9135;
	border-radius: 15px;
	color: #252526;
	
	padding: 0px 5px;
	white-space: nowrap;
}
.multi-range-slider-bar-only .thumb:active .caption {
	display: flex;
}
.multi-range-slider-bar-only .input-type-range:focus + .thumb .caption {
	display: flex;
}
.multi-range-slider-bar-only .input-type-range {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.multi-range-slider-bar-only .ruler {
	margin: 10px 0px -5px 0px;
	display: flex;
	display: none;
	overflow: hidden;
}
.multi-range-slider-bar-only .ruler .ruler-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 5px 0px;
	display: none;
}
.multi-range-slider-bar-only .ruler .ruler-rule:last-child {
	border-right: solid 1px;
}

.multi-range-slider-bar-only .sub-ruler {
	margin: -2px 0px -5px 0px;
	display: flex;
	display: none;
}
.multi-range-slider-bar-only .sub-ruler .ruler-sub-rule {
	border-left: solid 1px;
	border-bottom: solid 1px;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 3px 0px;
}
.multi-range-slider-bar-only .sub-ruler .ruler-sub-rule:last-child {
	border-right: solid 1px;
}

.multi-range-slider-bar-only .labels {
	display: flex;
	justify-content: space-between;
	padding: 0px;
	margin-top: 10px;
	margin-bottom: -20px;
	display: none;
}
.multi-range-slider-bar-only .label {
	font-size: 80%;
	display: flex;
	width: 1px;
	justify-content: center;
}
.multi-range-slider-bar-only .label:first-child {
	justify-content: start;
}
.multi-range-slider-bar-only .label:last-child {
	justify-content: end;
}
.multi-range-slider-bar-only.zero-ranage-margin .thumb-left {
	right: 12px;
}
.multi-range-slider-bar-only.zero-ranage-margin .thumb-right {
	left: 8px;
}

</style>