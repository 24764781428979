<template>
<main class="main__wrapper">
    <PlayerHeader
        :links="links"
        :setActive="setActive"
        :showMiddleComponent="true"
        :onlyShowHorizontalMenu="onlyShowHorizontalMenu"
        :student="currentStudent"
        @setActive="setActive"
        @close="$emit('close')"
        @saveChanges="() => {updateFullCard(); updateFlag = true}"
    >
    <!-- <component
        :is="headerMiddleComponent"
        v-bind="activeLinkMiddleComponentProps"
    /> -->
    <StatsHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'StatsHeaderSection'" />
    <FinancesHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'FinancesHeaderSection'" />
    <HealthcareHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'HealthcareHeaderSection'" />
    <ReportHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'ReportHeaderSection'" />

    </PlayerHeader>
    <div class="main__section" v-if="infoLoadedFlag">
        <MainTab 
            v-if="activeComponent == 'MainTab'" 
            @close="$emit('close')"
            :student="currentStudent"  
            :fullCardInfo="fullcardInfo"   
        />
        <ParametersTab  
            v-if="activeComponent == 'ParametersTab'" 
            v-model:login="currentStudent.login"
            v-model:password="currentStudent.password"
            v-model:amplua="currentStudent.amplua"
            v-model:role="fullcardInfo.role"
            v-model:playerNumber="currentStudent.player_number"
            v-model:leadingLeg="currentStudent.leading_foot"
            v-model:height="currentStudent.height"
            v-model:weight="currentStudent.weight"
            v-model:footSize="currentStudent.foot_size"
            v-model:breastVolume="currentStudent.breast_volume"
            v-model:careerList="fullcardInfo.career"    
        />
        <PlayerDocumentsTab 
            v-if="activeComponent == 'PlayerDocumentsTab'" 
            v-model:name="currentStudent.name"
            v-model:surname="currentStudent.surname"
            v-model:patronymic="currentStudent.patronymic"
            v-model:name_eng="currentStudent.name_eng"
            v-model:surname_eng="currentStudent.surname_eng"
            v-model:nationality="currentStudent.nationality"
            v-model:address="currentStudent.address"
            v-model:social_network1="fullcardInfo.social_profile_1"
            v-model:social_network2="fullcardInfo.social_profile_2"
            v-model:patronName="currentStudent.patrons[0].name"
            v-model:patronPhone="currentStudent.patrons[0].phone_number"
            v-model:birthday="birthday"
            v-model:abroadPassport = "fullcardInfo.abroad_passport"
            v-model:abroadPassportEndDate = "abroadPassportEndDate"
            v-model:passport = "currentStudent.passport"
            v-model:passportOffice = "fullcardInfo.passport_issue_office"
            v-model:passportIssueDate = "passportIssueDate"
            v-model:idCode = "currentStudent.id_number"
            v-model:phone = "currentStudent.phone"
            v-model:email = "currentStudent.email"
            v-model:startInClub = "startInClub"
            v-model:offerType = "fullcardInfo.offer_type"
            v-model:contractNumber = "fullcardInfo.contract_number"
            v-model:contractExpireDate = "contractExpireDate"
            v-model:attorney = "fullcardInfo.attorney"
            v-model:representative = "fullcardInfo.representative"
            v-model:agency = "fullcardInfo.agency"

            :passportPhotos="passportPhotos"
            @addPassportPhoto="e => passportPhotos.push(e)"
            :idPhotos="idPhotos"
            @addIdPhoto="e => idPhotos.push(e)"
            :attorneyPhotos="attorneyFiles"
            @addAttorneyPhoto="e => attorneyFiles.push(e)"
            :contractFiles="contractFiles"
            @addContractFile="e => contractFiles.push(e)"
            :abroadPassportFiles="abroadPassportFiles"
            @addAbroadPassportFile="e => abroadPassportFiles.push(e)"

            @deletePassportPhotos="e => passportPhotos.splice(e, 1)"
            @addPassportPhotos="e => passportPhotos.push(e)"

            @deleteIdPhotos="e => idPhotos.splice(e, 1)"
            @addIdPhotos="e => idPhotos.push(e)"

            @addContractFiles="e => contractFiles.push(e)"
            @deleteContractFiles="e => contractFiles.splice(e, 1)"   
            
            @addAbroadPassportFiles="e => abroadPassportFiles.push(e)"
            @deleteAbroadPassportFiles="e => abroadPassportFiles.splice(e, 1)"

            @addAttorneyFiles="e => attorneyFiles.push(e)"
            @deleteAttorneyPhotos="e => attorneyFiles.splice(e, 1)"
        />
        <StatsTab 
            v-if="activeComponent == 'StatsTab'" 
            :playerId="currentStudent.student_id"    
        />
        <TestingTab 
            v-if="activeComponent == 'TestingTab'" 
            :student="currentStudent"      
        />
        <FinancesTab v-if="activeComponent == 'FinancesTab'" />
        <HealthcareTab 
            v-if="activeComponent == 'HealthcareTab'" 
            :playerId="currentStudent.student_id"    
            :updateFlag="updateFlag"
            
            @updated="updateFlag = false"
        />
        <ReportTab 
            v-if="activeComponent == 'ReportTab'" 
            v-model:startPrice="fullcardInfo.start_price"
            v-model:endPrice="fullcardInfo.current_price"  
            :amplua="currentStudent.amplua"  
        />
    </div>
</main>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getPlayerFullCardByStudentId, updatePlayerFullCardByStudentId, updateStudent } from '@/services/apiRequests'

import ReportTab from "./components/tabs/ReportTab.vue";
import MainTab from "./components/tabs/MainTab.vue";
import ParametersTab from "./components/tabs/ParametersTab.vue";
import TestingTab from "./components/tabs/TestingTab.vue";
import HealthcareTab from "./components/tabs/HealthcareTab.vue";
import FinancesTab from "./components/tabs/FinancesTab.vue";
import StatsTab from "./components/tabs/StatsTab.vue";
import PlayerHeader from "./components/PlayerHeader.vue";
import PlayerDocumentsTab from "./components/tabs/PlayerDocumentsTab";
import StatsHeaderSection from "./components/StatsHeaderSection.vue";
import FinancesHeaderSection from "./components/FinancesHeaderSection.vue";
import HealthcareHeaderSection from "./components/HealthcareHeaderSection.vue";
import ReportHeaderSection from "./components/ReportHeaderSection.vue";

async function downloadImageAsBlob(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('Error downloading image:', error);
    }
}

export default {
    name: "PlayersDashboard",
    props: {
        student: {
            type: Object,
            required: false,
        },
    },
    setup(props, { emit }) {
        const group = ref(props.student.group_name)
        const age = ref(props.student.age)
        const studentAmplua = ref(props.student.amplua)
        const studentNumber = ref(props.student.player_number)

        const fullcardInfo = ref(null)
        const currentStudent = ref(Object.assign({}, props.student))
        const studentPhoto = ref(process.env.VUE_APP_API_URL + 'static/' + currentStudent.value.photo)

        const updatedFlag = ref(false)
        const infoLoadedFlag = ref(false)

        const birthday = ref(props.student.birthday.split('T')[0])
        const abroadPassportEndDate = ref(fullcardInfo.value?.abroad_passport_end_date ? fullcardInfo.value?.abroad_passport_end_date.split('T')[0] : '')
        const passportIssueDate = ref(fullcardInfo.value?.passport_issue_date ? fullcardInfo.value?.passport_issue_date.split('T')[0] : '')
        const startInClub = ref(currentStudent.value?.start_training ? currentStudent.value?.start_training.split('T')[0] : '')
        const contractExpireDate = ref(fullcardInfo.value?.contract_term ? fullcardInfo.value?.contract_term.split('T')[0] : '')

        const passportPhotos = ref(currentStudent.value.passport_files ? currentStudent.value.passport_files : [])
        const idPhotos = ref(currentStudent.value.id_code_files ? currentStudent.value.id_code_files : [])
        const attorneyFiles = ref(currentStudent.value.attorney_child_files ? currentStudent.value.attorney_child_files : [])

        const contractFiles = ref(fullcardInfo.value?.contracts_files ? fullcardInfo.value?.contracts_files : [])
        const abroadPassportFiles = ref(fullcardInfo.value?.abroad_passport_files ? fullcardInfo.value?.abroad_passport_files : [])

        const photoForPassport = ref(currentStudent.value.photo_for_passport ? currentStudent.value.photo_for_passport : [])

        const updateFlag = ref(false)

        const route = useRoute()
        const router = useRouter()

        const tabName = ref(route.query.tabName)
        const links = ref([
            {
                text: "Головна",
                isActive: true,
                component: "MainTab",
                onlyShowHorizontalMenu: true,
            },
            {
                text: "Параметри",
                isActive: false,
                component: "ParametersTab",
            },
            {
                text: "Документи",
                isActive: false,
                component: "PlayerDocumentsTab",
            },
            {
                text: "статистика",
                isActive: false,
                component: "StatsTab",
                headerMiddleComponent: "StatsHeaderSection",
                middleComponentProps: {
                    visitings_number: 123,
                    trainings_number: 23,
                    rating: 3,
                },
            },
            { text: "тестування", isActive: false, component: "TestingTab" },
            {
                text: "фінанси",
                isActive: false,
                component: "FinancesTab",
                headerMiddleComponent: "FinancesHeaderSection",
                middleComponentProps: {
                    salary: 100_000,
                    expenses: 22_000,
                },
            },
            {
                text: "Медицина",
                isActive: false,
                component: "HealthcareTab",
                headerMiddleComponent: "HealthcareHeaderSection",
                middleComponentProps: {
                    rating: 4,
                    blood_group: "O(I)-",
                    heart_rate: 34,
                },
            },
            {
                text: "Звіт скаута",
                isActive: false,
                component: "ReportTab",
                headerMiddleComponent: "ReportHeaderSection",
                middleComponentProps: {
                    potential: "B(Good A/P)",
                    number_of_reports: 4,
                    estimated_value: 200_000,
                    rating: 4,
                },
            },
        ])

        watch(birthday, (newVal) => {
            console.log('birthday ----- ', newVal)
        })

        const apiURL = ref(process.env.VUE_APP_API_URL)
        const photo = ref(
            props.student?.photo ? apiURL.value + 'static/' + props.student.photo : ''
        )

        const setActive = (activeLink) => {
            console.log('activeLink', activeLink)
            links.value.forEach((link) => {
                link.isActive = link === activeLink;
            });
            router.push({ query: { tabName: activeLink.component } })
        }

        const updateFullCard = async () => {
            fullcardInfo.value.abroad_passport_end_date = abroadPassportEndDate.value ? new Date(abroadPassportEndDate.value).toISOString() : null
            fullcardInfo.value.passport_issue_date = passportIssueDate.value ? new Date(passportIssueDate.value).toISOString() : null
            fullcardInfo.value.contract_term = contractExpireDate.value ? new Date(contractExpireDate.value).toISOString() : null
            currentStudent.value.start_training = startInClub.value ? new Date(startInClub.value).toISOString() : null
            currentStudent.value.birthday = birthday.value ? new Date(birthday.value).toISOString() : null

            fullcardInfo.value.start_price = +fullcardInfo.value.start_price
            fullcardInfo.value.current_price = +fullcardInfo.value.current_price
            console.log('fullcardInfo.value4567890', fullcardInfo.value)

            const res = await updatePlayerFullCardByStudentId(props.student.student_id, fullcardInfo.value, contractFiles.value, abroadPassportFiles.value)
            const res2 = await updateStudent(currentStudent.value, studentPhoto.value, idPhotos.value, passportPhotos.value, attorneyFiles.value, photoForPassport.value)
            // idPhotos.value, passportPhotos.value, attorneyFiles.value, photoForPassport.value
            emit('updateStudents')
            updatedFlag.value = true
            setTimeout(() => {
                updatedFlag.value = false
            }, 2000)
        }

        onMounted(async () => {
            const res = await getPlayerFullCardByStudentId(props.student.student_id)
            fullcardInfo.value = res
            console.log('fullcardInfo.value', fullcardInfo.value)

            abroadPassportEndDate.value = fullcardInfo.value?.abroad_passport_end_date ? fullcardInfo.value?.abroad_passport_end_date.split('T')[0] : ''
            passportIssueDate.value = fullcardInfo.value?.passport_issue_date ? fullcardInfo.value?.passport_issue_date.split('T')[0] : ''
            contractExpireDate.value = fullcardInfo.value?.contract_term ? fullcardInfo.value?.contract_term.split('T')[0] : ''
            startInClub.value = currentStudent.value?.start_training ? currentStudent.value?.start_training.split('T')[0] : ''
            birthday.value = currentStudent.value?.birthday ? currentStudent.value?.birthday.split('T')[0] : ''

            infoLoadedFlag.value = true

            idPhotos.value = []
            for (let i = 0; i < currentStudent.value.id_code_files.length; i++) {
                const blob = await downloadImageAsBlob(apiURL.value + 'static/' + currentStudent.value.id_code_files[i])
                idPhotos.value.push(new File([blob], currentStudent.value.id_code_files[i].split('.')[0], { type: `image/${currentStudent.value.id_code_files[i].split('.')[1]}` }))
            }

            passportPhotos.value = []
            for (let i = 0; i < currentStudent.value.passport_files.length; i++) {
                const blob = await downloadImageAsBlob(apiURL.value + 'static/' + currentStudent.value.passport_files[i])
                passportPhotos.value.push(new File([blob], currentStudent.value.passport_files[i].split('.')[0], { type: `image/${currentStudent.value.passport_files[i].split('.')[1]}` }))
            }

            attorneyFiles.value = []
            for (let i = 0; i < currentStudent.value.attorney_child_files.length; i++) {
                const blob = await downloadImageAsBlob(apiURL.value + 'static/' + currentStudent.value.attorney_child_files[i])
                attorneyFiles.value.push(new File([blob], currentStudent.value.attorney_child_files[i].split('.')[0], { type: `image/${currentStudent.value.attorney_child_files[i].split('.')[1]}` }))
            }

            contractFiles.value = []
            if (fullcardInfo.value.contracts_files) {
                for (let i = 0; i < fullcardInfo.value.contracts_files.length; i++) {
                    const blob = await downloadImageAsBlob(apiURL.value + 'static/' + fullcardInfo.value.contracts_files[i])
                    contractFiles.value.push(new File([blob], fullcardInfo.value.contracts_files[i].split('.')[0], { type: `image/${fullcardInfo.value.contracts_files[i].split('.')[1]}` }))
                }
            }

            abroadPassportFiles.value = []
            if (fullcardInfo.value.abroad_passport_files) {
                for (let i = 0; i < fullcardInfo.value.abroad_passport_files.length; i++) {
                    const blob = await downloadImageAsBlob(apiURL.value + 'static/' + fullcardInfo.value.abroad_passport_files[i])
                    abroadPassportFiles.value.push(new File([blob], fullcardInfo.value.abroad_passport_files[i].split('.')[0], { type: `image/${fullcardInfo.value.abroad_passport_files[i].split('.')[1]}` }))
                }
            }

            fullcardInfo.value.start_price = fullcardInfo.value.start_price ? fullcardInfo.value.start_price : 0
            fullcardInfo.value.current_price = fullcardInfo.value.current_price ? fullcardInfo.value.current_price : 0

            const currentTabObject = links.value.find((link) => link.component === tabName.value)
            setActive(currentTabObject)
        })

        return {
            group,
            age,
            studentAmplua,
            studentNumber,
            photo,
            fullcardInfo,
            currentStudent,
            birthday,
            abroadPassportEndDate,
            passportIssueDate,
            startInClub,
            contractExpireDate,
            updateFullCard,
            updatedFlag,
            passportPhotos,
            idPhotos,
            attorneyFiles,
            contractFiles,
            abroadPassportFiles,
            infoLoadedFlag,
            updateFlag,
            links,
            setActive,
        };
    },
    components: {
        ReportTab,
        ParametersTab,
        MainTab,
        TestingTab,
        HealthcareTab,
        FinancesTab,
        StatsTab,
        PlayerHeader,
        PlayerDocumentsTab,
        StatsHeaderSection,
        FinancesHeaderSection,
        HealthcareHeaderSection,
        ReportHeaderSection,
    },
    data() {
        return {
        };
    },
    computed: {
        activeComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.component : null;
        },
        showHeaderMiddleComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink && activeLink.middleComponent ? true : false;
        },
        headerMiddleComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.headerMiddleComponent : null;
        },
        onlyShowHorizontalMenu() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.onlyShowHorizontalMenu : false;
        },
        activeLinkMiddleComponentProps() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.middleComponentProps : {};
        },
    },
    methods: {
    },
};
</script>
