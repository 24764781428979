<template>
    <div>
        <table class="w-[97%] mt-[24px] mx-auto">
            <thead class="">
                <tr class="text-[#C59014] border-b-[1px] border-[#C59014] border-opacity-10">
                    <th class="px-3 py-2 text-center font-medium text-sm w-[7%]"></th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[20%]">матч</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">ліга</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">сезон</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">гол</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">асист</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">пен</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">автогол</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">пропущено</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[8%]">хв. на полі</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">жовті</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[5%]">червоні</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">оцінка</th>
                    <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">травма</th>
                </tr>
            </thead>
            <tbody>
                <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                    <td class="text-center">21.04.2024</td>
                    <td class="font-semibold text-center">Колос - Оболонь</td>
                    <td class="text-center">УПЛ</td>
                    <td class="text-center">2023-2024</td>
                    <td class="text-center">1</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                    <td class="text-center">96 хв</td>
                    <td class="text-center flex justify-center items-center h-[62px]">
                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="16" height="20" fill="#C59014"/>
                        </svg>
                    </td>
                    <td class="text-center"></td>
                    <td class="text-center">
                        <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                        <path d="M22.9808 0L24.6912 5.10709L30.0768 5.15559L25.7482 8.36044L27.3664 13.4975L22.9808 10.3711L18.5952 13.4975L20.2133 8.36044L15.8847 5.15559L21.2704 5.10709L22.9808 0Z" fill="#C59014"/>
                        <path d="M38.4998 0L40.2102 5.10709L45.5959 5.15559L41.2673 8.36044L42.8854 13.4975L38.4998 10.3711L34.1142 13.4975L35.7324 8.36044L31.4038 5.15559L36.7894 5.10709L38.4998 0Z" fill="#C59014"/>
                        <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                        <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                        </svg>
                    </td>
                    <td class="text-center">-</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'statistics-tab',    
}
</script>

<style>
    
</style>