<template>
  <nav class="horizontal-menu">
    <div class="horizontal-menu__list-container">
      <ul class="horizontal-menu__list">
        <li
          class="horizontal-menu__item"
          v-for="(link, index) in links"
          :key="index"
          :ref="`item-${index}`"
        >
          <div
            class="horizontal-menu__link"
            :class="{ 'horizontal-menu__link--active': link.isActive }"
            @click="setActive(link, index)"
          >
            {{ link.text }}
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  name: "HorizontalMenu",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setActive(activeLink, index) {
      this.$emit("setActive", activeLink);
      this.links.forEach((link) => {
        link.isActive = link === activeLink;
      });
      this.$nextTick(() => {
        this.$refs[`item-${index}`][0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      });
    },
  },
};
</script>
