<template>
  <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "PieGraphElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },

  },
  setup(props) {
    const { dataSet} = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;

    const screenWidth = ref(window.innerWidth);

    const bottom = screenWidth.value>780? "5%": "0%"

    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const option = {
        graphic: [
          {
            type: 'text',
            left: '10',
            top: '10',
            style: {
              text: '',
              fontSize: 15,
              fontWeight: 'bold',
            },
            id: 'title'

          },
          {
            type: 'text',
            left: '10',
            top: '30',
            style: {
              text: '',
              fontSize: 13,
              fontWeight: 'regular',
              fill: "#C6C6C6",
            },
            id: 'description'
          }
        ],
        tooltip: {
          show: false
        },
        color: dataSet.value.map(element=>element.color),
        legend: {
          bottom: bottom,
          left: 'center',
          icon: "circle"
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center',
              color: 'inherit',
              formatter: '{c} {d}%',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false,
              length: 20,
              length2: 20
            },
            data: dataSet.value.map(element=>{
              return {
                value:element.value,
                name: element.title
              }
            })
          }
        ]
      };

      myChart.setOption(option, true);
      myChart.on('mouseover', function(params) {
        if (params.componentType === 'series') {
          myChart.setOption({
            graphic: [
              {
                id: 'title',
                style: {
                  text: `${params.name}`,
                  fill: params.color
                }
              },
              {
                id: 'description',
                style: {
                  text: `${params.value} (${params.percent}%)`
                }
              },
            ]
          });
        }
      });
      myChart.on('mouseout', function(params) {
        console.log(params)
        myChart.setOption({
          graphic: [
            {
              id: 'title',
              style: {
                text: ``
              }
            },
            {
              id: 'description',
              style: {
                text: ``
              }
            },
          ]

        });
      });

    });

    onMounted(() => {
      if (!chartContainer.value) return;
      // Графік ініціалізується у watchEffect
    });
    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };
    onMounted(() => {
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }

      window.addEventListener('resize', updateChartSize);


    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });
    return {chartContainer};
  }
};
</script>

