<template>
  <div class="flex flex-col justify-center">
    <svg ref="svg" class="ml-[40px]" :width="canvasSize" :height="canvasSize">
      <g transform="translate(110, 110)">
        <circle
          :r="radius"
          fill="transparent"
          stroke="#434548"
          :stroke-width="lineWidth"
        />
        <g v-for="(segment, index) in computedData" :key="index">
          <path
            :d="describeArc(0, 0, radius +20, segment.startAngle, segment.endAngle)"
            :fill="colors[index]"
          />
        </g>
        <circle :r="innerRadius + 20" fill="#252526" />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    canvasSize: {
      type: Number,
      default: 220,
    },
    lineWidth: {
      type: Number,
      default: 36,
    },
    colors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedData() {
      const summAllElements = this.data.reduce((acc, value) => acc + value, 0);
      let startAngle = -Math.PI / 2;

      return this.data.map((value) => {
        const segmentPercent = (value * 100) / summAllElements;
        const anglePerSegment = (2 * Math.PI * segmentPercent) / 100;

        const segment = {
          startAngle,
          endAngle: startAngle + anglePerSegment - 0.0001,
        };
        console.log('comp value ----- ', segment)

        startAngle += anglePerSegment;

        return segment;
      });
    },
    radius() {
      return this.canvasSize / 2.5 - this.lineWidth / 2.5;
    },
    innerRadius() {
      return this.radius - this.lineWidth;
    },
  },
  methods: {
    describeArc(x, y, radius, startAngle, endAngle) {
      const start = this.polarToCartesian(x, y, radius, endAngle);
      const end = this.polarToCartesian(x, y, radius, startAngle);

      const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

      return [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        'L', x, y,
        'L', start.x, start.y,
      ].join(' ');
    },
    polarToCartesian(centerX, centerY, radius, angleInRadians) {
      const x = centerX + radius * Math.cos(angleInRadians);
      const y = centerY + radius * Math.sin(angleInRadians);
      return { x, y };
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>