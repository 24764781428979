<template>
<main class="main__wrapper">
    <PlayerHeader
        v-if="infoLoadedFlag"
        :links="links"
        :setActive="setActive"
        :showMiddleComponent="true"
        :onlyShowHorizontalMenu="onlyShowHorizontalMenu"
        :student="playerInfo"
        @setActive="setActive"
        @close="$emit('close')"
        @saveChanges="updatePlayer"
    >
    <StatsHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'StatsHeaderSection'" />
    <FinancesHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'FinancesHeaderSection'" />
    <HealthcareHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'HealthcareHeaderSection'" />
    <ReportHeaderSection v-if="showHeaderMiddleComponent && headerMiddleComponent == 'ReportHeaderSection'" />

    </PlayerHeader>


    <div class="main__section" v-if="infoLoadedFlag">
        <ReportTab 
            v-if="activeComponent == 'ReportTab'" 
            v-model:startPrice="startPrice"
            v-model:endPrice="endPrice"    
            :amplua="playerInfo.amplua"
        />
        <ParametersTab v-if="activeComponent == 'ParametersTab'" :updateId="updateId" />
    </div>
</main>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getAlienPlayerById } from '@/services/apiRequests'
import { uuid } from 'vue3-uuid';

import ReportTab from "./components/tabs/ReportTab.vue";
import PlayerHeader from "./components/PlayerHeader.vue";
import PlayerDocumentsTab from "./components/tabs/PlayerDocumentsTab";
import StatsHeaderSection from "./components/StatsHeaderSection.vue";
import FinancesHeaderSection from "./components/FinancesHeaderSection.vue";
import HealthcareHeaderSection from "./components/HealthcareHeaderSection.vue";
import ReportHeaderSection from "./components/ReportHeaderSection.vue";
import ParametersTab from "./components/tabs/ParametersTab.vue";

export default {
    name: "PlayersDashboard",

    setup(props, { emit }) {
        const route = useRoute();
        const router = useRouter();

        const playerId = ref(route.params.player_id);
        const infoLoadedFlag = ref(false);
        const activeComponent = ref(route.query.tabName);

        const startPrice = ref(0);
        const endPrice = ref(0);

        const playerInfo = ref({});

        const updateId = ref(uuid.v4());

        const updatePlayer = async () => {
            updateId.value = uuid.v4(); 
        }

        onMounted(async () => {
            playerInfo.value = await getAlienPlayerById(playerId.value);
            infoLoadedFlag.value = true;
        });

        const links = ref([
            {
                text: "Параметри",
                isActive: false,
                component: "ParametersTab",
            },
            {
                text: "Звіти скаутів",
                isActive: true,
                component: "ReportTab",
                headerMiddleComponent: "ReportHeaderSection",
                middleComponentProps: {
                    potential: "B(Good A/P)",
                    number_of_reports: 4,
                    estimated_value: 200_000,
                    rating: 4,
                },
            },
        ])

        const setActive = (activeLink) => {
            console.log('activeLink', activeLink)
            links.value.forEach((link) => {
                link.isActive = link === activeLink;
            });
            router.push({ query: { tabName: activeLink.component } })
        }


        return {
            playerId,
            infoLoadedFlag,
            playerInfo,
            activeComponent,
            startPrice,
            endPrice,
            links,
            setActive,
            updateId,
            updatePlayer
        };
    },
    components: {
        ReportTab,
        PlayerHeader,
        PlayerDocumentsTab,
        StatsHeaderSection,
        FinancesHeaderSection,
        HealthcareHeaderSection,
        ReportHeaderSection,
        ParametersTab
    },

    data() {
        return {
        };
    },
    
    computed: {
        activeComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.component : null;
        },
        showHeaderMiddleComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink && activeLink.middleComponent ? true : false;
        },
        headerMiddleComponent() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.headerMiddleComponent : null;
        },
        onlyShowHorizontalMenu() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.onlyShowHorizontalMenu : false;
        },
        activeLinkMiddleComponentProps() {
            const activeLink = this.links.find((link) => link.isActive);
            return activeLink ? activeLink.middleComponentProps : {};
        },
    },
    
    methods: {
    },
};
</script>
