<template>
    <div class="mt-[100px] mb-[100px]">
        <div class="w-full text-center uppercase text-[16px]">shadow teams</div>

        <div class="flex gap-[27px]"> 
            <div v-for="team in shadowTeams" class="flex overflow-x-auto mt-[32px]">
                <div class="w-max flex flex-col items-center relative cursor-pointer" @click="$router.push('/shadow-team-view/' + team.id)">
                    <img src="@/assets/shadow-team-field.svg" alt="">

                    <div :title="team.name" class="bg-black w-[141px] py-[10px] text-[16px] absolute top-[50%] truncate px-[10px] translate-y-[-50%] text-center text-[#C59014] font-semibold">
                        {{ team.name }}
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ShadowTeams',

    props: {
        shadowTeams: {
            type: Array,
            required: true
        }
    },
}
</script>

<style>
    
</style>