<template>
    <div>
        <svg width="22" height="110" viewBox="0 0 22 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M19.1488 109.101L19.1488 5.5785e-05M2.67236 109.101L2.67236 5.65052e-05" stroke="#474849" stroke-width="2.18202"/>
                <rect y="103.646" width="2.18202" height="21.8202" transform="rotate(-90 0 103.646)" fill="white" class="color_changed_fill" />
                <rect y="91.6445" width="2.18202" height="21.8202" transform="rotate(-90 0 91.6445)" fill="white" class="color_changed_fill" />
                <rect y="79.6436" width="2.18202" height="21.8202" transform="rotate(-90 0 79.6436)" fill="white" class="color_changed_fill" />
                <rect y="67.6421" width="2.18202" height="21.8202" transform="rotate(-90 0 67.6421)" fill="white" class="color_changed_fill" />
                <rect y="55.6411" width="2.18202" height="21.8202" transform="rotate(-90 0 55.6411)" fill="white" class="color_changed_fill" />
                <rect y="43.6406" width="2.18202" height="21.8202" transform="rotate(-90 0 43.6406)" fill="white" class="color_changed_fill" />
                <rect y="31.6392" width="2.18202" height="21.8202" transform="rotate(-90 0 31.6392)" fill="white" class="color_changed_fill" />
                <rect y="19.6382" width="2.18202" height="21.8202" transform="rotate(-90 0 19.6382)" fill="white" class="color_changed_fill" />
                <rect y="7.63672" width="2.18202" height="21.8202" transform="rotate(-90 0 7.63672)" fill="white" class="color_changed_fill" />
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "Stairs",
        props: {
        }
    }
</script>

<style lang="scss" scoped>

</style>