<template>
    <div class="w-[97%] mx-auto flex justify-between pt-[20px]">
        <div class="w-[50%]">
            <table class="w-[100%] mt-[24px]">
                <thead class="">
                    <tr class="text-[#C59014] border-b-[1px] border-[#C59014] border-opacity-10">
                        <th class="px-3 py-2 text-center font-medium text-sm w-[4%]">#</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[7%]">дата</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[25%]">звіт</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[9%]">документ</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[15%]">скаут</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[12%]">потенціал</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[20%]">середня оцінка</th>
                        <th class="px-3 py-2 text-center font-medium text-sm w-[6%]"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">1.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center text-[14px]">Звіт №1 від 28.07.2023</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            Д. Курпас
                        </td>
                        <td class="text-[12px] text-center">
                            (A)  Excellent A/P

                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">2.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center text-[14px]">Звіт №1 від 28.07.2023</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            Д. Курпас
                        </td>
                        <td class="text-[12px] text-center">
                            (A)  Excellent A/P

                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                    <tr class="text-white h-[62px] border-b-[1px] border-[#8C8C8C] border-opacity-10">
                        <td class="text-center truncate">3.</td>
                        <td class="text-[11px] text-center">
                            <div>12.11.2022<br>11:00</div>
                        </td>
                        <td class="text-center text-[14px]">Звіт №1 від 28.07.2023</td>
                        <td class="flex justify-center items-center h-[62px]">
                            <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.50195 15H9.50195M5.50195 10H13.502" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M5.00391 2.5C3.44811 2.54667 2.52052 2.71984 1.87868 3.36227C1 4.24177 1 5.6573 1 8.48836L1 14.9944C1 17.8255 1 19.241 1.87868 20.1205C2.75736 21 4.17157 21 7 21L12 21C14.8284 21 16.2426 21 17.1213 20.1205C18 19.241 18 17.8255 18 14.9944V8.48836C18 5.6573 18 4.24177 17.1213 3.36228C16.4795 2.71984 15.5519 2.54667 13.9961 2.5" stroke="#BD9135" stroke-width="1.5"/>
                            <path d="M5 2.75C5 1.7835 5.7835 1 6.75 1H12.25C13.2165 1 14 1.7835 14 2.75C14 3.7165 13.2165 4.5 12.25 4.5H6.75C5.7835 4.5 5 3.7165 5 2.75Z" stroke="#BD9135" stroke-width="1.5" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td class="text-[12px] text-center">
                            Д. Курпас
                        </td>
                        <td class="text-[12px] text-center">
                            (A)  Excellent A/P

                        </td>
                        <td class="flex justify-center">
                            <svg width="77" height="15" viewBox="0 0 77 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z" fill="#C59014"/>
                            <path d="M22.9805 0L24.6909 5.10709L30.0766 5.15559L25.748 8.36044L27.3661 13.4975L22.9805 10.3711L18.5949 13.4975L20.2131 8.36044L15.8845 5.15559L21.2701 5.10709L22.9805 0Z" fill="#C59014"/>
                            <path d="M38.5001 0L40.2104 5.10709L45.5961 5.15559L41.2675 8.36044L42.8857 13.4975L38.5001 10.3711L34.1145 13.4975L35.7326 8.36044L31.404 5.15559L36.7897 5.10709L38.5001 0Z" fill="#C59014"/>
                            <path d="M54.0193 0L55.7297 5.10709L61.1154 5.15559L56.7868 8.36044L58.405 13.4975L54.0193 10.3711L49.6337 13.4975L51.2519 8.36044L46.9233 5.15559L52.309 5.10709L54.0193 0Z" fill="#C59014"/>
                            <path d="M69.5389 0L71.2493 5.10709L76.6349 5.15559L72.3063 8.36044L73.9245 13.4975L69.5389 10.3711L65.1533 13.4975L66.7714 8.36044L62.4428 5.15559L67.8285 5.10709L69.5389 0Z" fill="#474849"/>
                            </svg>

                        </td>
                        <td class="">
                            <div class="flex justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="w-[30%]">
            <table class="w-full">
                <thead>
                    <tr class="text-[#C59014] border-b-[1px] border-[#C59014] border-opacity-10">
                        <th class="py-2 pl-[4px] text-start font-medium text-sm w-[51.6%]"></th>
                        <th class="py-2 pl-[12px] text-start font-medium text-sm w-[7.6%]">1</th>
                        <th class="py-2 pl-[12px] text-start font-medium text-sm w-[7.6%]">2</th>
                        <th class="py-2 pl-[12px] text-start font-medium text-sm w-[7.6%]">3</th>
                        <th class="py-2 pl-[12px] text-start font-medium text-sm w-[7.6%]">4</th>
                        <th class="py-2 pl-[12px] text-start font-medium text-sm w-[7.6%]">5</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">IQ</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="iq" value="1" v-model="iqValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="iq" value="2" v-model="iqValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="iq" value="3" v-model="iqValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="iq" value="4" v-model="iqValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="iq" value="5" v-model="iqValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">техніка</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="technic" value="1" v-model="technicValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="technic" value="2" v-model="technicValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="technic" value="3" v-model="technicValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="technic" value="4" v-model="technicValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="technic" value="5" v-model="technicValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">атака</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="attack" value="1" v-model="attackValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="attack" value="2" v-model="attackValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="attack" value="3" v-model="attackValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="attack" value="4" v-model="attackValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="attack" value="5" v-model="attackValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">бачення</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="vision" value="1" v-model="visionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="vision" value="2" v-model="visionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="vision" value="3" v-model="visionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="vision" value="4" v-model="visionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="vision" value="5" v-model="visionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">швидкість</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="speed" value="1" v-model="speedValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="speed" value="2" v-model="speedValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="speed" value="3" v-model="speedValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="speed" value="4" v-model="speedValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="speed" value="5" v-model="speedValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">фізика</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="physics" value="1" v-model="physicsValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="physics" value="2" v-model="physicsValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="physics" value="3" v-model="physicsValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="physics" value="4" v-model="physicsValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="physics" value="5" v-model="physicsValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                    <tr class="text-white h-[30px]">
                        <td class="text-start">захист</td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="protection" value="1" v-model="protectionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="protection" value="2" v-model="protectionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="protection" value="3" v-model="protectionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="protection" value="4" v-model="protectionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="radio">
                                <input type="radio" name="protection" value="5" v-model="protectionValue">
                                <span class="hidden"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="flex flex-col items-center">
                <div class="text-[#FFFFFF] font-bold text-center mt-[22px] mb-[8px] text-[13px]">Аналіз граця</div>
                <div>
                    <RadarChart :key="chartKey" :chart-data="chartData" :chart-size="{ width: '250px', height: '220px' }"/>
                </div>
                <div class="lowercase text-white text-[12px] mt-[10px]">ДАТА ОСТАННЬОЇ ЗМІНИ 06.08.2023 (17:00)</div>
            </div>

            <div class="w-[100%] mb-[15px] mt-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">оціночна вартість</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
    
                    type="text"
                    placeholder="120 000"
                >
            </div>

            <div class="w-[100%] mb-[15px]">
                <div class="text-[#BD9135] text-[14px] font-bold">вартість покупки</div>
                <input 
                    class="border-[#474849] bg-[#47484966] outline-none text-white border-[1px] text-[18px] w-[100%] py-[3px] px-[5px] rounded-[6px]" 
    
                    type="text"
                    placeholder="90 000"
                >
            </div>
        </div>

        
    </div>
</template>

<script>
import { ref, reactive, watch } from 'vue'
import { uuid } from 'vue3-uuid'
import RadarChart from '../components/RadarChart.vue'

export default {
    name: 'scout-tab',   
    
    setup() {
        const chartKey = ref(uuid.v4())
        const chartData = ref([0, 0, 0, 0, 0, 0])

        const iqValue = ref(0)
        const technicValue = ref(0)
        const attackValue = ref(0)
        const visionValue = ref(0)
        const speedValue = ref(0)
        const physicsValue = ref(0)
        const protectionValue = ref(0)

        watch(iqValue, (newVal) => {
            chartData.value[0] = +newVal
            console.log(chartData.value)
            chartKey.value = uuid.v4()
        })

        watch(technicValue, (newVal) => {
            chartData.value[1] = +newVal
            chartKey.value = uuid.v4()
        })

        watch(attackValue, (newVal) => {
            chartData.value[2] = +newVal
            chartKey.value = uuid.v4()
        })

        watch(visionValue, (newVal) => {
            chartData.value[3] = +newVal
            chartKey.value = uuid.v4()
        })

        watch(speedValue, (newVal) => {
            chartData.value[4] = +newVal
            chartKey.value = uuid.v4()
        })

        watch(physicsValue, (newVal) => {
            chartData.value[5] = +newVal
            chartKey.value = uuid.v4()
        })

        watch(protectionValue, (newVal) => {
            chartData.value[6] = +newVal
            chartKey.value = uuid.v4()
        })

        return {
            chartData,
            chartKey,
            iqValue,
            technicValue,
            attackValue,
            visionValue,
            speedValue,
            physicsValue,
            protectionValue
        }
    },

    components: {
        RadarChart
    }
}
</script>

<style scoped lang="scss">
.radio{
  display: block;
  cursor: pointer; 
  user-select:none;
  text-align: left;
  &+.radio{
    margin-top: 0;
  }
  input{
    display: none;
    &+span{
      display: inline-block; 
      position: relative;
      padding-left: 0;
      &:before{
        content: '';
        display: block; 
        position: absolute;
        top: -14px;
        left: 8px;
        border-radius: 50%;
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border: 1px solid #474849;
        background: #474849;
      }
      &:after{
        content: '';
        display: block; 
        width: 10px;
        height: 10px;
        background: #BD9135;
        position: absolute;
        border-radius: 50%;
        top: -11px;
        left: 11px;
        opacity: 0;
        transform: scale(0,0); 
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked+span:after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}
</style>