<template>
  <section class="analytics">
    <div class="analytics__container">
      <div class="analytics__header left-chart-block__header">
        <h2 class="left-chart-block__title">{{data.title}}</h2>
        <label class="left-chart-block__select">
          <select v-model="selectedYear">
            <option v-for="yearObj in data.data" :key="yearObj.year" :value="yearObj.year">
              {{ yearObj.year }}
            </option>
          </select>

        </label>
      </div>
      <div class="analytics__body">
        <table class="analytics__table table-analytics">
          <tr class="table-analytics-head">
            <th>фото</th>
            <th>тренер</th>
            <th>груп</th>
            <th>гравців</th>
            <th>динаміка к-ті проведених тренувань</th>
            <th>
                    дохідність по тренерах
                    <!-- <span>
                      <div class="analytics__switch switch">
                        <p class="switch__text--2">гроші</p>
                        <div class="switch__2">
                          <input id="switch-2" type="checkbox" />
                          <label for="switch-2"></label>
                        </div>
                        <p class="switch__text--2">списання</p>
                      </div>
                    </span> -->
            </th>
          </tr>
          <AnalyticsComponentElement :data="coach" v-for="(coach, index) in activeYearData" :key="index"/>
        </table>
      </div>
    </div>
  </section>

</template>

<script>
import {
  computed,
  watch,
  toRefs,
  ref
} from 'vue';

import AnalyticsComponentElement from "./AnalyticsComponentElement.vue";

export default {
  name: "AnalyticsComponent",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    AnalyticsComponentElement,
  },
  setup(props) {
    const { data } = toRefs(props);
    const selectedYear = ref(data.value.data.find(year => year.isActive)?.year || data.value.data[0].year);

    const activeYearData = computed(() => {
      return data.value.data.find(yearObj => yearObj.year === selectedYear.value)?.yearData || [];
    });

    watch(selectedYear, newValue => {
      data.value.data.forEach(yearObj => {
        yearObj.isActive = yearObj.year === newValue;
      });
    });

    return { selectedYear, activeYearData };
  }

};


</script>

