<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] w-[400px] h-[500px] rounded-[10px] pt-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="bg-[#BD9135] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            СТАТИСТИКА
        </div>
        <MinLineChart v-if="chartFlag" class="ml-[20px] mt-[20px]" :data="completedTrainingsByMothes"/>
        <div class="text-gray-300 text-[12px] text-center mt-[15px] font-light">
            графік проведених тренувань
        </div>
        <div class="mt-[20px] px-[20px]">
            <div class="text-[#BD9135] text-center">Проведені тренування по тижням</div>
            <div class="h-[300px] scrollbar overflow-y-auto mt-[10px] px-[10px]">
                <div class="flex justify-between" v-for="(week, id) in completedTrainingsByWeeks">
                    <div class="text-white">{{ weeksLabels[id - 1] }}</div>
                    <div v-if="weeksLabels[id - 1]" class="text-[#BD9135] font-bold">{{ week }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getRealIncomesByBalanceIdRequest, getTransactionsByBalanceIdRequest, getTrainerCompletedTrainingsByYear, getTrainerCompletedTrainingsByWeeksInYear } from '@/services/apiRequests'
    import MinLineChart from './MinLineChart.vue'

    function generateWeeksArray(startDate, endDate) {
        let weeksArray = [];
        
        // Преобразование дат в объекты Date
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        
        // Проверка, что startDate и endDate являются датами и startDate <= endDate
        if (Object.prototype.toString.call(startDate) === "[object Date]" && !isNaN(startDate) &&
            Object.prototype.toString.call(endDate) === "[object Date]" && !isNaN(endDate) &&
            startDate <= endDate) {

            // Начальная дата текущей недели
            let currentWeekStart = new Date(startDate);
            currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay()); // Приведение к началу текущей недели
            
            // Пока текущая дата начала недели не превышает конечную дату
            while (currentWeekStart <= endDate) {
                let nextWeekStart = new Date(currentWeekStart);
                nextWeekStart.setDate(nextWeekStart.getDate() + 7); // Переход к началу следующей недели
                nextWeekStart.setDate(nextWeekStart.getDate() - 1); // Возвращение к концу текущей недели

                // Форматирование начальной и конечной дат текущей недели в требуемый формат
                let weekRange = `${currentWeekStart.getDate()} ${getMonthName(currentWeekStart.getMonth())} - ${nextWeekStart.getDate()} ${getMonthName(nextWeekStart.getMonth())}`;
                weeksArray.push(weekRange);

                // Переход к следующей неделе
                currentWeekStart = nextWeekStart;
                currentWeekStart.setDate(currentWeekStart.getDate() + 1);
            }
        }
        
        return weeksArray;
    }

    function getMonthName(monthNumber) {
        const months = ['січ.', 'лют.', 'бер.', 'квіт.', 'трав.', 'черв.', 'лип.', 'серп.', 'вер.', 'жовт.', 'лист.', 'груд.'];
        return months[monthNumber];
    }

    export default {
        name: 'FinanceCard',

        props: {
            studentBalanceState: {
                type: Number,
                default: 0
            },
            serviceBalanceState: {
                type: Number,
                default: 0
            },
            studentBalanceId: {
                type: String,
                default: ''
            },
            serviceBalanceId: {
                type: String,
                default: ''
            },
            trainerId: {
                type: String,
                default: ''
            }
        },

        setup(props) {
            const allTrainersIncomes = ref([])
            const allTrainerTransactions = ref([])

            const completedTrainingsByMothes = ref([])
            const completedTrainingsByWeeks = ref([])
            const chartFlag = ref(false)
            const weeksLabels = ref([])

            onMounted(async () => {
                console.log('props.studentBalanceId ----- ', props.studentBalanceId)
                allTrainersIncomes.value = (await getRealIncomesByBalanceIdRequest(props.studentBalanceId)).data.real_incomes
                console.log('allTrainersIncomes.value ----- ', allTrainersIncomes.value)

                const resStudentBalance = await getTransactionsByBalanceIdRequest(props.studentBalanceId)
                const resServiceBalance = await getTransactionsByBalanceIdRequest(props.serviceBalanceState)
                allTrainerTransactions.value = [...resStudentBalance.data.transactions, ...resServiceBalance.data.transactions]

                completedTrainingsByMothes.value = (await getTrainerCompletedTrainingsByYear(new Date().getFullYear(), props.trainerId)).data
                console.log('completedTrainingsByMothes.value ----- ', completedTrainingsByMothes.value)

                completedTrainingsByWeeks.value = (await getTrainerCompletedTrainingsByWeeksInYear(new Date().getFullYear(), props.trainerId)).data.reverse()
                console.log('completedTrainingsByWeeks.value ----- ', completedTrainingsByWeeks.value)

                weeksLabels.value = generateWeeksArray('2024-01-01', '2024-12-31');
                weeksLabels.value = weeksLabels.value.slice(0, completedTrainingsByWeeks.value.length).reverse()

                console.log('weeksLabels.value ----- ', weeksLabels.value)
                chartFlag.value = true
            })

            const getStudentName = (transaction_label) => {
                const transactionLabelArray = transaction_label.split(' ')
                return `${transactionLabelArray[transactionLabelArray.length - 1]} ${transactionLabelArray[transactionLabelArray.length - 2]}`
            }

            const formateDate = (date) => {
                const dateArray = date.split('T')[0].split('-')
                return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`
            }
            
            return { 
                allTrainersIncomes,
                allTrainerTransactions,
                getStudentName,
                formateDate,
                completedTrainingsByMothes,
                chartFlag,
                weeksLabels,
                completedTrainingsByWeeks
            }
        },

        components: {
            MinLineChart
        }
    }
</script>

<style lang="scss" scoped>
  .scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
  }

  .scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #696969;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
  }
</style>