<template>
  <tr class="table-analytics__data">
            <td data-cell="фото">
              <AvatarContainer :src="require('@/assets/student_photo_placeholder.svg')" class="flex justify-center" :small="true"  />
            </td>
            <td data-cell="Тренер">{{data.coachsName}}</td>
            <td data-cell="Груп">{{data.numberOfGroups}}</td>
            <td data-cell="Гравців">{{data.numberOfPlayers}}</td>
            <td data-cell="Динаміка к-ті проведених тренувань">
              <div ref="chartContainer" style="width: 90%; height: 100px;"></div>
            </td>
            <td data-cell="Дохідність по тренерах">
              <div ref="barCharContainer" style="width: 90%; height: 100px;"></div>
            </td>
          </tr>
</template>

<script>
import {onMounted, onUnmounted, ref, toRefs, watchEffect} from 'vue';
import * as echarts from "echarts";

export default {
  name: "AnalyticsComponentElement",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { data } = toRefs(props);
    const chartContainer = ref(null);
    const barCharContainer = ref(null);
    let myBarChart;
    let myChart;
    watchEffect(()=>{
      if (!barCharContainer.value) return;
      if (!myBarChart) {
        myBarChart = echarts.init(barCharContainer.value, "transparent", {renderer: "svg"});
      }
      const optionBar = {
        color: ["#BD9135"],
        xAxis: {
          type: 'category',
          show: false,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        legend: {
          show: false
        },
        series: [
          {
            name: "Сальдо",
            data: data.value.totalPerYear,
            type: 'bar',
            label: {
              show: true,
              fontSize:14,
              position: 'top',
              color: "#BD9135"
            },
          }
        ]
      };
      myBarChart.setOption(optionBar, true);

    })
    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const option = {
        color: ["#BD9135"],
        xAxis: {
          type: 'category',
          show: false,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
        legend: {
          show: false
        },
        series: [
          {
            name: "Сальдо",

            data: data.value.trainingsPerYear,
            type: 'line',
            symbol: "circle",
            barWidth: '60%',
            symbolSize: 8,
            label: {
              show: true,
              fontSize:14,
              position: 'top',
              color: "#BD9135"
            },
            lineStyle: {
              width: 5
            }
          }
        ]
      };


      myChart.setOption(option, true);
    });

    onMounted(() => {
      if (!chartContainer.value||!barCharContainer.value) return;
    });
    const updateChartSize = () => {
      if(myChart)myChart.resize();
      if(myBarChart)myBarChart.resize();
    };
    onMounted(() => {
      window.addEventListener('resize', updateChartSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
        myChart.dispose(); // Очищуємо графік при знищенні компонента
        myBarChart.dispose(); // Очищуємо графік при знищенні компонента
    });

    return { chartContainer,barCharContainer};
  }
};
</script>

