<template>
  <div ref="chart" style="width: 200px; height: 80px;"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    values: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  mounted() {
    console.log('this.values', this.values);
    // Создание экземпляра графика при монтировании компонента
    this.createChart();
  },
  methods: {
    createChart() {
      // Инициализация экземпляра ECharts на элементе div с помощью ref
      const chartDom = this.$refs.chart;
      const myChart = echarts.init(chartDom, null, { renderer: 'svg' });

      // Опции для графика
      const option = {
        xAxis: {
          type: 'category',
          data: this.values.map((_, index) => index), // Индексы значений по оси X
          show: true,
          axisLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)'
            }
          }
        },
        yAxis: {
          type: 'value',
          show: true,
          axisLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)'
            }
          },
          splitLine: {
            lineStyle: {
              color: 'rgb(30, 30, 30)' // Цвет горизонтальных линий разметки
            }
          }
        },
        series: [{
          data: this.values,
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          lineStyle: {
            color: '#FFA500',
            width: 2
          },
          itemStyle: {
            color: '#FFA500'
          }
        }]
      };

      // Установка опций и отображение графика
      myChart.setOption(option);
    }
  }
};
</script>

<style>
/* Стили могут быть добавлены сюда */
</style>
