<template>
    <div class="input-upload">
        <label :for="name" class="input-upload__label">{{ label }}</label>
        <div class="input-upload__row">
            <div class="input-upload__field">
                <input :value="value" @change="e => $emit('update:modelValue', e.target.value)" :id="name" :name="name" :placeholder="placeholder" :type="inputType" />
            </div>
            <div class="input-upload__icons">
                <img @click="$emit('upload')" :src="share_icon"/>
                <img @click="$emit('view')" :src="file_icon"/>
            </div>
        </div>
    </div>
</template>

<script>
import share_icon from '@/assets/refactored_assets/img/players/share.svg'
import file_icon from '@/assets/refactored_assets/img/menu/file_red.svg'

export default {
    name: 'InputUpload',
    props: {
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        hidden: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            file_icon: file_icon,
            share_icon: share_icon
        }
    },
}
</script>