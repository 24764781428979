<template>
  <div :class="textNormal ? 'currency-component--text-normal' : 'currency-component'">
    {{ value.toLocaleString('uk-UA', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
    <span :class="smallSign ?'currency-component__sign--small' : 'currency-component__sign'">{{ currency }}</span>
  </div>
</template>

<script>
export default {
  name: "CurrencyComponent",
  props: {
    value: {
      type: Number,
      required: true
    },
    currency: {
      type: String,
      default: 'грн'
    },
    smallSign:{
      type: Boolean,
      required:false
    },
    textNormal: {
      type: Boolean,
      required:false,
    }
  }
}
</script>
