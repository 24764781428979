<template>
    <!-- <div class="bg-[#252526] fixed w-full h-full">
        <div class="login-form">
            <div class="w-[125px] select-none">
                <img src="@/assets/logo.svg">
            </div>
            <div>
                <input 
                    :class="{
                        'login-form-input-error' : v$.adminLogin.$error,
                        'login-form-input' : !v$.adminLogin.$error
                        }" 
                    @blur="v$.adminLogin.$touch" 
                    v-model="authData.adminLogin" 
                    placeholder="Логін"
                >
            </div>
            <div>
                <input 
                    :class="{
                        'login-form-input-error' : v$.adminPassword.$error,
                        'login-form-input' : !v$.adminPassword.$error
                        }" 
                    @blur="v$.adminPassword.$touch" 
                    v-model="authData.adminPassword" 
                    placeholder="Пароль"
                    type="password"
                >
            </div>
            <div>
                <button @click="processAuthorization" class="login-form-button select-none">Авторизуватись</button>
            </div>
        </div>
    </div> -->

    <!-- <div class="absolute bg-[#252526] w-full h-full">
        <img src="@/assets/login_bg.svg" class="absolute w-full">
    </div> -->
    <div class="fixed h-full w-full photo-background">
        <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/assets/sportkit_logo.svg" alt="">
        </div>
        <div class="mt-[84px] flex flex-col align-middle items-center justify-center w-full w-[400px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            <div class="flex justify-center">
                <img src="@/assets/logo.svg" class="logo w-[100px]" >
            </div>
            <div class="flex justify-center w-10/12 text-white text-[21px] mb-[18px] mt-[45px]">
                Hello admin!
            </div>
            <div 
                :class="{
                        'login-form-input-error' : v$.adminLogin.$error,
                        'login-form-input' : !v$.adminLogin.$error
                        }" 
            >
                <input 
                    v-model="authData.adminLogin" 
                    type="text" 
                    @blur="v$.adminLogin.$touch"  
                    @focus="removePlaceholderLogin" 
                    id="loginInput" 
                    class="input-text bg-transparent text-start placeholder:text-center rounded-[9px] outline-none text-[#FFF] font-medium placeholder:font-medium pl-[10px] w-full placeholder-[#8C8C8C] placeholder:text-[13px] placeholder:relative pr-[20px]" 
                    placeholder="логін"
                >
            </div>
            <div 
                :class="{
                        'login-form-input-error' : v$.adminPassword.$error,
                        'login-form-input' : !v$.adminPassword.$error
                        }"    
            >
                <input 
                    v-model="authData.adminPassword" 
                    type="password" 
                    @blur="v$.adminPassword.$touch"  
                    @focus="removePlaceholderPassword" 
                    id="passwordInput" 
                    class="input-text bg-transparent placeholder:text-center text-start rounded-[9px] outline-none text-[#FFF] font-medium placeholder:font-medium w-full pl-[10px] placeholder-[#8C8C8C] placeholder:relative placeholder:text-[13px]" 
                    placeholder="пароль"
                >
            </div>
            <div class="flex justify-center w-[90%] mt-[10px] mb-[33px]">
                <button @click="processAuthorization" class="bg-[#BD9135] w-full border-[1px] border-[#BD9135] rounded-[9px] items-center px-[13px] flex h-[44px] justify-center mb-[16px]">
                    <div class="w-full text-[20px] text-white font-extrabold">Log in</div>
                </button>
            </div>
            <div class="">
                <!-- <div class="text-[#FFF] text-[12px] text-center lg:mx-0 w-[355px] mb-[35px] mx-10">
                    Привіт!<br>Це віртуальний кабінет для гравців ФК Минай, якщо у тебе виникли проблеми з доступом, звернись до свого тренера
                </div> -->
            </div>
        </div>
        <div class="text-[#fff] text-[11px] absolute bottom-[30px] opacity-50 text-center w-full">
            © 2023 ALL RIGHTS RESERVED
        </div>
    </div>
</template>

<script>
    import { reactive, onMounted } from 'vue'
    import { getTokens, scoutGetTokens, getScoutByTokens } from '@/services/apiRequests'
    import { useRouter } from 'vue-router'
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    export default {
        setup() {
            const router = useRouter()

            const authData = reactive({
                adminLogin: '',
                adminPassword: ''
            })

            const rules = {
                adminLogin: { required },
                adminPassword: { required }
            }

            const v$ = useVuelidate(rules, authData)

            const processAuthorization = async () => {
                const adminTokensSetFlag = await getTokens(authData.adminLogin, authData.adminPassword)
                const scoutTokensSetFlag = await scoutGetTokens(authData.adminLogin, authData.adminPassword)
                    
                if (adminTokensSetFlag) {
                    localStorage.adminLogin = authData.adminLogin
                    localStorage.adminPassword = authData.adminPassword
                    localStorage.userType = 'admin'
                    router.go()
                } else if (scoutTokensSetFlag) {
                    const currentScout = await getScoutByTokens()
                    localStorage.adminLogin = authData.adminLogin
                    localStorage.adminPassword = authData.adminPassword
                    console.log('currentScout --- ', currentScout)
                    localStorage.userType = currentScout.job_title
                    router.go()
                } else {
                    authData.adminLogin = ''
                    authData.adminPassword = ''
                }
            }

            onMounted(() => {
                document.addEventListener('keydown', async function(event) {
                    if (event.key === 'Enter') {
                        await processAuthorization()
                    }
                })
            })

            return {
                v$,
                authData,
                processAuthorization,
            }
        }
    }
</script>

<style lang="scss">
    .login-form {
        @apply flex flex-col items-center align-middle justify-center absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%];
    }

    .login-form-input {
        @apply bg-[#474849] bg-opacity-40 rounded-[9px] items-center px-[13px] border-[1px] border-[#BD9135] flex h-[44px] justify-center w-[90%] mb-[16px];
    }

    .login-form-input-error {
        @apply bg-[#FA1367] bg-opacity-20 rounded-[9px] items-center px-[13px] border-[1px] border-[#FA1367] flex h-[44px] justify-center w-[90%] mb-[16px];
    }

    .login-form-button {
        @apply px-2 py-1 w-[250px] rounded-md bg-[#DA4167] text-white font-semibold;
    }

    .photo-background {
        background: url(@/assets/login_bg.svg) no-repeat center center fixed;
        background-size: cover;
    }
</style>