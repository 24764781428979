<template>
  <div class="stars-component">
    <svg v-for="(star, index) in stars" :key="index" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient :id="'grad-' + index" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop :offset="star.percentage + '%'" style="stop-color:#BD9135;stop-opacity:1" />
          <stop :offset="star.percentage + '%'" style="stop-color:#474849;stop-opacity:1" />
        </linearGradient>
      </defs>
      <path :fill="star.fill" d="M7.46124 0L9.17163 5.10709L14.5573 5.15559L10.2287 8.36044L11.8468 13.4975L7.46124 10.3711L3.07563 13.4975L4.69378 8.36044L0.365179 5.15559L5.75085 5.10709L7.46124 0Z"/>
    </svg>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { defineProps } from 'vue';

const props = defineProps({
  rating: {
    type: Number,
    required: true,
    default: 0
  }
});

const { rating } = toRefs(props);

const stars = computed(() => {
  const fullStars = Math.floor(rating.value / 2);
  const remaining = (rating.value / 2) - fullStars;
  const starArray = [];

  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      starArray.push({ fill: '#BD9135', percentage: 100 }); // Полностью желтая звезда
    } else if (i === fullStars && remaining > 0) {
      starArray.push({ fill: 'url(#grad-' + i + ')', percentage: remaining * 100 }); // Частично заполненная звезда
    } else {
      starArray.push({ fill: '#474849', percentage: 0 }); // Серый цвет
    }
  }
  return starArray;
});
</script>

<style scoped>
.stars-component {
  display: flex;
}
</style>
