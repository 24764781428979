<template>
    <div @click="$emit('close')" style="background-color: rgba(171, 211, 236, .25)" class="backdrop-blur-[2px] fixed w-full h-full mt-[-10px]"></div>
    <div class="bg-[#BD9135] ml-[60px] mb-[10px] w-[260px] px-4 py-3 rounded-[10px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="bg-[#E5E5E5] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <input v-model="locationName" class="bg-transparent outline-none border-none" placeholder="Назва локації" type="text">
        </div>
        <div class="bg-[#E5E5E5] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <textarea v-model="locationAddress" class="w-full bg-transparent outline-none border-none resize-none" rows="2" placeholder="Адреса"></textarea>
        </div>
        <div @click="sendToMap" class="bg-[#252526] cursor-pointer text-white px-[6px] py-[5px] rounded-[10px] mb-[14px] flex justify-between items-center">
            <div>
                Перейти на карту
            </div>
            <div>
                <img class="w-[15px]" src="@/assets/icons/link_icon.svg">
            </div>
        </div>
        <div class="bg-[#E5E5E5] px-[6px] py-[5px] rounded-[10px] mb-[14px]">
            <textarea v-model="locationDescription" class="w-full bg-transparent outline-none border-none resize-none" rows="2" placeholder="Додатковий опис об'єкта"></textarea>
        </div>
        <div>
            <div class="flex justify-between mb-[4px]">
                <div>
                    Фото об'єкта
                </div>
                <div class="flex">
                    <img @click="selectLocationPhoto" class="mr-[7px] w-[15px] cursor-pointer" src="@/assets/icons/upload_icon.svg">
                    <input class="hidden" type="file">
                    <img @click="deleteLocationPhoto" class="w-[15px] cursor-pointer" src="@/assets/icons/trash_icon.svg">
                </div>
            </div>
            <div class="w-full h-[132px] border-[2px] border-[#252526] rounded-[10px]">
                <img v-if="locationPhoto" :src="locationPhotoDataUrl" class="w-full h-full object-cover rounded-[10px]">
            </div>
        </div>
        <div @click="addNewLocationEvent" class="w-full bg-[#252526] text-white py-1 text-center text-[13px] mt-[10px] cursor-pointer select-none mx-auto rounded-md">
            Додати локацію
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { addNewLocation } from '@/services/apiRequests'

    export default {
        name: 'new-location-form',

        setup() {
            const locationName = ref('')
            const locationAddress = ref('')
            const locationMapMark = ref('')
            const locationDescription = ref('')

            const locationPhoto = ref(null)
            const locationPhotoDataUrl = ref(null)

            const selectLocationPhoto = () => {
                const input = document.querySelector('input[type="file"]')
                input.click()
                input.addEventListener('change', () => {
                    locationPhoto.value = input.files[0]
                    locationPhotoDataUrl.value = URL.createObjectURL(input.files[0])
                })
            }

            const deleteLocationPhoto = () => {
                locationPhoto.value = null
                locationPhotoDataUrl.value = null
            }

            const sendToMap = () => {
                const mapQuery = locationAddress.value.split(' ').join('%20')
                window.open(`https://www.openstreetmap.org/search?query=${mapQuery}`, '_blank')
            }

            const addNewLocationEvent = () => {
                addNewLocation(
                    locationName.value, 
                    locationAddress.value, 
                    locationDescription.value,
                    locationPhoto.value
                ).then(res => {
                    console.log(res)
                })
            }

            return {
                locationName,
                locationAddress,
                locationMapMark,
                locationDescription,
                locationPhoto,
                locationPhotoDataUrl,
                selectLocationPhoto,
                deleteLocationPhoto,
                sendToMap,
                addNewLocationEvent
            }
        }
    }
</script>
