<template>
    <div>
        <div class="px-[60px]">
        <div class="flex justify-end pr-[12px]">
            <div v-if="!loading" class="rounded-[5px] flex justify-between bg-[#8FC408] font-semibold pb-[2px] pt-[7px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Всі зміни збережено
                </div>
                <div>
                    ✓
                </div>
            </div>
            <div v-if="loading" class="rounded-[5px] flex justify-between bg-[#DA4167] font-semibold py-[2px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Збереження...
                </div>
            </div>  
        </div>

        <TableWrapper>
            <TableComponent >
                <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell class="text-start" minWidth="200px" value="Назва нормативу" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="" />
                </TableHeaderRow>
                </TableHeader>
                <TableBody>

                    <TableRow v-for="standard in allStandards" :key="standard.id">
                        <TableCell textAlign="start" :asChild="true">
                            <input v-if="!standard.updating" :value="standard.name" type="text" placeholder="Назва нормативу">
                            <input v-else v-model="standard.name" type="text" placeholder="Назва нормативу">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            
                            <div class="flex justify-end gap-[10px]">
                                <img v-if="!standard.updating" @click="standard.updating = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img v-else @click="updateStandard(standard.id, standard.name)" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img @click="deleteStandard(standard.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            </div>

                        </TableCell>
                    </TableRow>
                    
                    <TableRow>
                        <TableCell textAlign="start" :asChild="true">
                            <input v-model="newStandard" type="text" placeholder="Назва нормативу">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            
                            <div class="flex justify-end gap-[10px]">
                                <img @click="addStandard" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img @click="clearNewStandard" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            </div>

                        </TableCell>
                    </TableRow>

                </TableBody>
            </TableComponent>
        </TableWrapper>
    </div>
    </div>
</template>
<script>
import { ref, onMounted, watch } from 'vue'
import { addStandardRequest, getAllStandardsRequest, updateStandardById, deleteStandardById } from '@/services/apiRequests'

export default {
    name: 'StandardConstructor',
    setup(props, { emit }) {
        const newStandard = ref('')

        const allStandards = ref([])

        const loading = ref(false)

        const addStandard = async () => {
            loading.value = true
            await addStandardRequest({ name: newStandard.value })
            await getAllStandards()
            newStandard.value = ''
            loading.value = false
        }

        const clearNewStandard = () => {
            newStandard.value = ''
        }

        const getAllStandards = async () => {
            allStandards.value = await getAllStandardsRequest()
            allStandards.value = allStandards.value.map(standard => {
                return {
                    ...standard,
                    updating: false
                }
            })
        }

        const updateStandard = async (id, name) => {
            loading.value = true
            await updateStandardById(id, { name })
            await getAllStandards()
            loading.value = false
        }

        const deleteStandard = async (id) => {
            loading.value = true
            await deleteStandardById(id)
            await getAllStandards()
            loading.value = false
        }

        onMounted(async () => {
            await getAllStandards()
        })

        return {
            newStandard,
            allStandards,
            loading,
            addStandard,
            clearNewStandard,
            updateStandard,
            deleteStandard
        }
    } 
}
</script>
<style>
    
</style>