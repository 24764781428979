<template>
    <TableRow>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <div class="flex items-center select-none">
                <div @click="closed = !closed" class="cursor-pointer">
                    <svg v-if="!closed" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 9L11.2929 14.2929C11.6262 14.6262 11.7929 14.7929 12 14.7929C12.2071 14.7929 12.3738 14.6262 12.7071 14.2929L18 9" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>    
                    </svg>
                    <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99991 15.0001L11.2928 9.70717C11.6261 9.37383 11.7928 9.20717 11.9999 9.20717C12.207 9.20717 12.3737 9.37383 12.707 9.70717L17.9999 15.0001" stroke="#BD9135" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div>
                    <div v-if="season.name && !isUpdateSeason">{{ season.name }}</div>
                    <input v-else v-model="newSeasonName" type="text" placeholder="Назва сезону">
                </div>
            </div>
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="50" textAlign="start" :asChild="true">
            
            <div class="flex justify-end gap-[10px]">
                <img v-if="!isUpdateSeason" @click="isUpdateSeason = true" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img v-if="isUpdateSeason" @click="updateSeasonName" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="$emit('deleteSeason', season.name)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>

        </TableCell>
    </TableRow>
    <TableRow v-if="closed" v-for="(team, index) in season.teams">
        <TableCell :width="200" textAlign="start" :asChild="true">
            
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <div v-if="changeOldTeamObject?.id != team.id ">
                {{ team.name }}
            </div>
            <input v-else :value="team.name" @change="e => $emit('changeTeamName', {leagueId: league.id, teamId: team.id, value: e.target.value})" class="text-[#339989] text-[16px] w-[100px]" type="text" placeholder="Назва команди1">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            
            <img v-if="team.logo && team.logo.split('/')[team.logo.split('/').length - 1] != 'null'" :src="team.logo" @click="addLogoToOldTeam(index)" alt="team logo" class="w-[20px] cursor-pointer" />
            <img v-else @click="addLogoToOldTeam(index)" src="@/assets/icons/upload_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            <input ref="addLogoInputRef" :id="'logoInput_' + index" type="file" @change="proccessOldTeamLogo(team.id, index, team.name)" class="hidden" />

        </TableCell>
        <TableCell :width="50" textAlign="start" :asChild="true" class="">
            
            <div class="flex justify-end gap-[10px]">
                <img v-if="changeOldTeamObject?.id != team.id" @click="changeOldTeamObject = team" src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img v-if="changeOldTeamObject?.id == team.id" @click="changeOldTeamObject = null" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <img @click="$emit('deleteTeam', team.id)" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>

        </TableCell>
    </TableRow>
    <TableRow v-if="closed">
        <TableCell :width="200" textAlign="start" :asChild="true">
            
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            <input v-model="newTeamName" type="text" placeholder="Назва команди">
        </TableCell>
        <TableCell :width="200" textAlign="start" :asChild="true">
            
            <img v-if="newTeamLogo" :src="newTeamLogo" alt="team logo" class="w-[20px] cursor-pointer" />
            <img v-else @click="addLogoToTeam" src="@/assets/icons/upload_icon.svg" class="w-[20px] cursor-pointer" />
            <input ref="newTeamLogoAdd" type="file" @change="proccessNewTeamLogo" class="hidden" />

        </TableCell>
        <TableCell :width="50" textAlign="start"  :asChild="true" class="">
            
            <div class="flex justify-end gap-[10px]">
                <img @click="addNewTeam" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                <!-- <img src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px]" /> -->
                <img @click="deleteNewTeam" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
            </div>

        </TableCell>
    </TableRow>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'SeasonBlock',
    props: {
        season: {
            type: Object,
            required: true
        },
        league: {
            type: Object,
            required: true
        }
    },

    setup(props, { emit }) {
        const newTeamName = ref('');
        const newTeamLogo = ref(null);

        const closed = ref(false)

        const isUpdateSeason = ref(false)
        const newSeasonName = ref(props.season.name)

        const changeOldTeamFlag = ref(false)
        const changeOldTeamObject = ref(null)

        const updateSeasonName = () => {
            isUpdateSeason.value = false
            emit('updateSeasonName', {
                leagueId: props.league.id,
                oldName: props.season.name,
                newName: newSeasonName.value
            })
        }

        const addNewTeam = () => {
            emit('addNewTeam', {
                name: newTeamName.value,
                logo: newTeamLogo.value,
                logoObj: newTeamLogoObject.value,
                leagueId: props.league.id,
                season: props.season.name
            })
            newTeamName.value = ''
            newTeamLogo.value = null
        }

        const newTeamLogoAdd = ref(null)
        const newTeamLogoObject = ref(null)

        const proccessNewTeamLogo = () => {
            const file = newTeamLogoAdd.value.files[0]
            newTeamLogoObject.value = file
            const reader = new FileReader()
            reader.onload = () => {
                newTeamLogo.value = reader.result
            }
            reader.readAsDataURL(file)
        }

        const addLogoToTeam = () => {
            newTeamLogoAdd.value.click()
        }

        const addLogoToOldTeam = (index) => {
            const input = document.getElementById(`logoInput_${index}`)
            input.click()
        }

        const proccessOldTeamLogo = (teamId, index, name) => {
            const file = document.getElementById(`logoInput_${index}`).files[0]
            emit('changeOldTeamLogo', {
                    teamId,
                    logo: file,
                    name: name
                })
            // const reader = new FileReader()
            // reader.onload = () => {
            //     emit('changeOldTeamLogo', {
            //         teamId,
            //         logo: reader.result,
            //         name: name
            //     })
            // }
            // reader.readAsDataURL(file)
        }

        return {
            newTeamName,
            newTeamLogo,
            newTeamLogoAdd,
            newTeamLogoObject,
            addNewTeam,
            proccessNewTeamLogo,
            addLogoToTeam,
            closed,
            addLogoToOldTeam,
            proccessOldTeamLogo,
            isUpdateSeason,
            newSeasonName,
            updateSeasonName,
            changeOldTeamFlag,
            changeOldTeamObject
        }
    }
}
</script>

<style>
    
</style>
