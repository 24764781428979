<template>
  <div class="info-row">
    <span class="info-row__accent">{{ metric }}</span>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'InfoRow',
  props: {
    value: {
      type: String,
      required: true
    },
    metric: {
      type: String,
      required: true
    }
  }
}
</script>
