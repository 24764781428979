<template>
    <div ref="chart" style="width: 100%; height: 100%;"></div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
  import * as echarts from 'echarts';
  
  // Определение пропсов
  const props = defineProps({
    data1: {
      type: Array,
      required: true
    },
    data2: {
      type: Array,
      required: true
    },
    indicators: {
      type: Array,
      required: true
    },
    firstPlayerName: {
      type: String,
      required: true
    },
    secondPlayerName: {
      type: String,
      required: true
    }
  });
  
  const chart = ref(null);
  let chartInstance = null;
  
  const createChart = () => {
    if (!chartInstance) {
      chartInstance = echarts.init(chart.value);
    }
    
    chartInstance.setOption({
      tooltip: {},
      radar: {
        indicator: props.indicators,
        radius: '60%',
      },
      series: [
        {
          type: 'radar',
          data: [
            {
              value: props.data1.map((value) => value.toFixed(1)),
              name: props.firstPlayerName,
              areaStyle: {
                opacity: 0.5, // Прозрачность заливки
              }
            },
            {
              value: props.data2.map((value) => value.toFixed(1)),
              name: props.secondPlayerName,
              areaStyle: {
                opacity: 0.2, // Прозрачность заливки
              }
            },
          ],
        },
      ],
    });
  };
  
  onMounted(() => {
    createChart();
    window.addEventListener('resize', resizeChart);
  });
  
  onBeforeUnmount(() => {
    if (chartInstance) {
      chartInstance.dispose();
    }
    window.removeEventListener('resize', resizeChart);
  });
  
  // Watch для отслеживания изменений в пропсах и обновления графика
  watch([() => props.data1, () => props.data2, () => props.indicators], createChart);
  
  function resizeChart() {
    if (chartInstance) {
      chartInstance.resize();
    }
  }
  </script>
  
  <style scoped>
  /* Добавьте стили, если нужно */
  </style>
  