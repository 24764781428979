import axios from 'axios'

export async function getTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/admin_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)

    if (response.status == 200 && response.data.access_token) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        return false
    }
}

async function rewriteTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/admin_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('refresh_token')
        window.location.reload();
    } else if (response.status == 200 && response.data.access_token) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

export async function scoutGetTokens(login, password) {
    const session_url = process.env.VUE_APP_API_URL + 'auth/scout_auth'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            username: login,
            password: password
        }
    }

    const response = await axios(config)

    console.log('response.data', response.data)

    if (response.status == 200 && response.data.access_token) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
        return true
    } else {
        return false
    }
}

async function rewriteScoutTokensRequest() {
    const session_url = process.env.VUE_APP_API_URL + 'auth/scout_refresh'
    
    const config = {
        method: 'post',
        url: session_url,
        headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('refresh_token') }
    }

    let response = {}
    try {
        response = await axios(config)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('refresh_token')
        window.location.reload();
    } else if (response.status == 200 && response.data.access_token) {
        sessionStorage.setItem('access_token', response.data.access_token)
        sessionStorage.setItem('refresh_token', response.data.refresh_token)
    }
}

async function sendApiRequest(axiosConfig) {
    axiosConfig.headers = {
        'Authorization': 'Bearer ' + sessionStorage.getItem('access_token') 
    }
    let response = {}
    try {
        response = await axios(axiosConfig)
    } catch (error) {
        response.status = 422
    }

    if (response.status != 200) {
        await rewriteTokensRequest()
        response = await axios(axiosConfig)
        return response
    } else if (response.status == 200) {
        return response
    }
}

async function sendApiRequestReturnData(axiosConfig) {
    const response = await sendApiRequest(axiosConfig)
    return response.data
}

function formatDate(date) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

export async function getBlobFileByUrl(url) {
    const response = await fetch(url)
    return await response.blob()
}

export const getAllLocations = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_all_locations'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequest(config)
}

export const getAllGroups = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_all_groups'

    const config = {
      method: 'get',
      url: session_url,
    }
    
    return await sendApiRequest(config)
}

export const addNewTraining = async (groupName, groupId, locationName, locationId, startTime, endTime, date, trainingType, summary) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_new_training'
    let formattedDate = null

    if (typeof date === 'string') {
        formattedDate = (new Date(date)).toISOString()
    } else {
        formattedDate = (new Date(formatDate(date))).toISOString()
    }

    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: trainingType,
        summary: summary
    }

    const config = {
        method: 'post',
        url: session_url,
        data: data
    }
    
    return await sendApiRequest(config)
}

export const getAllTrainings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_all_trainings'
    const config = {
        method: 'get',
        url: session_url
    }
    return await sendApiRequest(config)
}

export const updateTraining = async (trainingId, groupName, groupId, locationName, locationId, startTime, endTime, date, type, description, summary) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_training_by_id'
    
    let formattedDate = null

    if (typeof date === 'string') {
        formattedDate = (new Date(date + ' GMT')).toISOString()
    } else {
        formattedDate = (new Date(formatDate(date) + ' GMT')).toISOString()
    }
    
    const data = {
        group_id: groupId,
        group_label: groupName,
        date: formattedDate,
        start_time: startTime,
        end_time: endTime,
        location_id: locationId,
        location_label: locationName,
        type: type,
        description: description,
        summary: summary
    }
    const config = {
        method: 'put',
        url: session_url + '?training_id=' + trainingId,
        data: data
    }
    return await sendApiRequest(config)
}

export const getLocationById = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/get_location_by_id'
    const config = {
        method: 'get',
        url: session_url + '?location_id=' + locationId,
        params: {
            location_id: locationId
        }
    }
    return await sendApiRequest(config)
}

export const getGroupById = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_group_by_id'
    const config = {
        method: 'get',
        url: session_url + '?group_id=' + groupId,
        params: {
            group_id: groupId
        }
    }
    return await sendApiRequest(config)
}

export const deleteTrainingById = async (trainingId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_training_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_id: trainingId
        }
    }

    return await sendApiRequest(config)
}

export const getAllTrainers = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_all_trainers'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

export const addNewGroup = async (groupName, groupYearRange, trainerId, paymentStatus) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_new_group'
    const config = {
        method: 'post',
        url: session_url,
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus
        }
    }
    return await sendApiRequest(config)
}

export const getTrainerById = async (trainerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_by_id'
    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainerId
        }
    }
    return await sendApiRequest(config)
}

export const getAllStudents = async (
        count = 100, 
        skip = 0, 
        balance_sort = null, 
        query = null, 
        startAgeRange = null, 
        endAgeRange = null, 
        status = ['active'], 
        amplua = null,
        team = null, 
        age = null,

        firstParameterMin = 0, 
        firstParameterMax = 10, 
        secondParameterMin = 0, 
        secondParameterMax = 10, 
        thirdParameterMin = 0, 
        thirdParameterMax = 10, 
        fourthParameterMin = 0, 
        fourthParameterMax = 10, 
        fifthParameterMin = 0, 
        fifthParameterMax = 10, 
        sixthParameterMin = 0, 
        sixthParameterMax = 10, 
        seventhParameterMin = 0, 
        seventhParameterMax = 10
    ) => {
    console.log('status -------- ', status)
    const session_url = process.env.VUE_APP_API_URL + 'student/get_all_students'
    const config = {
      method: 'post',
      url: session_url,

      data: {
            count: count,
            skip: skip,
            balance_sort: balance_sort,
            query: query,
            age_from: startAgeRange,
            age_to: endAgeRange,
            amplua: amplua,
            status: status,
            team: team == 'не вибрано' ? null : team,
            age: age == 'не вибрано' ? null : age,
            firstParameterMin: firstParameterMin,
            firstParameterMax: firstParameterMax,
            secondParameterMin: secondParameterMin,
            secondParameterMax:     secondParameterMax,
            thirdParameterMin:      thirdParameterMin,
            thirdParameterMax:      thirdParameterMax,
            fourthParameterMin:     fourthParameterMin,
            fourthParameterMax:     fourthParameterMax,
            fifthParameterMin:      fifthParameterMin,
            fifthParameterMax:      fifthParameterMax,
            sixthParameterMin:      sixthParameterMin,
            sixthParameterMax:      sixthParameterMax,
            seventhParameterMin:    seventhParameterMin,
            seventhParameterMax:    seventhParameterMax
      }
    }
    console.log('config -------- ', config)
    return await sendApiRequest(config)
}

export const addGroupToStudents = async (studentsIds, groupId, groupName) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/add_list_of_students_to_group'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            group_id: groupId,
            group_name: groupName
        },
        data: studentsIds
    }

    return await sendApiRequest(config)
}

export const getStudentsCountByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_number_of_students_by_group_id'
    
    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const updateGroupRequest = async (groupId, groupName, groupYearRange, trainerId, paymentStatus, cardFormat) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/update_group'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            group_id: groupId,
        },
        data: {
            name: groupName,
            year_range: groupYearRange,
            trainer_id: trainerId,
            payment_status: paymentStatus,
            student_card_type: cardFormat
        }
    }

    return await sendApiRequest(config)
}

export const deleteGroupRequest = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/delete_group'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            group_id: groupId,
        }
    }

    return await sendApiRequest(config)
}


export const addNewLocation = async (locationName, smallLocationName, locationAddress, locationDescription, geoUrl, locationPhoto) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/add_new_location'

    const formData = new FormData()
    formData.append('location', JSON.stringify({
        name: locationName,
        address: locationAddress,
        description: locationDescription,
        small_name: smallLocationName,
        geo_url: geoUrl
    }))
    formData.append('location_picture', locationPhoto)

    const config = {
        method: 'post',
        url: session_url,
        data: formData,
    }

    return await sendApiRequest(config)
}

export const updateLocation = async (locationId, locationName, smallLocationName, locationAddress, locationDescription, geoUrl, locationPhoto) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/update_location_by_id'

    const formData = new FormData()
    formData.append('location', JSON.stringify({
        name: locationName,
        address: locationAddress,
        description: locationDescription,
        small_name: smallLocationName,
        geo_url: geoUrl
    }))
    formData.append('location_picture', locationPhoto)

    const config = {
        method: 'put',
        url: session_url + '?location_id=' + locationId,
        data: formData
    }
    return await sendApiRequest(config)
}

export const deleteCurrentLocation = async (locationId) => {
    const session_url = process.env.VUE_APP_API_URL + 'location/delete_location_by_id'

    const config = {
        method: 'delete',
        url: session_url + '?location_id=' + locationId
    }

    return await sendApiRequest(config)
}

export const getAdmin = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'admin/get_admin'
    const config = {
      method: 'get',
      url: session_url
    }
    return await sendApiRequest(config)
}

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
  
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], {type: mimeString});
}
  
export const addNewStudent = async (new_student_obj, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_new_student'
    new_student_obj.birthday = new_student_obj.birthday.length == 0 ? new Date().toISOString() : new Date(new_student_obj.birthday).toISOString()
    new_student_obj.start_training = new_student_obj.start_training.length == 0 ? new Date().toISOString() : new Date(new_student_obj.start_training).toISOString()
    new_student_obj.passport_end_date = new_student_obj.passport_end_date.length == 0 ? new Date().toISOString() : new Date(new_student_obj.passport_end_date).toISOString()

    const formData = new FormData()
    
    if (photo !== null) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    new_student_obj.id_photos?.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    new_student_obj.passport_photos?.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    new_student_obj.attorney_files?.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    new_student_obj.photo_for_passport?.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    delete new_student_obj.id_photos
    delete new_student_obj.passport_photos
    delete new_student_obj.attorney_files
    delete new_student_obj.photo_for_passport
    
    formData.append('new_student', JSON.stringify(new_student_obj))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }
    return await sendApiRequest(config)
}

export const deleteStudent = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/delete_student'
    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: student_id
        }
    }
    return await sendApiRequest(config)
}

export const updateStudent = async (student_obj, photo, idPhotos, passportPhotos, attorneyFiles, photoForPassport) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/update_student'

    student_obj.birthday = new Date(student_obj.birthday).toISOString()
    student_obj.start_training = new Date(student_obj.start_training).toISOString()
    student_obj.passport_end_date = new Date(student_obj.passport_end_date).toISOString()

    const formData = new FormData()
    if (photo !== null && photo.startsWith('data')) {
        formData.append('student_picture', dataURItoBlob(photo))
    } else {
        formData.append('student_picture', null)
    }

    idPhotos.forEach((id_photo, index) => {
        formData.append(`id_photos`, id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append(`passport_photos`, passport_photo)
    })

    attorneyFiles.forEach((attorney_file, index) => {
        formData.append(`attorney_files`, attorney_file)
    })

    photoForPassport.forEach((photo_for_passport_file, index) => {
        formData.append(`photo_for_passport`, photo_for_passport_file)
    })

    formData.append('updated_student', JSON.stringify(student_obj))

    const config = {
        method: 'put',
        url: session_url,
        data: formData,
        params: {
            student_id: student_obj.id
        }
    }

    return await sendApiRequest(config)
}

export const getStudentById = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_student_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequest(config)
}

export const sendPhotoToRemoveBg = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    
    const response = await axios(
        {
            url: 'https://kolosapi.carpediem.pp.ua/subservice/removebg',
            method: 'POST',
            responseType: 'blob', 
            data: formData,
        }
    )

    return new Blob([response.data])
}

export const getBalanceState = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_state',
        params: {
            balance_id: balance_id
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response.balance_state
}

export const getAllIncomes = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_incomes_by_balance_id',
        params: {
            balance_id: balance_id,
            year: new Date().getFullYear()
        }
    }
    return await sendApiRequestReturnData(config)
}

export const getAllOutlays = async (balance_id, year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlay_by_balance_id',
        params: {
            balance_id: balance_id,
            year: year
        }
    }
    const response = await sendApiRequestReturnData(config)
    return response
}

export const deleteTrainerRequest = async (trainer_id) => {
  const session_url = process.env.VUE_APP_API_URL + 'trainer/delete_trainer_by_id'

  const config = {
      method: 'delete',
      url: session_url,
      params: {
          trainer_id: trainer_id
      }
  }

  return await sendApiRequest(config)
}

export const addNewTrainer = async (new_trainer_obj, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/add_new_trainer'

    new_trainer_obj.birthday = isNaN(new Date(new_trainer_obj.birthday)) ? null : new Date(new_trainer_obj.birthday).toISOString() 
    new_trainer_obj.club_start_date = isNaN(new Date(new_trainer_obj.club_start_date)) ? null : new Date(new_trainer_obj.club_start_date).toISOString() 
    new_trainer_obj.passport_end_date = isNaN(new Date(new_trainer_obj.passport_end_date)) ? null : new Date(new_trainer_obj.passport_end_date).toISOString()
    new_trainer_obj.issuing_date = isNaN(new Date(new_trainer_obj.issuing_date)) ? null : new Date(new_trainer_obj.issuing_date).toISOString()

    const formData = new FormData()
    
    if (photo !== null) {
        formData.append('trainer_picture', dataURItoBlob(photo))
    } else {
        formData.append('trainer_picture', null)
    }

    new_trainer_obj.id_photos.forEach((id_photo, index) => {
        formData.append('id_photos', id_photo)
    })

    new_trainer_obj.passport_photos.forEach((passport_photo, index) => {
        formData.append('passport_photos', passport_photo)
    })

    new_trainer_obj.ukrainian_passport_files.forEach((ukrainian_passport, index) => {
        formData.append('ukrainian_passport_files', ukrainian_passport)
    })

    delete new_trainer_obj.id_photos
    delete new_trainer_obj.passport_photos
    delete new_trainer_obj.ukrainian_passport_files
    
    formData.append('new_trainer', JSON.stringify(new_trainer_obj))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }
    return await sendApiRequest(config)
}

export const updateTrainer = async (updated_trainer_obj, trainer_photo, passportPhotos, idCodePhotos, ukrainianPassportPhotos) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/update_trainer'

    updated_trainer_obj.birthday = new Date(updated_trainer_obj.birthday).toISOString()
    updated_trainer_obj.club_start_date = new Date(updated_trainer_obj.club_start_date).toISOString()
    updated_trainer_obj.passport_end_date = new Date(updated_trainer_obj.passport_end_date).toISOString()
    updated_trainer_obj.issuing_date = new Date(updated_trainer_obj.issuing_date).toISOString()

    const formData = new FormData()

    if (trainer_photo !== null && trainer_photo.startsWith('data:image')) {
        formData.append('trainer_picture', dataURItoBlob(trainer_photo))
    }

    idCodePhotos.forEach((id_photo, index) => {
        formData.append('id_photos', id_photo)
    })

    passportPhotos.forEach((passport_photo, index) => {
        formData.append('passport_photos', passport_photo)
    })

    ukrainianPassportPhotos.forEach((ukrainian_passport, index) => {
        formData.append('ukrainian_passport_files', ukrainian_passport)
    })

    formData.append('updated_trainer', JSON.stringify(updated_trainer_obj))


    delete updated_trainer_obj.id_photos
    delete updated_trainer_obj.passport_photos
    delete updated_trainer_obj.ukrainian_passport_files

    const config = {
        method: 'put',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getGroupsByTrainerId = async (trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainer/get_trainer_groups_by_trainer_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getActiveStudentsInGroup = async (group_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_active_students_in_group'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: group_id
        }
    }

    return await sendApiRequest(config)
}

export const getPlanedTrainingsByGroup = async (group_id) => {
    const currentDate = new Date()

    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_planed_trainings_by_group',
        params: {
            group_id: group_id,
            current_date: currentDate.toISOString()
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getServiceBalanceByLabel = async (label) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_service_balance_by_label',
        params: {
            service_label: label
        }
    }
    return await sendApiRequestReturnData(config)
}

export const getCertainNumberOfIncomes = async (number_of_transactions, skip = 0, filters = null) => {
    filters = filters === null ? {
        receiver_type: 'all',
        transaction_type: 'all',
        amount_from: null,
        amount_to: null,
        date_from: null,
        date_to: null,
        current_balance_id: null,
        current_transaction_id: null
    } : filters


    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_certain_number_of_incomes',
        params: {
            count: number_of_transactions,
            skip: skip,
            filters: JSON.stringify(filters)
        },
    }

    return await sendApiRequestReturnData(config)
}

export const getBalanceObject = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_object_by_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getVirtualIncomeById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_virtual_income_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getUniversalTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_universal_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getUniversalTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_universal_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealParentTransactionByVirtualTransactionId = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_real_parent_transaction_by_virtual_transaction_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllVirtualBalances = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_virtual_balances'
    }

    return await sendApiRequestReturnData(config)
}

export const addVirtualIncomeRequest = async (balanceId, amount, label) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_virtual_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label
    }
    formData.append('virtual_income', JSON.stringify(txConfig))

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const addRealIncomeRequest = async (balanceId, amount, label, virtualTxId, images) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_income'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: balanceId,
        amount: amount,
        label: label,
        embed_virtual_income: virtualTxId
    }

    formData.append('real_income', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getServiceBalanceObjectByLabel = async (label) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_service_balance_object_by_label',
        params: {
            service_label: label
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainerStudentBalanceState = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_trainers_student_balance_states'
    }

    return await sendApiRequestReturnData(config)
}

export const getCertainNumberOfOutlays = async (number_of_transactions, skip = 0, filters = null) => {
    filters = filters === null ? {
        sender_type: 'all',
        transaction_type: 'all',
        amount_from: null,
        amount_to: null,
        date_from: null,
        date_to: null,
        current_balance_id: null,
        current_transaction_id: null
    } : filters

    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_certain_number_of_outlays',
        params: {
            count: number_of_transactions,
            skip: skip,
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomeTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_income_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomeTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_income_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getOutlayTransactionById = async (transaction_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_outlay_transaction_by_id',
        params: {
            transaction_id: transaction_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getOutlayTransactionByBalanceId = async (balance_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_outlay_transactions_by_balance_id',
        params: {
            balance_id: balance_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getSumOfAllVirtualOutlays = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_sum_of_all_virtual_outlays'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllVirtualOutlaysCount = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_virtual_outlays_count'
    }

    return await sendApiRequestReturnData(config)
}

export const getMeanRealIncomeFromStudent = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_mean_real_income_from_student'
    }

    return await sendApiRequestReturnData(config)
}

export const getYearIncomesBySources = async (year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_sources_by_year',
        params: {
            year: year
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomesBySourcesByMonth = async (year, month) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_sources_by_month_and_year',
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getIncomesByGroups = async (year, month) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups',
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllCompletedTrainingsByStudentId = async (student_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'trainings/get_students_completed_trainings',
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStudentsListInGroup = async (group_id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'groups/get_all_students_list_in_group',
        params: {
            group_id: group_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getStudentsCount = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'student/get_students_count'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTransactions = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_all_transactions'
    }

    return await sendApiRequestReturnData(config)
}

export const getBalanceByQuery = async (query) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_balance_by_query',
        params: {
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addRealTransactionRequest = async (senderBalanceId, receiverBalanceId, amount, label, images) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_transaction'

    const formData = new FormData()

    const txConfig = {
        receiver_balance_id: receiverBalanceId,
        sender_balance_id: senderBalanceId,
        amount: amount,
        label: label
    }
    console.log('txConfig ------- ', txConfig)
    formData.append('real_transaction', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getAllAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'admin/get_all_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllCashFromAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_cash_from_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllInvestFromAdmins = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'balance/get_all_invest_from_admins'
    }

    return await sendApiRequestReturnData(config)
}

export const getCountOfIncomes = async (filters) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_count_of_incomes',
        body: filters
    }

    return await sendApiRequestReturnData(config)
}

export const addOutlayCategory = async (name, id) => {
    console.log('name ------- ', name)

    const formData = new FormData()

    formData.append('name', name)

    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'outlay_category/add_outlay_category',
        data: {
            name : name,
            id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addOutlaySubcategory = async (name, outlayCategoryId, id) => {
    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'outlay_category/add_subcategory',
        data: {
            name: name,
            embed_category_id: outlayCategoryId,
            id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllOutlayCategoriesWithSubcategories = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_all_categories_with_subcategories'
    }

    return await sendApiRequestReturnData(config)
}

export const getAllOutlayCategories = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_all_categories'
    }

    return await sendApiRequestReturnData(config)
}

export const getSubcategoriesByCategoryId = async (id) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'outlay_category/get_subcategories_by_category_id',
        params: {
            category_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addRealOutlay = async (
        amount, 
        date, 
        label, 
        name, 
        senderBalanceId, 
        senderBalanceName, 
        categoryId, 
        categoryName, 
        subcategoryId, 
        subcategoryName, 
        creatorId, 
        creatorName, 
        images) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/add_real_outlay'

    const formData = new FormData()

    const txConfig = {
        amount: amount,
        date: date,
        label: label,
        name: name,
        sender_balance_id: senderBalanceId,
        sender_balance_name: senderBalanceName,
        category_id: categoryId,
        category_name: categoryName,
        subcategory_id: subcategoryId,
        subcategory_name: subcategoryName,
        creator_id: creatorId,
        creator_name: creatorName
    }

    formData.append('real_outlay', JSON.stringify(txConfig))

    images.forEach((image) => {
        formData.append('additional_files', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequest(config)
}

export const getRealOutlays = async () => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_real_outlays',
    }

    return await sendApiRequestReturnData(config)
}

export const deleteCategoryById = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'outlay_category/delete_category_by_id',
        params: {
            category_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteSubcategoryById = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'outlay_category/delete_subcategory_by_id',
        params: {
            subcategory_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateCategoryById = async (id, name) => {
    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'outlay_category/update_category_by_id',
        params: {
            category_id: id,
            category_name: name
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateSubcategoryById = async (id, name) => {
    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'outlay_category/update_subcategory_by_id',
        params: {
            subcategory_id: id,
            subcategory_name: name
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealOutlaysByFilters = async (count, skip, filters) => {
    console.log('filters ------- ', filters)
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + `transaction/get_certain_number_of_club_outlays`,
        params: {
            count: count,
            skip: skip,
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getCountOfClubOutlays = async (filters) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'transaction/get_count_of_club_outlays',
        params: {
            filters: JSON.stringify(filters)
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteClubOutlay = async (id) => {
    const config = {
        method: 'delete',
        url: process.env.VUE_APP_API_URL + 'transaction/delete_club_outlay',
        params: {
            transaction_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateClubOutlay = async (tx_id, new_outlay, additional_files) => {
    const formData = new FormData()

    formData.append('real_outlay', JSON.stringify(new_outlay))

    additional_files.forEach((file) => {
        formData.append('additional_files', file)
    })

    const config = {
        method: 'put',
        url: process.env.VUE_APP_API_URL + 'transaction/update_club_outlay',
        params: {
            transaction_id: tx_id
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getStatisticsClubOutlaysByYear = async (year) => {
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'statistic/get_club_outlays_by_year',
        params: {
            year: year
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getGroupsAttendanceByYearAndMonth = async (year, month) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_groups_attendance_by_month_and_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            month: month
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainerCompletedTrainingsByYear = async (year, trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_completed_trainings_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const getAllIncomesByGroupInYear = async (year) => {
    const session_url = process.env.VUE_APP_API_URL + 'statistic/get_incomes_by_groups_by_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year
        }
    }

    return await sendApiRequest(config)
}

export const getActiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_active_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getDebtStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_debt_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getArchiveStudentsCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_archive_students_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addStudentToArchive = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/add_student_to_archive'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const removeStudentFromArchive = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/remove_student_from_archive'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getRealIncomesByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_all_real_incomes_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getTransactionsByBalanceIdRequest = async (balanceId) => {
    const session_url = process.env.VUE_APP_API_URL + 'transaction/get_transactions_by_balance_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            balance_id: balanceId
        }
    }

    return await sendApiRequest(config)
}

export const getAllSettings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/get_settings'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateSettingsRequest = async (settings) => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/update_settings'

    const config = {
        method: 'post',
        url: session_url,
        data: settings
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingElementRequest = async (trainingElement, addition_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/add_new_training_element'

    const form = new FormData()

    form.append('request', JSON.stringify(trainingElement))

    addition_images.forEach((image) => {
        form.append('addition_images', image)
    })

    const config = {
        method: 'post',
        url: session_url,
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingElementsRequest = async (count, skip, filters = {}, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/get_all_training_elements'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: count,
            skip: skip,
            filters: filters,
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteElementByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/delete_training_element_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_element_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getStatisticsByElements = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/statistics_by_element_types'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequestReturnData(config)
}

export const getElementByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/get_training_element_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_element_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const createNewTrainingSchemaRequest = async (trainingSchema, file) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/create_schema'
    console.log('trainingSchema ------- ', trainingSchema)

    const formData = new FormData()

    formData.append('training_schema', JSON.stringify(trainingSchema))

    formData.append('file', file)

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingSchemasRequest = async (count, skip) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/get_all_schemas'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            count: count,
            skip: skip
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getSvgCodeOfSchema = async (filename) => {
    
    const config = {
        method: 'get',
        url: process.env.VUE_APP_API_URL + 'static/' + filename,
    }
    
    return await sendApiRequestReturnData(config)
}

export const deleteSchemaByIdRequest = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/delete_schema_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            schema_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateSchemaByIdRequest = async (id, trainingSchema, file) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/update_schema_by_id'

    const formData = new FormData()

    formData.append('training_schema', JSON.stringify(trainingSchema))

    formData.append('file', file)

    const config = {
        method: 'put',
        url: session_url,
        params: {
            schema_id: id
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const updateTrainingElementById = async (id, trainingElement, new_additional_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_elements/update_training_element_by_id'

    const form = new FormData()

    console.log('trainingElement ------- ', trainingElement)

    form.append('request', JSON.stringify(trainingElement))

    new_additional_images.forEach((image) => {
        form.append('addition_images', image)
    })

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_element_id: id
        },
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getSchemaById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_schemas/get_schema_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            schema_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingRequest = async (training, additional_images) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_constructor/add_new_training'
    
    const config = {
        method: 'post',
        url: session_url,
        data: training
    }

    return await sendApiRequestReturnData(config)
}

export const addNewTrainingSummary = async (trainingSummaryObject) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/add_summary'

    const config = {
        method: 'post',
        url: session_url,
        data: trainingSummaryObject
    }

    return await sendApiRequestReturnData(config)
}

export const getAllSummaries = async (count = 20, skip = 0, filters = {}, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_all_summaries'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            count: count,
            skip: skip,
            filters: filters,
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
} 

export const getSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteSummaryById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/delete_summary'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            summary_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateSummaryById = async (id, newSummary) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/update_summary'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            summary_id: id
        },
        data: newSummary
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingsSummaryCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summary_count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingSummaryProgram = async (trainingSummaryProgramObject) => {
    console.log('trainingSummaryProgramObject ------- ', trainingSummaryProgramObject)
    const session_url = process.env.VUE_APP_API_URL + 'training_program/add_new_training_program'

    const config = {
        method: 'post',
        url: session_url,
        data: trainingSummaryProgramObject
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainingPrograms = async (count = 20, skip = 0, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_programs_by_name'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            count: count,
            skip: skip,
            name: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteTrainingProgramById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/delete_training_program_by_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainingProgramById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_training_program_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateTrainingProgramById = async (id, newTrainingProgram) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/update_training_program_by_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            training_program_id: id
        },
        data: newTrainingProgram
    }

    return await sendApiRequestReturnData(config)
}

export const getSummariesByMicrocycleId = async (id, count = 20, skip = 0) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/get_summaries_by_microcycle_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            microcycle_id: id,
            count: count,
            skip: skip
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addSummaryToProgram = async (program_id, program_name, microcycle_id, microcycle_name, mesocycle_id, mesocycle_name, summary_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/add_summary_to_program'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            program_id: program_id,
            program_name: program_name,
            microcycle_id: microcycle_id,
            microcycle_name: microcycle_name,
            mesocycle_id: mesocycle_id,
            mesocycle_name: mesocycle_name,
            summary_id: summary_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const countTrainingPrograms = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/count_training_programs'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const countSummariesWithMicrocycleId = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'training_summary/count_summaries_with_microcycles_id'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const countSummariesInCurrentProgram = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/count_trainings_in_current_program'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_program_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getProgramsByName = async (name) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/get_programs_by_name'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            name: name,
            count: 20,
            skip: 0
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTrainingProgramsToGroup = async (groupId, trainingProgramsIds) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/add_group_to_programs'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_program_ids: trainingProgramsIds,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getProgramsByGroupId = async (groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'groups/get_programs_by_group_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            group_id: groupId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteProgramsFromGroup = async (groupId, trainingProgramsIds) => {
    const session_url = process.env.VUE_APP_API_URL + 'training_program/delete_group_from_programs'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            training_program_ids: trainingProgramsIds,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichAttendedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_attended_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const getStudentsWhichMissedTraining = async (trainingDate, groupId) => {
    const session_url = process.env.VUE_APP_API_URL + 'student/get_students_by_missed_training'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            training_date: trainingDate,
            group_id: groupId
        }
    }

    return await sendApiRequest(config)
}

export const parsePalarXls = async (file) => {
    const formData = new FormData()

    //multipart/form-data

    formData.append('file', file)

    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'training_summary_addons/parse_palar_xls',
        data: formData,
        responseType: 'blob',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    return await sendApiRequest(config)
}

export const getTrainerCompletedTrainingsByWeeksInYear = async (year, trainer_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_completed_trainings_by_weeks_in_year'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            year: year,
            trainer_id: trainer_id
        }
    }

    return await sendApiRequest(config)
}

export const addTrainerMeetUp = async (trainerMeetUp) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/add_trainer_meet_up'
    let formattedDate = null

    console.log('trainerMeetUp ------- ', trainerMeetUp)

    if (typeof trainerMeetUp.date === 'string') {
        trainerMeetUp.date = (new Date(trainerMeetUp.date)).toISOString()
    } else {
        trainerMeetUp.date = (new Date(formatDate(trainerMeetUp.date))).toISOString()
    }

    const config = {
        method: 'post',
        url: session_url,
        data: trainerMeetUp
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTrainerMeetUps = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_all_trainer_meet'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getTrainerMeetUpById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/get_trainer_meet_up_by_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            meet_up_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateTrainerMeetUpById = async (id, newMeetUp) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/update_trainer_meet_up_by_id'

    console.log('newMeetUp ------- ', newMeetUp)

    // if (typeof newMeetUp.date === 'string') {
    //     // const dateMinusOneDay = new Date(new Date(newMeetUp.date).setDate(new Date(newMeetUp.date).getDate() - 1))
    //     newMeetUp.date = new Date(newMeetUp.date).toISOString()
    // } else {
    //     newMeetUp.date = (new Date(formatDate(newMeetUp.date))).toISOString()
    // }

    const config = {
        method: 'put',
        url: session_url,
        params: {
            meet_up_id: id
        },
        data: newMeetUp
    }

    return await sendApiRequestReturnData(config)
}

export const deleteTrainerMeetUpById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'trainings/delete_trainer_meet_up_by_id'

    console.log('id ------- ', id)

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            meet_up_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const parsePalarXlsNew = async (file1, file2, gameFile) => {
    const formData = new FormData()

    //multipart/form-data

    formData.append('first_time', file1)
    formData.append('second_time', file2)
    formData.append('game', gameFile)

    console.log('first_time ------- ', file1)
    console.log('second_time ------- ', file2)
    console.log('game ------- ', gameFile)

    const config = {
        method: 'post',
        url: process.env.VUE_APP_API_URL + 'training_summary_addons/parse_palar_xls_new',
        data: formData,
        responseType: 'blob',
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    return await sendApiRequest(config)
}

export const getPlayerFullCardByStudentId = async (student_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_player_full_card_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: student_id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updatePlayerFullCardByStudentId = async (student_id, newPlayerFullCard, contractFiles, abroadPassportFiles) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/update_player_full_card_by_student_id'

    const formData = new FormData()

    formData.append('player_full_card', JSON.stringify(newPlayerFullCard))

    contractFiles.forEach((file) => {
        formData.append('contract_photos', file)
    })

    abroadPassportFiles.forEach((file) => {
        formData.append('abroad_passport_photos', file)
    })

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: student_id
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const addLeagueMatchCenter = async (leagueName, leagueLogo) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/add-league'

    const formData = new FormData()

    formData.append('league_name', leagueName)
    formData.append('league_logo', leagueLogo)

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getAllLeagues = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-all-leagues'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateLeagueById = async (id, leagueName, leagueLogo) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-league'

    const formData = new FormData()

    formData.append('league_name', leagueName)
    formData.append('league_logo', leagueLogo)

    const config = {
        method: 'put',
        url: session_url,
        params: {
            league_id: id,
            league_name: leagueName
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)

}

export const deleteLeagueById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/delete-league'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            league_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteTeamById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/delete-team'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            team_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addTeamToLeagueRequest = async (teamName, teamLogo, season, leagueId) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/add-team-to-league'

    const formData = new FormData()

    formData.append('team_name', teamName)
    formData.append('team_logo', teamLogo)
    formData.append('league_id', leagueId)
    formData.append('season', season)

    const config = {
        method: 'post',
        url: session_url,
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getAllTeams = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-all-teams'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addMatchReport = async (matchReport) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/add-match-report'

    const config = {
        method: 'post',
        url: session_url,
        data: matchReport
    }

    return await sendApiRequestReturnData(config)
}

export const getAllMatchReports = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-all-match-reports'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const deleteMatchReport = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/delete-match-report'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            match_report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getMatchReportById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-match-report'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            match_report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateMatchReport = async (id, newMatchReport) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-match-report'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            match_report_id: id
        },
        data: newMatchReport
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerStatisticsByPlayerId = async (playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-player-statistics-by-player-id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updatePlayerHealthParamsByStudentId = async (studentId, newHealthParams) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/update_player_health_params_by_student_id'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId
        },
        data: newHealthParams
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerHealthParamsByStudentId = async (studentId) => {
    console.log('studentId ------- ', studentId)
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_player_health_params_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addHealthReportByStudentId = async (studentId, healthReport, files) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/add_health_report_by_student_id'

    const formData = new FormData()

    formData.append('report', JSON.stringify(healthReport))
    
    files.forEach((file) => {
        formData.append('files', file)
    })

    const config = {
        method: 'post',
        url: session_url,
        params: {
            student_id: studentId
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const deleteHealthReportByStudentId = async (studentId, reportId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/delete_health_report_by_student_id'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            student_id: studentId,
            report_id: reportId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateHealthReportByStudentId = async (studentId, newHealthReport, newDocuments = []) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/update_health_report_by_student_id'

    const formData = new FormData()

    formData.append('report', JSON.stringify(newHealthReport))

    newDocuments.forEach((file) => {
        formData.append('files', file)
    })

    const config = {
        method: 'put',
        url: session_url,
        params: {
            student_id: studentId,
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const addStandardRequest = async (standard) => {
    const session_url = process.env.VUE_APP_API_URL + 'standards/add_standard'

    const config = {
        method: 'post',
        url: session_url,
        data: standard
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStandardsRequest = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'standards/get_standards'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateStandardById = async (id, newStandard) => {
    const session_url = process.env.VUE_APP_API_URL + 'standards/update_standard'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            standard_id: id
        },
        data: newStandard
    }

    return await sendApiRequestReturnData(config)
}

export const deleteStandardById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'standards/delete_standard'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            standard_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllStudentStandardsByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_full_card/get_all_student_standards_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerStatisticsByMatchReportIdAndPlayerId = async (matchReportId, playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/get-player-statistics-by-match-report-id-and-player-id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            match_report_id: matchReportId,
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updatePlayerStatisticsByMatchReportIdAndPlayerId = async (matchReportId, playerId, newStatistics) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-player-statistics'

    console.log('newStatistics ------- ', newStatistics)

    const config = {
        method: 'put',
        url: session_url,
        params: {
            match_report_id: matchReportId,
            player_id: playerId
        },
        data: newStatistics
    }

    return await sendApiRequestReturnData(config)
}

export const addPlayerScoutReport = async (scoutReport) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/add_player_scout_report'

    const config = {
        method: 'post',
        url: session_url,
        data: scoutReport
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerScoutReportsByStudentId = async (studentId, scout_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_player_scout_reports_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId,

            scout_id: scout_id,
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deletePlayerScoutReportById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/delete_player_scout_report'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updatePlayerScoutReportById = async (id, newScoutReport) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/update_player_scout_report'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            report_id: id
        },
        data: newScoutReport
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerScoutReport = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_player_scout_report'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAllScoutsWhichDoReportsByStudent = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_all_scouts_which_do_reports_by_student'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getPlayerScoutReports = async (limit = 20, skip = 0, query = "", filters) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_player_scout_reports'

    const filterTeam = filters.team;
    const filterPosition = filters.position;
    const filterScout = filters.scout;
    const filterPotential = filters.potential;
    const filterAge = filters.age;
    const minScoreValue = filters.minRating;
    const maxScoreValue = filters.maxRating;
    
    const config = {
        method: 'get',
        url: session_url,
        params: {
            limit: limit,
            skip: skip,
            query: query,
            filterTeam: filterTeam,
            filterPosition: filterPosition,
            filterScout: filterScout,
            filterPotential: filterPotential,
            filterAge: filterAge,
            minScoreValue: minScoreValue,
            maxScoreValue: maxScoreValue
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addScoutProfile = async (scoutProfile, scout_picture) => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/add-scout-profile'

    const form = new FormData()

    form.append('scout_profile', JSON.stringify(scoutProfile))

    if (scout_picture !== null && scout_picture.startsWith('data')) {
        form.append('scout_picture', dataURItoBlob(scout_picture))
    } else {
        form.append('scout_picture', null)
    }

    const config = {
        method: 'post',
        url: session_url,
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getAllScoutProfiles = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/get-scout-profiles'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const deleteScoutProfileById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/delete-scout-profile'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            scout_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateScoutProfileById = async (id, newScoutProfile, photo) => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/update-scout-profile'

    const form = new FormData()

    form.append('scout_profile', JSON.stringify(newScoutProfile))

    if (photo !== null && photo.startsWith('data')) {
        form.append('scout_picture', dataURItoBlob(photo))
    } else {
        form.append('scout_picture', null)
    }

    const config = {
        method: 'put',
        url: session_url,
        params: {
            scout_id: id
        },
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getScoutProfileById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/get-scout-profile'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            scout_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getScoutCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/get-count-scouts'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getAverageScoreByStudentId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_average_score_by_student_id'

    const config = {
        method: 'get',
        url: session_url,

        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const addAlienPlayer = async (alienPlayer, playerPhoto, teamLogoPicture) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/add-alien-player'

    const form = new FormData()

    form.append('alien_player', JSON.stringify(alienPlayer))

    if (playerPhoto !== null && playerPhoto.startsWith('data')) {
        form.append('player_picture', dataURItoBlob(playerPhoto))
    } else {
        form.append('player_picture', null)
    }

    if (teamLogoPicture !== null && teamLogoPicture.startsWith('data')) {
        form.append('player_team_picture', dataURItoBlob(teamLogoPicture))
    } else {
        form.append('player_team_picture', null)
    }

    const config = {
        method: 'post',
        url: session_url,
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getAllAlienPlayers = async (count = 20, skip = 0, query = '', amplua = [], team = '', age = null, firstParameterMin= null, firstParameterMax= null, secondParameterMin= null, secondParameterMax= null, thirdParameterMin= null, thirdParameterMax= null, fourthParameterMin= null, fourthParameterMax= null, fifthParameterMin= null, fifthParameterMax= null, sixthParameterMin= null, sixthParameterMax= null, seventhParameterMin= null, seventhParameterMax= null) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/get-alien-players'

    console.log('amplua ------- ', amplua)

    const config = {
        method: 'post',
        url: session_url,

        data: {
            count: count,
            skip: skip,
            query: query,
            amplua: amplua,
            team: team == 'не вибрано' ? null : team,
            age: age == 'не вибрано' ? null : age,
            firstParameterMin: firstParameterMin,
            firstParameterMax: firstParameterMax,
            secondParameterMin: secondParameterMin,
            secondParameterMax: secondParameterMax,
            thirdParameterMin: thirdParameterMin,
            thirdParameterMax: thirdParameterMax,
            fourthParameterMin: fourthParameterMin,
            fourthParameterMax: fourthParameterMax,
            fifthParameterMin: fifthParameterMin,
            fifthParameterMax: fifthParameterMax,
            sixthParameterMin: sixthParameterMin,
            sixthParameterMax: sixthParameterMax,
            seventhParameterMin: seventhParameterMin,
            seventhParameterMax: seventhParameterMax
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteAlienPlayerById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/delete-alien-player'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            player_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateAlienPlayerById = async (id, newAlienPlayer, playerPhoto, teamLogoPicture) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/update-alien-player'

    const form = new FormData()

    form.append('alien_player', JSON.stringify(newAlienPlayer))

    if (playerPhoto !== null && playerPhoto.startsWith('data')) {
        form.append('player_photo', dataURItoBlob(playerPhoto))
    } else {
        form.append('player_photo', null)
    }

    if (teamLogoPicture !== null && teamLogoPicture.startsWith('data')) {
        form.append('team_logo_picture', dataURItoBlob(teamLogoPicture))
    } else {
        form.append('team_logo_picture', null)
    }

    const config = {
        method: 'put',
        url: session_url,
        params: {
            player_id: id
        },
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienPlayerById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/get-alien-player'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            player_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienPlayersCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/get-count-alien-players'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addAlienPlayerScoutReport = async (newReport, firstTeamLogo, secondTeamLogo) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/add_player_scout_report'

    const form = new FormData()

    form.append('player_scout_report', JSON.stringify(newReport))

    if (firstTeamLogo !== null && firstTeamLogo.startsWith('data')) {
        form.append('first_team_match_logo', dataURItoBlob(firstTeamLogo))
    } else {
        form.append('first_team_match_logo', null)
    }

    if (secondTeamLogo !== null && secondTeamLogo.startsWith('data')) {
        form.append('second_team_match_logo', dataURItoBlob(secondTeamLogo))
    } else {
        form.append('second_team_match_logo', null)
    }

    const config = {
        method: 'post',
        url: session_url,
        data: form
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienPlayerScoutReports = async (limit, skip, query, filters) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_player_scout_reports'

    const filterTeam = filters.team;
    const filterPosition = filters.position;
    const filterScout = filters.scout;
    const filterPotential = filters.potential;
    const filterAge = filters.age;
    const minScoreValue = filters.minRating;
    const maxScoreValue = filters.maxRating;
    
    const config = {
        method: 'get',
        url: session_url,
        params: {
            limit: limit,
            skip: skip,
            query: query,
            filterTeam: filterTeam,
            filterPosition: filterPosition,
            filterScout: filterScout,
            filterPotential: filterPotential,
            filterAge: filterAge,
            minScoreValue: minScoreValue,
            maxScoreValue: maxScoreValue
        }
    }


    return await sendApiRequestReturnData(config)
}

export const getAlienPlayerScoutReportsByStudentId = async (studentId, scout_id) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_player_scout_reports_by_student_id'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId,

            scout_id: scout_id,
        }
    }

    return await sendApiRequestReturnData(config)
}


export const getAllScoutsWhichDoReportsByAlienPlayer = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_all_scouts_which_do_reports_by_student'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}
 
export const getOtherMatches = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_other_matches'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getLastAlienPlayerScoutReport = async (playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_last_alien_player_scout_report'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAverageScoreByAlienPlayerId = async (studentId) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_average_score_by_student_id'

    const config = {
        method: 'get',
        url: session_url,

        params: {
            student_id: studentId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienPlayerScoutReport = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_player_scout_report'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteAlienPlayerScoutReportById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/delete_player_scout_report'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            report_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateAlienPlayerScoutReportById = async (id, newScoutReport) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/update_player_scout_report'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            report_id: id
        },
        data: newScoutReport
    }

    return await sendApiRequestReturnData(config)
}

export const getCountPlayerScoutReports = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_count_player_scout_reports'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getCountAlienPlayerScoutReports = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_count_alien_player_scout_reports'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getUniqueScouts = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'player_scout_report_router/get_unique_scouts'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienUniqueScouts = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_alien_unique_scouts'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getUniqueTeams = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_alien_unique_teams'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const addShadowTeam = async (shadowTeam) => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/add-shadow-team'

    const config = {
        method: 'post',
        url: session_url,
        data: shadowTeam
    }

    return await sendApiRequestReturnData(config)
}

export const getAllShadowTeams = async (limit = 20, skip = 0, query = '') => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/get-shadow-teams'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            limit: limit,
            skip: skip,
            query: query
        }
    }

    return await sendApiRequestReturnData(config)
}

export const deleteShadowTeamById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/delete-shadow-team'

    const config = {
        method: 'delete',
        url: session_url,
        params: {
            shadow_team_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateShadowTeamById = async (id, newShadowTeam) => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/update-shadow-team'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            shadow_team_id: id
        },
        data: newShadowTeam
    }

    return await sendApiRequestReturnData(config)
}

export const getShadowTeamById = async (id) => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/get-shadow-team'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            shadow_team_id: id
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getCountShadowTeams = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/get-count-shadow-teams'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const transferPlayerToClub = async (playerId) => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/transfer-player-to-club'

    const config = {
        method: 'get',
        url: session_url,
        params: {
            player_id: playerId
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getSubscribedPlayersCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/get-subscribed-players-count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getSigningPlayersCount = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-players/get-signing-players-count'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getAlienReportsUniqueMatches = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'alien-player-scout-reports/get_alien_reports_unique_matches'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const getBestShadowTeam = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'shadow-team/get-best-shadow-team'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateScoreSettings = async (goalkeeperSettings, fieldPlayerSettings) => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/update_score_settings'

    const config = {
        method: 'post',
        url: session_url,
        data: {
            goalkeeper_settings: goalkeeperSettings,
            player_field_settings: fieldPlayerSettings
        }
    }

    return await sendApiRequestReturnData(config)
}

export const getScoreSettings = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'settings/get_score_settings'

    const config = {
        method: 'get',
        url: session_url
    }

    return await sendApiRequestReturnData(config)
}

export const updateTeamMatchCenter = async (teamId, teamName, logo) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-team'

    const formData = new FormData()

    formData.append('team_logo', logo)

    const config = {
        method: 'put',
        url: session_url,
        params: {
            team_id: teamId,
            team_name: teamName
        },
        data: formData
    }

    return await sendApiRequestReturnData(config)
}

export const getScoutByTokens = async () => {
    const session_url = process.env.VUE_APP_API_URL + 'scout-profiles/get-scout-profile-by-tokens'

    const config = {
        method: 'get',
        url: session_url,
    }

    return await sendApiRequestReturnData(config)
}

export const updateSeasonNameRequest = async (leagueId, OldSeasonName, NewSeasonName) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-season-name'

    const config = {
        method: 'post',
        url: session_url,
        params: {
            league_id: leagueId,
            old_season_name: OldSeasonName,
            new_season_name: NewSeasonName
        }
    }

    return await sendApiRequestReturnData(config)
}

export const updateTeamName = async (teamId, teamName) => {
    const session_url = process.env.VUE_APP_API_URL + 'match-center/update-team-name'

    const config = {
        method: 'put',
        url: session_url,
        params: {
            team_id: teamId,
            new_team_name: teamName
        }
    }

    return await sendApiRequestReturnData(config)
}