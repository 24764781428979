<template>
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="bg-[#252526] z-[130] w-[400px] h-[181px] rounded-[10px] pt-[10px] border-[1px] border-[#FFF] border-opacity-10">
        <div class="bg-[#BD9135] text-[13px] font-bold text-center rounded-[4px] mx-[14px] py-[2px]">
            СТАТИСТИКА
        </div>
        <carousel :items-to-show="1" :wrapAround="true">
            <slide 
                :key="1" 
                :class="{
                    'pl-[60px]' : screenWidth < 1360
                }"    
            >
                <div
                    :class="{
                        'scale-[0.95]' : screenWidth < 1360
                    }"
                >
                    <div class="flex mt-[15px] justify-around">
                        <div class="flex mx-auto justify-around">
                            <div class="h-[110px]">
                                <div>
                                    <circle-progress :fillColor="'#BD9135'" :emptyColor="'rgba(235, 235, 235, 0.1)'" :size="110" :percent="percentTrainingsAttended" :viewport="false" :borderWidth="12" :borderBgWidth="12" />
                                </div>
                                <div class="relative top-[-71px] left-[2px]">
                                    <div class="text-[#BD9135] text-2xl font-bold"> {{percentTrainingsAttended.toFixed(1)}}%</div>
                                </div>
                            </div>
                            <div class="w-[140px] mx-5 mt-[10px]">
                                <div class="flex items-center w-full justify-between">
                                    <div class="text-[#FFF]">тренувань</div>
                                    <div class="bg-[#BD9135] p-2 min-w-[5ch] text-center rounded-lg font-bold">{{ trainingsAttendedList.length + trainingsMissedList.length }}</div>
                                </div>
                                <div class="flex items-center w-full justify-between mt-3">
                                    <div class="text-[#FFF] mr-2">відвідувань</div>
                                    <div class="bg-[#339989] text-white p-2 min-w-[5ch] text-center rounded-lg font-bold">{{ trainingsAttendedList.length }}</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </slide>

            <slide 
                :key="2" 
                :class="{
                    'pl-[60px]' : screenWidth < 1360
                }"    
            >
                <div
                    :class="{
                        'scale-[0.95]' : screenWidth < 1360
                    }"
                >
                    <!-- <div class="text-white">
                        <div class="text-[10px] font-bold ">
                            СІЧЕНЬ 2022
                        </div>
                        <div class="text-[6px] ">
                            (відвідування)
                        </div>
                    </div> -->
                    <div class="w-[300px] ml-[20px]">
                        <training-calendar 
                            :groupId="groupId"
                            :trainingsMissedList="trainingsMissedList"
                            :trainingsAttendedList="trainingsAttendedList"
                        />
                    </div>
                </div>
            </slide>

            <template #addons>
                <navigation />
            </template>
        </carousel>
    </div>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import CircleProgress from "vue3-circle-progress"
    import TrainingCalendar from '../ui/TrainingCalendar/TrainingCalendar.vue'
    export default {
        name: 'stats-and-finance-card',
        props: {
            trainingsAttendedList: {
                type: Array,
                default: () => []
            },
            trainingsMissedList: {
                type: Array,
                default: () => []
            },
            groupId: {
                type: String,
                required: true
            }
        },

        setup(props) {
            const percentTrainingsAttended = ref(0)
            const trainingsPlanedList = ref([])
            const screenWidth = ref(window.innerWidth)

            onMounted(async () => {
                percentTrainingsAttended.value = props.trainingsAttendedList.length * 100 / (props.trainingsAttendedList.length + props.trainingsMissedList.length)
                percentTrainingsAttended.value = percentTrainingsAttended.value ? percentTrainingsAttended.value : 0

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                // const res = await getPlanedTrainingsByGroup(props.groupId)
                // trainingsPlanedList.value = res.map(item => new Date(item.date))
            })

            watch(() => props.trainingsAttendedList, () => {
                percentTrainingsAttended.value = props.trainingsAttendedList.length * 100 / (props.trainingsAttendedList.length + props.trainingsMissedList.length)
                percentTrainingsAttended.value = percentTrainingsAttended.value ? percentTrainingsAttended.value : 0
            })

            return {
                percentTrainingsAttended,
                trainingsPlanedList,
                screenWidth
            }
        },

        components: {
            CircleProgress,
            Carousel,
            Slide,
            Pagination,
            Navigation,
            TrainingCalendar
        },
    }
</script>
