<template>
    <div class="avatar-container" :class="{ 'avatar-container--small': small }">
        <img v-if="src" :src="src" :alt="alt" class="avatar-container__image" />
        <div class="text-[#BD9135]">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AvatarContainer',
    props: {
        src: {
            type: String,
            required: false
        },
        alt: {
            type: String,
            default: 'User Avatar'
        },
        small: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        }
    }
}
</script>

