

<template>
  <div ref="chartContainer" style="width: 100%; height: 300px;"></div>
</template>

<script>
import {ref, toRefs, onMounted, watchEffect, onUnmounted} from 'vue';
import * as echarts from 'echarts';


export default {
  name: "HorizontalLineChartElement",
  props: {
    dataSet: {
      type: Array,
      required: true
    },
    color: {
      type: Array,
      required: true
    }

  },
  setup(props) {
    const {color, dataSet} = toRefs(props);
    const chartContainer = ref(null);
    let myChart;
    let resizeObserver = null;

    watchEffect(() => {
      if (!chartContainer.value) return;
      if (!myChart) {
        myChart = echarts.init(chartContainer.value, "transparent", {renderer: "svg"});
      }
      const option = {
        title: {
          text: 'к-сть списань',
          bottom: 0,
          left: "center",
          textStyle: {
            color: '#fff',
            fontSize: 9
          }
        },
        color: color.value,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '3%',
          top:"0%",
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          show: false
        },
        yAxis: {
          type: 'category',
          data: dataSet.value.map(bar => bar.title),
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: color.value,
            fontSize: 16
          }
        },
        series: [
          {
            type: 'bar',
            data: dataSet.value.map(bar=>bar.value)
          }
        ]
      };
      myChart.setOption(option, true);
    });


    const updateChartSize = () => {
      if(myChart)myChart.resize();
    };
    onMounted(() => {
      if (!chartContainer.value) return;
      window.addEventListener('resize', updateChartSize);
      resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          console.log(entry)
          if (myChart) {
            myChart.resize();
          }
        }
      });
      if (chartContainer.value) {
        resizeObserver.observe(chartContainer.value);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateChartSize);
      if (myChart.value) {
        myChart.value.dispose(); // Очищуємо графік при знищенні компонента
      }
    });
    return {chartContainer};
  }
};
</script>

