<template>
  <div v-if="addMedicalReportFlag || selectedReport" @click="addMedicalReportFlag = false; selectedReport = null" class="absolute left-0 top-0 w-full h-full"></div>
  <div v-if="addMedicalReportFlag" class="relative">
    <MedicalReportForm 
      @save="addMedicalReport"
    />
  </div>

  <div v-if="selectedReport" class="relative">
    <MedicalReportForm 
      :report="selectedReport"
      @save="updateMedicalReport"
    />
  </div>

  <div v-if="reportFilesViewFlag" class="relative">
    <FileManager 
      :filesUrls="reportFilesForView"
      :canDelete="false"
      @close="reportFilesViewFlag = false"
    />
  </div>
  <div v-if="deleteFlag" class="relative">
    <DeleteForm 
      @yes="deleteReport"
      @no="deleteFlag = false"
    />
  </div>
  <div class="healthcare-tab">
    <div class="healthcare-tab__top-section">
      <div class="healthcare-tab__block">
        <InputComponent 
          :label="'група крові'" 
          :placeholder="'група крові'" 
          :name="'s'" 
          :value="blood_group"
          @update:modelValue="e => blood_group = e"
        />
        <InputComponent 
          :label="'кров\'яний тиск'" 
          :placeholder="'кров\'яний тиск'" 
          :name="'s'" 
          :value="blood_pressure"
          @update:modelValue="e => blood_pressure = e"
        />
        <ButtonComponent @click="canPlay = false" v-if="canPlay" :positive="true" :small="true">може грати</ButtonComponent>
        <ButtonComponent @click="canPlay = true" v-else :negative="true" :small="true">не може грати</ButtonComponent>
      </div>
      <div class="healthcare-tab__block">
        <InputComponent
          :label="'середній серцевий ритм'"
          :placeholder="'середній серцевий ритм'"
          :name="'s'"
          :value="heart_rate"
          @update:modelValue="e => heart_rate = e"
        />
        <InputComponent
          :label="'кардіозагартованість'"
          :placeholder="'кардіозагартованість'"
          :name="'s'"
          :value="cardio"
          @update:modelValue="e => cardio = e"
        />
        <ButtonComponent @click="canTrain = false" v-if="canTrain" :positive="true" :small="true">може тренуватися</ButtonComponent>
        <ButtonComponent @click="canTrain = true" v-else :negative="true" :small="true">не може тренуватися</ButtonComponent>
      </div>
    </div>
    <div class="healthcare-tab__button-wrapper">
      <ButtonComponent @click="addMedicalReportFlag = true">Додати документ</ButtonComponent>
    </div>

    <TableWrapper>
      <TableComponent :fullWidth="true">
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell :width="40" :value="'#'" />
            <TableHeaderCell :width="64" :value="'дата'" />
            <TableHeaderCell :width="348" :value="'аналіз/довідка/інше'" />
            <TableHeaderCell :width="60" :value="'документ'" />
            <TableHeaderCell :width="194" :value="'лікар'" />
            <TableHeaderCell :width="60" :value="''" />
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="(item, index) in reports" :key="index">
            <TableCell :width="40"  :value="index + 1 + '.'" />
            <TableCell :width="64" :asChild="true">
              <DateComponent :day="item.date" :time="item.time" />
            </TableCell>
            <TableCell :width="348" @click="selectedReport = item" :value="item.document_name" class="cursor-pointer" />
            <TableCell :width="60" :asChild="true">
              <div class="healthcare-tab__document-icon cursor-pointer">
                <img @click="selectRepostFiles(item.documents)" :src="document" alt="document" class="w-[17px] h-[20px]" />
              </div>
            </TableCell>
            <TableCell :width="194" :value="item.doctor" />
            <TableCell :width="60" :asChild="true">
              <div class="flex justify-end">
                <svg @click="() => { reportForDeletion = item; deleteFlag = true }" class="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="8" fill="#DA4167"/>
                <line x1="3.07812" y1="7.625" x2="12.9243" y2="7.625" stroke="white" stroke-width="2"/>
                </svg>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </TableComponent>
    </TableWrapper>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { updatePlayerHealthParamsByStudentId, getPlayerHealthParamsByStudentId, addHealthReportByStudentId, deleteHealthReportByStudentId, updateHealthReportByStudentId } from "@/services/apiRequests";

import MedicalReportForm from "../MedicalReportForm.vue";
import FileManager from "../widgets/FileManager.vue";
import DeleteForm from "../widgets/DeleteForm.vue";

import document from "@/assets/refactored_assets/img/players/document.svg";

export default {
  name: "HealthcareTab",
  props: {
    playerId: {
      type: String,
      required: true,
    },
    updateFlag: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const blood_group = ref('');
    const heart_rate = ref('');
    const blood_pressure = ref('');
    const cardio = ref('');

    const canPlay = ref(false);
    const canTrain = ref(false);

    const addMedicalReportFlag = ref(false);

    const reports = ref([]);

    const reportFilesForView = ref([]);
    const reportFilesViewFlag = ref(false);

    const selectedReport = ref(null);

    const selectRepostFiles = (files) => {
      reportFilesForView.value = files;
      reportFilesViewFlag.value = true;
    };

    const deleteFlag = ref(false);
    const reportForDeletion = ref(null);

    watch(() => props.updateFlag, async () => {
      if (props.updateFlag === false) return;

      await updatePlayerHealthParamsByStudentId(props.playerId, {
        student_id: props.playerId,
        blood_group: blood_group.value,
        average_heart_rate: heart_rate.value,
        blood_pressure: blood_pressure.value,
        cardio_hardening: cardio.value,
        can_play: canPlay.value,
        can_train: canTrain.value,

        reports: reports.value,
      });

      emit('updated');
    });

    const addMedicalReport = async (data) => {
      const report = {
        id: data.id,
        document_name: data.document_name,
        date: data.date,
        time: data.time,
        documents: data.documentsUrls,
        descriptions: data.descriptions,
        doctor: data.doctor
      }
      await addHealthReportByStudentId(props.playerId, report, data.documentsObjects);
      await updateAllReports();
      addMedicalReportFlag.value = false;
    };

    const updateMedicalReport = async (data) => {
      const report = {
        id: data.id,
        document_name: data.document_name,
        date: data.date,
        time: data.time,
        documents: data.documentsUrls,
        descriptions: data.descriptions,
        doctor: data.doctor
      }
      await updateHealthReportByStudentId(props.playerId, report, data.documentsObjects);
      await updateAllReports();
      selectedReport.value = null;
    };

    const deleteReport = async () => {
      await deleteHealthReportByStudentId(props.playerId, reportForDeletion.value.id);
      await updateAllReports();
      deleteFlag.value = false;
    };

    const updateAllReports = async () => {
      const response = await getPlayerHealthParamsByStudentId(props.playerId);

      blood_group.value = response.blood_group || '';
      heart_rate.value = response.average_heart_rate || '';
      blood_pressure.value = response.blood_pressure || '';
      cardio.value = response.cardio_hardening || '';

      reports.value = response.reports || [];

      canPlay.value = response?.can_play;
      canTrain.value = response?.can_train;
    };

    onMounted(async () => {
      await updateAllReports();
    });

    return {
      blood_group,
      heart_rate,
      blood_pressure,
      cardio,
      canPlay,
      canTrain,
      addMedicalReportFlag,
      reports,
      addMedicalReport,
      
      reportFilesForView,
      reportFilesViewFlag,
      selectRepostFiles,
      updateMedicalReport,

      deleteFlag,
      reportForDeletion,
      deleteReport,
      selectedReport
    };
  },

  components: {
    MedicalReportForm,
    FileManager,
    DeleteForm
  },

  data() {
    return {
      document: document,
      data: [
        {
          id: 1,
          date: { day: "06.06-2023", time: "12:00" },
          report: "Загальний аналіз крові",
          document: "",
          doctor: "О.Масловський",
          rating: 4,
        },
      ],
    };
  },
};
</script>
