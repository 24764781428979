<template>
  <div class="finances-section">
    <VerticalTextBlock :text="'Виплачено ЗП'" :textStart="true">
      <CurrencyComponent :value="salary" :smallSign="true" />
    </VerticalTextBlock>

    <VerticalTextBlock :text="'Непрямі виплати'" :textStart="true">
      <CurrencyComponent :value="expenses" :smallSign="true" />
    </VerticalTextBlock>
  </div>
</template>

<script>
export default {
  name: "FinancesHeaderSection",
  props: {
    salary: {
      type: Number,
      required: true,
    },
    expenses: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
