<template>
    <div class="absolute top-0 left-0 w-full h-full" @click="$emit('close')"></div>
    <div
        style="box-shadow: 4px 4px 4px 0px #00000040;"
        class="bg-[#252526] border-[1px] border-[#FFF] border-opacity-10 z-50 ml-[100px] rounded-[10px] w-[312px] px-[17px] py-[27px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="text-[18px] text-center mt-[10px] text-white font-semibold">
            Фільтри елементів
        </div>

        <div class="flex justify-between mt-[20px]">
            <div class="w-[100%]">
                <div class="text-[#8C8C8C] font-medium text-[14px]">
                    скаут
                </div>
                <div class="mt-[3px]">
                    <select v-model="selectedScout" class="w-full h-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 py-[7px] pl-[5px] outline-none">
                        <option class="bg-[#252526] text-white" value="all">всі</option>
                        <option v-for="scout in allScouts" class="bg-[#252526] text-white" :value="scout.scout_id">{{ scout.scout_name + ' ' + scout.scout_surname }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-[20px]">
            <ButtonComponent @click="applyFilter" :fullWidth="true">
                Застосувати
            </ButtonComponent>
            <ButtonComponent @click="resetFilter" class="mt-[10px]" :negative="true" :fullWidth="true">
                Скинути
            </ButtonComponent>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
    name: 'ScoutReportsFiltersForm',    

    props: {
        allScouts: {
            type: Array,
            default: () => []
        }
    },

    setup(props, { emit }) {
        const selectedScout = ref('all')
        
        const applyFilter = () => {
            emit('applyFilter', {
                scout: selectedScout.value
            })
        }

        const resetFilter = () => {
            emit('applyFilter', {
                scout: 'all'
            })
        }

        return {
            selectedScout,
            applyFilter,
            resetFilter
        }
    }
}
</script>

<style>
    
</style>