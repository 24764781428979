<template>
  <div
    class="radar-chart"
    ref="radarChart"
    :style="{ width: chartSize.width, height: chartSize.height }"
  ></div>
</template>
  
<script>
import * as echarts from 'echarts';

export default {
  props: {
    chartData: {
      required: false,
      default: [4200, 3000, 20000, 35000, 50000, 18000]
    },
    max: {
      required: false,
      default: 20000
    },
    chartSize: {
      type: Object,
      required: false,
      default: () => ({
        width: '400px',
        height: '400px'
      })
    },
    indicators: {
      type: Array,
      required: false,
      default: () => [
        { name: 'IQ', max: 20000 },
        { name: 'захист', max: 20000 },
        { name: 'фізика', max: 20000 },
        { name: 'швидкість', max: 20000 },
        { name: 'бачення', max: 20000 },
        { name: 'атака', max: 20000 },
        { name: 'техніка', max: 20000 }
      ]
    }
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.radarChart;
      const myChart = echarts.init(chartDom);
      const currentIndicators = this.indicators.map((indicator) => ({
        ...indicator,
        max: this.max
      }));
      const option = {
          radar: {
              radius: '65%', // уменьшает радиус, чтобы индикаторы были ближе к центру
              indicator: currentIndicators,
              axisName: {
                  textStyle: {
                      fontSize: 10, // размер шрифта меток
                      distance: 5, // отступ текста от ребра
                  }
              }
          },
          series: [
              {
                  name: 'Budget vs spending',
                  type: 'radar',
                  data: [
                      {
                          value: this.chartData,
                          name: 'Allocated Budget',
                      },
                  ],
                  color: ['#FFA500']
              }
          ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', this.updateChartSize(myChart));
    },
    updateChartSize(chart) {
      if (chart) chart.resize();
    }
  }
};
</script>

<style scoped>
/* Здесь можно добавить стили, если нужно */
</style>
