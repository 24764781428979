<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mt-2">
            <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>
            <!-- <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 rounded-[6px] px-[10px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px]" placeholder="Пошук..." type="text">
            </div> -->
            <div class="filter w-[228px]">
                <!-- <button class="bg-[#BD9135] hover:bg-[#a77f2b] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md" @click="$emit('addTrainer')">Додати</button> -->
                <ButtonComponent :fullWidth="true" @click="$emit('addTrainer')">Додати</ButtonComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        setup(props, { emit }) {
            const searchQuery = ref('')

            watch(searchQuery, (newValue) => {
                emit('search', newValue)
            })

            return {
                searchQuery
            }
        }
    }
</script>
