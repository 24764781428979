<template>
    <div ref="echarts" style="width: 400px; height: 210px;"></div>
  </template>
  
  <script>
  import * as echarts from 'echarts';
  
  export default {
    props: {
      chartData: {
        type: Array,
        required: true,
        default: [4200, 3000, 20000, 35000, 50000, 18000]
      }
    },
    data() {
      return {
      };
    },
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        const chartDom = this.$refs.echarts;
        const myChart = echarts.init(chartDom);
        const option =  {
            radar: {
                // shape: 'circle',
                indicator: [
                    { name: 'IQ', max: 5 },
                    { name: 'захист', max: 5 },
                    { name: 'фізика', max: 5 },
                    { name: 'швидкість', max: 5 },
                    { name: 'бачення', max: 5 },
                    { name: 'атака', max: 5 },
                    { name: 'техніка', max: 5 },
                ]
            },
            series: [
                {
                    name: 'Budget vs spending',
                    type: 'radar',
                    data: [
                        {
                            value: this.chartData,
                            name: 'Allocated Budget',
                        },
                    ],
                    color: ['#FFA500']
                }
            ]
        };
        myChart.setOption(option);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Здесь можно добавить стили, если нужно */
  </style>
  