<template>
    <button 
        @click="onClick" 
        :class="[
            'button-component', 
            { 'button-component--outlined': outlined }, 
            { 'button-component--negative': negative }, 
            { 'button-component--positive': positive },
            { 'button-component--small': small},
            { 'button-component--full': fullWidth},
            { 'button-component--gray': gray}
        ]"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',
    props: {
        onClick: {
            type: Function,
            required: true
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        negative: {
            type: Boolean,
            default: false
        },
        positive: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        gray: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default:false,
        }
    }
}
</script>