<template>
  <td
    :class="[
      'table__cell',
      { 'table__cell--text-start': textStart },
      { 'table__cell--text-end': textEnd },
      { 'table__cell--border-none': borderNone },
      { 'table__cell--font-primary': fontPrimary },
    ]"
    :style="{
      minWidth: width ? `${width}px` : 'auto',
      maxWidth: width ? `${width}px` : 'none',
      textAlign: textAlign,
    }"
  >
    <slot v-if="asChild"></slot>
    <span v-else>{{ value }}</span>
  </td>
</template>

<script>
export default {
  name: "TableCell",
  props: {
    value: {
      type: String,
      required: false,
    },
    asChild: {
      type: Boolean,
      default: false,
    },
    textStart: {
      type: Boolean,
      default: false,
    },
    textEnd: {
      type: Boolean,
      default: false,
    },
    borderNone: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: false,
    },
    fontPrimary: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "center",
    },
  },
  components: {},
  data() {
    return {};
  },
};
</script>
