<template>
    <div class="px-[60px]">
        <div class="flex justify-end pr-[12px]">
            <div v-if="!loading" class="rounded-[5px] flex justify-between bg-[#8FC408] font-semibold pb-[2px] pt-[7px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Всі зміни збережено
                </div>
                <div>
                    ✓
                </div>
            </div>
            <div v-if="loading" class="rounded-[5px] flex justify-between bg-[#DA4167] font-semibold py-[2px] px-[20px] w-[228px]">
                <div class="text-[16px]">
                    Збереження...
                </div>
            </div>  
        </div>

        <TableWrapper>
            <TableComponent >
                <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell class="text-start" minWidth="200px" value="Ліга" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="Сезон" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="Команда" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="Лого" />
                    <TableHeaderCell class="text-start" minWidth="200px" value="" />
                </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    <LeagueBlock 
                        v-for="league in leaguesAndTeams"
                        @changeLeagueName="changeLeagueName" 
                        @changeLeagueLogo="changeLeagueLogo"
                        @addNewTeam="addTeamToLeague"
                        @changeOldTeamLogo="changeOldTeamLogo"
                        @changeTeamName="changeTeamName"
                        @deleteTeam="deleteTeam"
                        @deleteLeague="deleteLeague"

                        @addNewSeason="addSeasonToLeague"

                        @updateSeasonName="updateSeasonName"

                        @deleteSeason="deleteSeason"
                        :league="league"
                    />
                    <TableRow>
                        <TableCell textAlign="start" :asChild="true">
                            <input v-model="newLeagueName" type="text" placeholder="Назва ліги">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            
                            <img v-if="newLeagueLogo" @click="addLogoToLeague" :src="newLeagueLogo" alt="team logo" class="w-[20px] cursor-pointer" />
                            <img v-else @click="addLogoToLeague" src="@/assets/icons/upload_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            <input ref="newLeagueLogoAdd" type="file" @change="proccessNewLeagueLogo" class="hidden" />

                        </TableCell>
                        <TableCell textAlign="start" :asChild="true">
                            
                            <div class="flex justify-end gap-[10px]">
                                <!-- <img src="@/assets/icons/pen_icon.svg" alt="team logo" class="w-[20px]" /> -->
                                <img @click="addLeague" src="@/assets/icons/green_check_point.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                                <img @click="deleteNewLeagueInfo" src="@/assets/icons/trash_icon.svg" alt="team logo" class="w-[20px] cursor-pointer" />
                            </div>

                        </TableCell>
                    </TableRow>
                </TableBody>
            </TableComponent>
        </TableWrapper>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { addLeagueMatchCenter, getAllLeagues, updateLeagueById, updateTeamName, updateSeasonNameRequest, deleteLeagueById, addTeamToLeagueRequest, getAllTeams, deleteTeamById, updateTeamMatchCenter } from '@/services/apiRequests.js';
import LeagueBlock from './components/LeagueBlock.vue';

export default {
    name: 'Teams',    

    setup() {
        const leaguesAndTeams = ref([])

        const loading = ref(false)

        const newLeagueName = ref('')
        const newTeamName = ref('')

        const newLeagueLogoAdd = ref(null)

        const newLeagueLogo = ref(null)
        const newLeagueLogoObject = ref(null)

        const updateSeasonName = async (ctx) => {
            const leagueId = ctx.leagueId
            const seasonOldName = ctx.oldName
            const seasonNewName = ctx.newName

            loading.value = true

            await updateSeasonNameRequest(leagueId, seasonOldName, seasonNewName)

            leaguesAndTeams.value.forEach(league => {
                if (league.id === leagueId) {
                    league.seasons.forEach(seas => {
                        if (seas.name === seasonOldName) {
                            seas.name = seasonNewName
                        }
                    })
                }
            })

            loading.value = false
        }

        const addLeague = async () => {
            loading.value = true
            const newLeagueResponse = await addLeagueMatchCenter(newLeagueName.value, newLeagueLogoObject.value)

            leaguesAndTeams.value.push({
                id: newLeagueResponse.league_id,
                name: newLeagueResponse.league_name,
                logo: process.env.VUE_APP_API_URL + 'static/' + newLeagueResponse.league_logo,
                fullView: false,
                
                seasons: []
            })
            loading.value = false

            newLeagueName.value = ''
            newLeagueLogo.value = null
            newLeagueLogoObject.value = null
        }

        const addSeasonToLeague = (ctx) => {
            const leagueId = ctx.leagueId
            const season = ctx.name

            leaguesAndTeams.value.forEach(league => {
                if (league.id === leagueId) {
                    league.seasons.push({
                        name: season,
                        teams: []
                    })
                }
            })
        }

        const addTeamToLeague = async (teamObj) => {
            loading.value = true

            const newTeamResponse = await addTeamToLeagueRequest(teamObj.name, teamObj.logoObj, teamObj.season, teamObj.leagueId)

            leaguesAndTeams.value.forEach(league => {
                if (league.id === teamObj.leagueId) {
                    league.seasons.forEach(season => {
                        if (season.name === teamObj.season) {
                            season.teams.push({
                                id: newTeamResponse.team_id,
                                name: newTeamResponse.team_name,
                                logo: process.env.VUE_APP_API_URL + 'static/' + newTeamResponse.team_logo
                            })
                        }
                    })
                }
            })
            loading.value = false
        }

        const addLogoToLeague = () => {
            newLeagueLogoAdd.value.click()
        }

        const deleteNewLeagueInfo = () => {
            newLeagueName.value = ''
            newLeagueLogo.value = null
            newLeagueLogoObject.value = null
        }

        const proccessNewLeagueLogo = () => {
            const file = newLeagueLogoAdd.value.files[0]
            newLeagueLogoObject.value = file
            const reader = new FileReader()
            reader.onload = () => {
                newLeagueLogo.value = reader.result
            }
            reader.readAsDataURL(file)
        }

        const changeTeamLogo = (ctx) => {
            const teamId = ctx.teamId
            const logo = ctx.logo

            leaguesAndTeams.value.forEach(league => {
                league.teams.forEach(team => {
                    if (team.id === teamId) {
                        team.logo = logo
                    }
                })
            })
        }

        const changeOldTeamLogo = async (ctx) => {
            const teamId = ctx.teamId
            const logo = ctx.logo
            const name = ctx.name

            loading.value = true

            console.log('teamId ------ ', teamId)
            console.log('logo ------ ', logo)
            console.log('name ------ ', name)

            await updateTeamMatchCenter(teamId, name, logo)

            await reloadLeaguesAndTeams()
        }

        const changeTeamName = async (ctx) => {
            const teamId = ctx.teamId
            const leagueId = ctx.leagueId
            const name = ctx.value

            leaguesAndTeams.value.forEach(league => {
                if (league.id == leagueId) {
                    league.seasons.forEach(season => {
                        season.teams.forEach(team => {
                            if (team.id === teamId) {
                                team.name = name
                            }
                        })
                    })
                }
            })

            await updateTeamName(teamId, name)
        }

        const changeLeagueLogo = async (ctx) => {
            const leagueId = ctx.leagueId
            const logo = ctx.logo
            const name = ctx.name

            await updateLeagueById(leagueId, name, logo)
            await reloadLeaguesAndTeams()
        }

        const deleteTeam = async (id) => {
            leaguesAndTeams.value.forEach(league => {
                league.seasons.forEach(season => {
                    season.teams = season.teams.filter(team => team.id !== id)
                })
            })

            loading.value = true
            await deleteTeamById(id)
            loading.value = false
        }

        const deleteLeague = async (id) => {
            leaguesAndTeams.value = leaguesAndTeams.value.filter(league => league.id !== id)

            loading.value = true
            await deleteLeagueById(id)
            loading.value = false
        }

        const changeLeagueName = async (ctx) => {
            const leagueId = ctx.leagueId
            const name = ctx.value

            console.log('changeLeagueName ------ ', ctx)

            leaguesAndTeams.value.forEach(league => {
                if (league.id === leagueId) {
                    league.name = name
                }
            })

            loading.value = true
            await updateLeagueById(leagueId, name, )
            loading.value = false
        }

        const reloadLeaguesAndTeams = async () => {
            loading.value = true
            const leagues = await getAllLeagues()
            const teams = await getAllTeams()

            console.log('leagues ------ ', leagues)
            console.log('teams ------ ', teams)

            const apiURL = process.env.VUE_APP_API_URL
            leaguesAndTeams.value = leagues.map(league => {
                const leagueTeams = teams.filter(team => team.league_id === league.league_id)

                let allSeasons = leagueTeams.map(team => team.season)
                allSeasons = [...new Set(allSeasons)]

                console.log('allSeasons ------ ', leagueTeams)

                return {
                    id: league.league_id,
                    name: league.league_name,
                    logo: apiURL + 'static/' + league.league_logo,
                    fullView: false,
                    seasons: [
                        ...allSeasons.map(season => {
                            return {
                                name: season,
                                teams: leagueTeams.filter(team => team.season === season).map(team => {
                                    return {
                                        id: team.team_id,
                                        name: team.team_name,
                                        logo: apiURL + 'static/' + team.team_logo
                                    }
                                })
                            }
                        })
                    ],
                }
            })
            loading.value = false
        }

        onMounted(async () => {
            loading.value = true
            const leagues = await getAllLeagues()
            const teams = await getAllTeams()

            console.log('leagues ------ ', leagues)
            console.log('teams ------ ', teams)

            const apiURL = process.env.VUE_APP_API_URL
            leaguesAndTeams.value = leagues.map(league => {
                const leagueTeams = teams.filter(team => team.league_id === league.league_id)

                let allSeasons = leagueTeams.map(team => team.season)
                allSeasons = [...new Set(allSeasons)]

                console.log('allSeasons ------ ', leagueTeams)

                return {
                    id: league.league_id,
                    name: league.league_name,
                    logo: apiURL + 'static/' + league.league_logo,
                    fullView: false,
                    seasons: [
                        ...allSeasons.map(season => {
                            return {
                                name: season,
                                teams: leagueTeams.filter(team => team.season === season).map(team => {
                                    return {
                                        id: team.team_id,
                                        name: team.team_name,
                                        logo: apiURL + 'static/' + team.team_logo
                                    }
                                })
                            }
                        })
                    ],
                }
            })
            loading.value = false
        })

        const deleteSeason = async (ctx) => {
            const leagueId = ctx.leagueId
            const season = ctx.season

            const allTeamsInSeason = leaguesAndTeams.value.find(league => league.id === leagueId).seasons.find(seas => seas.name === season).teams

            loading.value = true

            allTeamsInSeason.forEach(async team => {
                await deleteTeamById(team.id)
            })

            leaguesAndTeams.value.forEach(league => {
                if (league.id === leagueId) {
                    league.seasons = league.seasons.filter(seas => seas.name !== season)
                }
            })

            loading.value = false
        }

        return {
            leaguesAndTeams,
            newLeagueName,
            newTeamName,
            newLeagueLogo,
            addLeague,
            newLeagueLogoAdd,
            addLogoToLeague,
            proccessNewLeagueLogo,
            changeTeamLogo,
            changeTeamName,
            deleteTeam,
            addTeamToLeague,
            deleteLeague,
            changeLeagueName,
            deleteNewLeagueInfo,
            addSeasonToLeague,
            deleteSeason,
            changeOldTeamLogo,
            reloadLeaguesAndTeams,
            changeLeagueLogo,
            updateSeasonName,
            loading
        }
    },
    
    components: {
        LeagueBlock
    }
}
</script>

<style>
    
</style>