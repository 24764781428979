<template>
  <div class="main__second-line second-line">
    <div class="second-line__item" v-for="(metric, index) in metricsData" :key="index">
      <h3 class="second-line__title">{{ metric.name }}</h3>
      <div class="second-line__value">
        <span>{{ metric.isShowUAH ? "₴" : "" }}</span> <span>{{ metric.number.toLocaleString() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, watch} from 'vue';
export default {
  name: "GreenGridMetrics",
  props: {
    metricsData: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const { metricsData } = toRefs(props);
    watch(metricsData, (newVal) => {
      console.log('Змінено metricsData:', newVal);
    }, { deep: true });
  }
};
</script>

