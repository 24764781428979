<template>
<button @click="onClick" :style="{backgroundColor: backgroundColor}" :class="['icon-button', fullWidth ? 'icon-button--full' : '']"><img :src="src"/></button>
</template>

<script>
export default {
    name: 'IconButton',
    props: {
        onClick: {
            type: Function,
            required: true
        },
        src: {
            required: true,
            type: String
        },
        backgroundColor: {
            type: String,
            required:false,
        },
        fullWidth: {
            type:Boolean,
            default:false,
        },
    }
}
</script>
